import React, { useEffect, useState } from 'react';

import { MBProMandatesAPI } from '@meilleursbiens/api';
import { MBProMandate, MBProMandateCreateRequest } from '@meilleursbiens/types';
import {
  Button,
  DateInput,
  FileInput,
  Section,
  SwitchInput,
  TextAreaInput,
  TextInput,
  fadeInRight,
  fadeInUp,
} from '@meilleursbiens/ui';

import { Alert, AlertIcon, Box, Divider, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

import { FormikValues, useFormik } from 'formik';
import moment from 'moment/moment';
import * as Yup from 'yup';

interface MBProMandatesCreateFormPricesComponentProps {
  mandate: MBProMandateCreateRequest;
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  isPaper?: boolean;
  mandateDatas?: MBProMandate;
}

const ValidationSchemaSell = Yup.object().shape({
  special_clause: Yup.string().required('Les clauses spéciales est requise !'),
  sales_actions_to_achieve: Yup.string().required('Les actions de vente à réaliser sont requises !'),
  sales_actions_own_expenses: Yup.string().required('Les dépenses engagées sont requises !'),
  sales_actions_periodicity: Yup.string().required('La périodicité des retours au client est requise !'),
  signature_place: Yup.string().required('Le lieu de signature est requis !'),
  signature_date: Yup.string().required('La date de signature est requise !'),
});

const ValidationSchemaRent = Yup.object().shape({
  special_clause: Yup.string().required('Les clauses spéciales est requise !'),
  //rent_duration: Yup.string().required('La durée de location est requise !'),
  signature_place: Yup.string().required('Le lieu de signature est requis !'),
  signature_date: Yup.string().required('La date de signature est requise !'),
});

const ValidationSchemaSearch = Yup.object().shape({
  special_clause: Yup.string().required('Les clauses spéciales est requise !'),
  signature_place: Yup.string().required('Le lieu de signature est requis !'),
  signature_date: Yup.string().required('La date de signature est requise !'),
});

const ValidationSchemaPaper = Yup.object().shape({
  mandate_file: Yup.mixed().required('Le mandat est requis !'),
});

const INITIAL_VALUES_SELL = {
  special_clause: '',
  sales_actions_to_achieve: '',
  sales_actions_own_expenses: '',
  sales_actions_periodicity: '',
  signature_place: '',
  signature_date: moment().format('DD/MM/YYYY'),
} as FormikValues;

const INITIAL_VALUES_RENT = {
  special_clause: '',
  //rent_duration: '',
  signature_place: '',
  signature_date: moment().format('DD/MM/YYYY'),
} as FormikValues;

const INITIAL_VALUES_SEARCH = {
  special_clause: '',
  signature_place: '',
  signature_date: moment().format('DD/MM/YYYY'),
} as FormikValues;

const INITIAL_VALUES_PAPER = {
  mandate_file: null,
  is_mb_template: true,
} as FormikValues;

export default function (props: MBProMandatesCreateFormPricesComponentProps) {
  const { mandate, isEdit, isPaper, mandateDatas } = props;

  const INITIAL_VALUES_EDIT = {
    ...mandateDatas,
    signature_date: moment(mandateDatas?.signature_date).format('DD/MM/YYYY'),
  } as FormikValues;

  const [isLoadingPrecomplete, setIsLoadingPrecomplete] = useState(false);
  const [isPrecomplete, setIsPrecomplete] = useState(false);

  const _getValidationSchema = () => {
    if (isPaper) {
      return ValidationSchemaPaper;
    } else if (mandate.is_sell) {
      return ValidationSchemaSell;
    } else if (mandate.is_rent) {
      return ValidationSchemaRent;
    } else if (mandate.is_search) {
      return ValidationSchemaSearch;
    }
  };

  const _getInitialValues = () => {
    if (isPaper) {
      return INITIAL_VALUES_PAPER;
    } else if (isEdit) {
      return INITIAL_VALUES_EDIT;
    } else if (mandate.is_sell) {
      return INITIAL_VALUES_SELL;
    } else if (mandate.is_rent) {
      return INITIAL_VALUES_RENT;
    } else if (mandate.is_search) {
      return INITIAL_VALUES_SEARCH;
    } else {
      return {};
    }
  };

  const formik = useFormik({
    initialValues: _getInitialValues(),
    validationSchema: _getValidationSchema(),
    onSubmit: (values) => {
      console.log('onSubmit', values);
      props.onSubmit(formik.values);
    },
  });

  const _getMandateDuration = () => {
    if (mandate.is_rent) {
      if (formik.values.date_rent_start && formik.values.date_rent_end) {
        const start = moment(formik.values.date_rent_start, 'DD/MM/YYYY');
        const end = moment(formik.values.date_rent_end, 'DD/MM/YYYY');
        const duration = end.diff(start, 'days');
        formik.setFieldValue('mandate_duration', duration);
      }
    }
  };

  const _preloadMandate = async () => {
    setIsLoadingPrecomplete(true);
    setIsPrecomplete(true);
    MBProMandatesAPI.getMandateCreatePrecompletion(mandate['mandate_type'])
      .then((response) => {
        formik.setValues({
          ...formik.values,
          ...response,
        });
      })
      .finally(() => {
        setIsLoadingPrecomplete(false);
      });
  };

  useEffect(() => {
    _getMandateDuration();
  }, []);

  useEffect(() => {
    formik.setValues(_getInitialValues());
  }, [mandateDatas]);

  // Formating date_start and date_end to calculate mandate_duration
  useEffect(() => {
    _getMandateDuration();
  }, [formik.values.date_rent_start, formik.values.date_rent_end]);

  if (isPaper) {
    return (
      <Section
        size={'xs'}
        title={'Importez votre mandat'}
        description={'Importez votre mandat rempli et signé au format PDF'}
        mb={4}
      >
        <Box as={'form'} id="form-mandate" onSubmit={formik.handleSubmit} animation={`${fadeInRight} 0.2s ease`} pb={5}>
          <FileInput
            isRequired
            maxFileSize={25}
            accept={{
              'application/pdf': ['.pdf'],
            }}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            handleBlur={formik.handleBlur}
            label={''}
            name={'mandate_file'}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
          />
        </Box>
      </Section>
    );
  }

  return (
    <Box as={'form'} id="form-mandate" onSubmit={formik.handleSubmit} animation={`${fadeInRight} 0.2s ease`} pb={5}>
      {!isPrecomplete && (
        <Alert mb={4} animation={`${fadeInUp} 0.2s ease`} status="info">
          <AlertIcon />
          <VStack alignItems={'flex-start'}>
            <Text fontSize={'xs'}>
              Vous pouvez <b>pré-charger les informations à partir de vos anciens mandats</b> pour gagner du temps en
              cliquant-ici
            </Text>
            <Button size={'xs'} leftIcon={ArrowDownTrayIcon} onClick={_preloadMandate} isLoading={isLoadingPrecomplete}>
              Pré-charger les informations
            </Button>
          </VStack>
        </Alert>
      )}

      {mandate.is_sell && <SellInputs formik={formik} />}
      {/*      {mandate.is_rent && <RentInputs formik={formik} />} */}
      {mandate.is_search && <SearchInputs formik={formik} />}

      <TextAreaInput
        label={'Clauses spéciales'}
        name={'special_clause'}
        helperText={
          'Les clauses spéciales sont des informations supplémentaires que vous souhaitez ajouter à ce mandat, elles peuvent être des informations importantes pour le client.'
        }
        placeholder={'ex: Tout refus de visite doit être justifié par écrit, etc...'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired={true}
      />

      {mandate.is_rent && (
        <>
          <Spacer my={4} />

          <TextAreaInput
            label={'Conditions particulières du bail'}
            name={'rent_term'}
            helperText={`Les conditions particulières du bail permettent d'adapter le bail aux besoins spécifiques du bailleur ou du locataire tout en respectant la législation en vigueur.`}
            placeholder={'ex: Le locataire s’engage à ne pas sous-louer le bien, etc...'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
          />
        </>
      )}

      <Section
        size={'xs'}
        title={'Finalisation'}
        description={
          'Définissez les informations de finalisation du mandat, vous pouvez saisir la date et le lieu de signature.'
        }
        my={4}
      >
        <SimpleGrid columns={[1, 2]} spacing={4}>
          <TextInput
            label={'Ville de signature'}
            name={'signature_place'}
            placeholder={'ex: Paris 15e'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
          <DateInput
            label={'Date de signature'}
            name={'signature_date'}
            minDate={moment(mandateDatas?.created_at).format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
        </SimpleGrid>
      </Section>
    </Box>
  );
}

function SellInputs({ formik }: { formik: any }) {
  return (
    <>
      <TextAreaInput
        label={'Actions de vente à réaliser'}
        name={'sales_actions_to_achieve'}
        helperText={
          'Les actions de vente à réaliser sont les actions que vous vous engagez à réaliser pour vendre le bien.'
        }
        placeholder={"ex: Réaliser des visites, faire des photos, diffuser l'annonce sur LeBonCoin, BienIci, SeLoger"}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      <Spacer my={4} />

      <TextAreaInput
        label={'Actions à la charge du mandataire'}
        name={'sales_actions_own_expenses'}
        helperText={
          'Les actions à la charge du mandataire sont les dépenses que vous vous engagez à réaliser pour vendre le bien.'
        }
        placeholder={'ex: Frais de publicité, frais de déplacement, frais de notaire'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      <Spacer my={4} />

      <TextAreaInput
        label={'Périodicité des retours au client'}
        name={'sales_actions_periodicity'}
        helperText={
          'La périodicité des retours au client est la fréquence à laquelle vous vous engagez à informer le client sur l’avancement de la vente.'
        }
        placeholder={'ex: Tous les 15 jours'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      <Spacer my={4} />
    </>
  );
}

function RentInputs({ formik }: { formik: any }) {
  return <></>;
}

function SearchInputs({ formik }: { formik: any }) {
  return <></>;
}
