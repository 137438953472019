import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';

const variantSolid = defineStyle((props) => {
  const { colorScheme: c } = props;

  const color = `${c}.500`;
  const hoverColor = `${c}.600`;

  return {
    borderRadius: 6,
    borderWidth: '1px',
    borderColor: color,
    boxShadow: 'xs',
    bg: color,
    color: 'white',
    letterSpacing: '-0.02em',
    _hover: {
      borderColor: hoverColor,
      bg: hoverColor,
    },
    _focus: {
      boxShadow: '0px 0px 0px 2px #ffd5da',
    },
    _dark: {
      boxShadow: 'dark-xs',

      _focus: {
        boxShadow: '0px 0px 0px 2px #6e383e',
      },
    },
  };
});

const variantPrimary = {
  background: 'red.500',
  color: 'white',
  border: '2px solid rgba(10, 13, 18, 0.18)',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.02) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.02)',
  _hover: {
    background: '#c3261b',
    color: 'white',
    border: '2px solid rgba(10, 13, 18, 0.18)',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.02) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.02)',
  },
  _dark: {
    background: '#c1281d',
    color: '#ffcccc',
    border: '2px solid rgba(10, 13, 18, 0.18)',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.05) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.02) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.02)',
  }
};

const variantSecondary = defineStyle({
  background: '#fff',
  color: '#414651',
  borderWidth: 1,
  borderColor: '#e2e4e6',
  boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
  _hover: {
    background: '#FAFAFA',
    color: '#414651',
    borderWidth: 1,
    borderColor: '#e2e4e6',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
  },
  _dark: {
    background: '#1e1e1e',
    color: '#dcdcdc',
    borderWidth: 1,
    borderColor: '#171717',
    boxShadow: '0px 0px 0px 1px rgba(255, 255, 255, 0.12) inset, 0px -2px 0px 0px rgba(10, 13, 18, 0.05) inset, 0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
  },
});


const variantGhost = defineStyle((props) => {
  return {
    _checked: {
      bg: 'blackAlpha.100',
    },
    _dark: {
      _checked: {
        bg: 'whiteAlpha.100',
      },
    },
  };
});


const variantTertiary = defineStyle({
  borderWidth: '1px',
  borderColor: 'red.50',
  bg: 'red.50',
  color: 'red.500',
  _hover: {
    borderColor: 'red.50',
  },
  _focus: {
    boxShadow: '0px 0px 0px 2px #eedfe0',
  },
  _dark: {
    borderColor: 'red.500',
    bg: 'red.800',
    color: 'red.50',
    _hover: {
      borderColor: 'gray.600',
    },
    _focus: {
      boxShadow: '0px 0px 0px 2px #eedfe0',
    },
  },
});

const sizes = {
  xl: defineStyle({
    h: '2.7rem',
    minW: '2.8rem',
    fontSize: '0.9rem',
    px: '5',
  }),
  lg: defineStyle({
    h: '2.5rem',
    minW: '2.6rem',
    fontSize: '0.85rem',
    px: '4',
  }),
  md: defineStyle({
    h: '2.1rem',
    minW: '2.5rem',
    fontSize: '0.8rem',
    px: '3',
  }),
  sm: defineStyle({
    h: '1.90rem',
    minW: '2.3rem',
    fontSize: '0.78rem',
    px: '2',
  }),
  xs: defineStyle({
    h: '1.65rem',
    minW: '2.2rem',
    fontSize: '0.7rem',
    px: '2',
  }),
};

export default defineStyleConfig({
  defaultProps: {
    size: 'md',
    variant: 'secondary',
  },
  baseStyle: {
    fontWeight: 600,
    borderRadius: '0.5rem',
    transition: 'all 0.05s ease-in-out',
    _active: {
      transform: 'scale(0.99)',
    },
  },
  variants: {
    solid: variantSolid,
    outline: variantSecondary,
    ghost: variantGhost,
    primary: variantPrimary,
    secondary: variantSecondary,
    tertiary: variantTertiary,
  },
  sizes,
});
