import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { Input } from '@chakra-ui/react';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface PriceInputProps extends TextInputProps {
  min?: number;
  max?: number;
  step?: number;
}

const PriceInput = (props: PriceInputProps) => {
  const [currentValue, setCurrentValue] = useState(1);

  const formatCurrencyByEnd = (value: any) => {
    if (!Number(value)) return '0.00 €';

    const amount = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(parseFloat(value) / 100);

    return `${amount}`;
  };

  useEffect(() => {
    setCurrentValue(parseFloat(props.values[props.name]));
  }, []);

  useEffect(() => {
    setCurrentValue(parseFloat(props.values[props.name]));
  }, [props.values[props.name]]);

  useEffect(() => {
    if (props.values[props.name] !== currentValue) {
      setCurrentValue(parseFloat(props.values[props.name]));
    }
  }, [props.values[props.name], currentValue]);

  return (
    <TextInput
      type="hidden"
      customInput={
        <NumberFormat
          format={formatCurrencyByEnd}
          suffix=" €"
          name={props.name}
          thousandSeparator=" "
          decimalSeparator=","
          allowNegative={false}
          fixedDecimalScale
          decimalScale={2}
          mask={'_'}
          inputMode="numeric"
          displayType="input"
          customInput={Input}
          value={!currentValue || !Number(currentValue) ? 0 : Number(currentValue) * 100}
          isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            // @ts-ignore
            return formattedValue === '' || floatValue / 100 <= 10000000000;
          }}
          onValueChange={(values) => {
            // @ts-ignore
            setCurrentValue((parseFloat(values.value) / 100).toFixed(2));
            // @ts-ignore
            props.handleChange({
              // @ts-ignore
              target: {
                name: props.name,
                // @ts-ignore
                value: parseFloat(values.value) / 100,
              },
            });
          }}
          isRequired={props.isRequired}
        />
      }
      {...props}
      name={props.name + '_price'}
    />
  );
};

export default PriceInput;
