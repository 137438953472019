import { MBProClient, MBProClientOrigin, MBProClientStatus, MBProMandate, PaginationAPI } from '@meilleursbiens/types';

import { MBApi } from '../../../../../index';
import MBProAPI from '../../../../../MBApi';

export default class MBProCRMClientsAPI {
  static list() {
    return MBApi.url('/v1/mbpro/clients/list').needAuth().get<{
      clients: MBProClient[];
    }>();
  }

  static listPagination(rows: number, page: number, query: string, sortBy = 'id', sortOrder = 'asc') {
    return MBProAPI.url(
      '/v2/mbpro/clients/list' +
      query +
      '&page=' +
      page +
      '&pageSize=' +
      rows +
      '&sort_by=' +
      sortBy +
      '&sort_order=' +
      sortOrder
    )
      .needAuth()
      .get<PaginationAPI<MBProClient>>();
  }

  static search(query: string) {
    return MBApi.url('/v1/mbpro/clients/search')
      .parameters({
        query,
      })
      .needAuth()
      .get<MBProClient[]>();
  }

  static createContact(values: any) {
    return MBApi.url('/v1/mbpro/clients')
      .parameters(values)
      .needAuth()
      .needCache(0, null, {
        'MBProContactsAPI.listContact': 'delete',
        'MBProDashboardAPI.getSidebarCounts': 'delete',
      })
      .put<{
        client: MBProClient;
      }>();
  }

  static editClient(id: number | string, type: string, value: any) {
    return MBApi.url('/v3/mbpro/clients/edit/' + id)
      .parameters({
        type,
        value,
      })
      .needAuth()
      .needCache(0, null, {
        'MBProContactsAPI.listContact': 'delete',
      })
      .post<MBProClient>();
  }

  static importCSV(csv: File) {
    const formData = new FormData();
    formData.append('import_csv', csv);

    return MBApi.url('/v1/mbpro/clients/import').contentFormData().formData(formData).needAuth().postUpload<{
      success: number;
      errors: number;
    }>();
  }

  static exportToXLSX() {
    return MBApi.url('/v1/mbpro/clients/export').needAuth().get<string>();
  }

  static listOrigins() {
    return MBApi.url('/v1/mbpro/clients/origin/list').needAuth().needCache(30).get<{ origins: MBProClientOrigin[] }>();
  }

  static listStatus() {
    return MBApi.url('/v1/mbpro/clients/status/list').needAuth().needCache(30).get<{ statuses: MBProClientStatus[] }>();
  }

  static setStatus(id: number, status_id: number) {
    return MBApi.url('/v1/mbpro/clients/edit/status/' + id)
      .parameters({
        status: status_id,
      })
      .needAuth()
      .needCache(0, null, {
        'MBProContactsAPI.listContact': 'delete',
      })
      .post();
  }
}
