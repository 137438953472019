import * as React from 'react';
import { SVGProps } from 'react';

export default function (props: SVGProps<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      fill="none"
      viewBox="0 0 72 74"
      {...props}
    >
      <path
        fill="url(#paint0_linear_0_10616)"
        fillRule="evenodd"
        d="M53 62a2 2 0 1 1 0 4H35q-.177 0-.345-.03a29.86 29.86 0 0 1-17.079-6.292c-.387-.301-.97-.8-1.401-1.177A2.03 2.03 0 0 0 14.844 58H10a2 2 0 1 1 0-4h2a2 2 0 1 0 0-4H7a2 2 0 1 1 0-4h1a2 2 0 1 0 0-4c-.809 0-1.5-.583-1.635-1.38-.049-.29-.088-.523-.094-.57A30 30 0 0 1 6 36C6 19.432 19.432 6 36 6h15a2 2 0 1 1 0 4h-3a2 2 0 1 0 0 4h12a2 2 0 0 1 .002 4H57a2 2 0 1 0 0 4h4.306c.756 0 1.45.426 1.778 1.108.197.41.404.851.54 1.17A29.9 29.9 0 0 1 66 36a30 30 0 0 1-.246 3.857c-.027.212-.069.485-.114.764C65.51 41.42 64.81 42 64 42a2 2 0 1 0 0 4h1a2 2 0 1 1 0 4h-1.284c-.726 0-1.394.394-1.756 1.023-.236.409-.498.853-.693 1.156a30 30 0 0 1-3.08 4.014c-.313.343-.819.851-1.213 1.241a1.97 1.97 0 0 1-1.387.566H50a2 2 0 0 0-.023 4z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#0298F3"
        fillRule="evenodd"
        d="M32.473 13.562a6 6 0 0 1 7.054 0l16.723 12.15a6 6 0 0 1 2.18 6.708l-6.388 19.66a6 6 0 0 1-5.707 4.145h-20.67a6 6 0 0 1-5.707-4.145l-6.387-19.66a6 6 0 0 1 2.18-6.708z"
        clipRule="evenodd"
        opacity="0.1"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <g filter="url(#filter0_d_0_10616)">
        <path
          fill="#7F45F6"
          fillRule="evenodd"
          d="M28 36h16v32.336a1 1 0 0 1-1.47.883L36 65.75l-6.53 3.47a1 1 0 0 1-1.47-.884z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask0_0_10616"
        width="16"
        height="34"
        x="28"
        y="36"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28 36h16v32.336a1 1 0 0 1-1.47.883L36 65.75l-6.53 3.47a1 1 0 0 1-1.47-.884z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_0_10616)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M32 52h8v18h-8z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#0298F3"
        fillRule="evenodd"
        d="M33.649 16.708a4 4 0 0 1 4.702 0l17.172 12.477a4 4 0 0 1 1.453 4.472l-6.559 20.187a4 4 0 0 1-3.804 2.764H25.387a4 4 0 0 1-3.804-2.764l-6.56-20.187a4 4 0 0 1 1.454-4.472z"
        clipRule="evenodd"
        opacity="0.2"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <path
        fill="url(#paint1_linear_0_10616)"
        fillRule="evenodd"
        stroke="#FEFFFF"
        strokeOpacity="0.2"
        d="M33.649 14.708a4 4 0 0 1 4.702 0l17.172 12.476a4 4 0 0 1 1.453 4.473l-6.559 20.187a4 4 0 0 1-3.804 2.763H25.387a4 4 0 0 1-3.804-2.764l-6.56-20.187a4 4 0 0 1 1.454-4.472z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask1_0_10616"
        width="44"
        height="43"
        x="14"
        y="13"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M33.649 14.708a4 4 0 0 1 4.702 0l17.172 12.476a4 4 0 0 1 1.453 4.473l-6.559 20.187a4 4 0 0 1-3.804 2.763H25.387a4 4 0 0 1-3.804-2.764l-6.56-20.187a4 4 0 0 1 1.454-4.472z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g strokeLinecap="round" mask="url(#mask1_0_10616)">
        <path
          stroke="#94B4BD"
          d="M38.057 15.113a3.5 3.5 0 0 0-4.114 0L16.77 27.589a3.5 3.5 0 0 0-1.272 3.913l6.56 20.187a3.5 3.5 0 0 0 3.328 2.419h21.226a3.5 3.5 0 0 0 3.329-2.419L56.5 31.502a3.5 3.5 0 0 0-1.272-3.913z"
        ></path>
        <path
          stroke="#B1CDD0"
          d="M49.942 51.689 56.5 31.502M15.5 31.502l6.558 20.187"
        ></path>
        <path
          stroke="#C3E6E7"
          d="M56.5 31.502a3.5 3.5 0 0 0-1.27-3.913L38.056 15.113a3.5 3.5 0 0 0-4.114 0L16.77 27.589a3.5 3.5 0 0 0-1.272 3.913"
        ></path>
        <path
          stroke="#E5FEFF"
          d="M38.057 15.113a3.5 3.5 0 0 0-4.114 0L16.77 27.589"
          opacity="0.4"
        ></path>
        <path stroke="#fff" d="m33.943 15.113-4.293 3.119"></path>
      </g>
      <g filter="url(#filter1_d_0_10616)">
        <path
          fill="url(#paint2_linear_0_10616)"
          fillRule="evenodd"
          d="M34.824 20.854a2 2 0 0 1 2.352 0l12.865 9.348a2 2 0 0 1 .727 2.236l-4.914 15.124a2 2 0 0 1-1.902 1.382H28.049a2 2 0 0 1-1.903-1.382l-4.914-15.124a2 2 0 0 1 .727-2.236z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#94A0A4"
          strokeOpacity="0.06"
          d="M37.47 20.45a2.5 2.5 0 0 0-2.94 0l-12.865 9.347a2.5 2.5 0 0 0-.908 2.795l4.914 15.125a2.5 2.5 0 0 0 2.378 1.727h15.903a2.5 2.5 0 0 0 2.377-1.727l4.914-15.125a2.5 2.5 0 0 0-.908-2.795z"
        ></path>
      </g>
      <mask
        id="mask2_0_10616"
        width="30"
        height="29"
        x="21"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M34.824 20.854a2 2 0 0 1 2.352 0l12.865 9.348a2 2 0 0 1 .727 2.236l-4.914 15.124a2 2 0 0 1-1.902 1.382H28.049a2 2 0 0 1-1.903-1.382l-4.914-15.124a2 2 0 0 1 .727-2.236z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask2_0_10616)">
        <g opacity="0.2" style={{ mixBlendMode: "multiply" }}>
          <path
            fill="url(#paint3_linear_0_10616)"
            fillRule="evenodd"
            d="m36 36-16 4v-8zm0 0 4-16h-8zm0 0 16-4v8zm0 0 8.485-14.142 5.657 5.657zm0 0 8.485 14.142 5.657-5.657zm0 0-4 16h8zm0 0-8.485 14.142-5.657-5.657z"
            clipRule="evenodd"
          ></path>
          <path
            fill="url(#paint4_linear_0_10616)"
            d="m27.515 21.858-5.657 5.657L36 36z"
          ></path>
        </g>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m33.51 22.427-1.608 1.169"
        ></path>
      </g>
      <g filter="url(#filter2_d_0_10616)">
        <path
          fill="#fff"
          d="M33.45 32.49 36 28.769l2.55 3.721 4.327 1.276-2.75 3.575.123 4.51L36 40.338l-4.25 1.511.124-4.51-2.751-3.575z"
        ></path>
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M36.825 28.204a1 1 0 0 0-1.65 0l-2.35 3.427-3.985 1.175a1 1 0 0 0-.51 1.57l2.534 3.293-.114 4.154a1 1 0 0 0 1.335.97L36 41.4l3.915 1.392a1 1 0 0 0 1.335-.97l-.114-4.153 2.534-3.294a1 1 0 0 0-.51-1.569l-3.986-1.175z"
        ></path>
      </g>
      <g filter="url(#filter3_i_0_10616)">
        <path
          fill="url(#paint5_linear_0_10616)"
          d="M33.45 32.49 36 28.769l2.55 3.721 4.327 1.276-2.75 3.575.123 4.51L36 40.338l-4.25 1.511.124-4.51-2.751-3.575z"
        ></path>
      </g>
      <g filter="url(#filter4_d_0_10616)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m30.607 49.52-.346-.137a.412.412 0 0 1 0-.766l.346-.137c.4-.157.716-.473.873-.873l.137-.346a.412.412 0 0 1 .766 0l.137.346c.157.4.473.716.873.873l.346.137a.412.412 0 0 1 0 .766l-.346.137c-.4.157-.716.473-.873.873l-.137.346a.412.412 0 0 1-.766 0l-.137-.346a1.55 1.55 0 0 0-.873-.873"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter5_d_0_10616)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m47.76 32.15-.434-.17a.515.515 0 0 1 0-.96l.433-.17c.5-.197.894-.592 1.091-1.09l.17-.434a.515.515 0 0 1 .96 0l.17.433c.197.5.592.894 1.09 1.091l.434.17a.515.515 0 0 1 0 .96l-.433.17a1.94 1.94 0 0 0-1.091 1.09l-.17.434a.515.515 0 0 1-.96 0l-.17-.433a1.94 1.94 0 0 0-1.09-1.091"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter6_d_0_10616)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m23.456 36.89-.26-.102a.31.31 0 0 1 0-.576l.26-.102c.299-.118.536-.355.654-.654l.102-.26a.31.31 0 0 1 .576 0l.102.26c.118.299.355.536.655.654l.26.102a.31.31 0 0 1 0 .576l-.26.102c-.3.118-.537.355-.655.654l-.102.26a.31.31 0 0 1-.576 0l-.102-.26a1.16 1.16 0 0 0-.654-.654"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_0_10616"
          width="20"
          height="37.338"
          x="26"
          y="36"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.333333 0 0 0 0 0.790431 0 0 0 0 0.819608 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10616"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10616"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_0_10616"
          width="35.732"
          height="34.472"
          x="18.134"
          y="18.472"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.0196078 0 0 0 0 0.443137 0 0 0 0 0.643137 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10616"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10616"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_0_10616"
          width="21.754"
          height="21.081"
          x="25.123"
          y="25.769"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.596078 0 0 0 0 0.745098 0 0 0 0 0.780392 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10616"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10616"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_i_0_10616"
          width="13.754"
          height="14.081"
          x="29.123"
          y="28.769"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.508286 0 0 0 0 0.694443 0 0 0 0 0.72829 0 0 0 0.4 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10616"></feBlend>
        </filter>
        <filter
          id="filter4_d_0_10616"
          width="8"
          height="8"
          x="28"
          y="45"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.823529 0 0 0 0 0.831373 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10616"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10616"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter5_d_0_10616"
          width="9"
          height="9"
          x="45"
          y="27"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.823529 0 0 0 0 0.831373 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10616"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10616"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter6_d_0_10616"
          width="7"
          height="7"
          x="21"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.705882 0 0 0 0 0.823529 0 0 0 0 0.831373 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10616"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10616"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_0_10616"
          x1="5"
          x2="5"
          y1="6"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#59CBF2"></stop>
          <stop offset="1" stopColor="#40A7E8"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_10616"
          x1="13"
          x2="13"
          y1="13"
          y2="59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C4ECEB"></stop>
          <stop offset="1" stopColor="#A7BCC1"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_10616"
          x1="20"
          x2="20"
          y1="20"
          y2="52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0FBFE"></stop>
          <stop offset="1" stopColor="#C0DCDC"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_10616"
          x1="21.48"
          x2="21.48"
          y1="20"
          y2="49.041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AFD4D6"></stop>
          <stop offset="1" stopColor="#B9D9DB" stopOpacity="0.57"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_10616"
          x1="21.48"
          x2="21.48"
          y1="20"
          y2="49.041"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AFD4D6"></stop>
          <stop offset="1" stopColor="#B9D9DB" stopOpacity="0.57"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_0_10616"
          x1="29.123"
          x2="29.123"
          y1="28.769"
          y2="41.85"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B7D6D8"></stop>
          <stop offset="1" stopColor="#A7BCC1"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
