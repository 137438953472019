import React, { useState } from 'react';

import { MBProMandate, MBProMandateCreateRequest } from '@meilleursbiens/types';
import { FileInput, LoadingPage, Section, TextInput, fadeInRight } from '@meilleursbiens/ui';

import { Box, Spacer } from '@chakra-ui/react';

import { FormikValues, useFormik } from 'formik';
import { a } from 'vitest/dist/suite-IbNSsUWN';
import * as Yup from 'yup';

interface MBProMandatesCreateFormPropertyDetailsComponentProps {
  mandate: MBProMandateCreateRequest;
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  mandateDatas?: MBProMandate;
}

const validationSchema = Yup.object().shape({
  amendment_name: Yup.string().required(`Le nom de l'avenant est requis !`),
  amendment_file: Yup.mixed().required(`L'avenant papier est requis au format pdf !`),
});

export default function MBProMandatesCreateFormPropertyDetailsComponent(
  props: MBProMandatesCreateFormPropertyDetailsComponentProps
) {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      amendment_name: '',
      amendment_file: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log('submit');
      props.onSubmit(formik.values);
    },
  });

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <>
      <TextInput
        label={`Nom de l'avenant`}
        name={'amendment_name'}
        placeholder={'ex: Avenant pour prolongation du mandat de vente'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired={true}
      />

      <Spacer my={4} />

      <Section
        size={'xs'}
        title={'Importez votre avenant papier'}
        description={'Importez votre avenant papier rempli et signé au format PDF'}
        mb={4}
      >
        <Box as={'form'} id="form-mandate" onSubmit={formik.handleSubmit} animation={`${fadeInRight} 0.2s ease`} pb={5}>
          <FileInput
            isRequired
            maxFileSize={4}
            accept={{
              'application/pdf': ['.pdf'],
            }}
            handleChange={formik.handleChange}
            setFieldValue={formik.setFieldValue}
            handleBlur={formik.handleBlur}
            label={''}
            name={'amendment_file'}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
          />
        </Box>
      </Section>
    </>
  );
}
