import React, { useState } from 'react';

import { MBProCRMClientsAPI } from '@meilleursbiens/api';
import { MBProClient, MBProClientStatus } from '@meilleursbiens/types';
import { DotBadge, DotBadgeProps } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { Badge, Box, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Portal } from '@chakra-ui/react';
import { ChevronDownIcon } from '@heroicons/react/24/solid';

interface MBProCrmClientsCoreStatusEditComponentProps extends Omit<Omit<DotBadgeProps, 'size'>, 'text'> {
  client: MBProClient;
  onUpdate?: (client: MBProClient) => void;
  size?: 'lg' | 'md' | 'sm' | 'xs' | 'xl' | 'xxs';
  hasCaret?: boolean;
}

export default function MBProCrmClientsCoreStatusEditComponent(props: MBProCrmClientsCoreStatusEditComponentProps) {
  const { client, onUpdate, hasCaret = true } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [statuses, setStatuses] = useState<MBProClientStatus[]>([]);

  const _fetchApi = () => {
    if (statuses.length === 0) {
      setIsLoading(true);
      MBProCRMClientsAPI.listStatus().then((r) => {
        setStatuses(r.statuses);
        setIsLoading(false);
      });
    }
  };

  const _setStatusAPI = (status: any) => {
    setIsLoading(true);
    MBProCRMClientsAPI.setStatus(client.id, status.id)
      .then((r) => {
        const clientDatas = { ...client };

        if (clientDatas.status) {
          clientDatas.status.id = status.id;
          clientDatas.status.title = status.title;
          clientDatas.status.color = status.color;
        }

        onUpdate && onUpdate(clientDatas);
        setIsLoading(false);
      })
      .catch((e) => ToastUtils.showError(e));
  };

  if (client.status == null) {
    return (
      <Menu onOpen={_fetchApi}>
        <MenuButton as={HStack} align={'center'}>
          <DotBadge
            className={'no-select-text'}
            colorScheme={'gray.300'}
            size={'sm'}
            text={'Aucun'}
            cursor={'pointer'}
            p={0}
            borderWidth={0}
            boxShadow={'none'}
            {...props}
          />
        </MenuButton>
        <MenuList w={'150px'}>
          {statuses.map((status) => (
            <MenuItem onClick={() => _setStatusAPI(status.id)}>
              <Badge className={'no-select-text'} variant={'outline'} colorScheme={status.color}>
                {status.title}
              </Badge>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }

  return (
    <Menu onOpen={_fetchApi}>
      <MenuButton as={Flex} align={'center'} cursor={'pointer'}>
        <HStack spacing={1}>
          <DotBadge
            className={'no-select-text'}
            colorScheme={client.status.color + '.300'}
            size={'xs'}
            text={client.status.title}
            cursor={'pointer'}
            p={0}
            fontSize={'10px'}
            borderWidth={0}
            boxShadow={'none'}
            textProps={{
              fontSize: '10px',
              color: 'muted',
            }}
            _hover={{
              bg: 'muted',
            }}
            color={'muted'}
            {...props}
          />
          {props.hasCaret && <ChevronDownIcon width={10} />}
        </HStack>
      </MenuButton>
      <Portal>
        <MenuList w={'150px'}>
          {statuses.map((status) => (
            <MenuItem
              icon={<Box bg={status.color + '.300'} w={'14px'} h={'14px'} borderRadius={'40px'}></Box>}
              onClick={() => _setStatusAPI(status)}
            >
              {status.title}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
}
