import React, { memo } from 'react';

import { MBProUser } from '@meilleursbiens/types';
import { Badge, Card, Persona, TextDate } from '@meilleursbiens/ui';

import moment from 'moment/moment';

import { Handle, Position } from '@xyflow/react';
import { PriceUtils } from '@meilleursbiens/utils';
import { useColorModeValue } from '@chakra-ui/react';


const defaultWidth = 190;
const defaultHeight = 50;

export default memo(({ data, isConnectable }: {isConnectable: boolean, data: {
    user: MBProUser,
    amount: number
  }}) => {
  const { user, amount } = data;

  const borderColor = useColorModeValue('red.700', 'red.300')
  const handleColor = useColorModeValue('#F7F7F7', '#262626')

  return (
    <>
      <Handle
        type="target"
        position={Position.Bottom}
        style={{ background: handleColor }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Top}
        style={{ background: handleColor }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
        <Card p={2} borderRadius={'50px'} pr={3} width={defaultWidth} height={defaultHeight}>
          <Persona size={'sm'}
                   name={user.firstName + ' ' + user.name}
                   avatarUrl={user.profile_picture}
                   presence={"online"}
                   secondaryText={"Inscrit"}
                   secondaryTextProps={{
                     fontSize: '10px'
                   }}
                   secondaryTextRightElement={(
                     <TextDate date={moment(user.start_subscription_at).toDate()} fontSize={'10px'} color={'muted'} defaultFormat={'ago'}/>
                   )}

          />
          <Badge position={'absolute'} top={-2} right={-1} bg={"primary.500"} borderWidth={1} borderColor={borderColor} px={1} fontSize={'10px'} textColor={'white'} borderRadius={'50px'}>{PriceUtils.formatPrice(amount)}</Badge>
        </Card>
    </>
  );
});
