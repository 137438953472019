import { MBProClient, MBProClientsProfile } from '@meilleursbiens/types';

import { MBApi } from '../../../../../../index';

export default class MBProCRMClientsProfileAPI {
  static edit(client_id: number, key: string, value: any) {
    return MBApi.url('/v1/mbpro/clients/' + client_id + '/profile')
      .parameters({
        key,
        value,
      })
      .needAuth()
      .post<MBProClientsProfile>();
  }
}
