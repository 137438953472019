'use client';

import React, { useState, lazy } from 'react';

import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Text } from '@chakra-ui/react';

import { fadeInUp } from '../../Animations';
import animationData from './animation.json';

const Lottie = lazy(() => import('react-lottie-player'));

interface PDFSignSuccessProps {
  redirectUrl?: string;
  onEnd?: () => void;
}

export default function PDFSignSuccess(props: PDFSignSuccessProps) {
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  return (
    <Drawer
      isOpen={true}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      size={'sm'}
      placement={'bottom'}
      onClose={() => {}}
    >
      <DrawerOverlay
        bg={'url(https://mb-portail.s3.eu-west-3.amazonaws.com/images/backgrounds/bg-sign-blur.png) !important'}
        bgSize={'contain !important'}
      />
      <DrawerContent>
        <DrawerHeader></DrawerHeader>

        <DrawerBody pb={7}>
          <Flex direction={'column'} gap={3} w={'100%'} align={'center'} justify={'center'}>
            <Lottie
              style={{
                height: 200,
                width: 200
              }}
              animationData={animationData}
              loop={false}
              play={true}
              onComplete={() => {
                if (props.onEnd) {
                  setTimeout(() => {
                    props.onEnd && props.onEnd();
                  }, 1000);
                }
                if (props.redirectUrl) {
                  setTimeout(() => {
                    window.open(props.redirectUrl, '_self');
                  }, 1000);
                } else {
                  setIsAnimationComplete(true);
                }
              }}
            />
            {isAnimationComplete && (
              <Text animation={`${fadeInUp} 0.4s ease`} fontSize={'sm'} textAlign={'center'} fontWeight={500}>
                Votre signature a bien été enregistrée, vous allez recevoir un email de confirmation !
              </Text>
            )}
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
