import * as React from 'react';
import { SVGProps } from 'react';

export default function (props: SVGProps<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      fill="none"
      viewBox="0 0 72 74"
      {...props}
    >
      <path
        fill="url(#paint0_linear_0_10773)"
        fillRule="evenodd"
        d="M53 62a2 2 0 1 1 0 4H35q-.177 0-.345-.03a29.86 29.86 0 0 1-17.079-6.292c-.387-.301-.97-.8-1.401-1.177A2.03 2.03 0 0 0 14.844 58H10a2 2 0 1 1 0-4h2a2 2 0 1 0 0-4H7a2 2 0 1 1 0-4h1a2 2 0 1 0 0-4c-.809 0-1.5-.583-1.635-1.38-.049-.29-.088-.523-.094-.57A30 30 0 0 1 6 36C6 19.432 19.432 6 36 6h15a2 2 0 1 1 0 4h-3a2 2 0 1 0 0 4h12a2 2 0 0 1 .002 4H57a2 2 0 1 0 0 4h4.306c.756 0 1.45.426 1.778 1.108.197.41.404.851.54 1.17A29.9 29.9 0 0 1 66 36a30 30 0 0 1-.246 3.857c-.027.212-.069.485-.114.764C65.51 41.42 64.81 42 64 42a2 2 0 1 0 0 4h1a2 2 0 1 1 0 4h-1.284c-.726 0-1.394.394-1.756 1.023-.236.409-.498.853-.693 1.156a30 30 0 0 1-3.08 4.014c-.313.343-.819.851-1.213 1.241a1.97 1.97 0 0 1-1.387.566H50a2 2 0 0 0-.023 4z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#7705A4"
        fillRule="evenodd"
        d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.196v18.127a6 6 0 0 1-3 5.197l-15.699 9.063a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.935a6 6 0 0 1 3-5.196z"
        clipRule="evenodd"
        opacity="0.1"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <g filter="url(#filter0_d_0_10773)">
        <path
          fill="#32B4FF"
          fillRule="evenodd"
          d="M22 33h16v30.382a1 1 0 0 1-1.447.894L30 61l-6.553 3.276A1 1 0 0 1 22 63.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter1_d_0_10773)">
        <path
          fill="#32B4FF"
          fillRule="evenodd"
          d="M34 33h16v30.382a1 1 0 0 1-1.447.894L42 61l-6.553 3.276A1 1 0 0 1 34 63.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter2_d_0_10773)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28 38h16v30.382a1 1 0 0 1-1.447.894L36 66l-6.553 3.276A1 1 0 0 1 28 68.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask0_0_10773"
        width="16"
        height="32"
        x="28"
        y="38"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28 38h16v30.382a1 1 0 0 1-1.447.894L36 66l-6.553 3.276A1 1 0 0 1 28 68.382z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_0_10773)">
        <path fill="#F55462" d="M32 38h8v32h-8z"></path>
      </g>
      <path
        fill="#7705A4"
        fillRule="evenodd"
        d="M34 16.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 59.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V28.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
        opacity="0.2"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <path
        fill="url(#paint1_linear_0_10773)"
        fillRule="evenodd"
        stroke="url(#paint2_linear_0_10773)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M54 22.677a1 1 0 0 1 .629-.928l3.548-1.42a.6.6 0 0 1 .823.557v15.437a1 1 0 0 1-.629.928L54 39z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask1_0_10773"
        width="7"
        height="21"
        x="53"
        y="19"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M54 22.677a1 1 0 0 1 .629-.928l3.548-1.42a.6.6 0 0 1 .823.557v15.437a1 1 0 0 1-.629.928L54 39z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask1_0_10773)">
        <g
          filter="url(#filter3_i_0_10773)"
          opacity="0.6"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            d="m54.5 28.833 5.692-2.371a.5.5 0 1 0-.384-.924l-6 2.5c-.187.078.692.795.692.795"
          ></path>
        </g>
        <g
          filter="url(#filter4_i_0_10773)"
          opacity="0.6"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            d="m54.5 34.833 5.692-2.371a.5.5 0 1 0-.384-.924l-6 2.5c-.187.078.692.795.692.795"
          ></path>
        </g>
        <path
          fill="#7705A4"
          fillRule="evenodd"
          d="M38.999 12.677a6 6 0 0 0-6 0L17.3 21.74a6 6 0 0 0-3 5.196v18.127a6 6 0 0 0 3 5.197L33 59.323a6 6 0 0 0 6 0l15.698-9.063a6 6 0 0 0 3-5.196V26.935a6 6 0 0 0-3-5.196z"
          clipRule="evenodd"
          opacity="0.04"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint3_linear_0_10773)"
        fillRule="evenodd"
        stroke="url(#paint4_linear_0_10773)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 22.677a1 1 0 0 0-.629-.928l-3.548-1.42a.6.6 0 0 0-.823.557v15.437a1 1 0 0 0 .629.928L18 39z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask2_0_10773"
        width="7"
        height="21"
        x="12"
        y="19"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 22.677a1 1 0 0 0-.629-.928l-3.548-1.42a.6.6 0 0 0-.823.557v15.437a1 1 0 0 0 .629.928L18 39z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask2_0_10773)">
        <g
          filter="url(#filter5_i_0_10773)"
          opacity="0.6"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            d="m17.5 28.833-5.692-2.371a.5.5 0 1 1 .384-.924l6 2.5c.187.078-.692.795-.692.795"
          ></path>
        </g>
        <g
          filter="url(#filter6_i_0_10773)"
          opacity="0.6"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            d="m17.5 34.833-5.692-2.371a.5.5 0 1 1 .384-.924l6 2.5c.187.078-.692.795-.692.795"
          ></path>
        </g>
        <path
          fill="#7705A4"
          fillRule="evenodd"
          d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.196v18.127a6 6 0 0 1-3 5.197L39 59.323a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.935a6 6 0 0 1 3-5.196z"
          clipRule="evenodd"
          opacity="0.04"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint5_linear_0_10773)"
        fillRule="evenodd"
        stroke="url(#paint6_linear_0_10773)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M50 17.326a1 1 0 0 0-1.275-.962L36 20l-12.725-3.636a1 1 0 0 0-1.275.962V32a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint7_linear_0_10773)"
        fillRule="evenodd"
        stroke="#FFF04D"
        strokeOpacity="0.4"
        d="M34 14.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 57.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V26.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
      ></path>
      <g
        filter="url(#filter7_i_0_10773)"
        opacity="0.8"
        style={{ mixBlendMode: "multiply" }}
      >
        <path
          fill="#FABC10"
          fillRule="evenodd"
          d="m51.671 25.189-14.154-8.182a3.03 3.03 0 0 0-3.034 0L20.33 25.189a3.04 3.04 0 0 0-1.517 2.63v5.902l-1.147 1.723a1 1 0 0 0 0 1.108l1.147 1.724v5.906c0 1.084.579 2.087 1.517 2.63l14.154 8.181a3.03 3.03 0 0 0 3.034 0l14.154-8.181a3.04 3.04 0 0 0 1.517-2.63v-5.906l1.147-1.724a1 1 0 0 0 0-1.108l-1.148-1.723v-5.903a3.04 3.04 0 0 0-1.516-2.63M19.824 38.276v5.906c0 .723.385 1.391 1.01 1.753l14.155 8.182a2.02 2.02 0 0 0 2.022 0l14.154-8.182a2.03 2.03 0 0 0 1.011-1.753v-5.906l-1.147-1.724a1 1 0 0 1 0-1.108l1.147-1.723v-5.903c0-.723-.385-1.391-1.01-1.753L37.01 17.883a2.02 2.02 0 0 0-2.022 0l-14.154 8.182a2.03 2.03 0 0 0-1.012 1.753v5.903l1.148 1.723a1 1 0 0 1 0 1.108z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        stroke="#F9B307"
        d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382a3.5 3.5 0 0 0 1.75 3.03l15.919 9.191a3.5 3.5 0 0 0 3.5 0l15.919-9.19a3.5 3.5 0 0 0 1.75-3.031V26.809a3.5 3.5 0 0 0-1.75-3.03z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        d="m37.75 57.412 3.98-2.297"
        opacity="0.4"
      ></path>
      <path
        stroke="#FAC00E"
        strokeLinecap="round"
        d="M55.419 45.19V26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382"
      ></path>
      <path
        stroke="#FDDB3D"
        strokeLinecap="round"
        d="M55.419 26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031"
        opacity="0.6"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-7.96 4.595"
        opacity="0.6"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        d="m34.25 14.588-3.98 2.297"
      ></path>
      <g filter="url(#filter8_d_0_10773)">
        <path
          fill="url(#paint8_linear_0_10773)"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#F5900D"
          strokeOpacity="0.1"
          d="M37.25 20.144a2.5 2.5 0 0 0-2.5 0L22.894 26.99a2.5 2.5 0 0 0-1.25 2.165v13.69a2.5 2.5 0 0 0 1.25 2.165l11.856 6.846a2.5 2.5 0 0 0 2.5 0l11.856-6.846a2.5 2.5 0 0 0 1.25-2.165v-13.69a2.5 2.5 0 0 0-1.25-2.165z"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <mask
        id="mask3_0_10773"
        width="28"
        height="32"
        x="22"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask3_0_10773)">
        <path
          fill="#FFD640"
          fillRule="evenodd"
          d="m36 36 4-16h-8zm0 0 16-4v8zm0 0 8.485-14.142 5.657 5.657zm0 0 8.485 14.142 5.657-5.657zm0 0-4 16h8zm0 0-16 4v-8zm0 0-8.485 14.142-5.657-5.657zm-8.485-14.142-5.657 5.657L36 36z"
          clipRule="evenodd"
          opacity="0.3"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M48.606 27.856 36.75 21.01a1.5 1.5 0 0 0-1.5 0l-2.964 1.712-2.964 1.711-5.928 3.423M48.606 44.11 36.75 50.955a1.5 1.5 0 0 1-1.5 0"
          opacity="0.4"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m35.25 21.01-2.964 1.712"
        ></path>
      </g>
      <g filter="url(#filter9_d_0_10773)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m36 27 8 9-8 9-8-9z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M36.747 26.336a1 1 0 0 0-1.494 0l-8 9a1 1 0 0 0 0 1.328l8 9a1 1 0 0 0 1.494 0l8-9a1 1 0 0 0 0-1.328z"
        ></path>
      </g>
      <path
        fill="url(#paint9_linear_0_10773)"
        fillRule="evenodd"
        d="m36 27 8 9H28z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint10_linear_0_10773)"
        fillRule="evenodd"
        d="m36 45 8-9H28z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint11_linear_0_10773)"
        fillRule="evenodd"
        d="m36 27-3.857 9h7.714z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint12_linear_0_10773)"
        fillRule="evenodd"
        d="m36 45-3.857-9h7.714z"
        clipRule="evenodd"
      ></path>
      <g filter="url(#filter10_d_0_10773)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m46.91 29.78-.518-.205a.618.618 0 0 1 0-1.15l.519-.205a2.32 2.32 0 0 0 1.31-1.31l.204-.518a.618.618 0 0 1 1.15 0l.205.519c.236.599.71 1.073 1.31 1.31l.518.204a.618.618 0 0 1 0 1.15l-.519.205a2.32 2.32 0 0 0-1.309 1.31l-.205.518a.618.618 0 0 1-1.15 0l-.205-.519a2.32 2.32 0 0 0-1.31-1.31"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter11_d_0_10773)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m26.607 45.52-.346-.137a.412.412 0 0 1 0-.766l.346-.137c.4-.157.716-.473.873-.873l.137-.346a.412.412 0 0 1 .766 0l.137.346c.157.4.474.716.873.873l.346.137a.412.412 0 0 1 0 .766l-.346.137a1.55 1.55 0 0 0-.873.873l-.137.346a.412.412 0 0 1-.766 0l-.137-.346a1.55 1.55 0 0 0-.873-.873"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter12_d_0_10773)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m20.76 31.15-.434-.17a.515.515 0 0 1 0-.96l.433-.17c.5-.197.894-.592 1.091-1.09l.17-.434a.515.515 0 0 1 .96 0l.17.433c.197.5.592.894 1.09 1.091l.434.17a.515.515 0 0 1 0 .96l-.433.17a1.94 1.94 0 0 0-1.091 1.09l-.17.434a.515.515 0 0 1-.96 0l-.17-.433a1.94 1.94 0 0 0-1.09-1.091"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter13_d_0_10773)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m46.456 45.89-.26-.102a.31.31 0 0 1 0-.576l.26-.102c.299-.118.536-.355.654-.654l.102-.26a.31.31 0 0 1 .576 0l.102.26c.118.299.355.536.655.654l.26.102a.31.31 0 0 1 0 .576l-.26.102c-.3.118-.537.355-.655.654l-.102.26a.31.31 0 0 1-.575 0l-.103-.26a1.16 1.16 0 0 0-.654-.654"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_0_10773"
          width="20"
          height="35.383"
          x="20"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.309804 0 0 0 0 0.247059 0 0 0 0 0.866667 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_0_10773"
          width="20"
          height="35.383"
          x="32"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.309804 0 0 0 0 0.247059 0 0 0 0 0.866667 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_0_10773"
          width="20"
          height="35.383"
          x="26"
          y="38"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.309804 0 0 0 0 0.247059 0 0 0 0 0.866667 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_i_0_10773"
          width="6.718"
          height="4.333"
          x="53.782"
          y="25.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10773"></feBlend>
        </filter>
        <filter
          id="filter4_i_0_10773"
          width="6.718"
          height="4.333"
          x="53.782"
          y="31.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10773"></feBlend>
        </filter>
        <filter
          id="filter5_i_0_10773"
          width="6.718"
          height="4.333"
          x="11.5"
          y="25.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10773"></feBlend>
        </filter>
        <filter
          id="filter6_i_0_10773"
          width="6.718"
          height="4.333"
          x="11.5"
          y="31.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10773"></feBlend>
        </filter>
        <filter
          id="filter7_i_0_10773"
          width="37.005"
          height="39.8"
          x="17.497"
          y="16.6"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10773"></feBlend>
        </filter>
        <filter
          id="filter8_d_0_10773"
          width="33.713"
          height="37.381"
          x="19.144"
          y="18.309"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.927721 0 0 0 0 0.665867 0 0 0 0 0.0535899 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter9_d_0_10773"
          width="24"
          height="26"
          x="24"
          y="24"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.733333 0 0 0 0 0.105882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter10_d_0_10773"
          width="10"
          height="10"
          x="44"
          y="24"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter11_d_0_10773"
          width="8"
          height="8"
          x="24"
          y="41"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter12_d_0_10773"
          width="9"
          height="9"
          x="18"
          y="26"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter13_d_0_10773"
          width="7"
          height="7"
          x="44"
          y="42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10773"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10773"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_0_10773"
          x1="5.955"
          x2="5.955"
          y1="6"
          y2="64.152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE66FF"></stop>
          <stop offset="1" stopColor="#7E07E0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_10773"
          x1="59"
          x2="59"
          y1="20"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_10773"
          x1="59"
          x2="59"
          y1="20"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_10773"
          x1="13"
          x2="13"
          y1="20"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_10773"
          x1="13"
          x2="13"
          y1="20"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_0_10773"
          x1="22"
          x2="22"
          y1="16.354"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_0_10773"
          x1="22"
          x2="22"
          y1="16.354"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_0_10773"
          x1="13"
          x2="13"
          y1="13"
          y2="59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDE046"></stop>
          <stop offset="1" stopColor="#F9B800"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_0_10773"
          x1="20"
          x2="20"
          y1="20"
          y2="52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF550"></stop>
          <stop offset="1" stopColor="#FFD541"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_0_10773"
          x1="28"
          x2="28"
          y1="27"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27DEEB"></stop>
          <stop offset="1" stopColor="#08C9FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_0_10773"
          x1="28"
          x2="28"
          y1="36"
          y2="45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009BF6"></stop>
          <stop offset="1" stopColor="#00B5FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_0_10773"
          x1="32.143"
          x2="32.143"
          y1="27"
          y2="36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8AFFFF"></stop>
          <stop offset="1" stopColor="#0DF3FF"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_0_10773"
          x1="32.143"
          x2="32.143"
          y1="36"
          y2="45"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00BAF3"></stop>
          <stop offset="1" stopColor="#00B5FF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
