import React, { forwardRef } from 'react';

import { Button, IconButton, MenuItem } from '@meilleursbiens/ui';

import { MenuButton, MenuOptionGroup } from '@chakra-ui/icons';
import { Box, Menu, MenuList, Portal, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import {
  ArrowDownTrayIcon,
  Bars3Icon,
  ChevronDownIcon,
  DocumentPlusIcon,
  PlusCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';

interface MBProMandatesGeneralMenuComponentProps {
  onMandateCreateModal: () => void;
  onMandareCreatePaperModal: () => void;
  onOpenDownloadPaperModal: () => void;
}

const MBProMandatesGeneralMenuComponent = forwardRef((props: MBProMandatesGeneralMenuComponentProps, ref) => {
  const { onMandateCreateModal, onOpenDownloadPaperModal, onMandareCreatePaperModal } = props;
  const [isMobile] = useMediaQuery('(max-width: 479px)');

  return (
    <Menu>
      {isMobile ? (
        <MenuButton
          as={Button}
          size={'sm'}
          leftIcon={DocumentPlusIcon}
          rightIcon={ChevronDownIcon}
          variant="primary"
          color={'white'}
          _hover={{ bg: 'red.600', color: 'white' }}
          sx={{
            '> span:first-child': { marginRight: '-0.375rem' },
            '> span:nth-child(3) svg': { height: '0.688rem' },
            hr: { display: 'none' },
          }}
        />
      ) : (
        <MenuButton
          as={Button}
          size={'sm'}
          rightIcon={ChevronDownIcon}
          variant="primary"
          color={'white'}
          flexShrink={0}
          _hover={{ bg: 'red.600', color: 'white' }}
        >
          Prendre un mandat
        </MenuButton>
      )}
      <Portal>
        <MenuList>
          <MenuOptionGroup title="Mandat électronique"></MenuOptionGroup>
          <MenuItem icon={PlusIcon} onClick={onMandateCreateModal}>
            Prendre un mandat électronique
          </MenuItem>
          <MenuOptionGroup
            title="Mandat papier"
            _after={{ content: '"(3 étapes)"', color: 'muted', fontSize: 'xs', ml: 1 }}
          >
            <MenuItem icon={ArrowDownTrayIcon} onClick={onOpenDownloadPaperModal}>
              Télécharger un modèle <Box as={'span'} color={'muted'} fontSize={'0.688rem'}>{`(1/3)`}</Box>
            </MenuItem>
            <MenuItem icon={DocumentPlusIcon} onClick={onMandareCreatePaperModal}>
              Retourner un mandat papier <Box as={'span'} color={'muted'} fontSize={'0.688rem'}>{`(2/3)`}</Box>
            </MenuItem>
          </MenuOptionGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
});

export default MBProMandatesGeneralMenuComponent;
