import { MBProUser, MBProUserAfiliations, MBProUserAfiliationsPayments, PaginationAPI } from '@meilleursbiens/types';

import MBApi from '../../../../../MBApi';

export default class MBProAdminAfiliationsAPI {
  static stats() {
    return MBApi.url('/v1/mbpro/admin/afiliations/stats').needAuth().get<{
      number: number;
      amount: number;
    }>();
  }

  static tree() {
    return MBApi.url('/v1/mbpro/admin/afiliations/tree').needAuth().get<MBProUser[]>();
  }

  static listPayments(rows: number, page: number, query: string, sortBy = 'id', sortOrder = 'asc', user_id?: number) {
    return MBApi.url(`/v1/mbpro/admin/afiliations/payments${query}&page=${page}&pageSize=${rows}&sort_by=${sortBy}&sort_order=${sortOrder}${user_id ? `&userId=${user_id}` : ''}`)
      .needAuth()
      .get<PaginationAPI<MBProUserAfiliationsPayments>>();
  }

  static create(user_id: number, values: any) {
    return MBApi.url('/v1/mbpro/admin/afiliations/' + user_id)
      .parameters(values)
      .needAuth()
      .put<MBProUserAfiliations>();
  }

  static delete(afiliation_id: number) {
    return MBApi.url('/v1/mbpro/admin/afiliations/' + afiliation_id)
      .needAuth()
      .delete();
  }
}
