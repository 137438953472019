import {
  AcademicCapIcon,
  ChartBarIcon,
  CreditCardIcon,
  DocumentMagnifyingGlassIcon as DocumentSearchIcon,
  EyeIcon,
  HomeIcon,
  PresentationChartBarIcon,
  ShoppingCartIcon,
  UserGroupIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import { DocumentChartBarIcon } from '@heroicons/react/24/solid';

export interface SectionLink {
  label: string;
  subLabel: string;
  feature?: string;
  icon: any;
  href?: string;
}

export interface SectionCategory {
  id: string;
  label: string;
  badge?: string;
  icon: any;
  feature?: string;
  permission?: string;
  href?: string;
  collapseByDefault?: boolean;
  isAdmin?: boolean;
  isBetaTester?: boolean;
  isNew?: boolean;
  isCountable?: boolean;
  children?: SectionLink[];
}

export interface SectionHeading {
  id: string;
  label: string;
  hasTitle?: boolean;
  isAdmin?: boolean;
  children: SectionCategory[];
}

export const ACCOUNT_NAVIGATION_V2: SectionHeading[] = [
  {
    id: 'heading-home',
    label: 'Accueil',
    hasTitle: false,
    children: [
      {
        id: 'account.dashboard',
        label: 'Tableau de Bord',
        feature: 'mb_pro_dashboard',
        icon: ChartBarIcon,
        href: '/pro/dashboard',
      },
      {
        id: 'account.marketplace',
        label: 'Boutique',
        feature: 'mb_pro_marketplace',
        permission: 'mb.pro.marketplace',
        icon: ShoppingCartIcon,
        href: '/pro/marketplace/v2',
      },
    ],
  },
  {
    id: 'heading-real-estate',
    label: 'Activité Immobilière',
    hasTitle: true,
    children: [
      {
        id: 'account.estimations',
        label: 'Estimations',
        feature: 'mb_estimation',
        permission: 'mb.estimation.list',
        icon: PresentationChartBarIcon,
        href: '/pro/estimations',
        collapseByDefault: true,
        isCountable: true,
      },
      {
        id: 'account.mandates',
        label: 'Mandats',
        feature: 'mb_mandates',
        permission: 'mb.pro.mandates.list',
        icon: DocumentSearchIcon,
        href: '/pro/mandates',
        badge: 'Bêta',
        isBetaTester: false,
        isCountable: true,
      },
      {
        id: 'account.diffusion',
        label: 'Diffusion',
        icon: EyeIcon,
        feature: 'mb_neo_properties',
        permission: 'mb.neo.properties.list',
        href: '/pro/properties',
        collapseByDefault: true,
        isNew: true,
        isCountable: true,
      },
      {
        id: 'account.transactions',
        label: 'Transactions',
        feature: 'mb_transactions',
        permission: 'mb.pro.transaction.list',
        icon: HomeIcon,
        href: '/pro/transactions',
        isCountable: true,
      },
    ],
  },
  {
    id: 'heading-crm',
    label: 'Gestion de mon activité',
    hasTitle: true,
    children: [
      {
        id: 'account.clients_requests',
        label: 'Demandes clients',
        feature: 'mb_estimation_leads',
        permission: 'mb.estimation.leads',
        icon: UserGroupIcon,
        href: '/pro/clients/requests',
        isCountable: true,
      },
      {
        id: 'account.contacts',
        label: 'Clients',
        feature: 'mb_contacts',
        permission: 'mb.pro.clients.list',
        icon: UserGroupIcon,
        href: '/pro/contacts',
        badge: 'Nouveau',
        isCountable: true,
      },
      {
        id: 'account.documents',
        label: 'Documents',
        feature: 'mb_documents',
        permission: 'mb.pro.documents.list',
        icon: DocumentSearchIcon,
        href: '/pro/documents',
        isCountable: true,
      },
      {
        id: 'account.invoices',
        label: 'Comptabilité',
        feature: 'mb_transactions',
        permission: 'mb.pro.invoices.list',
        icon: DocumentChartBarIcon,
        href: '/pro/accounting/invoices',
      },
    ],
  },
  {
    id: 'heading-tools',
    label: 'Les outils immobiliers',
    hasTitle: true,
    children: [
      {
        id: 'menu.matching',
        label: 'Recherche immobilière',
        feature: 'mb_estimation_leads',
        permission: 'mb.estimation.leads',
        icon: UserGroupIcon,
        href: '/pro/ads',
        badge: 'Nouveau',
      },
      {
        id: 'account.cadastre',
        label: 'Cadastre et DVF',
        feature: 'mb_estimation_leads',
        permission: 'mb.estimation.list',
        icon: DocumentSearchIcon,
        href: '/pro/cadastre-dvf',
      },
    ],
  },
  {
    id: 'education',
    label: 'Regarder et Apprendre',
    hasTitle: true,
    children: [
      {
        id: 'account.formations',
        label: 'Formations',
        feature: 'mb_formations',
        permission: 'mb.pro.formations',
        icon: AcademicCapIcon,
        href: '/pro/formations',
      },
      {
        id: 'account.replays',
        label: 'Replays',
        feature: 'mb_replays',
        permission: 'mb.pro.replays',
        icon: VideoCameraIcon,
        href: '/pro/replays',
      },
    ],
  },
  {
    id: 'heading-employees',
    label: 'Espace Employé',
    hasTitle: true,
    isAdmin: true,
    children: [
      {
        id: 'menu.my_salaries',
        label: 'Fiches de paies',
        icon: CreditCardIcon,
        isAdmin: true,
        href: '/harp/salaries',
      },
      {
        id: 'menu.my_leaves',
        label: 'Congés & Absences',
        icon: CreditCardIcon,
        isAdmin: true,
        href: '/harp/leaves',
      },
    ],
  },
  {
    id: 'heading-account',
    label: 'Gérer mon compte',
    hasTitle: true,
    children: [
      {
        id: 'menu.my_mini_site',
        label: 'Mini-Site',
        feature: 'mb_neo_mini_site',
        icon: CreditCardIcon,
        href: '/portail/mini-site',
      },
      {
        id: 'menu.subscription',
        label: 'Abonnement',
        feature: 'mb_subscription',
        permission: 'mb.pro.subscriptions',
        icon: CreditCardIcon,
        href: '/pro/subscription',
      },
      /*   {
        id: 'menu.integration',
        label: 'Intégrations',
        feature: 'mb_subscription',
        permission: 'mb.pro.subscriptions',
        icon: CreditCardIcon,
        href: '/pro/integrations',
      },*/
      {
        id: 'account.my_account',
        label: 'Mon Compte',
        permission: 'mb.pro.account',
        icon: CreditCardIcon,
        href: '/auth/account',
      },
    ],
  },
];
