import React from 'react';

import { useDisableCrisp } from '@meilleursbiens/hooks';
import { Button } from '@meilleursbiens/ui';

import { Divider, HStack, Spacer, useColorMode } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';

import AdminLayoutComponent from '../components/Layout/Admin/AdminLayoutComponent';
import { MBCoreAdsSimulatorsAddedValueComponent } from '@meilleursbiens/pro';

export default function TestScreen() {
  useDisableCrisp();

  const { colorMode, toggleColorMode } = useColorMode();

  const variants = ['solid', 'outline', 'ghost', 'primary', 'secondary', 'tertiary'];

  const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];


  return (
    <AdminLayoutComponent title={'Tests'} description={"Gérez les tests au sein d'une même interface"}>
      <Button onClick={toggleColorMode}>Changer de mode de couleur</Button>

      {variants.map((variant) => (
        <>
          <HStack spacing={2} my={2}>
            {sizes.map((size) => (
              <Button
                variant={variant}
                size={size}>
                Créer une annonce
              </Button>
            ))}
          </HStack>

          <HStack spacing={2} my={2}>
            {sizes.map((size) => (
              <Button
                variant={variant}
                leftIcon={PlusIcon}
                size={size}>
                Créer une annonce
              </Button>
            ))}
          </HStack>

          <HStack spacing={2} my={2}>
            {sizes.map((size) => (
              <Button
                variant={variant}
                leftIcon={PlusIcon}
                isLoading={true}
                size={size}>
                Créer une annonce
              </Button>
            ))}
          </HStack>

          <HStack spacing={2} my={2}>
            {sizes.map((size) => (
              <Button
                variant={variant}
                leftIcon={PlusIcon}
                isDisabled={true}
                size={size}>
                Créer une annonce
              </Button>
            ))}
          </HStack>

          <Divider my={8} />
        </>
      ))}


      <Spacer />

      <MBCoreAdsSimulatorsAddedValueComponent />
    </AdminLayoutComponent>
  );
}
