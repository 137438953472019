import { useEffect, useState } from 'react';

import { MBAuthAPI } from '@meilleursbiens/api';
import { MBProUser } from '@meilleursbiens/types';

export default function useUser() {
  const [user, setUser] = useState<MBProUser | null>(null);

  const _fetchUser = () => {
    return MBAuthAPI.getUser().then((r: any) => {
      console.log('r', r);
      if (r === undefined) return null;
      if (r.user) return r.user;
      else return null;
    });
  };

  useEffect(() => {
    _fetchUser().then((u) => {
      setUser(u);
    });
  }, []);

  return user;
}
