import React, { useEffect, useState } from 'react';

import { MBProCRMClientsAPI } from '@meilleursbiens/api';
import { MBProCrmClientsCardComponent, MBProCrmClientsContactSelectModalComponent } from '@meilleursbiens/clients';
import { MBProClient, MBProMandate, MBProMandateCreateRequest } from '@meilleursbiens/types';
import { Button, IconButton, Section, TextInput, fadeInRight } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { Box, Flex, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';

import { useFormik } from 'formik';
import * as Yup from 'yup';

interface MBProMandatesCreateFormMandateClientsSelectComponentProps {
  mandate: MBProMandateCreateRequest;
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  isPaper?: boolean;
  mandateDatas?: MBProMandate;
}

const ValidationNotarySchema = Yup.object().shape({
  clients_id: Yup.array()
    .of(Yup.number())
    .min(1, 'Veuillez sélectionner au moins un mandant')
    .max(64, 'Vous ne pouvez pas sélectionner plus de 64 mandants')
    .required('Veuillez sélectionner au moins un mandant'),
});

const ValidationSchema = Yup.object().shape({
  clients_id: Yup.array()
    .of(Yup.number())
    .min(1, 'Veuillez sélectionner au moins un mandant')
    .max(64, 'Vous ne pouvez pas sélectionner plus de 64 mandants')
    .required('Veuillez sélectionner au moins un mandant'),
});

type ModalSelectType = 'notary' | 'clients';

export default function (props: MBProMandatesCreateFormMandateClientsSelectComponentProps) {
  const { mandate, mandateDatas, isEdit, isPaper } = props;

  const hasNotary = mandate.is_sell || mandate.is_search;

  const [clients, setClients] = useState<MBProClient[]>([]);
  const [apiClients, setApiClients] = useState<MBProClient[]>([]);
  const [notary, setNotary] = useState<MBProClient | null>(null);

  const [type, setType] = useState<ModalSelectType>('notary');

  const disclosure = useDisclosure();

  const bg = useColorModeValue('gray.50', 'gray.700');

  const formik = useFormik({
    initialValues: hasNotary
      ? {
          notary_id: mandate.notary_id,
          clients_id: mandate.clients_id,
        }
      : {
          clients_id: mandate.clients_id,
        },
    validationSchema: hasNotary && !isPaper ? ValidationNotarySchema : ValidationSchema,
    onSubmit: (values) => {
      props.onSubmit({
        clients_id: values.clients_id,
        notary_id: notary?.id,
      });
    },
  });

  const _addClient = (client: MBProClient) => {
    if (mandate.is_delegation && clients.length > 0) {
      ToastUtils.showError("Vous ne pouvez pas ajouter plus d'un mandant pour une délégation");
      return;
    }

    if (mandate.is_delegation) {
      if (!client.is_business) {
        ToastUtils.showError(
          'Vous ne pouvez ajouter que des agences pour une délégation, veuillez cocher la case "Est une entreprise" et remplir les informations de l\'agence partenaire sur votre client !'
        );
        return;
      }
    }

    // CHeck if the client is already in the list
    if (clients.find((c) => c.id === client.id)) {
      return;
    }

    setClients([...clients, client]);
    formik.setFieldValue(
      'clients_id',
      [...clients, client].map((c) => c.id)
    );
  };

  const _deleteClient = (client: MBProClient) => {
    const updatedClients = clients.filter((c) => c.id !== client.id);
    if (updatedClients.length === 0) {
      ToastUtils.showError('Au moins un mandant est requis !');
      return;
    }

    setClients(updatedClients);

    formik.setFieldValue(
      'clients_id',
      updatedClients.map((c) => c.id)
    );
  };

  const _setNotary = (client: MBProClient) => {
    setNotary(client);
    formik.setFieldValue('notary_id', client.id);
  };

  const _deleteNotary = () => {
    setNotary(null);
    formik.setFieldValue('notary_id', null);
  };

  const _fetchClients = async () => {
    try {
      const { clients } = await MBProCRMClientsAPI.list();
      setApiClients(clients);
    } catch (error) {
      ToastUtils.showError('Une erreur est survenue lors de la récupération des mandants');
    }
  };

  const getMandantRules = () => {
    let rules: string[] = [];

    if (type === 'notary') {
      rules = ['first_name', 'name'];
    } else {
      rules = [
        'first_name',
        'name',
        'email',
        'phone',
        'address',
        'postal_code',
        'city',
        'country',
        'profile.date_of_birth',
        'profile.birth_city',
      ];
    }
    if (mandate.is_delegation) {
      rules = [...rules, 'business_name', 'business_siret'];
    }

    return rules;
  };

  useEffect(() => {
    _fetchClients();

    formik.setFieldValue('clients_id', mandate.clients_id || []);
  }, []);

  useEffect(() => {
    if (apiClients.length > 0) {
      const clients_id = formik.values.clients_id || [];
      setClients(apiClients.filter((c) => clients_id.includes(c.id)));
      setNotary(apiClients.find((c) => c.id === formik.values.notary_id) || null);
    }
  }, [apiClients]);

  useEffect(() => {
    if (mandateDatas) {
      formik.setFieldValue(
        'clients_id',
        mandateDatas?.signs?.map((s) => s.client_id)
      );
      formik.setFieldValue('notary_id', mandateDatas?.notary_id);
    }
  }, [mandateDatas]);

  const selectMandantWording = mandate.is_delegation ? "Sélectionner l'agence partenaire" : 'Sélectionner les mandants';
  const selectMandantDescriptionWording = mandate.is_delegation
    ? "Sélectionner l'agence partenaire de la délégation. Vous pouvez ajouter une agence en cliquant sur le bouton ci-dessous."
    : 'Sélectionner les mandants de l’opération. Vous pouvez ajouter un mandant en cliquant sur le bouton ci-dessous.';
  const selectMandantButtonWording = mandate.is_delegation ? "Sélectionner l'agence partenaire" : 'Ajouter un mandant';

  return (
    <Box
      as={'form'}
      id={'form-mandate'}
      onSubmit={formik.handleSubmit}
      maxH={'500px'}
      overflowY={'auto'}
      animation={`${fadeInRight} 0.2s ease`}
    >
      {hasNotary && !isEdit && !isPaper && (
        <Section
          size={'xs'}
          title={'Sélectionner le notaire'}
          description={'Sélectionner le notaire du vendeur pour constituer le mandat'}
          mb={4}
        >
          {notary && (
            <MBProCrmClientsCardComponent
              client={notary}
              rightIcon={
                <IconButton
                  variant={'ghost'}
                  icon={TrashIcon}
                  onClick={() => _deleteNotary()}
                  aria-label={'Supprimer le mandant'}
                  color={'red.500'}
                />
              }
              onClick={() => {}}
              onUpdate={(client) => {
                _setNotary(client);
              }}
            />
          )}

          <Flex align={'center'} justify={'center'} w={'full'} bg={bg} borderRadius={'md'} p={4}>
            <Button
              leftIcon={PlusIcon}
              onClick={() => {
                setType('notary');
                disclosure.onOpen();
              }}
            >
              Sélectionner le notaire
            </Button>
          </Flex>

          <TextInput
            type={'hidden'}
            name={'notary_id'}
            errors={formik.errors}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            values={formik.values}
          />
        </Section>
      )}
      <Section size={'xs'} title={selectMandantWording} description={selectMandantDescriptionWording}>
        {clients.length === 0 && (
          <Box p={4} textAlign={'center'} color={'muted'}>
            Aucun client sélectionné
          </Box>
        )}

        {clients.map((client, index) => (
          <MBProCrmClientsCardComponent
            key={index}
            client={client}
            hiddenEditFields={[
              'marital_status',
              'nb_of_childrens',
              'father_status',
              'mother_status',
              'is_first_time',
              'is_family',
              'is_investor',
              'is_owner',
              'is_renter',
              'comment',
            ]}
            rightIcon={
              <IconButton
                variant={'ghost'}
                icon={TrashIcon}
                onClick={() => _deleteClient(client)}
                aria-label={'Supprimer le mandant'}
                color={'red.500'}
              />
            }
            onClick={() => {}}
            onUpdate={(client) => {
              const index = clients.findIndex((c) => c.id === client.id);
              clients[index] = client;
              setClients([...clients]);
            }}
          />
        ))}

        <Flex align={'center'} justify={'center'} w={'full'} bg={bg} borderRadius={'md'} p={4}>
          <Button
            leftIcon={PlusIcon}
            onClick={() => {
              setType('clients');
              disclosure.onOpen();
            }}
          >
            {selectMandantButtonWording}
          </Button>
        </Flex>

        <TextInput
          type={'hidden'}
          name={'clients_id'}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
        />
      </Section>

      <MBProCrmClientsContactSelectModalComponent
        disclosure={disclosure}
        hiddenEditFields={[
          'marital_status',
          'nb_of_childrens',
          'father_status',
          'mother_status',
          'is_first_time',
          'is_family',
          'is_investor',
          'is_owner',
          'is_renter',
          'comment',
        ]}
        rules={getMandantRules()}
        onChooseContact={(client) => {
          if (type === 'notary') {
            _setNotary(client);
          } else {
            _addClient(client);
          }
          disclosure.onClose();
        }}
        isDelegation={mandate.is_delegation ? true : false}
      />
    </Box>
  );
}
