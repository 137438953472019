interface IFileUploadIllustration {
  svgProps?: any;
  height?: number;
  width?: number;
}

export default function({ width = 230, height = 166, svgProps }: IFileUploadIllustration) {
  return (
    <svg width={width} height={height} viewBox="0 0 230 166" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
      <circle cx="118" cy="80" r="80" fill="#F5F5F5"/>
      <circle cx="34" cy="20" r="8" fill="#F5F5F5"/>
      <circle cx="206" cy="126" r="6" fill="#F5F5F5"/>
      <circle cx="33" cy="138" r="10" fill="#F5F5F5"/>
      <circle cx="218" cy="38" r="10" fill="#F5F5F5"/>
      <circle cx="199" cy="11" r="7" fill="#F5F5F5"/>
      <g filter="url(#filter0_ddd_1182_2741)">
        <path d="M64.0781 124.214L116.529 99.7552C119.163 98.5272 120.302 95.3969 119.074 92.7634L88.0986 26.3361L67.1232 18.7017L24.2085 38.7132C21.5751 39.9412 20.4357 43.0715 21.6637 45.7049L57.0863 121.669C58.3143 124.302 61.4446 125.442 64.0781 124.214Z" fill="url(#paint0_linear_1182_2741)"/>
        <path d="M88.3252 26.2305L88.2814 26.1366L88.1841 26.1012L67.2087 18.4668L67.1114 18.4314L67.0176 18.4751L24.1029 38.4866C21.3443 39.7729 20.1508 43.052 21.4372 45.8106L56.8597 121.775C58.1461 124.533 61.4252 125.727 64.1838 124.44L116.635 99.9818C119.394 98.6955 120.587 95.4164 119.301 92.6578L88.3252 26.2305Z" stroke="black" stroke-opacity="0.08" stroke-width="0.5"/>
        <path d="M67.123 18.7017L88.0984 26.3361L73.7935 33.0066L67.123 18.7017Z" fill="#E9EAEB"/>
      </g>
      <g filter="url(#filter1_ddd_1182_2741)">
        <path d="M89.1516 106.339H147.025C149.931 106.339 152.286 103.984 152.286 101.078V27.7837L136.503 12H89.1516C86.2459 12 83.8904 14.3555 83.8904 17.2612V101.078C83.8904 103.984 86.2459 106.339 89.1516 106.339Z" fill="url(#paint1_linear_1182_2741)"/>
        <path d="M152.536 27.7837V27.6802L152.463 27.6069L136.68 11.8232L136.606 11.75H136.503H89.1516C86.1078 11.75 83.6404 14.2175 83.6404 17.2612V101.078C83.6404 104.122 86.1078 106.589 89.1516 106.589H147.025C150.069 106.589 152.536 104.122 152.536 101.078V27.7837Z" stroke="black" stroke-opacity="0.08" stroke-width="0.5"/>
        <path d="M136.503 12L152.286 27.7837H136.503V12Z" fill="#E9EAEB"/>
      </g>
      <g filter="url(#filter2_ddd_1182_2741)">
        <path d="M118.911 99.724L171.362 124.182C173.996 125.41 177.126 124.271 178.354 121.638L209.329 55.2103L201.695 34.2349L158.78 14.2235C156.147 12.9955 153.017 14.1348 151.789 16.7683L116.366 92.7322C115.138 95.3657 116.277 98.496 118.911 99.724Z" fill="url(#paint2_linear_1182_2741)"/>
        <path d="M209.556 55.316L209.6 55.2221L209.564 55.1248L201.93 34.1494L201.895 34.0521L201.801 34.0084L158.886 13.9969C156.127 12.7106 152.848 13.9041 151.562 16.6626L116.139 92.6266C114.853 95.3852 116.047 98.6642 118.805 99.9506L171.256 124.409C174.015 125.695 177.294 124.502 178.58 121.743L209.556 55.316Z" stroke="black" stroke-opacity="0.08" stroke-width="0.5"/>
        <path d="M201.695 34.2349L209.329 55.2102L195.024 48.5398L201.695 34.2349Z" fill="#E9EAEB"/>
      </g>
      <path d="M90 116C90 100.536 102.536 88 118 88C133.464 88 146 100.536 146 116C146 131.464 133.464 144 118 144C102.536 144 90 131.464 90 116Z" fill="black" fill-opacity="0.2"/>
      <path d="M113.333 120.667L118 116M118 116L122.667 120.667M118 116V126.5M127.333 121.533C128.758 120.356 129.667 118.576 129.667 116.583C129.667 113.04 126.794 110.167 123.25 110.167C122.995 110.167 122.757 110.034 122.627 109.814C121.106 107.232 118.297 105.5 115.083 105.5C110.251 105.5 106.333 109.418 106.333 114.25C106.333 116.66 107.308 118.843 108.885 120.426" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <defs>
        <filter id="filter0_ddd_1182_2741" x="-0.559753" y="12.0312" width="141.857" height="154.406" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1182_2741"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.04 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1182_2741"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1182_2741"/>
          <feOffset dy="8"/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.03 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_1182_2741" result="effect2_dropShadow_1182_2741"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect3_dropShadow_1182_2741"/>
          <feOffset dy="20"/>
          <feGaussianBlur stdDeviation="12"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="effect2_dropShadow_1182_2741" result="effect3_dropShadow_1182_2741"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1182_2741" result="shape"/>
        </filter>
        <filter id="filter1_ddd_1182_2741" x="63.3904" y="11.5" width="109.396" height="135.339" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1182_2741"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.04 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1182_2741"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1182_2741"/>
          <feOffset dy="8"/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.03 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_1182_2741" result="effect2_dropShadow_1182_2741"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect3_dropShadow_1182_2741"/>
          <feOffset dy="20"/>
          <feGaussianBlur stdDeviation="12"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="effect2_dropShadow_1182_2741" result="effect3_dropShadow_1182_2741"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1182_2741" result="shape"/>
        </filter>
        <filter id="filter2_ddd_1182_2741" x="94.1425" y="12" width="141.857" height="154.406" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1182_2741"/>
          <feOffset dy="3"/>
          <feGaussianBlur stdDeviation="1.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.04 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1182_2741"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1182_2741"/>
          <feOffset dy="8"/>
          <feGaussianBlur stdDeviation="4"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.03 0"/>
          <feBlend mode="normal" in2="effect1_dropShadow_1182_2741" result="effect2_dropShadow_1182_2741"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect3_dropShadow_1182_2741"/>
          <feOffset dy="20"/>
          <feGaussianBlur stdDeviation="12"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.0392157 0 0 0 0 0.0496732 0 0 0 0 0.0705882 0 0 0 0.08 0"/>
          <feBlend mode="normal" in2="effect2_dropShadow_1182_2741" result="effect3_dropShadow_1182_2741"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_1182_2741" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_1182_2741" x1="60.9966" y1="124.418" x2="19.197" y2="49.0089" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F5F5F5"/>
          <stop offset="1" stop-color="#FAFAFA"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1182_2741" x1="86.2723" y1="105.223" x2="80.2585" y2="19.2131" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F5F5F5"/>
          <stop offset="1" stop-color="#FAFAFA"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1182_2741" x1="116.773" y1="97.4951" x2="147.672" y2="17.0024" gradientUnits="userSpaceOnUse">
          <stop stop-color="#F5F5F5"/>
          <stop offset="1" stop-color="#FAFAFA"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
