import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Slider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  Divider,
  Text,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormHelperText,
  HStack,
  Progress,
  CircularProgress,
  CircularProgressLabel,
  Badge,
  Button,
  useToast,
  IconButton,
  Switch,
} from '@chakra-ui/react';
import { PriceUtils } from '@meilleursbiens/utils';
import { Card } from '@meilleursbiens/ui';
import { jsPDF } from 'jspdf';
import { autoTable } from 'jspdf-autotable';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

export default function MBCoreAdsSimulatorsAddedValueComponent() {
  // Couleurs dynamiques selon le thème
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const accentColor = useColorModeValue('red.500', 'red.300');
  const tableHeaderBg = useColorModeValue('gray.50', 'gray.600');
  const sectionBg = useColorModeValue('gray.50', 'gray.600');
  const toast = useToast();
  const printRef = useRef(null);
  const [isGeneratingPdf, setIsGeneratingPdf] = useState<boolean>(false);
  const [includeDetentionCosts, setIncludeDetentionCosts] = useState<boolean>(false);

  // États pour les coûts d'acquisition
  const [prixAchat, setPrixAchat] = useState(280000);
  const [fraisAgence, setFraisAgence] = useState(0);
  const [fraisNotaire, setFraisNotaire] = useState(22400);
  const [fraisDossierBancaire, setFraisDossierBancaire] = useState(1000);
  const [fraisCourtier, setFraisCourtier] = useState(2800);
  const [fraisGarantie, setFraisGarantie] = useState(3500);
  
  // États pour les coûts de rénovation
  const [coutsTravaux, setCoutsTravaux] = useState(33000);
  const [coutsMobilier, setCoutsMobilier] = useState(0);
  
  // États pour les coûts de gestion
  const [revenusBruts, setRevenusBruts] = useState(0);
  const [taxeFonciere, setTaxeFonciere] = useState(300);
  const [chargesCopropriete, setChargesCopropriete] = useState(300);
  const [assurancePNO, setAssurancePNO] = useState(150);
  const [comptabilite, setComptabilite] = useState(0);
  
  // États pour les coûts de détention
  const [dureeDetention, setDureeDetention] = useState(5);
  const [interetsBancaires, setInteretsBancaires] = useState(500);
  const [fraisEntretien, setFraisEntretien] = useState(120);
  const [fraisGestion, setFraisGestion] = useState(0);
  const [chargesComptable, setChargesComptable] = useState(240);
  const [autresCharges, setAutresCharges] = useState(0);
  
  // États pour la phase de vente
  const [prixVente, setPrixVente] = useState(350000);
  const [fraisAgenceVente, setFraisAgenceVente] = useState(0);
  const [diagnostics, setDiagnostics] = useState(300);
  const [mainleveeHypotheque, setMainleveeHypotheque] = useState(200);
  const [restitutionHypotheque, setRestitutionHypotheque] = useState(0);
  const [IRA, setIRA] = useState(0);
  const [autresChargesVente, setAutresChargesVente] = useState(0);

  const [prixVenteNet, setPrixVenteNet] = useState(0);

  // État pour stocker les résultats
  const [result, setResult] = useState<any>(null);

  // Fonction pour suggérer des valeurs plausibles en fonction du prix d'achat
  const suggestValues = () => {
    // Calcul des frais de notaire (environ 8% pour l'ancien, moins pour le neuf)
    const nouveauFraisNotaire = Math.round(prixAchat * 0.08);
    
    // Frais d'agence (environ 5% si applicable)
    const nouveauFraisAgence = Math.round(prixAchat * 0.05);
    
    // Frais de courtier (environ 0.3% du prix d'achat)
    const nouveauFraisCourtier = Math.round(prixAchat * 0.003);
    
    // Frais de garantie (environ 1.2% du prix d'achat)
    const nouveauFraisGarantie = Math.round(prixAchat * 0.007);
    
    // Coûts des travaux (environ 10-15% pour une rénovation standard)
    const nouveauCoutsTravaux = Math.round(prixAchat * 0.12);
    
    // Taxe foncière mensuelle (environ 1% du prix d'achat annuel, divisé par 12)
    const nouveauTaxeFonciere = Math.round((prixAchat * 0.007) / 12);
    
    // Charges de copropriété (estimation basée sur la taille probable du bien)
    const nouveauChargesCopropriete = Math.round(prixAchat < 200000 ? 150 : prixAchat < 400000 ? 300 : 450);
    
    // Intérêts bancaires mensuels (estimation basée sur un prêt de 80% du prix d'achat)
    const nouveauInteretsBancaires = Math.round((prixAchat * 0.8 * 0.035) / 12);
    
    // Prix de vente estimé (croissance annuelle de 3% sur la durée de détention)
    const nouveauPrixVente = Math.round(prixAchat * Math.pow(1.03, dureeDetention));
    
    // Frais d'agence pour la vente (environ 5% du prix de vente)
    const nouveauFraisAgenceVente = Math.round(nouveauPrixVente * 0.05);
    
    // Mise à jour des états
    setFraisNotaire(nouveauFraisNotaire);
    setFraisAgence(nouveauFraisAgence);
    setFraisCourtier(nouveauFraisCourtier);
    setFraisGarantie(nouveauFraisGarantie);
    setCoutsTravaux(nouveauCoutsTravaux);
    setTaxeFonciere(nouveauTaxeFonciere);
    setChargesCopropriete(nouveauChargesCopropriete);
    setInteretsBancaires(nouveauInteretsBancaires);
    setPrixVente(nouveauPrixVente);
    setFraisAgenceVente(nouveauFraisAgenceVente);
    
    toast({
      title: "Valeurs mises à jour",
      description: "Les valeurs ont été ajustées en fonction du prix d'achat de " + formatCurrency(prixAchat),
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  // Calcul de la plus-value
  const calculateAddedValue = () => {
    // Calcul des coûts d'acquisition
    const coutAcquisitionTotal = prixAchat + fraisAgence + fraisNotaire + fraisDossierBancaire + fraisCourtier + fraisGarantie;
    
    // Calcul des coûts de rénovation
    const coutRenovationTotal = coutsTravaux + coutsMobilier;
    
    // Calcul des coûts de détention annuels
    const coutDetentionAnnuel = includeDetentionCosts ? 
      (taxeFonciere + chargesCopropriete + assurancePNO) * 12 + 
      interetsBancaires * 12 + fraisEntretien * 12 + fraisGestion * 12 + 
      chargesComptable + autresCharges : 0;
    
    // Coût de détention total sur la période
    const coutDetentionTotal = coutDetentionAnnuel * dureeDetention;
    
    // Calcul des frais de vente
    const fraisVenteTotal = fraisAgenceVente + diagnostics + mainleveeHypotheque + restitutionHypotheque + IRA + autresChargesVente;
    
    // Calcul du prix de vente net
    const prixVenteNetCalcule = prixVente - fraisVenteTotal;
    setPrixVenteNet(prixVenteNetCalcule);
    
    // Calcul du coût total de l'investissement
    const coutTotalInvestissement = coutAcquisitionTotal + coutRenovationTotal + coutDetentionTotal;
    
    // Calcul de la plus-value brute
    const plusValueBrute = prixVenteNetCalcule - prixAchat;
    
    // Calcul de la plus-value nette
    const plusValueNette = prixVenteNetCalcule - coutTotalInvestissement;
    
    // Calcul du ROI
    const roi = (plusValueNette / coutTotalInvestissement) * 100;
    
    // Calcul du ROI annualisé
    const roiAnnualise = roi / dureeDetention;
    
    // Calcul du taux de rendement interne (TRI) simplifié
    // Note: Un calcul précis du TRI nécessiterait une méthode itérative plus complexe
    const tri = Math.pow((prixVenteNetCalcule / coutTotalInvestissement), (1 / dureeDetention)) - 1;
    
    // Calcul du multiplicateur d'investissement
    const multiplicateurInvestissement = prixVenteNetCalcule / coutTotalInvestissement;
    
    // Calcul du ratio coût/bénéfice
    const ratioCoutBenefice = plusValueNette / coutTotalInvestissement;
    
    // Calcul du rendement moyen annuel
    const rendementMoyenAnnuel = (plusValueNette / dureeDetention) / coutTotalInvestissement * 100;
    
    // Calcul du point mort (break-even)
    const pointMort = coutTotalInvestissement / prixVente * 100;
    
    // Calcul de la répartition des coûts
    const pourcentageAcquisition = (coutAcquisitionTotal / coutTotalInvestissement) * 100;
    const pourcentageRenovation = (coutRenovationTotal / coutTotalInvestissement) * 100;
    const pourcentageDetention = (coutDetentionTotal / coutTotalInvestissement) * 100;
    
    setResult({
      coutAcquisitionTotal,
      coutRenovationTotal,
      coutDetentionAnnuel,
      coutDetentionTotal,
      fraisVenteTotal,
      prixVenteNetCalcule,
      coutTotalInvestissement,
      plusValueBrute,
      plusValueNette,
      roi,
      roiAnnualise,
      tri: tri * 100,
      multiplicateurInvestissement,
      ratioCoutBenefice,
      rendementMoyenAnnuel,
      pointMort,
      pourcentageAcquisition,
      pourcentageRenovation,
      pourcentageDetention,
      coutMensuelMoyen: coutDetentionAnnuel / 12,
      plusValueNetteParAn: plusValueNette / dureeDetention,
      rentabiliteInvestissement: (plusValueNette / coutTotalInvestissement) * 100,
      tauxCroissanceAnnuelCompose: (Math.pow((prixVente / prixAchat), (1 / dureeDetention)) - 1) * 100
    });
  };

  // Effet pour recalculer les résultats lorsque les valeurs changent
  useEffect(() => {
    calculateAddedValue();
  }, [
    prixAchat, fraisAgence, fraisNotaire, fraisDossierBancaire, fraisCourtier, fraisGarantie,
    coutsTravaux, coutsMobilier,
    taxeFonciere, chargesCopropriete, assurancePNO, comptabilite,
    dureeDetention, interetsBancaires, fraisEntretien, fraisGestion, chargesComptable, autresCharges,
    prixVente, fraisAgenceVente, diagnostics, mainleveeHypotheque, restitutionHypotheque, IRA, autresChargesVente,
    includeDetentionCosts
  ]);

  // Fonction pour formater les montants en devise
  const formatCurrency = (value: number, isForPdf: boolean = false): string => {
    const price = PriceUtils.formatPriceWithDecimals(value).toString();
    return price.replace('\u202F', ' ');
  };

  // Fonction pour générer le rapport PDF
  const generatePDF = () => {
    if (!result) return;
    
    setIsGeneratingPdf(true);
    
    try {
      const doc = new jsPDF();
      
      // Titre du document
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(20);
      doc.text("Rapport de simulation de plus-value immobilière", 15, 20);
      
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(10);
      doc.text("Date de simulation: " + new Date().toLocaleDateString(), 15, 30);
      
      // Paramètres de l'investissement
      doc.setFontSize(16);
      doc.text("Paramètres d'acquisition", 15, 40);
      
      const acquisitionParams = [
        ["Prix d'achat", formatCurrency(prixAchat, true)],
        ["Frais d'agence", formatCurrency(fraisAgence, true)],
        ["Frais de notaire", formatCurrency(fraisNotaire, true)],
        ["Frais bancaires", formatCurrency(fraisDossierBancaire, true)],
        ["Frais de courtier", formatCurrency(fraisCourtier, true)],
        ["Frais de garantie", formatCurrency(fraisGarantie, true)]
      ];
      
      autoTable(doc, {
        startY: 45,
        head: [["Paramètre", "Valeur"]],
        body: acquisitionParams,
        theme: 'striped',
        headStyles: { fillColor: [220, 50, 50] }
      });
      
      // Paramètres de rénovation
      doc.setFontSize(16);
      doc.text("Paramètres de rénovation", 15, (doc as any).lastAutoTable.finalY + 15);
      
      const renovationParams = [
        ["Coûts des travaux", formatCurrency(coutsTravaux, true)],
        ["Coûts du mobilier", formatCurrency(coutsMobilier, true)]
      ];
      
      autoTable(doc, {
        startY: (doc as any).lastAutoTable.finalY + 20,
        head: [["Paramètre", "Valeur"]],
        body: renovationParams,
        theme: 'striped',
        headStyles: { fillColor: [50, 150, 50] }
      });
      
      // Paramètres de détention
      doc.setFontSize(16);
      doc.text("Paramètres de détention", 15, (doc as any).lastAutoTable.finalY + 15);
      
      const detentionParams = [
        ["Durée de détention", dureeDetention + " ans"],
        ["Coûts de détention inclus", includeDetentionCosts ? "Oui" : "Non"],
        ...(includeDetentionCosts ? [
          ["Intérêts bancaires mensuels", formatCurrency(interetsBancaires, true)],
          ["Taxe foncière mensuelle", formatCurrency(taxeFonciere, true)],
          ["Charges de copropriété", formatCurrency(chargesCopropriete, true)],
          ["Assurance PNO", formatCurrency(assurancePNO, true)],
          ["Frais d'entretien", formatCurrency(fraisEntretien, true)]
        ] : [])
      ];
      
      autoTable(doc, {
        startY: (doc as any).lastAutoTable.finalY + 20,
        head: [["Paramètre", "Valeur"]],
        body: detentionParams,
        theme: 'striped',
        headStyles: { fillColor: [50, 50, 220] }
      });
      
      // Paramètres de vente
      doc.setFontSize(16);
      doc.text("Paramètres de vente", 15, (doc as any).lastAutoTable.finalY + 15);
      
      const venteParams = [
        ["Prix de vente estimé", formatCurrency(prixVente, true)],
        ["Frais d'agence (vente)", formatCurrency(fraisAgenceVente, true)],
        ["Diagnostics", formatCurrency(diagnostics, true)],
        ["Mainlevée hypothèque", formatCurrency(mainleveeHypotheque, true)],
        ["Indemnités remboursement anticipé", formatCurrency(IRA, true)]
      ];
      
      autoTable(doc, {
        startY: (doc as any).lastAutoTable.finalY + 20,
        head: [["Paramètre", "Valeur"]],
        body: venteParams,
        theme: 'striped',
        headStyles: { fillColor: [150, 100, 50] }
      });
      
      // Résultats financiers
      doc.setFontSize(16);
      doc.text("Résultats financiers", 15, (doc as any).lastAutoTable.finalY + 30);
      
      const resultParams = [
        ["Coût d'acquisition total", formatCurrency(result.coutAcquisitionTotal, true)],
        ["Coût de rénovation total", formatCurrency(result.coutRenovationTotal, true)],
        ["Coût de détention total", formatCurrency(result.coutDetentionTotal, true)],
        ["Frais de vente total", formatCurrency(result.fraisVenteTotal, true)],
        ["Prix de vente net", formatCurrency(result.prixVenteNetCalcule, true)],
        ["Plus-value brute", formatCurrency(result.plusValueBrute, true)],
        ["Plus-value nette", formatCurrency(result.plusValueNette, true)],
        ["ROI total", result.roi.toFixed(2) + "%"],
        ["ROI annualisé", result.roiAnnualise.toFixed(2) + "%"],
        ["TRI", result.tri.toFixed(2) + "%"],
        ["Multiplicateur d'investissement", result.multiplicateurInvestissement.toFixed(2) + "x"],
        ["Point mort", result.pointMort.toFixed(2) + "%"]
      ];
      
      autoTable(doc, {
        startY: (doc as any).lastAutoTable.finalY + 20,
        head: [["Indicateur", "Valeur"]],
        body: resultParams,
        theme: 'striped',
        headStyles: { fillColor: [50, 150, 50] }
      });
      
      // Répartition des coûts
      doc.setFontSize(16);
      doc.text("Répartition des coûts", 15, (doc as any).lastAutoTable.finalY + 15);
      
      const repartitionParams = [
        ["Acquisition", result.pourcentageAcquisition.toFixed(1) + "%"],
        ["Rénovation", result.pourcentageRenovation.toFixed(1) + "%"],
        ["Détention", result.pourcentageDetention.toFixed(1) + "%"]
      ];
      
      autoTable(doc, {
        startY: (doc as any).lastAutoTable.finalY + 20,
        head: [["Type de coût", "Pourcentage"]],
        body: repartitionParams,
        theme: 'striped',
        headStyles: { fillColor: [150, 100, 50] }
      });
      
      // Pied de page
      const pageCount = (doc as any).internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setTextColor(100, 100, 100);
        doc.text("Simulation générée par MeilleursBiens.com - Page " + i + " sur " + pageCount, 15, doc.internal.pageSize.height - 7);
      }
      
      // Avertissement
      doc.setPage(pageCount);
      doc.setFontSize(10);
      doc.setTextColor(100, 100, 100);
      doc.text("Cette simulation est donnée à titre indicatif et ne constitue pas un conseil en investissement.", 15, (doc as any).lastAutoTable.finalY + 20);
      
      // Téléchargement du PDF
      doc.save("simulation-plus-value-immobiliere.pdf");
      
      toast({
        title: "Rapport généré avec succès",
        description: "Votre rapport PDF a été téléchargé",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de la génération du PDF:", error);
      toast({
        title: "Erreur",
        description: "Impossible de générer le rapport PDF",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsGeneratingPdf(false);
    }
  };

  return (
    <Card>
      <VStack spacing={6} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
          {/* Colonne de gauche: Acquisition et Rénovation */}
          <VStack spacing={6} align="stretch">
            <Box p={4} bg={sectionBg} borderRadius="md">
              <Heading as="h3" size="md" mb={4}>
                Acquisition
              </Heading>
              
              <FormControl mb={4}>
                <FormLabel>Prix d'achat</FormLabel>
                <NumberInput
                  value={prixAchat}
                  onChange={(_, value) => setPrixAchat(value)}
                  min={0}
                  step={1000}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <HStack mt={2} justify="space-between">
                  <FormHelperText fontSize="sm" color="muted">
                    Montant payé pour l'acquisition du bien immobilier
                  </FormHelperText>
                  <Button 
                    size="xs" 
                    colorScheme="red" 
                    onClick={suggestValues}
                    title="Suggérer des valeurs plausibles basées sur le prix d'achat"
                  >
                    Suggérer des valeurs
                  </Button>
                </HStack>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Frais d'agence</FormLabel>
                <NumberInput
                  value={fraisAgence}
                  onChange={(_, value) => setFraisAgence(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Commission versée à l'agence immobilière pour l'achat
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Frais de notaire</FormLabel>
                <NumberInput
                  value={fraisNotaire}
                  onChange={(_, value) => setFraisNotaire(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Frais liés à l'acte authentique (environ 7-8% du prix d'achat)
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Frais bancaires</FormLabel>
                <NumberInput
                  value={fraisDossierBancaire}
                  onChange={(_, value) => setFraisDossierBancaire(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Frais de dossier et autres frais liés au crédit immobilier
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Frais de courtier</FormLabel>
                <NumberInput
                  value={fraisCourtier}
                  onChange={(_, value) => setFraisCourtier(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Honoraires du courtier en prêt immobilier
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Frais de garantie</FormLabel>
                <NumberInput
                  value={fraisGarantie}
                  onChange={(_, value) => setFraisGarantie(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Frais liés aux garanties bancaires (hypothèque, caution, etc.)
                </FormHelperText>
              </FormControl>
            </Box>
            
            <Box p={4} bg={sectionBg} borderRadius="md">
              <Heading as="h3" size="md" mb={4}>
                Rénovation
              </Heading>
              
              <FormControl mb={4}>
                <FormLabel>Coûts des travaux</FormLabel>
                <NumberInput
                  value={coutsTravaux}
                  onChange={(_, value) => setCoutsTravaux(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Montant total des travaux de rénovation et d'amélioration
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Coûts du mobilier</FormLabel>
                <NumberInput
                  value={coutsMobilier}
                  onChange={(_, value) => setCoutsMobilier(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Dépenses pour l'ameublement et l'équipement du bien
                </FormHelperText>
              </FormControl>
            </Box>
            
            <Box p={4} bg={sectionBg} borderRadius="md">
              <Heading as="h3" size="md" mb={4}>
                Charges mensuelles
              </Heading>
              
              <FormControl mb={4}>
                <FormLabel>Taxe foncière mensuelle</FormLabel>
                <NumberInput
                  value={taxeFonciere}
                  onChange={(_, value) => setTaxeFonciere(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Montant mensuel de la taxe foncière (impôt local sur la propriété)
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Charges de copropriété</FormLabel>
                <NumberInput
                  value={chargesCopropriete}
                  onChange={(_, value) => setChargesCopropriete(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Charges mensuelles de copropriété
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Assurance PNO</FormLabel>
                <NumberInput
                  value={assurancePNO}
                  onChange={(_, value) => setAssurancePNO(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Assurance Propriétaire Non Occupant mensuelle
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Comptabilité</FormLabel>
                <NumberInput
                  value={comptabilite}
                  onChange={(_, value) => setComptabilite(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Frais mensuels de comptabilité
                </FormHelperText>
              </FormControl>
            </Box>
          </VStack>
          
          {/* Colonne de droite: Détention et Vente */}
          <VStack spacing={6} align="stretch">
            <Box p={4} bg={sectionBg} borderRadius="md">
            <HStack justify="space-between">
                <Heading as="h3" size="md" mb={4}>
                  Détention
                </Heading>
                <Switch 
                  isChecked={includeDetentionCosts} 
                  onChange={() => setIncludeDetentionCosts(!includeDetentionCosts)} 
                  colorScheme="red" 
                  size="md"
                />
              </HStack>
              
              <FormControl mb={4}>
                <FormLabel>Durée de détention (années)</FormLabel>
                <NumberInput
                  value={dureeDetention}
                  onChange={(_, value) => setDureeDetention(value)}
                  min={1}
                  max={30}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Nombre d'années pendant lesquelles vous conserverez le bien
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Intérêts bancaires mensuels</FormLabel>
                <NumberInput
                  value={interetsBancaires}
                  onChange={(_, value) => setInteretsBancaires(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Montant mensuel des intérêts payés sur votre crédit immobilier
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Frais d'entretien mensuels</FormLabel>
                <NumberInput
                  value={fraisEntretien}
                  onChange={(_, value) => setFraisEntretien(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Frais mensuels pour l'entretien du bien
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Frais de gestion mensuels</FormLabel>
                <NumberInput
                  value={fraisGestion}
                  onChange={(_, value) => setFraisGestion(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Frais mensuels de gestion locative
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Autres charges annuelles</FormLabel>
                <NumberInput
                  value={autresCharges}
                  onChange={(_, value) => setAutresCharges(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Autres charges annuelles non catégorisées
                </FormHelperText>
              </FormControl>
            </Box>
            
            <Box p={4} bg={sectionBg} borderRadius="md">
              <Heading as="h3" size="md" mb={4}>
                Vente
              </Heading>
              
              <FormControl mb={4}>
                <FormLabel>Prix de vente estimé</FormLabel>
                <NumberInput
                  value={prixVente}
                  onChange={(_, value) => setPrixVente(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Prix de vente estimé du bien à la fin de la période de détention
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Frais d'agence (vente)</FormLabel>
                <NumberInput
                  value={fraisAgenceVente}
                  onChange={(_, value) => setFraisAgenceVente(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Commission versée à l'agence immobilière pour la vente
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Diagnostics</FormLabel>
                <NumberInput
                  value={diagnostics}
                  onChange={(_, value) => setDiagnostics(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Coût des diagnostics obligatoires pour la vente (DPE, amiante, etc.)
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Mainlevée d'hypothèque</FormLabel>
                <NumberInput
                  value={mainleveeHypotheque}
                  onChange={(_, value) => setMainleveeHypotheque(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Frais de mainlevée d'hypothèque
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Restitution d'hypothèque</FormLabel>
                <NumberInput
                  value={restitutionHypotheque}
                  onChange={(_, value) => setRestitutionHypotheque(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Frais de restitution d'hypothèque
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Indemnités de remboursement anticipé</FormLabel>
                <NumberInput
                  value={IRA}
                  onChange={(_, value) => setIRA(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Pénalités pour remboursement anticipé du crédit
                </FormHelperText>
              </FormControl>
              
              <FormControl mb={4}>
                <FormLabel>Autres charges de vente</FormLabel>
                <NumberInput
                  value={autresChargesVente}
                  onChange={(_, value) => setAutresChargesVente(value)}
                  min={0}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText fontSize="sm" color="muted">
                  Autres frais liés à la vente du bien
                </FormHelperText>
              </FormControl>
            </Box>
          </VStack>
        </SimpleGrid>
        
        {result && (
          <VStack spacing={6} align="stretch" mt={8}>

            <HStack justify="space-between" align="center">
            <Heading as="h3" size="md">
              Résultats de votre simulation
            </Heading>

            <Button 
                leftIcon={<ArrowDownTrayIcon width={20} strokeWidth={2}  />} 
                colorScheme="red" 
                onClick={generatePDF}
                isLoading={isGeneratingPdf}
                loadingText="Génération en cours..."
              >
                Générer un rapport PDF
              </Button>
            </HStack>
            
            <SimpleGrid columns={{ base: 1, md: 4 }} spacing={4}>
              <Stat
                p={4}
                shadow="sm"
                border="1px"
                borderColor={borderColor}
                borderRadius="md"
              >
                <StatLabel>Plus-value nette</StatLabel>
                <StatNumber noOfLines={1} color={result.plusValueNette >= 0 ? "green.500" : "red.500"}>
                  {formatCurrency(result.plusValueNette)}
                </StatNumber>
                <StatHelpText>après tous les frais</StatHelpText>
              </Stat>
              
              <Stat
                p={4}
                shadow="sm"
                border="1px"
                borderColor={borderColor}
                borderRadius="md"
              >
                <StatLabel>ROI total</StatLabel>
                <StatNumber noOfLines={1} color={result.roi >= 0 ? "green.500" : "red.500"}>
                  {result.roi.toFixed(2)}%
                </StatNumber>
                <StatHelpText>sur la période de {dureeDetention} ans</StatHelpText>
              </Stat>
              
              <Stat
                p={4}
                shadow="sm"
                border="1px"
                borderColor={borderColor}
                borderRadius="md"
              >
                <StatLabel>ROI annualisé</StatLabel>
                <StatNumber noOfLines={1} color={result.roiAnnualise >= 0 ? "green.500" : "red.500"}>
                  {result.roiAnnualise.toFixed(2)}%
                </StatNumber>
                <StatHelpText>par an</StatHelpText>
              </Stat>
              
              <Stat
                p={4}
                shadow="sm"
                border="1px"
                borderColor={borderColor}
                borderRadius="md"
              >
                <StatLabel>TRI</StatLabel>
                <StatNumber noOfLines={1} color={result.tri >= 0 ? "green.500" : "red.500"}>
                  {result.tri.toFixed(2)}%
                </StatNumber>
                <StatHelpText>taux de rendement interne</StatHelpText>
              </Stat>
            </SimpleGrid>

            <Alert 
              status={result.roi >= 15 ? "success" : result.roi >= 5 ? "info" : "warning"} 
              borderRadius="md"
              mt={0}
            >
              <AlertIcon />
              <VStack align="start" spacing={1}>
                <AlertTitle>
                  {result.roi >= 15 
                    ? "Projet très rentable" 
                    : result.roi >= 5 
                      ? "Projet rentable" 
                      : "Rentabilité limitée"}
                </AlertTitle>
                <AlertDescription>
                  {result.roi >= 15 
                    ? `Avec un ROI de ${result.roi.toFixed(2)}%, ce projet présente une excellente rentabilité. Le TRI de ${result.tri.toFixed(2)}% est très attractif.`
                    : result.roi >= 5 
                      ? `Avec un ROI de ${result.roi.toFixed(2)}%, ce projet est rentable. Le TRI de ${result.tri.toFixed(2)}% est dans la moyenne du marché.`
                      : `Avec un ROI de ${result.roi.toFixed(2)}%, ce projet présente une rentabilité limitée. Envisagez de renégocier le prix d'achat ou d'optimiser les coûts de rénovation.`}
                </AlertDescription>
              </VStack>
            </Alert>
            
            <Tabs isFitted size='sm' colorScheme="red">
              <TabList mb="1em">
                <Tab>Détails financiers</Tab>
                <Tab>Analyse des coûts</Tab>
                <Tab>Indicateurs de performance</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    <Box
                      shadow="sm"
                      border="1px"
                      borderColor={borderColor}
                      borderRadius="md"
                    >
                      <Heading as="h4" size="sm" mb={2} p={4} pb={0}>
                        Détail des coûts
                      </Heading>
                      <Table variant="simple" size="sm" mb={0}>
                        <Tbody>
                          <Tr>
                            <Td>Coût d'acquisition</Td>
                            <Td isNumeric>{formatCurrency(result.coutAcquisitionTotal)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Coût de rénovation</Td>
                            <Td isNumeric>{formatCurrency(result.coutRenovationTotal)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Coût de détention</Td>
                            <Td isNumeric>{formatCurrency(result.coutDetentionTotal)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Frais de vente</Td>
                            <Td isNumeric>{formatCurrency(result.fraisVenteTotal)}</Td>
                          </Tr>
                          <Tr fontWeight="bold">
                            <Td>Coût total</Td>
                            <Td isNumeric>{formatCurrency(result.coutTotalInvestissement)}</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Box>
                    
                    <Box
                      shadow="sm"
                      border="1px"
                      borderColor={borderColor}
                      borderRadius="md"
                    >
                      <Heading as="h4" size="sm" mb={2} p={4} pb={0}>
                        Résultat financier
                      </Heading>
                      <Table variant="simple" size="sm">
                        <Tbody>
                          <Tr>
                            <Td>Prix d'achat</Td>
                            <Td isNumeric>{formatCurrency(prixAchat)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Prix de vente brut</Td>
                            <Td isNumeric>{formatCurrency(prixVente)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Prix de vente net</Td>
                            <Td isNumeric>{formatCurrency(result.prixVenteNetCalcule)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Plus-value brute</Td>
                            <Td isNumeric color={result.plusValueBrute >= 0 ? "green.500" : "red.500"}>
                              {formatCurrency(result.plusValueBrute)}
                            </Td>
                          </Tr>
                          <Tr fontWeight="bold">
                            <Td>Plus-value nette</Td>
                            <Td isNumeric color={result.plusValueNette >= 0 ? "green.500" : "red.500"}>
                              {formatCurrency(result.plusValueNette)}
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Box>
                  </SimpleGrid>
                </TabPanel>
                
                <TabPanel>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    <Box
                      p={4}
                      shadow="sm"
                      border="1px"
                      borderColor={borderColor}
                      borderRadius="md"
                    >
                      <Heading as="h4" size="sm" mb={3}>
                        Répartition des coûts
                      </Heading>
                      <VStack spacing={4} align="stretch">
                        <Box>
                          <HStack justify="space-between" mb={1}>
                            <Text fontSize="sm">Acquisition ({result.pourcentageAcquisition.toFixed(1)}%)</Text>
                            <Text fontSize="sm">{formatCurrency(result.coutAcquisitionTotal)}</Text>
                          </HStack>
                          <Progress value={result.pourcentageAcquisition} size="sm" colorScheme="blue" borderRadius="md" />
                        </Box>
                        <Box>
                          <HStack justify="space-between" mb={1}>
                            <Text fontSize="sm">Rénovation ({result.pourcentageRenovation.toFixed(1)}%)</Text>
                            <Text fontSize="sm">{formatCurrency(result.coutRenovationTotal)}</Text>
                          </HStack>
                          <Progress value={result.pourcentageRenovation} size="sm" colorScheme="green" borderRadius="md" />
                        </Box>
                        <Box>
                          <HStack justify="space-between" mb={1}>
                            <Text fontSize="sm">Détention ({result.pourcentageDetention.toFixed(1)}%)</Text>
                            <Text fontSize="sm">{formatCurrency(result.coutDetentionTotal)}</Text>
                          </HStack>
                          <Progress value={result.pourcentageDetention} size="sm" colorScheme="orange" borderRadius="md" />
                        </Box>
                      </VStack>
                    </Box>
                    
                    <Box
                      shadow="sm"
                      border="1px"
                      borderColor={borderColor}
                      borderRadius="md"
                    >
                      <Heading as="h4" size="sm" mb={2} p={4} pb={0}>
                        Coûts mensuels moyens
                      </Heading>
                      <Table variant="simple" size="sm">
                        <Tbody>
                          <Tr>
                            <Td>Taxe foncière</Td>
                            <Td isNumeric>{formatCurrency(taxeFonciere)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Charges copropriété</Td>
                            <Td isNumeric>{formatCurrency(chargesCopropriete)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Intérêts bancaires</Td>
                            <Td isNumeric>{formatCurrency(interetsBancaires)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Autres charges</Td>
                            <Td isNumeric>{formatCurrency(fraisEntretien + fraisGestion + (chargesComptable + autresCharges)/12)}</Td>
                          </Tr>
                          <Tr fontWeight="bold">
                            <Td>Total mensuel</Td>
                            <Td isNumeric>{formatCurrency(result.coutMensuelMoyen)}</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </Box>
                  </SimpleGrid>
                </TabPanel>
                
                <TabPanel>
                  <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
                    <Stat
                      p={4}
                      shadow="sm"
                      border="1px"
                      borderColor={borderColor}
                      borderRadius="md"
                    >
                      <StatLabel>Multiplicateur d'investissement</StatLabel>
                      <StatNumber color={result.multiplicateurInvestissement >= 1 ? "green.500" : "red.500"}>
                        {result.multiplicateurInvestissement.toFixed(2)}x
                      </StatNumber>
                      <StatHelpText>Ratio prix de vente / coût total</StatHelpText>
                    </Stat>
                    
                    <Stat
                      p={4}
                      shadow="sm"
                      border="1px"
                      borderColor={borderColor}
                      borderRadius="md"
                    >
                      <StatLabel>Plus-value annuelle</StatLabel>
                      <StatNumber color={result.plusValueNetteParAn >= 0 ? "green.500" : "red.500"}>
                        {formatCurrency(result.plusValueNetteParAn)}
                      </StatNumber>
                      <StatHelpText>Gain moyen par an</StatHelpText>
                    </Stat>
                    
                    <Stat
                      p={4}
                      shadow="sm"
                      border="1px"
                      borderColor={borderColor}
                      borderRadius="md"
                    >
                      <StatLabel>Taux de croissance annuel</StatLabel>
                      <StatNumber color={result.tauxCroissanceAnnuelCompose >= 0 ? "green.500" : "red.500"}>
                        {result.tauxCroissanceAnnuelCompose.toFixed(2)}%
                      </StatNumber>
                      <StatHelpText>TCAC du bien</StatHelpText>
                    </Stat>
                    
                    <Stat
                      p={4}
                      shadow="sm"
                      border="1px"
                      borderColor={borderColor}
                      borderRadius="md"
                    >
                      <StatLabel>Point mort</StatLabel>
                      <StatNumber>
                        {result.pointMort.toFixed(2)}%
                      </StatNumber>
                      <StatHelpText>% du prix de vente pour rentabilité</StatHelpText>
                    </Stat>
                </SimpleGrid>
             </TabPanel>
             </TabPanels>
            </Tabs>
            
            <Alert status="info" borderRadius="md">
              <AlertIcon />
              <VStack align="start" spacing={1}>
                <AlertTitle>À savoir</AlertTitle>
                <AlertDescription>
                  Cette simulation ne prend pas en compte la fiscalité sur les plus-values immobilières, 
                  qui dépend de votre situation personnelle et de la durée de détention du bien.
                </AlertDescription>
              </VStack>
            </Alert>
            
            <HStack spacing={4} justify="center" mt={4}>
              <Text fontSize="sm" color="gray.500">
                Cette simulation est donnée à titre indicatif et ne constitue pas un conseil en investissement.
              </Text>
            </HStack>
          </VStack>
        )}
        </VStack>
    </Card>
  );
}
