import React from 'react';

import { StarIcon } from '@chakra-ui/icons';
import { StackProps } from '@chakra-ui/layout/dist/stack/stack';
import {
  Avatar,
  AvatarBadge,
  AvatarProps,
  Box,
  HStack,
  Text,
  TextProps,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';

export interface PersonaProps extends StackProps {
  name?: string;
  nameRightElement?: React.ReactNode;
  avatarUrl?: string;
  presence?: 'online' | 'offline' | 'away' | 'busy' | 'star';
  secondaryText?: string | number;
  secondaryTextRightElement?: React.ReactNode;
  size: 'lg' | 'md' | 'sm' | 'xs' | '2xl' | 'xl';
  onClick?: () => void;
  avatarProps?: AvatarProps;
  nameProps?: TextProps;
  secondaryTextProps?: TextProps;
}

const Persona = forwardRef<PersonaProps, 'div'>((props: PersonaProps) => {
  const sizeToFontSize = {
    xs: 11,
    sm: 13,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 24,
  };

  function Badge() {
    if (props.presence === 'online') {
      return <AvatarBadge boxSize="1.18em" bg="green.500" />;
    }
    if (props.presence === 'offline') {
      return <AvatarBadge boxSize="1.18em" bg="gray.500" />;
    }
    if (props.presence === 'away') {
      return <AvatarBadge boxSize="1.18em" bg="yellow.500" />;
    }
    if (props.presence === 'busy') {
      return;
    }
    if (props.presence === 'star') {
      return (
        <AvatarBadge boxSize="1.18em" bg="yellow.400">
          <StarIcon w={'0.7em'} color="yellow.50" />
        </AvatarBadge>
      );
    }
    return <></>;
  }

  return (
    <HStack
      position={'relative'}
      spacing={2}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      data-tag="allowRowEvents"
      {...props}
    >
      <Avatar name={props.name} src={props.avatarUrl} size={props.size} {...props.avatarProps}>
        <Badge />
      </Avatar>
      {props.name || props.secondaryText ? (
        <Box>
          <HStack>
            <Text
              noOfLines={1}
              fontSize={sizeToFontSize[props.size]}
              fontWeight={600}
              lineHeight={'1em'}
              color={useColorModeValue('gray.900', 'white')}
              {...props.nameProps}
            >
              {props.name}
            </Text>
            {props.nameRightElement && props.nameRightElement}
          </HStack>
          {props.secondaryText && (
            <HStack spacing={1}>
              <Text
                noOfLines={1}
                color={'muted'}
                fontSize={sizeToFontSize[props.size] - 2}
                {...props.secondaryTextProps}
              >
                {props.secondaryText}
              </Text>
              {props.secondaryTextRightElement && props.secondaryTextRightElement}
            </HStack>
          )}
        </Box>
      ) : null}
    </HStack>
  );
});

export default Persona;
