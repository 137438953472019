import { MBApi } from '@meilleursbiens/api';
import { MBProVideo, MBProVideoWatch } from '@meilleursbiens/types';

export default class MBProReplaysAPI {
  static updateWatch(replayId: number, duration: number, durationMax: number) {
    return MBApi.url('/v1/mbpro/replays/watch/' + replayId)
      .parameters({
        duration,
        max_duration: durationMax,
      })
      .needAuth()
      .patch<MBProVideoWatch>();
  }

  static listAdmin() {
    return MBApi.url('/v1/mbpro/admin/replays').needAuth().get<{
      videos: MBProVideo[];
    }>();
  }

  static createAdmin(values: any) {
    return MBApi.url(`/v1/mbpro/admin/replays`)
      .needAuth()
      .parameters({
        ...values,
      })
      .put<MBProVideo>();
  }

  static deleteAdmin(id: number) {
    return MBApi.url(`/v1/mbpro/admin/replays/${id}`).needAuth().delete<void>();
  }

  static getReplayById(id: number) {
    return MBApi.url(`/v1/mbpro/admin/replays/${id}`).needAuth().get<MBProVideo>();
  }

  static updateReplay(id: number, values: MBProVideo) {
    return MBApi.url(`/v1/mbpro/admin/replays/${id}`).parameters(values).needAuth().patch<MBProVideo>();
  }
}
