import { MBProUserSeen } from '@meilleursbiens/types';

import MBProAPI from '../../../../../MBApi';

export default class MBProUserSeenAPI {
  static getOne(key: string) {
    return MBProAPI.url('/v1/mbpro/user/seen/' + key)
      .needAuth()
      .get<MBProUserSeen>();
  }

  static create(key: string, hasSeen = false) {
    return MBProAPI.url('/v1/mbpro/user/seen')
      .parameters({
        key,
        has_seen: hasSeen,
      })
      .needAuth()
      .post<MBProUserSeen>();
  }
}
