import { useEffect, useState } from 'react';

import { MBProFormationsAPI, MBProReplaysAPI, YoutubeUtilsAPI } from '@meilleursbiens/api';
import { MBProVideo, MBProVideoCategory } from '@meilleursbiens/types';
import { Button, SelectInput, TextAreaInput, TextInput } from '@meilleursbiens/ui';
import { ToastUtils, VideoUtils } from '@meilleursbiens/utils';

import { AddIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  Spacer,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const ValidationSchemaVideo = Yup.object().shape({
  title: Yup.string().required('Le titre est requis !'),
  description: Yup.string().required('Une description longue du prestataire est requise !'),
  youtube_link: Yup.string().required('Le lien de la formation est requis !'),
  category_id: Yup.string().when('videoType', {
    is: 'formation',
    then: Yup.string().required('La catégorie est requise !'),
  }),
  thumbnail_url: Yup.string(),
  duration: Yup.number(),
  created_at: Yup.date(),
  updated_at: Yup.date(),
});

const ValidationSchemaCategory = Yup.object().shape({
  title: Yup.string().required('Le titre de la catégorie est requis !'),
  description: Yup.string().required('La description de la catégorie est requise !'),
});

export function fetchCategories(setIsLoading: any, setCategories: any) {
  setIsLoading((prevState: any) => ({
    ...prevState,
    isCategoriesLoading: true,
  }));
  MBProFormationsAPI.listCategories()
    .then((r: any) => {
      setCategories(r);
    })
    .finally(() =>
      setIsLoading((prevState: any) => ({
        ...prevState,
        isCategoriesLoading: false,
      }))
    )
    .catch((e: any) => {
      ToastUtils.showError(e);
    });
}

interface MBProAdminVideosFormComponentProps {
  videoType: string;
  isEdit?: boolean;
  video?: MBProVideo;
  videos: MBProVideo[];
  onClose: () => void;
  setVideos: (formations: MBProVideo[]) => void;
}

export default function MBProAdminVideosFormComponent({
  videoType,
  isEdit = false,
  video,
  videos,
  onClose,
  setVideos,
}: MBProAdminVideosFormComponentProps) {
  const [isLoading, setIsLoading] = useState<{ formation?: boolean; category?: boolean; replay?: boolean }>({
    replay: false,
    formation: false,
    category: false,
  });
  const [categories, setCategories] = useState<MBProVideoCategory[]>([]);
  const [formReady, setFormReady] = useState<boolean>(false);
  const [selectedEditCategory, setSelectedEditCategory] = useState<Partial<MBProVideoCategory>>({
    id: 0,
    title: '',
    description: '',
  });

  useEffect(() => {
    setFormReady(true);
    fetchCategories(setIsLoading, setCategories);
  }, []);

  const formik = useFormik({
    initialValues: {
      id: video ? video.id : 0,
      title: '',
      description: '',
      youtube_link: '',
      category_id: 0,
      thumbnail_url: '',
      duration: 0,
      created_at: video ? video.created_at : new Date().toDateString(),
      updated_at: new Date().toDateString(),
    },

    validationSchema: ValidationSchemaVideo,
    onSubmit: async (values) => {
      videoType === 'formation' ? setIsLoading({ formation: true }) : setIsLoading({ replay: true });
      const videoid = VideoUtils.getVideoIdFromUrl(values.youtube_link) || '';

      const thumbnail_url = await YoutubeUtilsAPI.getThumbnailURL(videoid.toString());
      values.thumbnail_url = thumbnail_url;
      const duration = await VideoUtils.getDurationFromYoutubeLink(values.youtube_link);
      values.duration = duration;
      if (isEdit) {
        if (!video) return;
        if (videoType === 'formation') {
          console.log('test');
          MBProFormationsAPI.updateFormation(video.id, values).then((res: any) => {
            const clonedFormations = [...videos];
            const index = clonedFormations.findIndex((video) => video.id === res.id);
            clonedFormations[index] = values;
            clonedFormations[index].id = res.id;
            setVideos(clonedFormations);
            ToastUtils.showSuccess('La formation a bien été modifiée !');
          });
        } else {
          MBProReplaysAPI.updateReplay(video.id, values).then((res: any) => {
            const clonedReplays = [...videos];
            const index = clonedReplays.findIndex((video) => video.id === res.id);
            clonedReplays[index] = values;
            clonedReplays[index].id = res.id;
            setVideos(clonedReplays);
            ToastUtils.showSuccess('Le replay a bien été modifié !');
          });
        }
      } else {
        if (videoType === 'formation') {
          MBProFormationsAPI.createAdmin(values).then((res: any) => {
            setVideos([res.video, ...videos]);
            ToastUtils.showSuccess('La formation a bien été créée !');
          });
        } else {
          MBProReplaysAPI.createAdmin(values).then((res: any) => {
            setVideos([res.video, ...videos]);
            ToastUtils.showSuccess('Le replay a bien été créé !');
          });
        }
      }
      onClose();
    },
  });

  const addCategoryFormik = useFormik({
    initialValues: {
      title: '',
      description: '',
    },
    validationSchema: ValidationSchemaCategory,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading({ category: true });
      try {
        const res = await MBProFormationsAPI.createCategory(values.title, values.description);
        setCategories([
          ...categories,
          {
            id: res.id,
            title: values.title,
            description: values.description,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
          },
        ]);
        resetForm();
      } catch (error) {
        console.error('Error creating category:', error);
      } finally {
        ToastUtils.showSuccess('La catégorie a bien été créée !');
        setIsLoading({ category: false });
      }
    },
  });

  const editCategoryFormik = useFormik({
    initialValues: {
      title: video?.category?.title || '',
      description: video?.category?.description || '',
      category_id: video?.category_id || 0,
    },
    validationSchema: ValidationSchemaCategory,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading({ category: true });
      console.log('category');
      try {
        const res = await MBProFormationsAPI.updateCategory(
          selectedEditCategory.id as number,
          values.title,
          values.description
        );
        const categoriesDatas = [...categories];
        const index = categoriesDatas.findIndex((category) => category.id === selectedEditCategory.id);
        categoriesDatas[index].title = values.title;
        categoriesDatas[index].description = values.description;
        setCategories(categoriesDatas);
      } catch (error) {
        console.error('Error while editing the category:', error);
      } finally {
        ToastUtils.showSuccess('La catégorie a bien été modifiée !');
        setIsLoading({ category: false });
      }
    },
  });

  useEffect(() => {
    if (isEdit) {
      if (!video) return;
      formik.setFieldValue('title', video.title);
      formik.setFieldValue('description', video.description);
      formik.setFieldValue('youtube_link', video.youtube_link);
      if (videoType === 'formation') formik.setFieldValue('category_id', video.category_id);
      //if (videoType === 'formation') editCategoryFormik.setFieldValue('category_id', video.category_id);
      formik.setFieldValue('thumbnail_url', video.thumbnail_url);
      formik.setFieldValue('duration', video.duration);
    }
  }, [video]);

  useEffect(() => {
    if (selectedEditCategory.id !== 0) {
      editCategoryFormik.setFieldValue('title', selectedEditCategory.title);
      editCategoryFormik.setFieldValue('description', selectedEditCategory.description);
    }
  }, [selectedEditCategory]);

  return !formReady ? (
    <Spinner display={'flex'} margin={'3rem auto'} />
  ) : (
    <form onSubmit={formik.handleSubmit} className="delay-0_2s">
      <TextInput
        label={videoType === 'formation' ? 'Titre de la formation' : 'Titre du replay'}
        name={'title'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        placeholder={
          videoType === 'formation'
            ? 'Formation en achat de publicités sur LeBonCoin'
            : 'Replay du café des agents du 22/02/2022'
        }
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        marginBottom={'1rem'}
      />

      <TextAreaInput
        label={videoType === 'formation' ? 'Description de la formation' : 'Description du replay'}
        name={'description'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        placeholder={
          videoType === 'formation'
            ? "Achat d'emplacements LeBonCoin via des passerelles..."
            : 'Découvrez le dernier café des agents...'
        }
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        marginBottom={'1rem'}
      />

      <TextInput
        label={videoType === 'formation' ? 'Lien de la formation' : 'Lien du replay'}
        name={'youtube_link'}
        placeholder={'https://youtube.com/watch?v=123456'}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        marginBottom={'1rem'}
      />

      <TextInput
        name={'thumbnail_url'}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        display={'none'}
      />

      <TextInput
        name={'duration'}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        display={'none'}
      />

      {videoType === 'formation' && (
        <>
          <SelectInput
            label={'Catégorie de formation'}
            name={'category_id'}
            placeholder={'Rechercher une catégorie'}
            options={categories.map((category) => ({
              value: category?.id,
              label: category?.title,
            }))}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            marginBottom={'1rem'}
            overflow={'hidden'}
            className="selectCategory"
          />

          <Accordion allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton padding={0}>
                  <Text fontSize={'0.8rem'} color={'gray.400'} paddingY={'1rem'}>
                    Créer une nouvelle catégorie
                  </Text>
                  <AccordionIcon color={'gray.400'} />
                </AccordionButton>
              </h2>
              <AccordionPanel padding={1} position={'relative'} margin-bottom={'1rem'}>
                <TextInput
                  label={'Titre de la catégorie'}
                  name={'title'}
                  placeholder={'Prospection Immobilière'}
                  handleChange={addCategoryFormik.handleChange}
                  marginBottom={'1rem'}
                  values={addCategoryFormik.values}
                  errors={addCategoryFormik.errors}
                  touched={addCategoryFormik.touched}
                  handleBlur={addCategoryFormik.handleBlur}
                />
                <TextAreaInput
                  label={'Description de la catégorie'}
                  name={'description'}
                  placeholder={'La Prospection Immobilière consiste en  ...'}
                  handleChange={addCategoryFormik.handleChange}
                  marginBottom={'1rem'}
                  values={addCategoryFormik.values}
                  errors={addCategoryFormik.errors}
                  touched={addCategoryFormik.touched}
                  handleBlur={addCategoryFormik.handleBlur}
                />
                <Button
                  leftIcon={PlusIcon}
                  className="addCategory"
                  role="button"
                  marginBottom={'1rem'}
                  onClick={() => addCategoryFormik.handleSubmit()}
                >
                  Ajouter la catégorie
                </Button>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Accordion allowToggle>
            <AccordionItem
              sx={{
                '& > .chakra-collapse': {
                  overflow: 'visible !important',
                },
              }}
            >
              <h2>
                <AccordionButton padding={0}>
                  <Text fontSize={'0.8rem'} color={'gray.400'} paddingY={'1rem'}>
                    Modifier une catégorie
                  </Text>
                  <AccordionIcon color={'gray.400'} />
                </AccordionButton>
              </h2>
              <AccordionPanel padding={1} position={'relative'} margin-bottom={'1rem'}>
                <SelectInput
                  label={'Catégorie à modifier'}
                  name={'category_id'}
                  placeholder={'Rechercher une catégorie'}
                  options={categories.map((category) => ({
                    value: category?.id,
                    label: category?.title,
                  }))}
                  values={editCategoryFormik.values}
                  errors={editCategoryFormik.errors}
                  touched={editCategoryFormik.touched}
                  handleBlur={editCategoryFormik.handleBlur}
                  handleChange={(e) => {
                    editCategoryFormik.handleChange(e);
                    const selectedCategory = categories.find((category) => category.id === parseInt(e.target.value));
                    if (selectedCategory) {
                      setSelectedEditCategory(selectedCategory);
                    }
                  }}
                  className="selectCategory"
                />

                <Spacer mb={4} />

                <TextInput
                  label={'Titre de la catégorie'}
                  name={'title'}
                  placeholder={'Prospection Immobilière'}
                  handleChange={editCategoryFormik.handleChange}
                  marginBottom={'1rem'}
                  values={editCategoryFormik.values}
                  errors={editCategoryFormik.errors}
                  touched={editCategoryFormik.touched}
                  handleBlur={editCategoryFormik.handleBlur}
                />

                <TextAreaInput
                  label={'Description de la catégorie'}
                  name={'description'}
                  placeholder={'La Prospection Immobilière consiste en  ...'}
                  handleChange={editCategoryFormik.handleChange}
                  marginBottom={'1rem'}
                  values={editCategoryFormik.values}
                  errors={editCategoryFormik.errors}
                  touched={editCategoryFormik.touched}
                  handleBlur={editCategoryFormik.handleBlur}
                />
                <Button
                  leftIcon={PencilIcon}
                  className="addCategory"
                  role="button"
                  marginBottom={'1rem'}
                  onClick={() => editCategoryFormik.handleSubmit()}
                >
                  Modifier la catégorie
                </Button>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      )}

      <div className="is-fullwidth is-flex mb-20" style={{ justifyContent: 'flex-end', marginTop: '1rem' }}>
        <Button mt={'0.5rem'} variant={'primary'} type="submit" className={isLoading.formation ? ' is-loading' : ''}>
          Sauvegarder
        </Button>
      </div>
    </form>
  );
}
