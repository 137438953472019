import React, { useEffect, useState } from 'react';

import { useUser } from '@meilleursbiens/hooks';
import { MBProMandate, MBProMandateCreateRequest } from '@meilleursbiens/types';
import {
  DateInput,
  NumberInput,
  PriceInput,
  RangeInput,
  Section,
  SelectButtonInput,
  SelectInput,
  SwitchInput,
  fadeInRight,
} from '@meilleursbiens/ui';
import { MandateUtils } from '@meilleursbiens/utils';

import { Alert, AlertIcon, Box, Divider, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react';

import { FormikValues, useFormik } from 'formik';
import moment from 'moment/moment';
import * as Yup from 'yup';

interface MBProMandatesCreateFormPricesComponentProps {
  mandate: MBProMandateCreateRequest;
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  mandateDatas?: MBProMandate;
}

const ValidationSchemaSell = Yup.object().shape({
  mandate_duration: Yup.number().min(1, 'La durée doit être supérieure à 1 jours !').required('La durée est requise !'),
  is_tacite_renewal: Yup.boolean().required('Le renouvellement tacite est requis !'),
  origin: Yup.string().required("L'origine du mandat est requise !"),
  price_agent_fee_amount: Yup.number().nullable().min(0, 'Le montant doit être supérieur à 1€ !'),
  price_agent_fee_percentage: Yup.number()
    .nullable()
    .min(0, 'Le pourcentage doit être supérieur à 0% !')
    .max(100, 'Le pourcentage doit être inférieur à 100% !'),
  price_estimated: Yup.number().min(0, 'Le montant doit être supérieur à 0 !').required('Le montant est requis !'),
  price_public: Yup.number().min(0, 'Le montant doit être supérieur à 0 !').required('Le montant est requis !'),
});

const ValidationSchemaRent = Yup.object().shape({
  mandate_duration: Yup.number().min(1, 'La durée doit être supérieure à 1 jours !').required('La durée est requise !'),
  is_tacite_renewal: Yup.boolean().required('Le renouvellement tacite est requis !'),
  //origin: Yup.string().required("L'origine du mandat est requise !"),
  price_agent_fee_amount: Yup.number().nullable().min(0, 'Le montant doit être supérieur à 1€ !'),
  price_agent_fee_percentage: Yup.number()
    .nullable()
    .min(0, 'Le pourcentage doit être supérieur à 0% !')
    .max(100, 'Le pourcentage doit être inférieur à 100% !'),
  price_public: Yup.number().min(0, 'Le montant doit être supérieur à 0 !').required('Le montant est requis !'),
  price_rent_tenant_visit_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_lessor_visit_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_tenant_write_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_lessor_write_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_tenant_inventory_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_lessor_inventory_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_tenant_bail_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_lessor_bail_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_tenant_prestation_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_tenant_total_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  price_rent_lessor_total_fee: Yup.number()
    .min(0, 'Le montant doit être supérieur à 0 !')
    .required('Le montant est requis !'),
  rent_duration: Yup.number().min(1, 'La durée doit être supérieure à 1 jours !').required('La durée est requise !'),
  rent_frequency: Yup.number()
    .min(1, 'Le jour doit être supérieur à 1 !')
    .max(31, 'Le jour doit être inférieur à 31 !')
    .required('Le jour est requis !'),
  rent_costs: Yup.number().min(0, 'Le montant doit être supérieur à 0 !').required('Le montant est requis !'),
});

const ValidationSchemaSearch = Yup.object().shape({
  mandate_duration: Yup.number().min(1, 'La durée doit être supérieure à 1 jours !').required('La durée est requise !'),
  is_tacite_renewal: Yup.boolean().required('Le renouvellement tacite est requis !'),
  //origin: Yup.string().required("L'origine du mandat est requise !"),
  price_agent_fee_amount: Yup.number().nullable().min(0, 'Le montant doit être supérieur à 1€ !'),
  price_agent_fee_percentage: Yup.number()
    .nullable()
    .min(0, 'Le pourcentage doit être supérieur à 0% !')
    .max(100, 'Le pourcentage doit être inférieur à 100% !'),
});

const ValidationSchemaDelegation = Yup.object().shape({
  //origin: Yup.string().required("L'origine du mandat est requise !"),
  price_agent_fee_amount: Yup.number().nullable().min(0, 'Le montant doit être supérieur à 1€ !'),
  price_agent_fee_percentage: Yup.number()
    .nullable()
    .min(0, 'Le pourcentage doit être supérieur à 0% !')
    .max(100, 'Le pourcentage doit être inférieur à 100% !'),
  price_public: Yup.number().min(0, 'Le montant doit être supérieur à 0 !').required('Le montant est requis !'),
  delegation_internal_fee_percentage: Yup.number()
    .min(0, 'Le pourcentage doit être supérieur à 0% !')
    .max(100, 'Le pourcentage doit être inférieur à 100% !')
    .required('Le pourcentage est requis !'),
  delegation_external_fee_percentage: Yup.number()
    .min(0, 'Le pourcentage doit être supérieur à 0% !')
    .max(100, 'Le pourcentage doit être inférieur à 100% !')
    .required('Le pourcentage est requis !'),
});

const INITIAL_VALUES_SELL = {
  date_start: moment().format('DD/MM/YYYY'),
  date_end: moment().add(3, 'months').format('DD/MM/YYYY'),
  mandate_duration: 0,
  is_tacite_renewal: true,
  //origin: 13,
  price_agent_fee_amount: 0,
  price_agent_fee_percentage: 0,
  fee_type: 'percentage',
  price_agent_fee_direction: 1,
  price_estimated: 0,
  price_public: 0,
} as FormikValues;

const INITIAL_VALUES_RENT = {
  date_start: moment().format('DD/MM/YYYY'),
  date_end: moment().add(3, 'months').format('DD/MM/YYYY'),
  mandate_duration: 0,
  is_tacite_renewal: true,
  //origin: 13,
  price_agent_fee_amount: 0,
  price_agent_fee_percentage: 0,
  fee_type: 'percentage',
  price_public: 0,
  price_rent_tenant_visit_fee: 0,
  price_rent_lessor_visit_fee: 0,
  price_rent_tenant_write_fee: 0,
  price_rent_lessor_write_fee: 0,
  price_rent_tenant_inventory_fee: 0,
  price_rent_lessor_inventory_fee: 0,
  price_rent_tenant_bail_fee: 0,
  price_rent_lessor_bail_fee: 0,
  price_rent_tenant_prestation_fee: 0,
  price_rent_tenant_total_fee: 0,
  price_rent_lessor_total_fee: 0,
  rent_duration: 365,
  rent_frequency: 1,
  rent_costs: 0,
} as FormikValues;

const INITIAL_VALUES_SEARCH = {
  date_start: moment().format('DD/MM/YYYY'),
  date_end: moment().add(3, 'months').format('DD/MM/YYYY'),
  mandate_duration: 0,
  is_tacite_renewal: true,
  //origin: 13,
  price_agent_fee_amount: 0,
  price_agent_fee_percentage: 0,
  fee_type: 'percentage',
  price_agent_fee_direction: 1,
} as FormikValues;

const INITIAL_VALUES_DELEGATION = {
  //origin: 13,
  price_agent_fee_amount: 0,
  price_agent_fee_percentage: 0,
  fee_type: 'percentage',
  price_agent_fee_direction: 1,
  delegation_internal_fee_percentage: 50,
  delegation_external_fee_percentage: 50,
} as FormikValues;

export default function (props: MBProMandatesCreateFormPricesComponentProps) {
  const { mandate, isEdit, mandateDatas } = props;

  const user = useUser();

  const INITIAL_VALUES_EDIT = {
    ...mandateDatas,
    date_start: moment(mandateDatas?.created_at).format('DD/MM/YYYY'),
    date_end: moment(mandateDatas?.created_at).add(mandateDatas?.mandate_duration, 'days').format('DD/MM/YYYY'),
    fee_type: mandateDatas?.price_agent_fee_percentage ? 'percentage' : 'amount',
  } as FormikValues;

  const _getValidationSchema = () => {
    if (mandate.is_sell) {
      return ValidationSchemaSell;
    } else if (mandate.is_rent) {
      return ValidationSchemaRent;
    } else if (mandate.is_search) {
      return ValidationSchemaSearch;
    } else if (mandate.is_delegation) {
      return ValidationSchemaDelegation;
    }
  };

  const _getInitialValues = () => {
    if (isEdit) {
      return INITIAL_VALUES_EDIT;
    } else if (mandate.is_sell) {
      return {
        ...INITIAL_VALUES_SELL,
        ...mandate,
        fee_type: mandate?.price_agent_fee_amount ? 'amount' : 'percentage',
      };
    } else if (mandate.is_rent) {
      return {
        ...INITIAL_VALUES_RENT,
        ...mandate,
        fee_type: mandate?.price_agent_fee_amount ? 'amount' : 'percentage',
      };
    } else if (mandate.is_search) {
      return {
        ...INITIAL_VALUES_SEARCH,
        ...mandate,
        fee_type: mandate?.price_agent_fee_amount ? 'amount' : 'percentage',
      };
    } else if (mandate.is_delegation) {
      return {
        ...INITIAL_VALUES_DELEGATION,
        ...mandate,
        fee_type: mandate?.price_agent_fee_amount ? 'amount' : 'percentage',
      };
    } else {
      return {};
    }
  };

  const formik = useFormik({
    initialValues: _getInitialValues(),
    validationSchema: _getValidationSchema(),
    onSubmit: (values) => {
      props.onSubmit(formik.values);
    },
  });

  const _getMandateDuration = () => {
    if (formik.values.date_start && formik.values.date_end) {
      const start = moment(formik.values.date_start, 'DD/MM/YYYY');
      let end = moment(formik.values.date_end, 'DD/MM/YYYY');
      const duration = end.diff(start, 'days');

      if (
        duration < 0 ||
        ((mandateDatas?.type?.is_exclusive || [2, 3, 15, 17, 21].includes(Number(mandate?.mandate_type))) &&
          duration > 92)
      ) {
        end = start.add(3, 'months');
        const endFormatted = end.format('DD/MM/YYYY');
        formik.setFieldValue('date_end', endFormatted);
      }
      formik.setFieldValue('mandate_duration', duration);

      //console.log(duration, formik.values.date_start, end);
    }
  };

  useEffect(() => {
    _getMandateDuration();
    formik.setValues(_getInitialValues());
  }, []);

  // Formating date_start and date_end to calculate mandate_duration
  useEffect(() => {
    _getMandateDuration();
  }, [formik.values.date_start, formik.values.date_end]);

  useEffect(() => {
    if (
      formik.values.mandate_duration < 0 ||
      formik.values.mandate_duration > 730 ||
      ((mandateDatas?.type?.is_exclusive || [2, 3, 15, 17, 21].includes(Number(mandate?.mandate_type))) &&
        formik.values.mandate_duration > 93)
    ) {
      const start = moment(formik.values.date_start, 'DD/MM/YYYY');
      const end = moment(start).add(3, 'months');
      const duration = end.diff(start, 'days');

      formik.setFieldValue('mandate_duration', duration);
      return;
    }
    if (formik.values.mandate_duration > 0) {
      const start = moment(formik.values.date_start, 'DD/MM/YYYY');
      const end = start.add(formik.values.mandate_duration, 'days');
      const endFormatted = end.format('DD/MM/YYYY');

      formik.setFieldValue('date_end', endFormatted);
    }
  }, [formik.values.mandate_duration]);

  useEffect(() => {
    if (formik.values.fee_type === 'amount') {
      formik.setFieldValue('price_agent_fee_percentage', null);
      if (isEdit) {
        formik.setFieldValue(
          'price_agent_fee_amount',
          (mandateDatas?.price_agent_fee_amount && mandateDatas?.price_agent_fee_amount) || 0
        );
      } else {
        formik.setFieldValue(
          'price_agent_fee_amount',
          (mandate?.price_agent_fee_amount && mandate?.price_agent_fee_amount) || 0
        );
      }
    } else {
      formik.setFieldValue('price_agent_fee_amount', null);
      if (isEdit) {
        formik.setFieldValue(
          'price_agent_fee_percentage',
          (mandateDatas?.price_agent_fee_percentage && mandateDatas?.price_agent_fee_percentage) || 0
        );
      } else {
        formik.setFieldValue(
          'price_agent_fee_percentage',
          (mandate?.price_agent_fee_percentage && mandate?.price_agent_fee_percentage) || 0
        );
      }
    }
  }, [formik.values.fee_type]);

  // Calcule du prix automatique sur une vente (price_public)
  useEffect(() => {
    if (mandate.is_sell || mandate.is_delegation) {
      if (formik.values.fee_type === 'amount') {
        formik.setFieldValue(
          'price_public',
          parseFloat(formik.values.price_estimated) + parseFloat(formik.values.price_agent_fee_amount)
        );
      }

      if (formik.values.fee_type === 'percentage') {
        formik.setFieldValue(
          'price_public',
          parseFloat(formik.values.price_estimated) / (1 - parseFloat(formik.values.price_agent_fee_percentage) / 100)
        );
      }
    }
  }, [
    formik.values.price_estimated,
    formik.values.price_public,
    formik.values.price_agent_fee_percentage,
    formik.values.price_agent_fee_amount,
  ]);

  useEffect(() => {
    if (formik.values.is_tacite_renewal) {
      formik.setFieldValue('mandate_tacite_renewal_duration', '3 mois');
    } else {
      formik.setFieldValue('mandate_tacite_renewal_duration', null);
    }
  }, [formik.values.is_tacite_renewal]);

  return (
    <Box as={'form'} id="form-mandate" onSubmit={formik.handleSubmit} animation={`${fadeInRight} 0.2s ease`} pb={5}>
      {mandate.is_sell && (
        <>
          <SelectInput
            label={'Origine du mandat'}
            name={'origin'}
            options={MandateUtils.getMandateOrigins()}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
          <Spacer my={4} />
        </>
      )}

      {!mandate.is_delegation && (
        <>
          <SimpleGrid columns={[1, 2]} spacing={4} mb={4}>
            <DateInput
              label={'Date de début de mandat'}
              name={'date_start'}
              minDate={moment(mandateDatas?.created_at).format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired={true}
            />
            <DateInput
              label={'Date de fin de mandat'}
              name={'date_end'}
              minDate={moment(mandateDatas?.created_at).format('DD/MM/YYYY') || moment().format('DD/MM/YYYY')}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired={true}
            />
          </SimpleGrid>
          <NumberInput
            label={'Durée du mandat (en jours)'}
            name={'mandate_duration'}
            unity={'jours'}
            helperText={'Saisissez les dates au dessus pour calculer automatiquement la durée du mandat.'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
          {(mandateDatas?.type?.is_exclusive || [2, 3, 15, 17, 21].includes(Number(mandate?.mandate_type))) && (
            <Alert mt={2} size="sm">
              <AlertIcon w="1rem" h="1rem" />
              <Text fontSize="xs">Le mandat exclusif ne peut légalement excéder une durée de 3 mois.</Text>
            </Alert>
          )}

          <Spacer my={4} />

          <SwitchInput
            label={'Souhaitez-vous appliquer une tacite reconduction ?'}
            name={'is_tacite_renewal'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />

          {formik.values.is_tacite_renewal && (
            <>
              <Spacer my={4} />

              <SelectInput
                label={'Durée de la tacite reconduction'}
                name={'mandate_tacite_renewal_duration'}
                options={MandateUtils.getTaciteRenewalDurations()}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                values={formik.values}
                errors={formik.errors}
                touched={formik.touched}
              />
            </>
          )}
        </>
      )}

      <Spacer my={8} />

      <Section
        size={'xs'}
        title={'Rémunération & Prix'}
        description={
          'Définissez les prix et votre rémunération pour ce mandat, vous pouvez saisir soit un pourcentage, soit un montant fixe.'
        }
      >
        {!mandate.is_rent && user && user.admin && (
          <>
            <SelectButtonInput
              label={'Honoraires à la charge'}
              name={'price_agent_fee_direction'}
              options={[
                { value: '1', label: 'Vendeur' },
                { value: '2', label: 'Acquéreur' },
              ]}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
            />

            <Spacer my={3} />
          </>
        )}

        <SelectButtonInput
          label={'Comment souhaitez-vous remplir votre rémunération ?'}
          name={'fee_type'}
          options={[
            { value: 'percentage', label: 'Pourcentage' },
            { value: 'amount', label: 'Montant fixe' },
          ]}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
        />

        <Spacer my={3} />

        <Box animation={`${fadeInRight} 0.2s ease`} display={formik.values['fee_type'] === 'amount' ? 'block' : 'none'}>
          <PriceInput
            label={'Votre rémunération (montant)'}
            name={'price_agent_fee_amount'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={formik.values['fee_type'] === 'amount'}
            animation={`${fadeInRight} 0.2s ease`}
          />
        </Box>
        <Box
          animation={`${fadeInRight} 0.2s ease`}
          display={formik.values['fee_type'] === 'percentage' ? 'block' : 'none'}
        >
          <NumberInput
            label={'Votre rémunération (pourcentage)'}
            name={'price_agent_fee_percentage'}
            unity={'%'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={formik.values['fee_type'] === 'percentage'}
          />
        </Box>

        <Divider my={4} />

        {mandate.is_sell && <SellInputs formik={formik} />}
        {mandate.is_rent && <RentInputs formik={formik} />}
        {mandate.is_search && <SearchInputs formik={formik} />}
        {mandate.is_delegation && <DelegationInputs formik={formik} />}
      </Section>
    </Box>
  );
}

function SellInputs({ formik }: { formik: any }) {
  return (
    <>
      <PriceInput
        label={'Prix estimé du bien'}
        name={'price_estimated'}
        helperText={
          'Le prix estimé du bien est le prix de vente que vous avez estimé pour le bien, il ne faut pas inclure les honoraires dans ce montant.'
        }
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired={true}
      />

      <Spacer my={4} />

      <PriceInput
        label={'Prix total (FAI)'}
        name={'price_public'}
        helperText={
          'Le prix total du bien est le prix de vente que vous avez estimé pour le bien ainsi que vos honoraires inclus.'
        }
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />
    </>
  );
}

function RentInputs({ formik }: { formik: any }) {
  const _calculateTotalTenantFee = () => {
    const total =
      parseFloat(formik.values.price_rent_tenant_visit_fee) +
      parseFloat(formik.values.price_rent_tenant_write_fee) +
      parseFloat(formik.values.price_rent_tenant_inventory_fee) +
      parseFloat(formik.values.price_rent_tenant_bail_fee) +
      parseFloat(formik.values.price_rent_tenant_prestation_fee);
    formik.setFieldValue('price_rent_tenant_total_fee', total);
  };

  const _calculateTotalLessorFee = () => {
    const total =
      parseFloat(formik.values.price_rent_lessor_visit_fee) +
      parseFloat(formik.values.price_rent_lessor_write_fee) +
      parseFloat(formik.values.price_rent_lessor_inventory_fee) +
      parseFloat(formik.values.price_rent_lessor_bail_fee);
    formik.setFieldValue('price_rent_lessor_total_fee', total);
  };

  useEffect(() => {
    _calculateTotalLessorFee();
    _calculateTotalTenantFee();
  }, [formik.values]);

  return (
    <>
      <SimpleGrid columns={[1, 2]} spacing={4}>
        <Section
          size={'xs'}
          title={'Frais du locataire'}
          description={'Définissez les frais du locataire pour ce mandat.'}
        >
          <VStack>
            <PriceInput
              label={'Frais de visite'}
              name={'price_rent_lessor_visit_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <PriceInput
              label={'Frais constitution de dossier'}
              name={'price_rent_lessor_write_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <PriceInput
              label={'Frais de rédaction du bail'}
              name={'price_rent_lessor_inventory_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <PriceInput
              label={"Frais d'état des lieux"}
              name={'price_rent_lessor_bail_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <Spacer mt={'58px'} />
            <Divider />
            <PriceInput
              label={'Frais totaux du locataire'}
              name={'price_rent_lessor_total_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              helperText={'Ces frais sont calculés automatiquement en fonction des frais saisis ci-dessus.'}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
          </VStack>
        </Section>
        <Section
          size={'xs'}
          title={'Frais du bailleur'}
          description={'Définissez les frais du bailleur pour ce mandat.'}
        >
          <VStack>
            <PriceInput
              label={'Frais de visite'}
              name={'price_rent_tenant_visit_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <PriceInput
              label={'Frais constitution de dossier'}
              name={'price_rent_tenant_write_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <PriceInput
              label={"Frais d'état des lieux"}
              name={'price_rent_tenant_inventory_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <PriceInput
              label={'Frais de rédaction du bail'}
              name={'price_rent_tenant_bail_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <PriceInput
              label={'Frais de prestation'}
              name={'price_rent_tenant_prestation_fee'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
            <Divider />
            <PriceInput
              label={'Frais totaux du bailleur'}
              name={'price_rent_tenant_total_fee'}
              helperText={'Ces frais sont calculés automatiquement en fonction des frais saisis ci-dessus.'}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              isRequired
            />
          </VStack>
        </Section>
      </SimpleGrid>

      <Divider my={4} />

      <PriceInput
        label={'Dépôt de garantie'}
        name={'rent_deposit_amount'}
        helperText={
          'Le dépôt de garantie est une somme d’argent versée par le locataire au bailleur lors de la signature du bail.'
        }
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      <Spacer my={4} />

      <PriceInput
        label={'Montant des charges'}
        name={'rent_costs'}
        helperText={
          'Inclure seulement le montant des charges locatives, hors loyer, qui sont à la charge du locataire.'
        }
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      <Spacer my={4} />

      <PriceInput
        label={'Loyer charges comprises'}
        name={'price_public'}
        helperText={
          'Indiquez le montant du loyer charges comprises, c’est-à-dire le montant du loyer auquel sont ajoutées les charges locatives.'
        }
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      <Divider my={4} />

      <NumberInput
        label={'Jour de paiement du loyer'}
        name={'rent_frequency'}
        placeholder={'ex: 1'}
        helperText={'Indiquez le jour du mois où le locataire devra payer son loyer (uniquement le nombre).'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      <Divider my={4} />

      <NumberInput
        label={'Durée du bail (en jours)'}
        name={'rent_duration'}
        placeholder={'ex: 1'}
        helperText={'Indiquez la durée du bail en jours, par défaut c’est 1 an soit 365 jours'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />
    </>
  );
}

function SearchInputs({ formik }: { formik: any }) {
  return <></>;
}

function DelegationInputs({ formik }: { formik: any }) {
  return (
    <>
      <Section
        size={'xs'}
        title={'Répartition des honoraires'}
        description={
          'La répartition permet de définir le pourcentage de rémunération de chaque partie sur le montant des honoraires'
        }
      >
        <Divider my={4} />

        <NumberInput
          label={'Pourcentage de répartition pour vous'}
          name={'delegation_internal_fee_percentage'}
          placeholder={'ex: 50'}
          helperText={'Indiquez le pourcentage de rémunération qui vous est attribué'}
          handleChange={(e) => {
            formik.handleChange(e);
            // @ts-ignore
            formik.setFieldValue('delegation_external_fee_percentage', 100 - e.target.value);
          }}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          unity={'%'}
          min={1}
          max={99}
          step={1}
          isRequired
        />

        <Spacer my={4} />

        <NumberInput
          label={'Pourcentage de répartition pour l’agence partenaire'}
          name={'delegation_external_fee_percentage'}
          placeholder={'ex: 50'}
          helperText={"Indiquez le pourcentage de rémunération qui est attribué à l'agence partenaire"}
          handleChange={(e) => {
            formik.handleChange(e);
            // @ts-ignore
            formik.setFieldValue('delegation_external_fee_percentage', 100 - e.target.value);
          }}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          unity={'%'}
          min={1}
          max={99}
          step={1}
          isRequired
        />

        <Spacer my={4} />
      </Section>

      <Spacer my={4} />

      <Section size={'xs'} title={'Prix du bien'}>
        <Divider my={4} />

        <PriceInput
          label={'Prix estimé du bien'}
          name={'price_estimated'}
          helperText={
            'Le prix estimé du bien est le prix de vente que vous avez estimé pour le bien, il ne faut pas inclure les honoraires dans ce montant.'
          }
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired={true}
        />

        <Spacer my={4} />

        <PriceInput
          label={'Prix total (FAI)'}
          name={'price_public'}
          helperText={
            'Le prix total du bien est le prix de vente que vous avez estimé pour le bien ainsi que vos honoraires inclus.'
          }
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired
        />
      </Section>

      <Spacer my={4} />
    </>
  );
}
