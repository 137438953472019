import * as React from 'react';
import { SVGProps } from 'react';

export default function (props: SVGProps<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      fill="none"
      viewBox="0 0 72 74"
      {...props}
    >
      <path
        fill="url(#paint0_linear_0_10541)"
        fillRule="evenodd"
        d="M53 62a2 2 0 1 1 0 4H35q-.177 0-.345-.03a29.86 29.86 0 0 1-17.079-6.292c-.387-.301-.97-.8-1.401-1.177A2.03 2.03 0 0 0 14.844 58H10a2 2 0 1 1 0-4h2a2 2 0 1 0 0-4H7a2 2 0 1 1 0-4h1a2 2 0 1 0 0-4c-.809 0-1.5-.583-1.635-1.38-.049-.29-.088-.523-.094-.57A30 30 0 0 1 6 36C6 19.432 19.432 6 36 6h15a2 2 0 1 1 0 4h-3a2 2 0 1 0 0 4h12a2 2 0 0 1 .002 4H57a2 2 0 1 0 0 4h4.306c.756 0 1.45.426 1.778 1.108.197.41.404.851.54 1.17A29.9 29.9 0 0 1 66 36a30 30 0 0 1-.246 3.857c-.027.212-.069.485-.114.764C65.51 41.42 64.81 42 64 42a2 2 0 1 0 0 4h1a2 2 0 1 1 0 4h-1.284c-.726 0-1.394.394-1.756 1.023-.236.409-.498.853-.693 1.156a30 30 0 0 1-3.08 4.014c-.313.343-.819.851-1.213 1.241a1.97 1.97 0 0 1-1.387.566H50a2 2 0 0 0-.023 4z"
        clipRule="evenodd"
      ></path>
      <circle
        cx="36"
        cy="36"
        r="22"
        fill="#50C22B"
        opacity="0.1"
        style={{ mixBlendMode: "multiply" }}
      ></circle>
      <g filter="url(#filter0_d_0_10541)">
        <path
          fill="#265388"
          fillRule="evenodd"
          d="M28 36h16v32.336a1 1 0 0 1-1.47.883L36 65.75l-6.53 3.47a1 1 0 0 1-1.47-.884z"
          clipRule="evenodd"
        ></path>
      </g>
      <circle
        cx="36"
        cy="38"
        r="20"
        fill="#62CF3F"
        opacity="0.2"
        style={{ mixBlendMode: "multiply" }}
      ></circle>
      <circle cx="36" cy="36" r="20" fill="url(#paint1_linear_0_10541)"></circle>
      <mask
        id="mask0_0_10541"
        width="40"
        height="40"
        x="16"
        y="16"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <circle cx="36" cy="36" r="20" fill="#fff"></circle>
      </mask>
      <g mask="url(#mask0_0_10541)">
        <path
          stroke="#64788F"
          d="M16.5 36c0 10.77 8.73 19.5 19.5 19.5S55.5 46.77 55.5 36"
        ></path>
        <path
          stroke="#78A2A9"
          strokeLinecap="round"
          strokeWidth="2"
          d="M49 39.753 60.753 28M44 28.753 55.753 17M7 47.753 18.753 36M51 25.753 62.753 14M14 44.753 25.753 33M31 56.753 42.753 45M47 49.753 58.753 38"
          opacity="0.12"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <path
          stroke="#8B99AF"
          strokeLinecap="round"
          d="M49.789 49.789A19.44 19.44 0 0 0 55.5 36c0-10.77-8.73-19.5-19.5-19.5S16.5 25.23 16.5 36c0 5.385 2.183 10.26 5.711 13.789"
        ></path>
        <path
          stroke="#CCD8EA"
          strokeLinecap="round"
          d="M55.5 36c0-10.77-8.73-19.5-19.5-19.5S16.5 25.23 16.5 36"
          opacity="0.4"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M36 16.5a19.4 19.4 0 0 0-10.006 2.76"
          opacity="0.2"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M32.514 16.81q-1.394.253-2.712.696"
          opacity="0.6"
        ></path>
      </g>
      <g filter="url(#filter1_d_0_10541)">
        <circle
          cx="36"
          cy="36"
          r="15"
          fill="url(#paint2_linear_0_10541)"
        ></circle>
        <circle
          cx="36"
          cy="36"
          r="15.5"
          stroke="#64788F"
          strokeOpacity="0.06"
        ></circle>
      </g>
      <mask
        id="mask1_0_10541"
        width="30"
        height="30"
        x="21"
        y="21"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <circle cx="36" cy="36" r="15" fill="#fff"></circle>
      </mask>
      <g mask="url(#mask1_0_10541)">
        <path
          stroke="#78A2A9"
          strokeLinecap="round"
          strokeWidth="2"
          d="M44 26.753 55.753 15M51 23.753 62.753 12M14 42.753 25.753 31M31 54.753 42.753 43M49 37.753 60.753 26"
          opacity="0.12"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M36 21.5c-1.966 0-3.84.391-5.55 1.1"
          opacity="0.6"
        ></path>
      </g>
      <g filter="url(#filter2_d_0_10541)">
        <path fill="#fff" d="M30.267 36 36 28.629 41.733 36 36 43.371z"></path>
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M29.478 35.386a1 1 0 0 0 0 1.228l5.733 7.371a1 1 0 0 0 1.578 0l5.733-7.371a1 1 0 0 0 0-1.228l-5.733-7.371a1 1 0 0 0-1.578 0z"
        ></path>
      </g>
      <g filter="url(#filter3_i_0_10541)">
        <path
          fill="url(#paint3_linear_0_10541)"
          d="M30.267 36 36 28.629 41.733 36 36 43.371z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_0_10541"
          x1="5"
          x2="5"
          y1="6"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9E242"></stop>
          <stop offset="1" stopColor="#84C400"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_10541"
          x1="16"
          x2="16"
          y1="16"
          y2="56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ABB7CC"></stop>
          <stop offset="1" stopColor="#76879C"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_10541"
          x1="21"
          x2="21"
          y1="21"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6D1E4"></stop>
          <stop offset="1" stopColor="#A4B1C4"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_10541"
          x1="30.267"
          x2="30.267"
          y1="28.629"
          y2="43.371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A3B0C5"></stop>
          <stop offset="1" stopColor="#7A8AA1"></stop>
        </linearGradient>
        <filter
          id="filter0_d_0_10541"
          width="20"
          height="37.338"
          x="26"
          y="36"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.603922 0 0 0 0 0.713726 0 0 0 0 0.356863 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10541"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10541"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_0_10541"
          width="40"
          height="40"
          x="16"
          y="17"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.279884 0 0 0 0 0.409586 0 0 0 0 0.470833 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10541"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10541"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_0_10541"
          width="19.466"
          height="22.742"
          x="26.267"
          y="25.629"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.19669 0 0 0 0 0.27695 0 0 0 0 0.483333 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10541"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10541"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_i_0_10541"
          width="11.466"
          height="15.742"
          x="30.267"
          y="28.629"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.324977 0 0 0 0 0.428027 0 0 0 0 0.579167 0 0 0 0.4 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10541"></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
