import React, { useEffect, useState } from 'react';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

import * as IBAN from 'iban-ts';

export type IBANInputProps = TextInputProps

const IBANInput = (props: IBANInputProps) => {

  const [isIBANValid, setIsIBANValid] = useState(false);

  useEffect(() => {
    if (props.values[props.name]) {
      console.log(IBAN.isValid(props.values[props.name]));
      if(IBAN.isValid(props.values[props.name]))
        setIsIBANValid(true)
      else
        setIsIBANValid(false);
    }
  }, [props.values[props.name]]);

  return (
    <TextInput
      type="text"
      {...props}
      inputMask={"**** **** **** **** **** **** ****"}
      inputMaskChar={'_'}
      name={props.name}
      errors={{
        ...props.errors,
        [props.name]: isIBANValid ? undefined : "L'IBAN n'est pas valide, veuillez le vérifier et le corriger !"
      }}
      touched={{
        ...props.touched,
        [props.name]: isIBANValid ? undefined : true
      }}
      helperText={isIBANValid ? "L'IBAN saisi est valide !" : undefined}
      helperTextProps={{
        color: isIBANValid ? 'green.500' : 'red.500'
      }}
    />
  );
};

export default IBANInput;
