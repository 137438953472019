import React, { useEffect } from 'react';

import { MBProMandate, MBProMandateCreateRequest } from '@meilleursbiens/types';
import { DateInput, PriceInput, Section, SelectButtonInput, fadeInRight } from '@meilleursbiens/ui';

import { Box, Divider, Fade, Spacer } from '@chakra-ui/react';

import { FormikValues, useFormik } from 'formik';
import moment from 'moment/moment';
import * as Yup from 'yup';


interface MBProMandatesCreateFormBuyOfferComponentProps {
  mandate: MBProMandateCreateRequest;
  onSubmit: (values: any) => void;
  mandateDatas?: MBProMandate;
}

const ValidationSchema = Yup.object().shape({
  apport_immobilier: Yup.number().required('Ce champ est requis'),
  is_emprunt: Yup.boolean().required('Ce champ est requis'),
  emprunt: Yup.number().required('Ce champ est requis'),
  price_public: Yup.number().required('Ce champ est requis'),
  date_max_offre: Yup.string().required('Ce champ est requis'),
});

const INITIAL_VALUES = {
  apport_immobilier: 0,
  is_emprunt: true,
  emprunt: 0,
  price_public: 0,
  date_max_offre: moment().format('DD/MM/YYYY'),
} as FormikValues;

export default function (props: MBProMandatesCreateFormBuyOfferComponentProps) {
  const { mandate, mandateDatas } = props;

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      props.onSubmit(formik.values);
    },
  });

  // Calcule du prix automatique sur une vente (price_public)
  useEffect(() => {
    if(mandateDatas){
      formik.setFieldValue('price_public', mandateDatas?.price_public);
    }
  }, [
    mandateDatas,
  ]);

  return (
    <Box as={'form'} id="form-mandate" onSubmit={formik.handleSubmit} animation={`${fadeInRight} 0.2s ease`} pb={5}>
      <PriceInput
        label={'Prix de vente'}
        name={'price_public'}
        helperText={'Saisissez le prix de l\'offre de vente'}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired={true}
      />
      <Spacer my={3}/>
      <Section
        size={'xs'}
        title={'Financement'}
        description={
          'Définissez le financement pour ce mandat, vous pouvez saisir soit un pourcentage, soit un montant fixe.'
        }>
        <SelectButtonInput
          label={'Est-ce que le client a besoin d’un emprunt ?'}
          name={'is_emprunt'}
          options={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired={true}
        />
        <Spacer my={2}/>
        <Fade in={formik.values.is_emprunt} unmountOnExit>
          <PriceInput
            label={'Apport immobilier'}
            name={'apport_immobilier'}
            helperText={'Saisissez le montant de l’apport de votre client'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
          <Spacer my={2}/>
          <PriceInput
            label={'Emprunt immobilier'}
            name={'emprunt_immobilier'}
            helperText={'Saisissez le montant de l’emprunt immobilier'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
        </Fade>
      </Section>
      <Divider my={3}/>

      <DateInput
        label={'Date d\'expiration de l\'offre'}
        name={'date_max_offre'}
        minDate={moment().format('DD/MM/YYYY')}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired={true}
      />
    </Box>
  );
}
