import * as React from 'react';
import { SVGProps } from 'react';

export default function (props: SVGProps<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      fill="none"
      viewBox="0 0 72 74"
      {...props}
    >
      <path
        fill="url(#paint0_linear_0_10934)"
        fillRule="evenodd"
        d="M53 62a2 2 0 1 1 0 4H35q-.177 0-.345-.03a29.86 29.86 0 0 1-17.079-6.292c-.387-.301-.97-.8-1.401-1.177A2.03 2.03 0 0 0 14.844 58H10a2 2 0 1 1 0-4h2a2 2 0 1 0 0-4H7a2 2 0 1 1 0-4h1a2 2 0 1 0 0-4c-.809 0-1.5-.583-1.635-1.38-.049-.29-.088-.523-.094-.57A30 30 0 0 1 6 36C6 19.432 19.432 6 36 6h15a2 2 0 1 1 0 4h-3a2 2 0 1 0 0 4h12a2 2 0 0 1 .002 4H57a2 2 0 1 0 0 4h4.306c.756 0 1.45.426 1.778 1.108.197.41.404.851.54 1.17A29.9 29.9 0 0 1 66 36a30 30 0 0 1-.246 3.857c-.027.212-.069.485-.114.764C65.51 41.42 64.81 42 64 42a2 2 0 1 0 0 4h1a2 2 0 1 1 0 4h-1.284c-.726 0-1.394.394-1.756 1.023-.236.409-.498.853-.693 1.156a30 30 0 0 1-3.08 4.014c-.313.343-.819.851-1.213 1.241a1.97 1.97 0 0 1-1.387.566H50a2 2 0 0 0-.023 4z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.196v18.127a6 6 0 0 1-3 5.197l-15.699 9.063a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.935a6 6 0 0 1 3-5.196z"
        clipRule="evenodd"
        opacity="0.1"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <g filter="url(#filter0_d_0_10934)">
        <path
          fill="#7F45F6"
          fillRule="evenodd"
          d="M20 33h16v30.382a1 1 0 0 1-1.447.894L28 61l-6.553 3.276A1 1 0 0 1 20 63.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter1_d_0_10934)">
        <path
          fill="#50E2C2"
          fillRule="evenodd"
          d="M24 36h16v30.382a1 1 0 0 1-1.447.894L32 64l-6.553 3.276A1 1 0 0 1 24 66.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter2_d_0_10934)">
        <path
          fill="#7F45F6"
          fillRule="evenodd"
          d="M36 33h16v30.382a1 1 0 0 1-1.447.894L44 61l-6.553 3.276A1 1 0 0 1 36 63.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter3_d_0_10934)">
        <path
          fill="#50E2C2"
          fillRule="evenodd"
          d="M32 36h16v30.382a1 1 0 0 1-1.447.894L40 64l-6.553 3.276A1 1 0 0 1 32 66.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter4_d_0_10934)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28 38h16v30.382a1 1 0 0 1-1.447.894L36 66l-6.553 3.276A1 1 0 0 1 28 68.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask0_0_10934"
        width="16"
        height="32"
        x="28"
        y="38"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28 38h16v30.382a1 1 0 0 1-1.447.894L36 66l-6.553 3.276A1 1 0 0 1 28 68.382z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_0_10934)">
        <path fill="#F55462" d="M32 38h8v32h-8z"></path>
      </g>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M34 17.656a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.465v18.38a4 4 0 0 1-2 3.465L38 61.346a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V30.31a4 4 0 0 1 2-3.465z"
        clipRule="evenodd"
        opacity="0.1"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <path
        fill="url(#paint1_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint2_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m24.817 39.124-5-2v-1l-5.01-2.088a.6.6 0 0 0-.824.647l.702 4.46a1 1 0 0 0 .583.759l9.549 4.222z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint3_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint4_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m22.817 34.124-5-2v-1l-5.01-2.088a.6.6 0 0 0-.824.647l.702 4.46a1 1 0 0 0 .583.759l9.549 4.222z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint5_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint6_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m20.817 29.124-5-2v-1l-5.01-2.088a.6.6 0 0 0-.824.647l.702 4.46a1 1 0 0 0 .583.759l9.549 4.222z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint7_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint8_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m18.817 24.124-5-2v-1l-5.01-2.088a.6.6 0 0 0-.824.647l.702 4.46a1 1 0 0 0 .583.759l9.549 4.222z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask1_0_10934"
        width="19"
        height="26"
        x="7"
        y="18"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M10.21 26.128a1.62 1.62 0 0 0-.942-1.226 1 1 0 0 1-.583-.76l-.702-4.459a.6.6 0 0 1 .824-.647l4.551 1.897a.74.74 0 0 1 .458.687c0 .304.186.578.468.69l3.275 1.31a2 2 0 0 1 1.258 1.858v1.769c0 .65.395 1.235 1 1.477.604.241 1 .826 1 1.477v2.046c0 .65.395 1.235 1 1.477.604.241 1 .826 1 1.477v2.046c0 .65.395 1.235 1 1.477.604.241 1 .826 1 1.477v.852a2 2 0 0 1-2.81 1.829l-6.739-2.98a1 1 0 0 1-.583-.76l-.475-3.014a1.62 1.62 0 0 0-.942-1.226 1 1 0 0 1-.583-.76l-.475-3.014a1.62 1.62 0 0 0-.942-1.226 1 1 0 0 1-.583-.76z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask1_0_10934)">
        <path
          fill="#000"
          fillRule="evenodd"
          d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.197v18.127a6 6 0 0 1-3 5.196l-15.699 9.063a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.937a6 6 0 0 1 3-5.197z"
          clipRule="evenodd"
          opacity="0.1"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint9_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint10_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m47.183 39.124 5-2v-1l5.01-2.088a.6.6 0 0 1 .824.647l-.702 4.46a1 1 0 0 1-.583.759l-9.549 4.222z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint11_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint12_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m49.183 34.124 5-2v-1l5.01-2.088a.6.6 0 0 1 .824.647l-.702 4.46a1 1 0 0 1-.583.759l-9.549 4.222z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint13_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint14_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m51.183 29.124 5-2v-1l5.01-2.088a.6.6 0 0 1 .824.647l-.702 4.46a1 1 0 0 1-.583.759l-9.549 4.222z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint15_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint16_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m53.183 24.124 5-2v-1l5.01-2.088a.6.6 0 0 1 .824.647l-.702 4.46a1 1 0 0 1-.583.759l-9.549 4.222z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask2_0_10934"
        width="19"
        height="26"
        x="46"
        y="18"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M61.79 26.128c.085-.542.44-1.004.942-1.226a1 1 0 0 0 .583-.76l.702-4.459a.6.6 0 0 0-.824-.647l-4.552 1.897a.74.74 0 0 0-.458.687.74.74 0 0 1-.467.69l-3.275 1.31a2 2 0 0 0-1.258 1.858v1.769a1.59 1.59 0 0 1-1 1.477 1.59 1.59 0 0 0-1 1.477v2.046a1.59 1.59 0 0 1-1 1.477 1.59 1.59 0 0 0-1 1.477v2.046a1.59 1.59 0 0 1-1 1.477 1.59 1.59 0 0 0-1 1.477v.852a2 2 0 0 0 2.81 1.829l6.739-2.98a1 1 0 0 0 .583-.76l.474-3.014c.086-.542.44-1.004.943-1.226a1 1 0 0 0 .583-.76l.474-3.014c.086-.542.44-1.004.943-1.226a1 1 0 0 0 .583-.76z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask2_0_10934)">
        <path
          fill="#000"
          fillRule="evenodd"
          d="M38.999 12.677a6 6 0 0 0-6 0l-15.7 9.063a6 6 0 0 0-3 5.196v18.127a6 6 0 0 0 3 5.197L33 59.323a6 6 0 0 0 6 0l15.698-9.063a6 6 0 0 0 3-5.196V26.935a6 6 0 0 0-3-5.196z"
          clipRule="evenodd"
          opacity="0.1"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint17_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint18_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m18 42-3.526 1.763a1 1 0 0 0-.544.762l-.77 5.773a.6.6 0 0 0 .893.6L20 47.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint19_linear_0_10934)"
        fillRule="evenodd"
        stroke="url(#paint20_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m54 42 3.526 1.763a1 1 0 0 1 .544.762l.77 5.773a.6.6 0 0 1-.893.6L52 47.5z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint21_linear_0_10934)"
        stroke="url(#paint22_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M44.23 16.052c.448.252.269.935-.245.935h-15.97c-.514 0-.693-.683-.245-.935l7.997-4.496a.5.5 0 0 1 .49 0z"
      ></path>
      <g filter="url(#filter5_i_0_10934)">
        <path
          fill="url(#paint23_linear_0_10934)"
          fillRule="evenodd"
          d="M54 50.03a1 1 0 0 1-.5.866L39 59.268a6 6 0 0 1-6 0l-14.5-8.372a1 1 0 0 1-.5-.866V46.13a1 1 0 0 1 1.502-.865l13.687 7.944a5.6 5.6 0 0 0 5.622 0l13.687-7.944A1 1 0 0 1 54 46.13z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        stroke="url(#paint24_linear_0_10934)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M53.5 50.03a.5.5 0 0 1-.25.433l-14.5 8.372a5.5 5.5 0 0 1-5.5 0l-14.5-8.372a.5.5 0 0 1-.25-.433V46.13a.5.5 0 0 1 .751-.432l13.687 7.944a6.1 6.1 0 0 0 6.124 0l13.687-7.944a.5.5 0 0 1 .751.432z"
      ></path>
      <mask id="path-28-inside-1_0_10934" fill="#fff">
        <path
          fillRule="evenodd"
          d="M32.002 14.737v3.728a1 1 0 0 1-.445.832l-7.396 4.93-2.606 1.737A1 1 0 0 1 20 25.132v-4.558a1 1 0 0 1 .496-.864l7.142-4.165 2.532-3.798c.55-.823 1.832-.434 1.832.555z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="url(#paint25_linear_0_10934)"
        fillRule="evenodd"
        d="M32.002 14.737v3.728a1 1 0 0 1-.445.832l-7.396 4.93-2.606 1.737A1 1 0 0 1 20 25.132v-4.558a1 1 0 0 1 .496-.864l7.142-4.165 2.532-3.798c.55-.823 1.832-.434 1.832.555z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint26_linear_0_10934)"
        d="M32.002 14.737h-1v.004zm-.038 4.002-.961-.274zm-.308.482.654.757zm-.099.076.401.916a1 1 0 0 0 .154-.084zm0 0-.4-.916a1 1 0 0 0-.154.084zm-7.396 4.93-.555-.832zm-2.606 1.737.554.832zm-1.059-6.253-.504-.864zm7.142-4.166.504.864a1 1 0 0 0 .328-.309zm2.532-3.798.832.555zm.832 2.994h2v-.008zm0 0v3.724h2V14.74zm0 3.724 1.924.549a2 2 0 0 0 .076-.55zm0 0 1.308 1.513a2 2 0 0 0 .616-.964zm0 0 1.11 1.664q.104-.07.198-.15zm.154-.084.802 1.832zm-.153.084-7.397 4.93 1.11 1.664 7.396-4.93zm-7.397 4.93L21 25.132l1.11 1.664 2.605-1.737zM21 25.132h-2c0 1.597 1.78 2.55 3.11 1.664zm0 0v-4.558h-2v4.558zm0-4.558-1.008-1.727A2 2 0 0 0 19 20.574zm0 0 7.142-4.165-1.008-1.727-7.142 4.165zm7.47-4.474 2.533-3.798-1.665-1.11-2.532 3.799zm2.533-3.798h2c0-1.979-2.567-2.756-3.665-1.11zm0 0v2.435h2v-2.435z"
        mask="url(#path-28-inside-1_0_10934)"
      ></path>
      <mask id="path-30-inside-2_0_10934" fill="#fff">
        <path
          fillRule="evenodd"
          d="M40 14.737v3.728a1 1 0 0 0 .445.832l7.397 4.93 2.606 1.737a1 1 0 0 0 1.554-.832v-4.558a1 1 0 0 0-.496-.864l-7.142-4.165-2.532-3.798c-.549-.823-1.832-.434-1.832.555z"
          clipRule="evenodd"
        ></path>
      </mask>
      <path
        fill="url(#paint27_linear_0_10934)"
        fillRule="evenodd"
        d="M40 14.737v3.728a1 1 0 0 0 .445.832l7.397 4.93 2.606 1.737a1 1 0 0 0 1.554-.832v-4.558a1 1 0 0 0-.496-.864l-7.142-4.165-2.532-3.798c-.549-.823-1.832-.434-1.832.555z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint28_linear_0_10934)"
        d="m40 14.737 1 .004v-.005zm.445 4.56-.554.832q.09.06.192.1zm0 0 .555-.832a1 1 0 0 0-.192-.1zm7.397 4.93.555-.832zm2.606 1.737-.555.832zm1.058-6.253.504-.864zm-7.142-4.166-.831.555q.128.192.328.31zm-2.532-3.798.832-.554zM39 14.733v.008h2zm0 .008v3.724h2V14.74zm0 3.724a2 2 0 0 0 .89 1.664L41 18.465zm1.083 1.764.725-1.864zm-.192-.1 7.396 4.93 1.11-1.664L41 18.465zm7.396 4.93 2.606 1.737 1.11-1.664-2.606-1.737zm2.606 1.737c1.33.886 3.11-.067 3.11-1.664h-2zm3.11-1.664v-4.558h-2v4.558zm0-4.558a2 2 0 0 0-.993-1.727l-1.008 1.727zm-.993-1.727-7.142-4.165-1.007 1.727 7.142 4.165zm-6.813-3.856-2.533-3.798L41 12.303l2.532 3.797zm-2.533-3.798C41.566 9.546 39 10.323 39 12.303h2zM39 12.303v2.433h2v-2.434z"
        mask="url(#path-30-inside-2_0_10934)"
      ></path>
      <path
        fill="url(#paint29_linear_0_10934)"
        fillRule="evenodd"
        stroke="#FFF04D"
        strokeOpacity="0.4"
        d="M34 14.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 57.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V26.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask3_0_10934"
        width="40"
        height="46"
        x="16"
        y="13"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M34 14.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 57.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V26.809a4 4 0 0 1 2-3.464z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask3_0_10934)">
        <path
          fill="#FFD640"
          d="m36 36 6-24H30zL23.272 14.787l-8.485 8.485zl-24-6v12zL14.787 48.728l8.485 8.485zl-6 24h12zl12.728 21.213 8.485-8.485zl24 6V30zl21.213-12.728-8.485-8.485z"
          opacity="0.4"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <g filter="url(#filter6_di_0_10934)">
          <path
            fill="#676763"
            fillRule="evenodd"
            d="m51.67 25.189-14.154-8.182a3.03 3.03 0 0 0-3.033 0L20.33 25.189a3.04 3.04 0 0 0-1.517 2.63v5.902l-1.147 1.723a1 1 0 0 0 0 1.108l1.147 1.724v5.906c0 1.084.578 2.087 1.517 2.63l14.154 8.181a3.03 3.03 0 0 0 3.034 0l14.154-8.181a3.04 3.04 0 0 0 1.517-2.63v-5.906l1.147-1.724a1 1 0 0 0 0-1.108l-1.148-1.723v-5.903a3.04 3.04 0 0 0-1.516-2.63M19.824 38.276v5.906c0 .723.386 1.391 1.011 1.753l14.155 8.182a2.02 2.02 0 0 0 2.022 0l14.154-8.182a2.03 2.03 0 0 0 1.011-1.753v-5.906l-1.147-1.724a1 1 0 0 1 0-1.108l1.147-1.723v-5.903c0-.723-.385-1.391-1.01-1.753L37.01 17.883a2.02 2.02 0 0 0-2.022 0l-14.154 8.182a2.03 2.03 0 0 0-1.012 1.753v5.903l1.148 1.723a1 1 0 0 1 0 1.108z"
            clipRule="evenodd"
          ></path>
        </g>
        <mask
          id="mask4_0_10934"
          width="38"
          height="40"
          x="17"
          y="16"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "luminance" }}
        >
          <path
            fill="#fff"
            fillRule="evenodd"
            d="m51.67 25.189-14.154-8.182a3.03 3.03 0 0 0-3.033 0L20.33 25.189a3.04 3.04 0 0 0-1.517 2.63v5.902l-1.147 1.723a1 1 0 0 0 0 1.108l1.147 1.724v5.906c0 1.084.578 2.087 1.517 2.63l14.154 8.181a3.03 3.03 0 0 0 3.034 0l14.154-8.181a3.04 3.04 0 0 0 1.517-2.63v-5.906l1.147-1.724a1 1 0 0 0 0-1.108l-1.148-1.723v-5.903a3.04 3.04 0 0 0-1.516-2.63M19.824 38.276v5.906c0 .723.386 1.391 1.011 1.753l14.155 8.182a2.02 2.02 0 0 0 2.022 0l14.154-8.182a2.03 2.03 0 0 0 1.011-1.753v-5.906l-1.147-1.724a1 1 0 0 1 0-1.108l1.147-1.723v-5.903c0-.723-.385-1.391-1.01-1.753L37.01 17.883a2.02 2.02 0 0 0-2.022 0l-14.154 8.182a2.03 2.03 0 0 0-1.012 1.753v5.903l1.148 1.723a1 1 0 0 1 0 1.108z"
            clipRule="evenodd"
          ></path>
        </mask>
        <g
          fill="#5A5951"
          mask="url(#mask4_0_10934)"
          opacity="0.4"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fillRule="evenodd"
            d="m36 36-24-6v12zm0 0 6-24H30zm0 0 24 6V30zm0 0 12.728-21.213 8.485 8.485zm0 0 21.213 12.728-8.485 8.485zm0 0L23.272 57.213l-8.485-8.485zm0 0 6 24H30z"
            clipRule="evenodd"
          ></path>
          <path d="m23.272 14.787-8.485 8.485L36 36z"></path>
        </g>
        <path
          stroke="#FAB90A"
          d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382a3.5 3.5 0 0 0 1.75 3.03l15.919 9.191a3.5 3.5 0 0 0 3.5 0l15.919-9.19a3.5 3.5 0 0 0 1.75-3.031V26.809a3.5 3.5 0 0 0-1.75-3.03z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m37.75 57.412 3.98-2.297"
          opacity="0.4"
        ></path>
        <path
          stroke="#FAC81C"
          strokeLinecap="round"
          d="M55.419 45.19V26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382"
        ></path>
        <path
          stroke="#FFE56A"
          strokeLinecap="round"
          d="M55.419 26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031"
          opacity="0.6"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-7.96 4.595"
          opacity="0.6"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m34.25 14.588-3.98 2.297"
        ></path>
      </g>
      <g filter="url(#filter7_d_0_10934)">
        <path
          fill="url(#paint30_linear_0_10934)"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#F5900D"
          strokeOpacity="0.1"
          d="M37.25 20.144a2.5 2.5 0 0 0-2.5 0L22.894 26.99a2.5 2.5 0 0 0-1.25 2.165v13.69a2.5 2.5 0 0 0 1.25 2.165l11.856 6.846a2.5 2.5 0 0 0 2.5 0l11.856-6.846a2.5 2.5 0 0 0 1.25-2.165v-13.69a2.5 2.5 0 0 0-1.25-2.165z"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <mask
        id="mask5_0_10934"
        width="28"
        height="32"
        x="22"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask5_0_10934)">
        <g fill="#FFD640" opacity="0.3" style={{ mixBlendMode: "multiply" }}>
          <path
            fillRule="evenodd"
            d="m36 36-16 4v-8zm0 0 4-16h-8zm0 0 16-4v8zm0 0 8.485-14.142 5.657 5.657zm0 0 8.485 14.142 5.657-5.657zm0 0-4 16h8zm0 0-8.485 14.142-5.657-5.657z"
            clipRule="evenodd"
          ></path>
          <path d="m27.515 21.858-5.657 5.657L36 36z"></path>
        </g>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M48.606 27.856 36.75 21.01a1.5 1.5 0 0 0-1.5 0l-2.964 1.712-2.964 1.711-5.928 3.423"
          opacity="0.4"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m35.25 21.01-2.964 1.712"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M48.606 44.11 36.75 50.955a1.5 1.5 0 0 1-1.5 0"
          opacity="0.4"
        ></path>
      </g>
      <g filter="url(#filter8_d_0_10934)">
        <mask
          id="path-51-outside-3_0_10934"
          width="22"
          height="19"
          x="25"
          y="27.804"
          fill="#000"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M25 27.804h22v19H25z"></path>
          <path
            fillRule="evenodd"
            d="m27 35 3-5.196h12L45 35zm9 9 9-9H27z"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m27 35 3-5.196h12L45 35zm9 9 9-9H27z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M30 29.804v-2a2 2 0 0 0-1.732 1zM27 35l-1.732-1A2 2 0 0 0 27 37zm15-5.196 1.732-1a2 2 0 0 0-1.732-1zM45 35v2a2 2 0 0 0 1.732-3zm0 0 1.414 1.414A2 2 0 0 0 45 33zm-9 9-1.414 1.414a2 2 0 0 0 2.828 0zm-9-9v-2a2 2 0 0 0-1.414 3.414zm1.268-6.196-3 5.196 3.464 2 3-5.196zm13.732-1H30v4h12zM46.732 34l-3-5.196-3.464 2 3 5.196zM27 37h18v-4H27zm16.586-3.414-9 9 2.828 2.828 9-9zM27 37h18v-4H27zm10.414 5.586-9-9-2.828 2.828 9 9z"
          mask="url(#path-51-outside-3_0_10934)"
        ></path>
      </g>
      <path
        fill="url(#paint31_linear_0_10934)"
        fillRule="evenodd"
        d="m36 44 9-9H27z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint32_linear_0_10934)"
        fillRule="evenodd"
        d="m36 44-3-9h6z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint33_linear_0_10934)"
        d="M30 29.804 27 35h18l-3-5.196z"
      ></path>
      <path fill="url(#paint34_linear_0_10934)" d="M30 29.804 33 35h-6z"></path>
      <path fill="url(#paint35_linear_0_10934)" d="M36 29.804 39 35h-6z"></path>
      <path fill="url(#paint36_linear_0_10934)" d="M42 29.804 45 35h-6z"></path>
      <g filter="url(#filter9_d_0_10934)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m46.91 29.78-.518-.205a.618.618 0 0 1 0-1.15l.519-.205a2.32 2.32 0 0 0 1.309-1.31l.205-.518a.618.618 0 0 1 1.15 0l.205.519c.236.599.71 1.073 1.31 1.31l.518.204a.618.618 0 0 1 0 1.15l-.519.205a2.32 2.32 0 0 0-1.31 1.31l-.204.518a.618.618 0 0 1-1.15 0l-.205-.519a2.32 2.32 0 0 0-1.31-1.31"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter10_d_0_10934)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m26.607 45.52-.346-.137a.412.412 0 0 1 0-.766l.346-.137c.4-.157.716-.473.873-.873l.137-.346a.412.412 0 0 1 .766 0l.137.346c.157.4.473.716.873.873l.346.137a.412.412 0 0 1 0 .766l-.346.137a1.55 1.55 0 0 0-.873.873l-.137.346a.412.412 0 0 1-.766 0l-.137-.346a1.55 1.55 0 0 0-.873-.873"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter11_d_0_10934)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m20.76 31.15-.434-.17a.515.515 0 0 1 0-.96l.433-.17a1.94 1.94 0 0 0 1.091-1.09l.17-.434a.515.515 0 0 1 .96 0l.17.433c.197.5.592.894 1.09 1.091l.434.17a.515.515 0 0 1 0 .96l-.433.17c-.5.197-.894.592-1.091 1.09l-.17.434a.515.515 0 0 1-.96 0l-.17-.433a1.94 1.94 0 0 0-1.09-1.091"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter12_d_0_10934)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m46.455 45.89-.26-.102a.31.31 0 0 1 0-.576l.26-.102c.3-.118.537-.355.655-.654l.102-.26a.31.31 0 0 1 .575 0l.103.26c.118.299.355.536.654.654l.26.102a.31.31 0 0 1 0 .576l-.26.102c-.299.118-.536.355-.654.654l-.102.26a.31.31 0 0 1-.576 0l-.102-.26a1.16 1.16 0 0 0-.655-.654"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter13_d_0_10934)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m42.304 35.26-.174-.068a.206.206 0 0 1 0-.384l.174-.068a.77.77 0 0 0 .436-.436l.068-.174a.206.206 0 0 1 .384 0l.068.174c.079.2.237.357.436.436l.174.068a.206.206 0 0 1 0 .384l-.174.068a.77.77 0 0 0-.436.436l-.068.174a.206.206 0 0 1-.384 0l-.068-.174a.77.77 0 0 0-.436-.436"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_0_10934"
          x1="5"
          x2="5"
          y1="6"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#575753"></stop>
          <stop offset="1" stopColor="#403F36"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_10934"
          x1="6.004"
          x2="6.004"
          y1="19.086"
          y2="44.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_10934"
          x1="13.976"
          x2="13.976"
          y1="33.99"
          y2="44.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_10934"
          x1="3.967"
          x2="3.967"
          y1="18.978"
          y2="44.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_10934"
          x1="11.976"
          x2="11.976"
          y1="28.99"
          y2="39.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_0_10934"
          x1="1.907"
          x2="1.907"
          y1="18.944"
          y2="44.163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_0_10934"
          x1="9.976"
          x2="9.976"
          y1="23.99"
          y2="34.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_0_10934"
          x1="-0.065"
          x2="-0.065"
          y1="18.99"
          y2="44.157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_0_10934"
          x1="7.976"
          x2="7.976"
          y1="18.99"
          y2="29.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_0_10934"
          x1="65.996"
          x2="65.996"
          y1="19.086"
          y2="44.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_0_10934"
          x1="58.024"
          x2="58.024"
          y1="33.99"
          y2="44.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_0_10934"
          x1="68.033"
          x2="68.033"
          y1="18.978"
          y2="44.086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_0_10934"
          x1="60.024"
          x2="60.024"
          y1="28.99"
          y2="39.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_0_10934"
          x1="70.093"
          x2="70.093"
          y1="18.944"
          y2="44.163"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_0_10934"
          x1="62.024"
          x2="62.024"
          y1="23.99"
          y2="34.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_0_10934"
          x1="72.065"
          x2="72.065"
          y1="18.99"
          y2="44.157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_0_10934"
          x1="64.024"
          x2="64.024"
          y1="18.99"
          y2="29.124"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_0_10934"
          x1="13.155"
          x2="13.155"
          y1="42"
          y2="50.977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_0_10934"
          x1="13.155"
          x2="13.155"
          y1="42"
          y2="50.977"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint19_linear_0_10934"
          x1="59"
          x2="59"
          y1="42"
          y2="51.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint20_linear_0_10934"
          x1="59"
          x2="59"
          y1="42"
          y2="51.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint21_linear_0_10934"
          x1="27"
          x2="27"
          y1="11"
          y2="17.487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint22_linear_0_10934"
          x1="27"
          x2="27"
          y1="11"
          y2="17.487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint23_linear_0_10934"
          x1="18"
          x2="18"
          y1="45.129"
          y2="60.072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F39C05"></stop>
          <stop offset="1" stopColor="#F59100"></stop>
        </linearGradient>
        <linearGradient
          id="paint24_linear_0_10934"
          x1="18"
          x2="18"
          y1="45.129"
          y2="60.072"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBA45"></stop>
          <stop offset="1" stopColor="#F59100"></stop>
        </linearGradient>
        <linearGradient
          id="paint25_linear_0_10934"
          x1="20"
          x2="20"
          y1="11.3"
          y2="26.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint26_linear_0_10934"
          x1="20"
          x2="20"
          y1="11.3"
          y2="26.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint27_linear_0_10934"
          x1="40"
          x2="40"
          y1="11.3"
          y2="26.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#75756F"></stop>
          <stop offset="1" stopColor="#3A342A"></stop>
        </linearGradient>
        <linearGradient
          id="paint28_linear_0_10934"
          x1="40"
          x2="40"
          y1="11.3"
          y2="26.133"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint29_linear_0_10934"
          x1="13"
          x2="13"
          y1="13"
          y2="59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDE046"></stop>
          <stop offset="1" stopColor="#F9B800"></stop>
        </linearGradient>
        <linearGradient
          id="paint30_linear_0_10934"
          x1="20"
          x2="20"
          y1="20"
          y2="52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF550"></stop>
          <stop offset="1" stopColor="#FFD541"></stop>
        </linearGradient>
        <linearGradient
          id="paint31_linear_0_10934"
          x1="36"
          x2="36"
          y1="44"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#878787"></stop>
          <stop offset="1" stopColor="#1F1F1F"></stop>
        </linearGradient>
        <linearGradient
          id="paint32_linear_0_10934"
          x1="39"
          x2="39"
          y1="44"
          y2="35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#878787"></stop>
          <stop offset="1" stopColor="#4E4E4E"></stop>
        </linearGradient>
        <linearGradient
          id="paint33_linear_0_10934"
          x1="45"
          x2="45"
          y1="35"
          y2="29.804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff"></stop>
          <stop offset="1" stopColor="#A8A8A8"></stop>
        </linearGradient>
        <linearGradient
          id="paint34_linear_0_10934"
          x1="29"
          x2="29"
          y1="35"
          y2="30.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181"></stop>
          <stop offset="1" stopColor="#A0A0A0"></stop>
        </linearGradient>
        <linearGradient
          id="paint35_linear_0_10934"
          x1="35"
          x2="35"
          y1="35"
          y2="30.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181"></stop>
          <stop offset="1" stopColor="#A0A0A0"></stop>
        </linearGradient>
        <linearGradient
          id="paint36_linear_0_10934"
          x1="41"
          x2="41"
          y1="35"
          y2="30.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#818181"></stop>
          <stop offset="1" stopColor="#A0A0A0"></stop>
        </linearGradient>
        <filter
          id="filter0_d_0_10934"
          width="20"
          height="35.383"
          x="18"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_0_10934"
          width="20"
          height="35.383"
          x="22"
          y="36"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_0_10934"
          width="20"
          height="35.383"
          x="34"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_d_0_10934"
          width="20"
          height="35.383"
          x="30"
          y="36"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_d_0_10934"
          width="20"
          height="35.383"
          x="26"
          y="38"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0 0.61352 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter5_i_0_10934"
          width="36"
          height="15.944"
          x="18"
          y="45.127"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.929412 0 0 0 0 0.568627 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10934"></feBlend>
        </filter>
        <filter
          id="filter6_di_0_10934"
          width="39.005"
          height="40.8"
          x="16.497"
          y="16"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="0.4"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.909804 0 0 0 0 0.556863 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.31048 0 0 0 0 0.31048 0 0 0 0 0.279572 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect2_innerShadow_0_10934"></feBlend>
        </filter>
        <filter
          id="filter7_d_0_10934"
          width="33.713"
          height="37.381"
          x="19.144"
          y="18.309"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.927721 0 0 0 0 0.665867 0 0 0 0 0.0535899 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter8_d_0_10934"
          width="26"
          height="22.196"
          x="23"
          y="26.804"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.733333 0 0 0 0 0.105882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter9_d_0_10934"
          width="10"
          height="10"
          x="44"
          y="24"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter10_d_0_10934"
          width="8"
          height="8"
          x="24"
          y="41"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter11_d_0_10934"
          width="9"
          height="9"
          x="18"
          y="26"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter12_d_0_10934"
          width="7"
          height="7"
          x="44"
          y="42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter13_d_0_10934"
          width="6"
          height="6"
          x="40"
          y="32"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10934"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10934"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
