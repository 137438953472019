import { MBProClient } from '@meilleursbiens/types';
import { Button } from '@meilleursbiens/ui';

import {
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';

import { MBProCrmClientsFormEditProfileComponent } from '../../forms';

interface MBProCrmClientsContactEditModalComponent {
  disclosure: ReturnType<typeof useDisclosure>;
  onUpdate: (client: MBProClient) => void;
  client: MBProClient;
  hiddenFields?: string[];
}

export default function MBProCrmClientsContactEditModalComponent(props: MBProCrmClientsContactEditModalComponent) {
  return (
    <Modal size={'lg'} isOpen={props.disclosure.isOpen} onClose={props.disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modifier un client</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <MBProCrmClientsFormEditProfileComponent
            client={props.client}
            onUpdate={props.onUpdate}
            hiddenFields={props.hiddenFields}
          />
        </ModalBody>

        <ModalFooter>
          <ButtonGroup size={'sm'}>
            <Button onClick={props.disclosure.onClose}>Annuler</Button>
            <Button variant={'primary'} onClick={props.disclosure.onClose}>
              Enregistrer
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
