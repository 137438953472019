export default class ColorUtils {

  /**
   * Génère un dégradé de type mesh gradient à partir d'une couleur de base
   * @param color Couleur de base au format hexadécimal (#RRGGBB)
   * @returns CSS pour un dégradé de type mesh gradient
   */
  static generateMeshGradientFromColor(color: string, opacity: number): string {
    // Convertir la couleur hex en RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Créer des variations légères de la couleur de base
    const color1 = this.adjustColor(r, g, b, 20, 10, -10);
    const color2 = this.adjustColor(r, g, b, -15, 5, 15);
    const color3 = this.adjustColor(r, g, b, 10, -20, 5);

    // Appliquer l'opacité aux couleurs
    const applyOpacity = (hexColor: string) => {
      const alpha = Math.floor(opacity * 255).toString(16).padStart(2, '0');
      return hexColor + alpha;
    };

    // Générer un dégradé radial à plusieurs points avec opacité
    return `radial-gradient(circle at 0% 0%, rgba(255, 255, 255, 0.2) 0%, transparent 50%),
          radial-gradient(circle at 75% 75%, rgba(255, 255, 255, 0.5) 0%, transparent 50%),
          radial-gradient(circle at 50% 25%, rgba(255, 255, 255, 0.7) 0%, transparent 50%),
          ${color}${opacity < 1 ? applyOpacity(color) : ''}`;
  }

  /**
   * Retourne une version plus foncée de la couleur spécifiée
   * @param color Couleur de base au format hexadécimal (#RRGGBB)
   * @param percentage Pourcentage d'assombrissement (0-100)
   * @returns Couleur plus foncée au format hexadécimal
   */
  static darkenColor(color: string, percentage: number): string {
    // Vérifier que le pourcentage est dans la plage 0-100
    percentage = Math.min(100, Math.max(0, percentage));

    // Facteur d'assombrissement (0 à 1)
    const factor = 1 - (percentage / 100);

    // Convertir la couleur hex en RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Assombrir chaque composante
    const newR = Math.round(r * factor);
    const newG = Math.round(g * factor);
    const newB = Math.round(b * factor);

    // Convertir en hexadécimal
    return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
  }

  /**
   * Retourne une version plus claire de la couleur spécifiée
   * @param color Couleur de base au format hexadécimal (#RRGGBB)
   * @param percentage Pourcentage d'éclaircissement (0-100)
   * @returns Couleur plus claire au format hexadécimal
   */
  static lightenColor(color: string, percentage: number = 20): string {
    // Vérifier que le pourcentage est dans la plage 0-100
    percentage = Math.min(100, Math.max(0, percentage));

    // Facteur d'éclaircissement (0 à 1)
    const factor = percentage / 100;

    // Convertir la couleur hex en RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Éclaircir chaque composante en ajoutant un pourcentage de la différence avec 255
    const newR = Math.round(r + (255 - r) * factor);
    const newG = Math.round(g + (255 - g) * factor);
    const newB = Math.round(b + (255 - b) * factor);

    // Convertir en hexadécimal
    return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
  }

  /**
   * Ajuste une couleur RGB en ajoutant des valeurs de décalage
   * @returns Couleur au format hexadécimal
   */
  private static adjustColor(r: number, g: number, b: number, rOffset: number, gOffset: number, bOffset: number): string {
    // Ajuster les valeurs RGB et s'assurer qu'elles restent dans la plage 0-255
    const newR = Math.min(255, Math.max(0, r + rOffset));
    const newG = Math.min(255, Math.max(0, g + gOffset));
    const newB = Math.min(255, Math.max(0, b + bOffset));

    // Convertir en hexadécimal
    return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
  }

}
