import { useEffect, useState } from 'react';
import { AuthConstant } from '@meilleursbiens/constants';
import { MBProUser } from '@meilleursbiens/types';

export default function useIsImpersonate(user: MBProUser | undefined) {
  const [isImpersonating, setIsImpersonating] = useState(false);

  const _checkImpersonate = () => {
    const localStorageImpersonate = localStorage.getItem(AuthConstant.BACKUP_KEY + AuthConstant.JWT_TOKEN_KEY);
    if (localStorageImpersonate != null) setIsImpersonating(true);
  };

  useEffect(() => {
    _checkImpersonate();
  }, []);

  useEffect(() => {
    _checkImpersonate();
  }, [user]);

  return { isImpersonating, setIsImpersonating };
}
