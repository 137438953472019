import { ServicesConstant } from '@meilleursbiens/constants';

export default class VideoUtils {
  static parseISO8601Duration(isoDuration: string) {
    const match = isoDuration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
    if (!match) {
      return 0;
    }
    const hours = parseInt(match[1], 10) || 0;
    const minutes = parseInt(match[2], 10) || 0;
    const seconds = parseInt(match[3], 10) || 0;
    return Math.floor(hours * 60 + minutes + seconds / 60);
  }

  static getVideoIdFromUrl(videoUrl: string) {
    let videoId;
    if (videoUrl.includes('watch?v=')) {
      videoId = videoUrl.split('watch?v=')[1];
    } else if (videoUrl.includes('youtu.be')) {
      videoId = videoUrl.split('.be/')[1];
    }
    return videoId;
  }

  static async getDurationFromYoutubeLink(videoUrl: string) {
    const apiKey = ServicesConstant.YOUTUBE.API_KEY;
    const videoId = VideoUtils.getVideoIdFromUrl(videoUrl);
    const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&part=contentDetails&key=${apiKey}`;

    const response = await fetch(apiUrl);
    const data = await response.json();

    const duration = VideoUtils.parseISO8601Duration(data.items[0].contentDetails.duration);
    return duration;
  }
}
