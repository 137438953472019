import React, { useEffect } from 'react';

import { useDebounce } from 'use-debounce';

import {
  Box,
  Flex,
  Image,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { MBProAdminAgentsAPI, MBProAdminUserAPI } from '@meilleursbiens/api';
import { MBProUser } from '@meilleursbiens/types';
import { ToastUtils } from '@meilleursbiens/utils';

import { Button } from '../../../Components';
import TextInput, { TextInputProps } from '../TextInput/TextInput';

export type AutocompleteInputsProps = TextInputProps;

const AutocompleteUserInput = (props: AutocompleteInputsProps) => {
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const [isLoading, setIsLoading] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [availableChoices, setAvailableChoices] = React.useState<Partial<MBProUser[]>>([]);

  const [search, setSearch] = React.useState<string>('');
  const [searchDebounce] = useDebounce(search, 500);

  const [selectedUser, setSelectedUser] = React.useState<Partial<MBProUser> | null>(null);

  const _fetchUsers = async () => {
    if (isLoading) return;

    setIsLoading(true);
    MBProAdminAgentsAPI.searchAutocomplete(searchDebounce)
      .then((r) => setAvailableChoices(r))
      .catch((e) => ToastUtils.showError(e))
      .finally(() => setIsLoading(false));
  };

  const _fetchDefaultUser = async () => {
    if (props.values[props.name]) {
      MBProAdminUserAPI.getUser(props.values[props.name]).then((r) => setSelectedUser(r.user));
    }
  };

  useEffect(() => {
    if (searchDebounce.length < 3) {
      onClose();
      setAvailableChoices([]);
      return;
    }

    _fetchUsers();
  }, [searchDebounce]);

  useEffect(() => {
    if (availableChoices.length === 0) onClose();
    else onOpen();
  }, [availableChoices]);

  useEffect(() => {
    _fetchDefaultUser();
  }, [props.values[props.name]]);

  return (
    <Popover isOpen={isOpen} onClose={onClose} autoFocus={false} matchWidth offset={[0, props.helperText ? -20 : 0]}>
      <PopoverTrigger>
        <Box w={'100%'} position={'relative'}>
          <TextInput
            type="hidden"
            customInput={
              <>
                {selectedUser && (
                  <Flex
                    align={'center'}
                    justify={'space-between'}
                    borderRadius={'md'}
                    borderColor={borderColor}
                    borderWidth={1}
                    boxShadow={'sm'}
                    w={'100%'}
                    py={2}
                    px={2}
                    pl={4}
                  >
                    <Flex align={'center'} justify={'flex-start'} gap={2}>
                      <Image src={selectedUser.profile_picture} boxSize={'20px'} borderRadius={'100%'} />
                      <Text fontSize={'sm'} fontWeight={500}>
                        {selectedUser.firstName} {selectedUser.name}
                      </Text>
                    </Flex>
                    <Button size={'sm'} variant={'ghost'} onClick={() => setSelectedUser(null)}>
                      Modifier l'agent
                    </Button>
                  </Flex>
                )}
                {!selectedUser && (
                  <Input type="text" onChange={(e) => setSearch(e.target.value)} onBlur={() => onClose()} />
                )}
              </>
            }
            helperText={props.helperText || ''}
            name={props.name}
            placeholder={'Rechercher un agent...'}
            value={props.values[props.name]}
            errors={props.errors}
            touched={props.touched}
            label={props.label}
            isHorizontal={props.isHorizontal}
            values={props.values}
            handleChange={props.handleChange}
            handleBlur={props.handleBlur}
          />
        </Box>
      </PopoverTrigger>
      <PopoverContent h={'200px'}>
        <Box overflowY={'scroll'} h={'100%'}>
          {availableChoices.map((user, i) => {
            if (!user) return;
            return (
              <Flex
                justify={'flex-start'}
                align={'center'}
                w={'100%'}
                h={'35px'}
                px={3}
                key={user.id || i}
                cursor={'pointer'}
                borderBottomWidth={1}
                borderColor={borderColor}
                _hover={{ bg: 'gray.100' }}
                onClick={() => {
                  props.handleChange({
                    // @ts-ignore
                    target: {
                      name: props.name,
                      // @ts-ignore
                      value: user.id,
                    },
                  });
                  setSelectedUser(user);
                  onClose();
                }}
              >
                <Flex align={'center'} justify={'flex-start'} gap={2}>
                  <Image src={user.profile_picture} boxSize={'20px'} borderRadius={'100%'} />
                  <Text fontSize={'sm'} fontWeight={500}>
                    {user.firstName} {user.name}
                  </Text>
                </Flex>
              </Flex>
            );
          })}
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default AutocompleteUserInput;
