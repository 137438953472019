import React from 'react';

import { HStack, Switch } from '@chakra-ui/react';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface SwitchInputProps extends TextInputProps {
  colorScheme?: string;
}

const _isChecked = (value: any) => {
  return value === true || value === 'true' || value === 1 || value === '1';
};

const SwitchInput = (props: SwitchInputProps) => {
  const [isChecked, setIsChecked] = React.useState(_isChecked(props.values[props.name] || false));

  React.useEffect(() => {
    setIsChecked(_isChecked(props.values[props.name] || false));
  }, [props.values[props.name]]);

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    props.handleChange({
      // @ts-ignore
      target: {
        name: props.name,
        // @ts-ignore
        value: e.target.checked,
      },
    });
  };

  return (
    <HStack spacing={4} w={'100%'}>
      <Switch colorScheme={props.colorScheme || 'green'} isChecked={isChecked} onChange={_handleChange} />
      <TextInput
        type="hidden"
        display={'none'}
        customInput={<></>}
        helperText={props.helperText || ''}
        name={props.name + '_hidden'}
        placeholder={''}
        value={''}
        errors={props.errors}
        touched={props.touched}
        label={props.label}
        isHorizontal={props.isHorizontal}
        values={props.values}
        handleChange={() => {}}
        handleBlur={() => {}}
        inputGroupProps={{
          display: 'none',
        }}
        helperTextProps={{
          mt: 0,
        }}
        labelTextProps={{
          mb: 0,
        }}
      />
    </HStack>
  );
};

export default SwitchInput;
