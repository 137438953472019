import React, { useEffect, useState } from 'react';

import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export interface AutocompleteInputsProps extends TextInputProps {
  choices: string[];
}

const AutocompleteInputs = (props: AutocompleteInputsProps) => {
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const [isFocused, setIsFocused] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [keyDownCount, setKeyDownCount] = useState(0);

  const [availableChoices, setAvailableChoices] = React.useState<string[]>(props.choices);

  useEffect(() => {
    const value = props.values[props.name] || '';
    if (value.length === 0 || value == '') {
      setAvailableChoices(props.choices);
      onClose();
      return;
    }

    if (keyDownCount >= 2) {
      onOpen();
    }

    const choices = props.choices.filter((choice) =>
      choice.toLowerCase().includes(props.values[props.name].toLowerCase())
    );
    setAvailableChoices(choices);

    if (choices.length === 0) onClose();
  }, [props.values[props.name]]);

  return (
    <Popover isOpen={isOpen} onClose={onClose} autoFocus={false} matchWidth>
      <PopoverTrigger>
        <TextInput
          {...props}
          type="text"
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
            onClose();
          }}
          onKeyDown={(e) => setKeyDownCount(keyDownCount + 1)}
        />
      </PopoverTrigger>
      <PopoverContent h={'200px'}>
        <Box overflowY={'scroll'} h={'100%'}>
          {availableChoices.map((choice) => (
            <Flex
              justify={'flex-start'}
              align={'center'}
              w={'100%'}
              h={'35px'}
              px={3}
              key={choice}
              cursor={'pointer'}
              borderBottomWidth={1}
              borderColor={borderColor}
              _hover={{ bg: 'gray.100' }}
              onClick={() => {
                props.handleChange({
                  // @ts-ignore
                  target: {
                    name: props.name,
                    value: choice,
                  },
                });
                onClose();
              }}
            >
              <Text fontSize={'sm'} fontWeight={500}>
                {choice}
              </Text>
            </Flex>
          ))}
        </Box>
      </PopoverContent>
    </Popover>
  );
};

export default AutocompleteInputs;
