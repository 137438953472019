import React from 'react';

import { lazyRetry } from '@meilleursbiens/utils';

const MBProDashboardScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/account/MBProDashboardScreen'), 'MBProDashboardScreen')
);
const MBProMarketplaceScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/account/marketplace/MBProMarketplaceScreen'), 'MBProMarketplaceScreen')
);
const MBAccountsContactsScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/account/contacts/MBAccountContactsScreen'), 'MBAccountsContactsScreen')
);
const AccountContactViewScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/account/contacts/AccountContactViewScreen'), 'AccountContactViewScreen')
);
const AccountTransactionsScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/transactions/AccountTransactionsScreen'),
    'AccountTransactionsScreen'
  )
);
const AccountTransactionsCreateScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/transactions/creator/AccountTransactionsCreateScreen'),
    'AccountTransactionsCreateScreen'
  )
);
const AccountTransactionsModifyScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/transactions/creator/AccountTransactionsModifyScreen'),
    'AccountTransactionsModifyScreen'
  )
);
const AccountTransactionViewScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/transactions/AccountTransactionViewScreen'),
    'AccountTransactionViewScreen'
  )
);
const AccountTransactionLocationViewScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/transactions/AccountTransactionLocationViewScreen'),
    'AccountTransactionLocationViewScreen'
  )
);
const AccountTransactionsLocationCreateScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/transactions/creator/AccountTransactionsLocationCreateScreen'),
    'AccountTransactionsLocationCreateScreen'
  )
);
const MiniSiteEditScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPortail/MiniSiteEditScreen'), 'MiniSiteEditScreen')
);
const MBProAccountFormationsScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/formations/MBProAccountFormationsScreen'),
    'MBProAccountFormationsScreen'
  )
);
const MBProAccountReplaysScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/account/formations/MBProAccountReplaysScreen'), 'MBProAccountReplaysScreen')
);
const MBProPublicCRMClientsSearchViewerScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/public/CRM/Clients/MBProPublicCRMClientsSearchViewerScreen'),
    'MBProPublicCRMClientsSearchViewerScreen'
  )
);
const MBProAccountDocumentsScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/documents/MBProAccountDocumentsScreen'),
    'MBProAccountDocumentsScreen'
  )
);
const AccountIntegrationScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/account/integrations/AccountIntegrationScreen'), 'AccountIntegrationScreen')
);
const AccountSubscriptionScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/subscription/AccountSubscriptionScreen'),
    'AccountSubscriptionScreen'
  )
);
const MBProCalendarSettingsScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/calendar/MBProCalendarSettingsScreen'),
    'MBProCalendarSettingsScreen'
  )
);
const MBProAccountingInvoiceScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/accounting/MBProAccountingInvoiceScreen'),
    'MBProAccountingInvoiceScreen'
  )
);
const MobileViewEstimationScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/Mobile/Marketplace/MobileViewEstimationScreen'), 'MobileViewEstimationScreen')
);
const MobileMarketplaceScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/Mobile/Marketplace/MobileMarketplaceScreen'), 'MobileMarketplaceScreen')
);
const MBProToolsCadastreScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/account/tools/MBProToolsCadastreScreen'), 'MBProToolsCadastreScreen')
);
const MBProToolsPortalAdsSearchScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/tools/MBProToolsPortalAdsSearchScreen'),
    'MBProToolsPortalAdsSearchScreen'
  )
);
const MBSaaSComparatorAgency = React.lazy(() =>
  lazyRetry(() => import('../screens/SaaS/Comparator/MBSaaSComparatorAgency'), 'MBSaaSComparatorAgency')
);
const MBProPublicVisitVouchersSignScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/public/Documents/MBProPublicVisitVouchersSignScreen'),
    'MBProPublicVisitVouchersSignScreen'
  )
);
const MBProAccountMandatesScreen = React.lazy(() =>
  lazyRetry(() => import('../screens/MBPro/account/mandates/MBProAccountMandatesScreen'), 'MBProAccountMandatesScreen')
);
const MBProPublicMandateSignScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/public/Mandates/MBProPublicMandateSignScreen'),
    'MBProPublicMandateSignScreen'
  )
);
const MBProPublicBuyOffersSignScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/public/Documents/MBProPublicBuyOffersSignScreen'),
    'MBProPublicBuyOffersSignScreen'
  )
);
const MBProPublicMandateAmendmentsSignScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/public/Mandates/MBProPublicMandateAmendmentsSignScreen'),
    'MBProPublicMandateAmendmentsSignScreen'
  )
);
const MBProPublicMandateSignSynthesisScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/public/Mandates/MBProPublicMandateSignSynthesisScreen'),
    'MBProPublicMandateSignSynthesisScreen'
  )
);
const MBProAccountCRMClientsRequestsScreen = React.lazy(() =>
  lazyRetry(
    () => import('../screens/MBPro/account/contacts/requests/MBProAccountCRMClientsRequestsScreen'),
    'MBProAccountCRMClientsRequestsScreen'
  )
);

export default {
  PRO_DASHBOARD: {
    url: '/pro/dashboard',
    component: MBProDashboardScreen,
    routeType: 'private.validated',
  },
  MARKETPLACE: {
    url: '/pro/marketplace/v2',
    component: MBProMarketplaceScreen,
    routeType: 'private.validated',
  },
  CONTACTS: {
    url: '/pro/contacts',
    component: MBAccountsContactsScreen,
    routeType: 'private.validated',
  },
  CONTACTS_VIEW: {
    url: '/pro/contacts/:id',
    component: AccountContactViewScreen,
    routeType: 'private.validated',
  },
  TRANSACTIONS_LIST: {
    url: '/pro/transactions',
    component: AccountTransactionsScreen,
    routeType: 'private.validated',
  },
  TRANSACTIONS_CREATE: {
    url: '/pro/transactions/create',
    component: AccountTransactionsCreateScreen,
    routeType: 'private.unstyled',
  },
  TRANSACTIONS_EDIT: {
    url: '/pro/transactions/edit/:id',
    component: AccountTransactionsModifyScreen,
    routeType: 'private.unstyled',
  },
  TRANSACTIONS_VIEW: {
    url: '/pro/transactions/:id',
    component: AccountTransactionViewScreen,
    routeType: 'private.validated',
  },
  TRANSACTIONS_LOCATION_CREATE: {
    url: '/pro/transactions-location/create',
    component: AccountTransactionsLocationCreateScreen,
    routeType: 'private.unstyled',
  },
  TRANSACTIONS_LOCATION_VIEW: {
    url: '/pro/transactions-location/:id',
    component: AccountTransactionLocationViewScreen,
    routeType: 'private.validated',
  },
  INVOICES_LIST: {
    url: '/pro/accounting/invoices',
    component: MBProAccountingInvoiceScreen,
    routeType: 'private.validated',
  },
  PRO_MINI_SITE: {
    url: '/portail/mini-site',
    component: MiniSiteEditScreen,
    routeType: 'private.validated',
  },
  PRO_FORMATIONS: {
    url: '/pro/formations',
    component: MBProAccountFormationsScreen,
    routeType: 'private.validated',
  },
  PRO_REPLAYS: {
    url: '/pro/replays',
    component: MBProAccountReplaysScreen,
    routeType: 'private.validated',
  },
  PRO_DOCUMENTS: {
    url: '/pro/documents',
    component: MBProAccountDocumentsScreen,
    routeType: 'private.validated',
  },
  PRO_INTEGRATIONS: {
    url: '/pro/integrations',
    component: AccountIntegrationScreen,
    routeType: 'private.validated',
  },
  PRO_SUBSCRIPTIONS: {
    url: '/pro/subscription',
    component: AccountSubscriptionScreen,
    routeType: 'private.validated',
  },
  PRO_SETTINGS_CALENDAR: {
    url: '/pro/settings/calendar',
    component: MBProCalendarSettingsScreen,
    routeType: 'private.validated',
  },
  PRO_MARKETPLACE_FORM: {
    url: '/mobile/marketplace/:api_token/:id/:user_id',
    component: MobileMarketplaceScreen,
    routeType: 'public',
  },
  PRO_VIEW_ESTIMATION_FROM_APP: {
    url: '/mobile/estimation/view/:api_token/:refresh_token/:id/:user_id',
    component: MobileViewEstimationScreen,
    routeType: 'public',
  },
  PRO_TOOLS_CADASTRE_DVF: {
    url: '/pro/cadastre-dvf',
    component: MBProToolsCadastreScreen,
    routeType: 'private.validated',
  },
  PRO_TOOLS_PORTALS_SEARCH: {
    url: '/pro/ads',
    component: MBProToolsPortalAdsSearchScreen,
    routeType: 'private.validated',
  },
  PRO_MANDATES_LIST: {
    url: '/pro/mandates',
    component: MBProAccountMandatesScreen,
    routeType: 'private.validated',
  },
  PRO_CLIENTS_REQUESTS_LIST: {
    url: '/pro/clients/requests',
    component: MBProAccountCRMClientsRequestsScreen,
    routeType: 'private.validated',
  },
  COMPARATOR_AGENCY: {
    url: '/saas/comparator/agency',
    component: MBSaaSComparatorAgency,
    routeType: 'public',
  },
  PUBLIC_ADS_SEARCH: {
    url: '/public/ads-search/:code',
    component: MBProPublicCRMClientsSearchViewerScreen,
    routeType: 'public',
  },
  PUBLIC_VISIT_VOUCHERS: {
    url: '/public/visit-vouchers/:id',
    component: MBProPublicVisitVouchersSignScreen,
    routeType: 'public',
  },
  PUBLIC_MANDATE_SIGN: {
    url: '/public/mandates/sign/:id',
    component: MBProPublicMandateSignScreen,
    routeType: 'public',
  },
  PUBLIC_MANDATE_AMENDMENTS_SIGN: {
    url: '/public/mandates/amendments/sign/:id',
    component: MBProPublicMandateAmendmentsSignScreen,
    routeType: 'public',
  },
  PUBLIC_MANDATE_SIGN_SYNTHESIS: {
    url: '/public/mandates/synthesis/:id',
    component: MBProPublicMandateSignSynthesisScreen,
    routeType: 'public',
  },
  PUBLIC_BUY_OFFERS_SIGN: {
    url: '/public/buy-offers/sign/:id',
    component: MBProPublicBuyOffersSignScreen,
    routeType: 'public',
  }
};
