import { MBProUsersAfiliationsRank, MBProUsersAfiliationsRanks } from '../index';


export default class MBProUserAfiliationsUtils {

  /**
   * Permet de récupérer le rang en fonction de la valeur
   *
   * @param value Nombre de parrainages
   */
  static getRankByValue(value: number): MBProUsersAfiliationsRank {
      const ranks = MBProUsersAfiliationsRanks;

      let selectedRank = null;
      ranks.forEach((rank) => {
        if(value >= rank.value) {
          selectedRank = rank;
        }
      })


      if (!selectedRank) {
          return MBProUsersAfiliationsRanks[0];
      }

      return selectedRank;
  }

}
