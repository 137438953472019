import MBProUsersAfiliationsFirstBadgeComponent from '../badges/MBProUsersAfiliationsFirstBadgeComponent';
import MBProUsersAfiliationsSecondBadgeComponent from '../badges/MBProUsersAfiliationsSecondBadgeComponent';
import MBProUsersAfiliationsThirdBadgeComponent from '../badges/MBProUsersAfiliationsThirdBadgeComponent';
import MBProUsersAfiliationsFourthBadgeComponent from '../badges/MBProUsersAfiliationsFourthBadgeComponent';
import MBProUsersAfiliationsFifthBadgeComponent from '../badges/MBProUsersAfiliationsFifthBadgeComponent';
import MBProUsersAfiliationsSixthBadgeComponent from '../badges/MBProUsersAfiliationsSixthBadgeComponent';
import MBProUsersAfiliationsSeventhBadgeComponent from '../badges/MBProUsersAfiliationsSeventhBadgeComponent';
import MBProUsersAfiliationsEighthBadgeComponent from '../badges/MBProUsersAfiliationsEighthBadgeComponent';
import MBProUsersAfiliationsNinthBadgeComponent from '../badges/MBProUsersAfiliationsNinthBadgeComponent';
import { MBProUsersAfiliationsRank } from '../index';
import MBProUsersAfiliationsZeroBadgeComponent from '../badges/MBProUsersAfiliationsZeroBadgeComponent';

export const MBProUsersAfiliationsRanks: MBProUsersAfiliationsRank[] = [
  {
    name: 'Néophyte',
    value: 0,
    color: '#ad90f5',
    icon: MBProUsersAfiliationsZeroBadgeComponent,
  },
  {
    name: 'Novice',
    value: 1,
    color: '#fdd255',
    icon: MBProUsersAfiliationsFirstBadgeComponent,
    reduction: "-40€",
    annualReduction: "-480€"
  },
  {
    name: 'Bâtisseur',
    value: 2,
    color: '#b0d323',
    icon: MBProUsersAfiliationsSecondBadgeComponent,
    reduction: "-90€",
    annualReduction: "-1080€"
  },
  {
    name: 'Propulseur',
    value: 3,
    color: '#59d5a0',
    icon: MBProUsersAfiliationsThirdBadgeComponent,
    reduction: "-150€",
    annualReduction: "-1800€"
  },
  {
    name: 'Ambassadeur',
    value: 4,
    color: '#4bb7eb',
    icon: MBProUsersAfiliationsFourthBadgeComponent,
    reduction: "-210€",
    annualReduction: "-2520€"
  },
  {
    name: 'Influenceur',
    value: 5,
    color: '#458afd',
    icon: MBProUsersAfiliationsFifthBadgeComponent,
    reduction: "-270€",
    annualReduction: "-3240€"
  },
  {
    name: 'Stratège',
    value: 7,
    color: '#887ee5',
    icon: MBProUsersAfiliationsSixthBadgeComponent,
    reduction: "-390€",
    annualReduction: "-4680€"
  },
  {
    name: 'Recruteur',
    value: 9,
    color: '#5e85c4',
    icon: MBProUsersAfiliationsSeventhBadgeComponent,
    reduction: "-510€",
    annualReduction: "-6120€"
  },
  {
    name: 'Leader',
    value: 12,
    color: '#8352b0',
    icon: MBProUsersAfiliationsEighthBadgeComponent,
    reduction: "-630€",
    annualReduction: "-7560€"
  },
  {
    name: 'Élite',
    value: 15,
    color: '#bd4b55',
    icon: MBProUsersAfiliationsNinthBadgeComponent,
    reduction: "-810€",
    annualReduction: "-9720€"
  },
];
