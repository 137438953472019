import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { PencilSquareIcon, ArrowsUpDownIcon as SwitchVerticalIcon, UsersIcon } from '@heroicons/react/24/outline';
import { MBCoreTeamAPI } from '@meilleursbiens/api';
import { useSessionStorage } from '@meilleursbiens/hooks';
import { MBProTeam, MBProUser } from '@meilleursbiens/types';

import { MBCoreTeamsEditModalComponent, MBCoreTeamsInviteModalComponent } from '../../modals';

export default function TeamsSwitchMenu({
  user,
  isSquare = false,
  children,
  subItems,
  redirectTo = '/',
}: {
  user: MBProUser;
  isSquare?: boolean;
  children?: React.ReactNode;
  subItems?: React.ReactNode;
  redirectTo?: string;
}) {
  const navigate = useNavigate();

  const [isInit, setInit] = useState();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [teams, setTeams] = useSessionStorage<any>('mb_user_teams', []);
  const [selectedTeam, setSelectedTeam] = useSessionStorage<any>('mb_user_teams_selected_team', null);
  const [membersNb, setMembersNb] = useSessionStorage<number>('mb_user_teams_members', 1);

  const {
    isOpen: isTeamMembersModalOpen,
    onOpen: onOpenTeamMembersModal,
    onClose: onCloseTeamMembersModal,
  } = useDisclosure();

  const { isOpen: isTeamEditModalOpen, onOpen: onOpenTeamEditModal, onClose: onCloseTeamEditModal } = useDisclosure();

  const _fetchTeams = async () => {
    MBCoreTeamAPI.getTeams()
      .then((response) => {
        setTeams(response.teams);
        setSelectedTeam(response.current_team);
        setMembersNb(response.current_team_members);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  };

  const _switchTeam = (team: MBProTeam) => {
    if (!team) return;
    setIsLoading(true);
    MBCoreTeamAPI.switchTeam(team.id)
      .then((response) => {
        _fetchTeams();
        sessionStorage.clear();
        navigate(0);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (selectedTeam == null) setIsLoading(true);
    _fetchTeams();
  }, []);

  const _getProfilePicture = (team: MBProTeam) => {
    if (team == null)
      return 'https://mbpro.s3.eu-west-3.amazonaws.com/2f61f2d9-ccfa-4fab-9415-c17f19211e59.png/j2AogneCLjDsLRTtgF7mw8OAyHCoHbEZlY0A0pMV.png';

    if (team.logo) {
      return team.logo;
    }

    return 'https://mbpro.s3.eu-west-3.amazonaws.com/2f61f2d9-ccfa-4fab-9415-c17f19211e59.png/j2AogneCLjDsLRTtgF7mw8OAyHCoHbEZlY0A0pMV.png';
  };

  return (
    <>
      <Menu closeOnSelect={true}>
        <MenuButton
          as={Flex}
          display={'flex'}
          direction={'row'}
          align={'center'}
          justify={'space-between'}
          minH={'40px'}
          pr={3}
          cursor={'pointer'}
          borderRadius={'8px'}
          _hover={{
            backgroundColor: useColorModeValue('gray.50', 'gray.700'),
          }}
        >
          <Flex direction={'row'} w={'full'} alignItems={'center'} justify={'space-between'}>
            <HStack>
              <Image
                w={'44px'}
                h={'44px'}
                borderRadius={'8px'}
                objectFit={'cover'}
                src={_getProfilePicture(selectedTeam)}
              />

              {!isSquare && (
                <Stack>
                  <Skeleton isLoaded={!isLoading} style={{ marginBottom: 0 }}>
                    <Tooltip label={selectedTeam?.name}>
                      <Text
                        fontSize={'13px'}
                        fontWeight={'bold'}
                        noOfLines={1}
                        className="no-select-text"
                        style={{ marginBottom: 0 }}
                      >
                        {selectedTeam && selectedTeam.society_type ? selectedTeam.society_type.name : undefined}{' '}
                        {selectedTeam?.name}
                      </Text>
                    </Tooltip>
                    <Text
                      fontSize={'xs'}
                      noOfLines={1}
                      className="no-select-text"
                      color={useColorModeValue('gray.500', 'gray.400')}
                      style={{ marginTop: 0 }}
                    >
                      {membersNb} membre{membersNb > 1 ? 's' : ''}
                    </Text>
                  </Skeleton>
                </Stack>
              )}
            </HStack>

            {!isSquare && (
              <Flex justify={'flex-end'}>
                <SwitchVerticalIcon width={17} color={useColorModeValue('#b8babe', '#8d8d8d')} />
              </Flex>
            )}
          </Flex>
        </MenuButton>
        <MenuList>
          {teams?.map((team: MBProTeam) => (
            <MenuItem minH="40px" px={2} isDisabled={team.id === selectedTeam.id} onClick={() => _switchTeam(team)}>
              <Image
                w={'44px'}
                h={'44px'}
                borderRadius={'8px'}
                src={_getProfilePicture(team)}
                alt={team?.name}
                mr="12px"
              />
              <Stack style={{ marginBottom: 0 }} w={'170px'}>
                {/* @ts-ignore */}
                <Text
                  fontSize={'13px'}
                  fontWeight={'bold'}
                  noOfLines={1}
                  className="no-select-text"
                  style={{ marginBottom: 0 }}
                >
                  {team && team.society_type
                    ? // @ts-ignore
                      team.society_type.name
                    : undefined}{' '}
                  {team?.name}
                </Text>
                <Text
                  fontSize={'xs'}
                  noOfLines={1}
                  className="no-select-text"
                  color={useColorModeValue('gray.500', 'gray.400')}
                  style={{ marginTop: 0 }}
                >
                  {team.slug}
                </Text>
              </Stack>
            </MenuItem>
          ))}
          <MenuDivider />
          <>
            <MenuItem icon={<PencilSquareIcon width={20} />} px={2} onClick={onOpenTeamEditModal}>
              Editer l'équipe
            </MenuItem>
            <MenuItem icon={<UsersIcon width={20} />} px={2} isDisabled onClick={onOpenTeamMembersModal}>
              Gérer les membres de l'équipe
            </MenuItem>
            <MenuDivider />
          </>
          {subItems && subItems}
        </MenuList>
      </Menu>
      {selectedTeam && (
        <>
          <MBCoreTeamsInviteModalComponent
            teamId={selectedTeam.id}
            isOpen={isTeamMembersModalOpen}
            onClose={() => {
              onCloseTeamMembersModal();
              if (selectedTeam == null) setIsLoading(true);
              _fetchTeams();
            }}
          />
          <MBCoreTeamsEditModalComponent
            teamId={selectedTeam.id}
            isOpen={isTeamEditModalOpen}
            onClose={() => {
              onCloseTeamEditModal();
              if (selectedTeam == null) setIsLoading(true);
              _fetchTeams();
            }}
          />
        </>
      )}
    </>
  );
}
