import React, { useEffect } from 'react';

import { MBProClient } from '@meilleursbiens/types';
import { Badge, MenuItem, Persona } from '@meilleursbiens/ui';

import {
  Box,
  Flex,
  HStack,
  ListItem,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
  Tooltip,
  UnorderedList,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  CheckCircleIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';

import MBProCrmClientsCoreStatusEditComponent from '../core/MBProCrmClientsCoreStatusEditComponent/MBProCrmClientsCoreStatusEditComponent';
import MBProCrmClientsContactEditModalComponent from '../modals/MBProCrmClientsContactEditModalComponent/MBProCrmClientsContactEditModalComponent';

interface MBProCrmClientsCardComponentProps {
  isLoading?: boolean;
  isSelected?: boolean;
  client?: MBProClient;
  onClick?: () => void;
  rightIcon?: React.ReactNode;
  hasContextMenu?: boolean;
  onUpdate?: (client: MBProClient) => void;
  hiddenEditFields?: string[];
  requiredFields?: string[];
  ruleToMessage?: (rule: string) => string;
}

export default function MBProCrmClientsCardComponent(props: MBProCrmClientsCardComponentProps) {
  const disclosure = useDisclosure();

  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const activeBackground = useColorModeValue('gray.50', 'gray.700');

  const [missingFields, setMissingFields] = React.useState<string[]>([]);

  useEffect(() => {
    if (props.requiredFields && props.client) {
      const fields = props.requiredFields.filter((field) => {
        const keys = field.split('.');
        let value: any = props.client;
        for (const key of keys) {
          if (value[key as keyof MBProClient] === undefined || value[key as keyof MBProClient] === null) {
            return true;
          }
          value = value[key];
        }
        return false;
      });
      setMissingFields(fields);
    }
  }, [props.client]);

  if (props.isLoading || !props.client)
    return (
      <Flex
        borderBottomWidth={1}
        borderColor={borderColor}
        direction={'column'}
        align={'center'}
        py={4}
        px={3}
        w={'100%'}
        onClick={props.onClick}
        bg={props.isSelected ? activeBackground : 'transparent'}
        {...(props.onClick && { cursor: 'pointer' })}
      >
        <Flex direction={'row'} align={'center'} justify={'space-between'} flex={1} w={'100%'}>
          <HStack>
            <SkeletonCircle size="12" />
            <Stack>
              <Skeleton w={'70px'} h={'10px'} />
              <Skeleton w={'100px'} h={'20px'} />
            </Stack>
          </HStack>
          <Skeleton w={'70px'} h={'20px'} />
        </Flex>
      </Flex>
    );

  const { client } = props;

  const hasCaretRight = !props.hasContextMenu && !props.rightIcon;
  const hasRightIcon = props.rightIcon || !props.hasContextMenu;

  return (
    <Flex
      borderBottomWidth={1}
      borderColor={borderColor}
      direction={'column'}
      align={'center'}
      py={4}
      px={3}
      w={'100%'}
      bg={props.isSelected ? activeBackground : 'transparent'}
      onClick={props.onClick}
      {...(props.onClick && { cursor: 'pointer' })}
    >
      <Flex direction={'row'} align={'center'} justify={'space-between'} flex={1} w={'100%'}>
        <HStack position={'relative'}>
          <Persona
            size={'sm'}
            name={client.first_name + ' ' + client.name}
            secondaryText={' '}
            avatarProps={{
              h: '2.7em',
              w: '2.7em',
              mr: '6px',
            }}
            {...(client.is_pinned == 1 && { presence: 'star' })}
            secondaryTextRightElement={
              <HStack spacing={1} mt={1}>
                <MBProCrmClientsCoreStatusEditComponent client={client} onUpdate={props.onUpdate} hasCaret />
                {client.searches_count && (
                  <>
                    {client.searches_count > 0 && (
                      <>
                        <Text color={'muted'} mt={'5px'} fontSize={'10px'}>
                          -
                        </Text>
                        <Text color={'red'} mt={'5px'} fontSize={'10px'}>
                          {client.searches_count} Alerte{client.searches_count > 1 ? 's' : ''}
                        </Text>
                      </>
                    )}
                  </>
                )}
              </HStack>
            }
          ></Persona>
        </HStack>
        <HStack>
          {missingFields.length > 0 && (
            <Tooltip
              bg={'red.50'}
              label={
                <Box color={'gray.700'}>
                  Veuillez compléter les champs suivants :
                  <UnorderedList ml={4} mt={'0.125rem'}>
                    {missingFields.map((missingField, index) => (
                      <ListItem key={index}>
                        <Box key={index}></Box>
                        {props.ruleToMessage && props.ruleToMessage(missingField)}
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
              }
            >
              <Flex as="span" fontSize={'xs'} color={'red.300'} w={'100%'} alignItems={'center'}>
                <InformationCircleIcon height={'1rem'} />
                <Box as="span" ml={1}>
                  Infos manquantes
                </Box>
              </Flex>
            </Tooltip>
          )}
          {hasRightIcon && props.rightIcon}
          {hasCaretRight && <ChevronRightIcon width={15} />}
          {props.hasContextMenu && (
            <Menu>
              <MenuButton
                cursor={'pointer'}
                as={Flex}
                align={'center'}
                justify={'center'}
                p={1}
                borderRadius={'50%'}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Badge colorScheme={'gray'} size={'sm'}>
                  ...
                </Badge>
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem
                    icon={CheckCircleIcon}
                    onClick={() => {
                      props.onClick && props.onClick();
                    }}
                  >
                    Sélectionner le client
                  </MenuItem>
                  <MenuItem icon={PencilSquareIcon} onClick={disclosure.onOpen}>
                    Modifier le client
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          )}
        </HStack>
      </Flex>
      <MBProCrmClientsContactEditModalComponent
        hiddenFields={props.hiddenEditFields}
        disclosure={disclosure}
        onUpdate={(client) => {
          if (props.onUpdate) {
            props.onUpdate(client);
          }
        }}
        client={client}
      />
    </Flex>
  );
}
