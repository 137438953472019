import { MBProMandate } from '@meilleursbiens/types';

import { PriceUtils } from './index';

export default class MandateUtils {
  static getAgentFees(mandate: MBProMandate) {
    if (mandate.price_agent_fee_amount > 0) {
      return PriceUtils.formatPriceWithDecimals(mandate.price_agent_fee_amount);
    } else {
      if (mandate.type && mandate.type.category === 2) {
        return PriceUtils.formatPriceWithDecimals(
          (((mandate?.price_rent_tenant_total_fee ?? 0) +
            (mandate?.price_rent_lessor_total_fee ?? 0)) *
            mandate.price_agent_fee_percentage) /
          100
        )
      } else {
        return PriceUtils.formatPriceWithDecimals(
          mandate.price_public - mandate.price_estimated
        )
      }
    }
  }

  static getMandateOccupations() {
    return [
      { value: 0, label: 'Libre de toute occupation' },
      { value: 1, label: 'Locataire' },
      { value: 2, label: 'Occupant à titre gratuit' },
      { value: 3, label: 'Occupant à titre onéreux' },
      { value: 4, label: 'Usufruitier' },
      { value: 5, label: 'Nue-propriétaire' },
      { value: 6, label: 'Copropriétaire' },
      { value: 7, label: 'Indivisaire' },
      { value: 8, label: 'Mandataire' },
      { value: 9, label: 'Curateur' },
      { value: 10, label: 'Tuteur' },
      { value: 11, label: 'Autre' },
    ];
  }

  static getMandateOrigins() {
    return [
      { value: 13, label: 'Acquisition' },
      { value: 0, label: 'Succession' },
      { value: 1, label: 'Donation' },
      { value: 2, label: 'Divorce / Séparation' },
      { value: 3, label: 'Mutation professionnelle' },
      { value: 4, label: 'Surendettement' },
      { value: 5, label: 'Retraite' },
      { value: 6, label: 'Investissement' },
      { value: 7, label: 'Expatriation' },
      { value: 8, label: 'Réorganisation familiale' },
      { value: 9, label: 'Changement de situation personnelle' },
      { value: 10, label: 'Départ en maison de retraite ou logement adapté' },
      { value: 11, label: 'Liquidation judiciaire' },
      { value: 12, label: 'Vente pour apurement de dettes fiscales' },
      { value: 14, label: 'Fin de période de défiscalisation' },
      { value: 15, label: 'Incendie ou catastrophe naturelle' },
    ];
  }

  static getTaciteRenewalDurations() {
    return [
      { value: '3 mois', label: '3 mois' },
      { value: '6 mois', label: '6 mois' },
      { value: '12 mois', label: '12 mois' },
    ];
  }
}
