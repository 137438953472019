import React, { useEffect, useState } from 'react';

import { Box, Popover, PopoverContent, PopoverTrigger, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { useKeyPress } from '@meilleursbiens/hooks';

import TextInput, { TextInputProps } from '../TextInput/TextInput';

export type EmailInputProps = TextInputProps;

const VENDORS = [
  'gmail.com',
  'meilleursbiens.com',
  'yahoo.fr',
  'outlook.com',
  'laposte.net',
  'free.fr',
  'orange.fr',
  'sfr.fr',
  'bbox.fr',
  'numericable.fr',
  'wanadoo.fr',
  'hotmail.com',
];

const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const EmailInput = (props: EmailInputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const hoverBackground = useColorModeValue('gray.50', 'gray.700');

  const disclosure = useDisclosure();

  const [keyDownCount, setKeyDownCount] = useState(0);
  const arrowDownPressed = useKeyPress('ArrowDown');
  const arrowUpPressed = useKeyPress('ArrowUp');
  const enterPressed = useKeyPress('Enter');

  const [vendors, setVendors] = useState(VENDORS);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  useEffect(() => {
    const value = props.values[props.name];
    if (value) {
      if (validateEmail(value)) {
        disclosure.onClose();
        return;
      } else {
        if (value.length > 1 && keyDownCount > 3) {
          disclosure.onOpen();
          return;
        }

        if (value.includes('@') && disclosure.isOpen) {
          const vendorValue = _secondPart();
          setVendors(VENDORS.filter((v) => v.includes(vendorValue.toLowerCase())));
          if (vendors.length > 0) {
            setSelectedIndex(0);
          } else {
            setSelectedIndex(0);
          }
        } else {
          setVendors(VENDORS);
          setSelectedIndex(0);
        }
      }
    }
  }, [props.values[props.name]]);

  const _firstPart = () => {
    const value = props.values[props.name];
    if (value) {
      return value.split('@')[0];
    }
    return '';
  };

  const _secondPart = () => {
    const value = props.values[props.name];
    if (value) {
      return value.split('@')[1];
    }
    return '';
  };

  const _onSelectInput = (vendor: string) => {
    const firstPart = props.values[props.name].split('@')[0];
    const value = firstPart + '@' + vendor;
    props.handleChange({
      // @ts-ignore
      target: {
        name: props.name,
        value: value,
      },
    });
  };

  useEffect(() => {
    if (!enterPressed) return;
    if (vendors.length == 0) return;
    if (vendors.length - 1 < selectedIndex) return;
    _onSelectInput(vendors[selectedIndex]);
    disclosure.onClose();
  }, [enterPressed]);

  useEffect(() => {
    if (!arrowUpPressed) return;
    if (selectedIndex <= 0) return;
    setSelectedIndex(selectedIndex - 1);
  }, [arrowUpPressed]);

  useEffect(() => {
    if (!arrowDownPressed) return;
    if (selectedIndex >= vendors.length - 1) return;
    setSelectedIndex(selectedIndex + 1);
  }, [arrowDownPressed]);

  return (
    <Popover
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      autoFocus={false}
      initialFocusRef={inputRef}
      returnFocusOnClose={true}
      matchWidth
      closeOnBlur={true}
    >
      <PopoverTrigger>
        <TextInput
          ref={inputRef}
          {...props}
          type="email"
          onBlur={(e) => {
            if (disclosure.isOpen) disclosure.onClose();
            props.onBlur && props.onBlur(e);
          }}
          onKeyDown={(e) => {
            setKeyDownCount(keyDownCount + 1);
          }}
        />
      </PopoverTrigger>
      <PopoverContent>
        {vendors.map((v, i) => {
          if (i > 15) return;
          return (
            <Box
              w={'100%'}
              px={3}
              py={1}
              borderBottomWidth={1}
              borderBottomColor={borderColor}
              cursor={'pointer'}
              bg={i == selectedIndex ? hoverBackground : 'transparent'}
              onClick={() => {
                _onSelectInput(v);
                disclosure.onClose();
              }}
              fontSize={13}
              noOfLines={1}
            >
              {_firstPart()}@{v}
            </Box>
          );
        })}
      </PopoverContent>
    </Popover>
  );
};

export default EmailInput;
