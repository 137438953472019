import { MBNeoPropertiesClients, MBProClient } from '@meilleursbiens/types';

export default class ClientsUtils {

  static getNeoClientsFirstVendor(clients: MBNeoPropertiesClients[]): MBProClient | null {
    const vendor = clients.find((client) => client.type === 0 || client.type === 3);

    if (vendor) {
      return vendor.client;
    }

    return null;
  }

}
