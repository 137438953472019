import React, { memo } from 'react';

import { Badge, Card, Persona } from '@meilleursbiens/ui';
import { PriceUtils } from '@meilleursbiens/utils';

import { useColorModeValue } from '@chakra-ui/react';

import { Handle, Position } from '@xyflow/react';


const defaultWidth = 190;
const defaultHeight = 50;

export default memo(({ data, isConnectable }: {isConnectable: boolean, data: {
    amount: number,
    number: number
  }}) => {
  const { amount, number } = data;

  const borderColor = useColorModeValue('red.700', 'red.300')

  console.log(data)

  return (
    <>
      <Handle
        type="source"
        position={Position.Top}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Card p={2} borderRadius={'50px'} pr={3} width={defaultWidth} height={defaultHeight}>
        <Persona size={'sm'}
                 name={"MeilleursBiens"}
                 avatarUrl={"https://img.logo.dev/meilleursbiens.com"}
                 secondaryText={number > 0 ? number + " affiliation" + (number > 1 ? "s" : "") : "Réseau immobilier"}
                 secondaryTextProps={{
                   fontSize: '10px'
                 }}
        />
        {amount > 0 ? (
          <Badge position={'absolute'} top={-2} right={-1} bg={"primary.500"} borderWidth={1} borderColor={borderColor} px={1} fontSize={'10px'} textColor={'white'} borderRadius={'50px'}>{PriceUtils.formatPrice(amount)}</Badge>
        ) : (
          <></>
        )}
      </Card>
    </>
  );
});
