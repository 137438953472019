import { MBApi } from '@meilleursbiens/api';
import { MBProVideo, MBProVideoCategory, MBProVideoWatch } from '@meilleursbiens/types';

export default class MBProFormationsAPI {
  static list() {
    return MBApi.url(`/v1/mbpro/formations`).needAuth().get<{
      videos: MBProVideo[];
    }>();
  }

  static updateWatch(formationId: number, duration: number, durationMax: number) {
    return MBApi.url('/v1/mbpro/formations/watch/' + formationId)
      .parameters({
        duration,
        max_duration: durationMax,
      })
      .needAuth()
      .patch<MBProVideoWatch>();
  }

  static listCategories() {
    return MBApi.url('/v1/mbpro/formations/categories').needAuth().get<MBProVideoCategory[]>();
  }

  static createCategory(title: string, description: string) {
    return MBApi.url('/v1/mbpro/admin/formations/categories')
      .parameters({ title, description })
      .needAuth()
      .put<MBProVideoCategory>();
  }

  static updateCategory(id: number, title: string, description: string) {
    return MBApi.url('/v1/mbpro/admin/formations/categories/' + id)
      .parameters({ title, description })
      .needAuth()
      .patch<MBProVideoCategory>();
  }

  static getFormationById(id: number) {
    return MBApi.url(`/v1/mbpro/admin/formations/${id}`).needAuth().get<MBProVideo>();
  }

  static updateFormation(id: number, values: MBProVideo) {
    return MBApi.url(`/v1/mbpro/admin/formations/${id}`).parameters(values).needAuth().patch<MBProVideo>();
  }

  static listAdmin() {
    return MBApi.url(`/v1/mbpro/admin/formations`).needAuth().get<{
      videos: MBProVideo[];
    }>();
  }

  static createAdmin(values: any) {
    return MBApi.url(`/v1/mbpro/admin/formations`)
      .needAuth()
      .parameters({
        ...values,
      })
      .put<MBProVideo>();
  }

  static deleteAdmin(id: number) {
    return MBApi.url(`/v1/mbpro/admin/formations/${id}`).needAuth().delete<void>();
  }
}
