import { MBProMandate, MBProMandateCategory, MBProMandateCreateRequest, PaginationAPI } from '@meilleursbiens/types';

import MBProAPI from '../../../../MBApi';

export default class MBProMandatesAPI {
  static list(rows: number, page: number, query: string, sortBy = 'id', sortOrder = 'asc') {
    return MBProAPI.url(
      '/v1/mbpro/mandates' +
        query +
        '&page=' +
        page +
        '&pageSize=' +
        rows +
        '&sort_by=' +
        sortBy +
        '&sort_order=' +
        sortOrder
    )
      .needAuth()
      .get<PaginationAPI<MBProMandate>>();
  }

  static getMandate(id: number) {
    return MBProAPI.url('/v1/mbpro/mandates/' + id)
      .needAuth()
      .get<MBProMandate>();
  }

  static searchMandates(search: string) {
    return MBProAPI.url('/v1/mbpro/mandates/search').parameters({ search }).needAuth().post<MBProMandate[]>();
  }

  static listCategories() {
    return MBProAPI.url('/v1/mbpro/mandates/categories').needAuth().needCache(60).get<MBProMandateCategory[]>();
  }

  static getMandateCreatePrecompletion(mandate_type_id: any) {
    return MBProAPI.url('/v1/mbpro/mandates/create/precomplete/' + mandate_type_id)
      .needAuth()
      .get<Partial<MBProMandate>>();
  }

  static createMandate(values: MBProMandateCreateRequest) {
    return MBProAPI.url('/v1/mbpro/mandates')
      .parameters(values)
      .needAuth()
      .needCache(0, null, {
        'MBProDashboardAPI.getSidebarCounts': 'delete',
      })
      .put<MBProMandate>();
  }

  static duplicateMandate(mandate_id: number) {
    return MBProAPI.url('/v1/mbpro/mandates/' + mandate_id + '/duplicate')
      .needAuth()
      .post<MBProMandate>();
  }

  static createPaperMandate(values: any) {
    const formData = new FormData();
    formData.append('clients_id', JSON.stringify(values.clients_id));
    formData.append('file', values.mandate_file);
    formData.append('mandate_type', values.mandate_type);
    formData.append('is_mb_template', values.is_mb_template);

    return MBProAPI.url('/v1/mbpro/mandates/paper')
      .contentFormData()
      .formData(formData)
      .needCache(0, null, {
        'MBProDashboardAPI.getSidebarCounts': 'delete',
      })
      .needAuth()
      .postUpload<MBProMandate>();
  }

  static returnPaperMandate(mandate_id: number, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return MBProAPI.url('/v1/mbpro/mandates/paper/' + mandate_id)
      .contentFormData()
      .formData(formData)
      .needAuth()
      .postUpload<MBProMandate>();
  }

  static getMandatePDF(id: number) {
    return MBProAPI.url(`/v1/mbpro/mandates/${id}/pdf/view`).needAuth().get<string>();
  }

  static sendToSign(id: number, is_sms = false) {
    const userAgent = navigator.userAgent;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return MBProAPI.url(`/v1/mbpro/mandates/${id}/signs/send`)
      .parameters({
        userAgent,
        timezone,
        is_sms,
      })
      .needAuth()
      .post<MBProMandate>();
  }

  static relaunchSign(id: number, is_sms = false) {
    return MBProAPI.url(`/v1/mbpro/mandates/${id}/signs/relaunch?is_sms=${is_sms}`)
      .parameters({
        is_sms,
      })
      .needAuth()
      .post<MBProMandate>();
  }

  static editMandate(id: number, values: MBProMandate) {
    return MBProAPI.url(`/v1/mbpro/mandates/${id}`).parameters(values).needAuth().patch<MBProMandate>();
  }

  static resiliateMandate(id: number, reason: string) {
    return MBProAPI.url(`/v1/mbpro/mandates/${id}/cancel?reason=${reason}`).needAuth().delete();
  }

  static editMandateMandants(id: number, values: { clients_id: number[] }) {
    return MBProAPI.url(`/v1/mbpro/mandates/${id}/signs`)
      .parameters(values)
      .needAuth()
      .patch<{ clients_id: number[] }>();
  }

  static getPaperMandateTemplateURL(id: string) {
    return MBProAPI.url(`/v1/mbpro/mandates/categories/${id}/paper/download`).needAuth().get<string>();
  }

  static uploadDPE(mandate_id: number, values: any) {
    const formData = new FormData();
    if (values.file) formData.append('file', values.file);
    formData.append('is_dpe_not_required', values.is_dpe_not_required);

    return MBProAPI.url(`/v1/mbpro/mandates/${mandate_id}/dpe`)
      .contentFormData()
      .formData(formData)
      .needAuth()
      .postUpload<MBProMandate>();
  }

  static getDPEURL(id: number) {
    return MBProAPI.url(`/v1/mbpro/mandates/${id}/dpe`).needAuth().get<string>();
  }
}
