import * as React from 'react';
import { SVGProps } from 'react';

export default function (props: SVGProps<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      fill="none"
      viewBox="0 0 72 74"
      {...props}
    >
      <path
        fill="url(#paint0_linear_0_10848)"
        fillRule="evenodd"
        d="M53 62a2 2 0 1 1 0 4H35q-.177 0-.345-.03a29.86 29.86 0 0 1-17.079-6.292c-.387-.301-.97-.8-1.401-1.177A2.03 2.03 0 0 0 14.844 58H10a2 2 0 1 1 0-4h2a2 2 0 1 0 0-4H7a2 2 0 1 1 0-4h1a2 2 0 1 0 0-4c-.809 0-1.5-.583-1.635-1.38-.049-.29-.088-.523-.094-.57A30 30 0 0 1 6 36C6 19.432 19.432 6 36 6h15a2 2 0 1 1 0 4h-3a2 2 0 1 0 0 4h12a2 2 0 0 1 .002 4H57a2 2 0 1 0 0 4h4.306c.756 0 1.45.426 1.778 1.108.197.41.404.851.54 1.17A29.9 29.9 0 0 1 66 36a30 30 0 0 1-.246 3.857c-.027.212-.069.485-.114.764C65.51 41.42 64.81 42 64 42a2 2 0 1 0 0 4h1a2 2 0 1 1 0 4h-1.284c-.726 0-1.394.394-1.756 1.023-.236.409-.498.853-.693 1.156a30 30 0 0 1-3.08 4.014c-.313.343-.819.851-1.213 1.241a1.97 1.97 0 0 1-1.387.566H50a2 2 0 0 0-.023 4z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#A4053A"
        fillOpacity="0.2"
        fillRule="evenodd"
        d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.196v18.127a6 6 0 0 1-3 5.197l-15.699 9.063a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.935a6 6 0 0 1 3-5.196z"
        clipRule="evenodd"
        opacity="0.2"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <g filter="url(#filter0_d_0_10848)">
        <path
          fill="#7F45F6"
          fillRule="evenodd"
          d="M22 33h16v30.382a1 1 0 0 1-1.447.894L30 61l-6.553 3.276A1 1 0 0 1 22 63.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter1_d_0_10848)">
        <path
          fill="#7F45F6"
          fillRule="evenodd"
          d="M34 33h16v30.382a1 1 0 0 1-1.447.894L42 61l-6.553 3.276A1 1 0 0 1 34 63.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter2_d_0_10848)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28 38h16v30.382a1 1 0 0 1-1.447.894L36 66l-6.553 3.276A1 1 0 0 1 28 68.382z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask0_0_10848"
        width="16"
        height="32"
        x="28"
        y="38"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M28 38h16v30.382a1 1 0 0 1-1.447.894L36 66l-6.553 3.276A1 1 0 0 1 28 68.382z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_0_10848)">
        <path fill="#F55462" d="M32 38h8v32h-8z"></path>
      </g>
      <path
        fill="#A4053A"
        fillOpacity="0.2"
        fillRule="evenodd"
        d="M34 16.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 59.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V28.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
        opacity="0.8"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <path
        fill="url(#paint1_linear_0_10848)"
        fillRule="evenodd"
        stroke="url(#paint2_linear_0_10848)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m19 24.5-5-2v-1l-4.802-2a.6.6 0 0 0-.802.736l5.696 17.771a1 1 0 0 0 .651.649L19 40z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask1_0_10848"
        width="12"
        height="20"
        x="8"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M19 25.854a2 2 0 0 0-1.257-1.857l-3.275-1.31a.74.74 0 0 1-.468-.69c0-.301-.18-.572-.458-.688l-1.547-.645c-1.574-.655-3.195.833-2.674 2.457l4.613 14.394a2 2 0 0 0 1.303 1.297l1.16.366A2 2 0 0 0 19 37.271z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask1_0_10848)">
        <g
          filter="url(#filter3_i_0_10848)"
          opacity="0.6"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            d="M19.186 30.036a.5.5 0 1 1-.372.928l-5-2a.5.5 0 0 1-.314-.464v-.667l-5.692-2.371a.5.5 0 1 1 .384-.924l6 2.5a.5.5 0 0 1 .308.462v.662z"
          ></path>
        </g>
        <g
          filter="url(#filter4_i_0_10848)"
          opacity="0.6"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            d="M20.186 36.036a.5.5 0 1 1-.372.928l-5-2a.5.5 0 0 1-.314-.464v-.667l-5.692-2.371a.5.5 0 1 1 .384-.924l6 2.5a.5.5 0 0 1 .308.462v.661z"
          ></path>
        </g>
        <path
          fill="#A4053A"
          fillOpacity="0.2"
          fillRule="evenodd"
          d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.196v18.127a6 6 0 0 1-3 5.197L39 59.323a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.935a6 6 0 0 1 3-5.196z"
          clipRule="evenodd"
          opacity="0.2"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint3_linear_0_10848)"
        fillRule="evenodd"
        stroke="url(#paint4_linear_0_10848)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m53 24.5 5-2v-1l4.802-2a.6.6 0 0 1 .802.736l-5.696 17.771a1 1 0 0 1-.651.649L53 40z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask2_0_10848"
        width="12"
        height="20"
        x="52"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M53 25.854a2 2 0 0 1 1.257-1.857l3.275-1.31a.74.74 0 0 0 .468-.69c0-.301.18-.572.458-.688l1.547-.645c1.575-.655 3.195.833 2.674 2.457l-4.613 14.394a2 2 0 0 1-1.303 1.297l-1.16.366A2 2 0 0 1 53 37.271z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask2_0_10848)">
        <g
          filter="url(#filter5_i_0_10848)"
          opacity="0.6"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            d="M52.814 30.036a.5.5 0 1 0 .372.928l5-2a.5.5 0 0 0 .314-.464v-.667l5.692-2.371a.5.5 0 1 0-.384-.924l-6 2.5a.5.5 0 0 0-.308.462v.662z"
          ></path>
        </g>
        <g
          filter="url(#filter6_i_0_10848)"
          opacity="0.6"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            d="M51.814 36.036a.5.5 0 1 0 .372.928l5-2a.5.5 0 0 0 .314-.464v-.667l5.692-2.371a.5.5 0 1 0-.384-.924l-6 2.5a.5.5 0 0 0-.308.462v.661z"
          ></path>
        </g>
        <path
          fill="#A4053A"
          fillOpacity="0.2"
          fillRule="evenodd"
          d="M39.002 12.677a6 6 0 0 0-6 0L17.303 21.74a6 6 0 0 0-3 5.196v18.127a6 6 0 0 0 3 5.197l15.699 9.063a6 6 0 0 0 6 0L54.7 50.26a6 6 0 0 0 3-5.196V26.935a6 6 0 0 0-3-5.196z"
          clipRule="evenodd"
          opacity="0.2"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint5_linear_0_10848)"
        fillRule="evenodd"
        stroke="url(#paint6_linear_0_10848)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m18 42-3.526 1.763a1 1 0 0 0-.544.762l-.77 5.773a.6.6 0 0 0 .893.6L20 47.5z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask3_0_10848"
        width="8"
        height="9"
        x="13"
        y="42"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M18.767 44.109a2 2 0 0 0-2.774-1.105l-1.045.522a2 2 0 0 0-1.088 1.524l-.326 2.444c-.219 1.641 1.537 2.822 2.975 2l2.028-1.158a2 2 0 0 0 .887-2.42z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask3_0_10848)">
        <path
          fill="#F37E02"
          fillRule="evenodd"
          d="M32.78 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.196v18.127a6 6 0 0 1-3 5.197L38.78 59.323a6 6 0 0 1-6 0L17.081 50.26a6 6 0 0 1-3-5.196V26.935a6 6 0 0 1 3-5.196z"
          clipRule="evenodd"
          opacity="0.1"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint7_linear_0_10848)"
        fillRule="evenodd"
        stroke="url(#paint8_linear_0_10848)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m54 42 3.526 1.763a1 1 0 0 1 .544.762l.77 5.773a.6.6 0 0 1-.893.6L52 47.5z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask4_0_10848"
        width="9"
        height="11"
        x="51"
        y="41"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m54 42 3.526 1.763a1 1 0 0 1 .544.762l.77 5.773a.6.6 0 0 1-.893.6L52 47.5z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask4_0_10848)">
        <path
          fill="#F37E02"
          fillRule="evenodd"
          d="M39.22 12.677a6 6 0 0 0-6 0L17.522 21.74a6 6 0 0 0-3 5.196v18.127a6 6 0 0 0 3 5.197l15.698 9.063a6 6 0 0 0 6 0L54.92 50.26a6 6 0 0 0 3-5.196V26.935a6 6 0 0 0-3-5.196z"
          clipRule="evenodd"
          opacity="0.1"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint9_linear_0_10848)"
        fillRule="evenodd"
        d="M30.02 23.675a4 4 0 0 1-2.258 3.6l-1.96.949c-2.656 1.284-5.74-.651-5.74-3.602V19.78a2 2 0 0 1 1-1.732l7.458-4.307a1 1 0 0 1 1.5.866z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#FDEDAC"
        strokeLinecap="round"
        d="m21.207 18.54 7.574-4.372"
        opacity="0.4"
      ></path>
      <path
        fill="url(#paint10_linear_0_10848)"
        fillRule="evenodd"
        d="M41.98 23.675a4 4 0 0 0 2.258 3.6l1.96.949c2.656 1.284 5.741-.651 5.741-3.602V19.78a2 2 0 0 0-1-1.732l-7.46-4.307a1 1 0 0 0-1.5.866z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#FDEDAC"
        strokeLinecap="round"
        d="m50.793 18.54-7.574-4.372"
        opacity="0.4"
      ></path>
      <path
        fill="url(#paint11_linear_0_10848)"
        fillRule="evenodd"
        stroke="#FFF04D"
        strokeOpacity="0.4"
        d="M34 14.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 57.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V26.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask5_0_10848"
        width="40"
        height="46"
        x="16"
        y="13"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M35 13.577a2 2 0 0 1 2 0l17.919 10.346a2 2 0 0 1 1 1.732v20.69a2 2 0 0 1-1 1.732L37 58.423a2 2 0 0 1-2 0L17.081 48.077a2 2 0 0 1-1-1.732v-20.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask5_0_10848)">
        <g
          filter="url(#filter7_i_0_10848)"
          opacity="0.8"
          style={{ mixBlendMode: "multiply" }}
        >
          <path
            fill="#FABC10"
            fillRule="evenodd"
            d="m51.671 25.189-14.154-8.182a3.03 3.03 0 0 0-3.034 0L20.33 25.189a3.04 3.04 0 0 0-1.517 2.63v5.902l-1.147 1.723a1 1 0 0 0 0 1.108l1.148 1.724v5.906c0 1.084.578 2.087 1.516 2.63l14.154 8.181a3.03 3.03 0 0 0 3.034 0l14.154-8.181a3.04 3.04 0 0 0 1.517-2.63v-5.906l1.147-1.724a1 1 0 0 0 0-1.108l-1.148-1.723v-5.903a3.04 3.04 0 0 0-1.516-2.63M19.824 38.276v5.906c0 .723.385 1.391 1.01 1.753l14.155 8.182a2.02 2.02 0 0 0 2.022 0l14.154-8.182a2.03 2.03 0 0 0 1.011-1.753v-5.906l-1.147-1.724a1 1 0 0 1 0-1.108l1.147-1.723v-5.903c0-.723-.385-1.391-1.01-1.753L37.01 17.883a2.02 2.02 0 0 0-2.022 0l-14.154 8.182a2.03 2.03 0 0 0-1.012 1.753v5.903l1.148 1.723a1 1 0 0 1 0 1.108z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          stroke="#FAB90A"
          d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382a3.5 3.5 0 0 0 1.75 3.03l15.919 9.191a3.5 3.5 0 0 0 3.5 0l15.919-9.19a3.5 3.5 0 0 0 1.75-3.031V26.809a3.5 3.5 0 0 0-1.75-3.03z"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m37.75 57.412 3.98-2.297"
          opacity="0.4"
        ></path>
        <path
          stroke="#FAC81C"
          strokeLinecap="round"
          d="M55.419 45.19V26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382"
        ></path>
        <path
          stroke="#FDDB3D"
          strokeLinecap="round"
          d="M55.419 26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031"
          opacity="0.6"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-7.96 4.595"
          opacity="0.6"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m34.25 14.588-3.98 2.297"
        ></path>
      </g>
      <g filter="url(#filter8_d_0_10848)">
        <path
          fill="url(#paint12_linear_0_10848)"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#F5900D"
          strokeOpacity="0.1"
          d="M37.25 20.144a2.5 2.5 0 0 0-2.5 0L22.894 26.99a2.5 2.5 0 0 0-1.25 2.165v13.69a2.5 2.5 0 0 0 1.25 2.165l11.856 6.846a2.5 2.5 0 0 0 2.5 0l11.856-6.846a2.5 2.5 0 0 0 1.25-2.165v-13.69a2.5 2.5 0 0 0-1.25-2.165z"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <mask
        id="mask6_0_10848"
        width="28"
        height="32"
        x="22"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask6_0_10848)">
        <g fill="#FFD640" opacity="0.3" style={{ mixBlendMode: "multiply" }}>
          <path
            fillRule="evenodd"
            d="m36 36-16 4v-8zm0 0 4-16h-8zm0 0 16-4v8zm0 0 8.485-14.142 5.657 5.657zm0 0 8.485 14.142 5.657-5.657zm0 0-4 16h8zm0 0-8.485 14.142-5.657-5.657z"
            clipRule="evenodd"
          ></path>
          <path d="m27.515 21.858-5.657 5.657L36 36z"></path>
        </g>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M48.606 27.856 36.75 21.01a1.5 1.5 0 0 0-1.5 0l-2.964 1.712-2.964 1.711-5.928 3.423"
          opacity="0.4"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m35.25 21.01-2.964 1.712"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M48.606 44.11 36.75 50.955a1.5 1.5 0 0 1-1.5 0"
          opacity="0.4"
        ></path>
      </g>
      <g filter="url(#filter9_d_0_10848)">
        <path
          fill="#fff"
          d="M29.778 30.557 36 26.833l6.222 3.724v10.886L36 45.167l-6.222-3.724z"
        ></path>
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M29.264 29.7a1 1 0 0 0-.486.857v10.886a1 1 0 0 0 .486.858l6.223 3.724a1 1 0 0 0 1.026 0l6.223-3.724a1 1 0 0 0 .486-.858V30.557a1 1 0 0 0-.486-.858l-6.222-3.724a1 1 0 0 0-1.028 0z"
        ></path>
      </g>
      <path
        fill="url(#paint13_linear_0_10848)"
        fillRule="evenodd"
        d="M29.778 30.557 36 26.833v4.01l-2.828 1.72z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint14_linear_0_10848)"
        fillRule="evenodd"
        d="M29.778 30.557v10.886l3.394-2.005v-6.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint15_linear_0_10848)"
        fillRule="evenodd"
        d="m36 45.167-6.222-3.724 3.394-2.005L36 41.156z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint16_linear_0_10848)"
        fillRule="evenodd"
        d="m36 45.167 6.222-3.724-3.394-2.005L36 41.156z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint17_linear_0_10848)"
        fillRule="evenodd"
        d="M42.222 30.557v10.886l-3.394-2.005v-6.875z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint18_linear_0_10848)"
        fillRule="evenodd"
        d="M42.222 30.557 36 26.833v4.01l2.828 1.72z"
        clipRule="evenodd"
      ></path>
      <path
        fill="url(#paint19_linear_0_10848)"
        fillRule="evenodd"
        d="M33.172 39.438 36 41.156l2.828-1.718v-6.876L36 30.845l-2.828 1.718z"
        clipRule="evenodd"
      ></path>
      <g filter="url(#filter10_d_0_10848)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m46.91 29.78-.518-.205a.618.618 0 0 1 0-1.15l.519-.205a2.32 2.32 0 0 0 1.31-1.31l.204-.518a.618.618 0 0 1 1.15 0l.205.519c.236.599.71 1.073 1.31 1.31l.518.204a.618.618 0 0 1 0 1.15l-.519.205a2.32 2.32 0 0 0-1.31 1.31l-.204.518a.618.618 0 0 1-1.15 0l-.205-.519a2.32 2.32 0 0 0-1.31-1.31"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter11_d_0_10848)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m24.607 46.52-.346-.137a.412.412 0 0 1 0-.766l.346-.137c.4-.157.716-.473.873-.873l.137-.346a.412.412 0 0 1 .766 0l.137.346c.157.4.474.716.873.873l.346.137a.412.412 0 0 1 0 .766l-.346.137c-.4.157-.716.473-.873.873l-.137.346a.412.412 0 0 1-.766 0l-.137-.346a1.55 1.55 0 0 0-.873-.873"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter12_d_0_10848)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m20.76 31.15-.434-.17a.515.515 0 0 1 0-.96l.433-.17c.5-.197.894-.592 1.091-1.09l.17-.434a.515.515 0 0 1 .96 0l.17.433c.197.5.592.894 1.09 1.091l.434.17a.515.515 0 0 1 0 .96l-.433.17a1.94 1.94 0 0 0-1.091 1.09l-.17.434a.515.515 0 0 1-.96 0l-.17-.433a1.94 1.94 0 0 0-1.09-1.091"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter13_d_0_10848)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m46.456 44.89-.26-.102a.31.31 0 0 1 0-.576l.26-.102c.299-.118.536-.355.654-.654l.102-.26a.31.31 0 0 1 .576 0l.102.26c.118.299.355.536.655.654l.26.102a.31.31 0 0 1 0 .576l-.26.102c-.3.118-.537.355-.655.654l-.102.26a.31.31 0 0 1-.575 0l-.103-.26a1.16 1.16 0 0 0-.654-.654"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_0_10848"
          x1="5"
          x2="5"
          y1="6"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F7D"></stop>
          <stop offset="1" stopColor="#EC2E4A"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_10848"
          x1="8"
          x2="8"
          y1="19"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_10848"
          x1="8"
          x2="8"
          y1="19"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_10848"
          x1="64"
          x2="64"
          y1="19"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_10848"
          x1="64"
          x2="64"
          y1="19"
          y2="40"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_0_10848"
          x1="13"
          x2="13"
          y1="42"
          y2="51.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_0_10848"
          x1="13"
          x2="13"
          y1="42"
          y2="51.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_0_10848"
          x1="59"
          x2="59"
          y1="42"
          y2="51.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_0_10848"
          x1="59"
          x2="59"
          y1="42"
          y2="51.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_0_10848"
          x1="20.061"
          x2="20.061"
          y1="13.607"
          y2="28.939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC24"></stop>
          <stop offset="1" stopColor="#F9B800"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_0_10848"
          x1="51.939"
          x2="51.939"
          y1="13.607"
          y2="28.939"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCC24"></stop>
          <stop offset="1" stopColor="#F9B800"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_0_10848"
          x1="13"
          x2="13"
          y1="13"
          y2="59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDE046"></stop>
          <stop offset="1" stopColor="#F9B800"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_0_10848"
          x1="20"
          x2="20"
          y1="20"
          y2="52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF550"></stop>
          <stop offset="1" stopColor="#FFD541"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_0_10848"
          x1="36.47"
          x2="34.776"
          y1="30.786"
          y2="27.609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD3D3"></stop>
          <stop offset="1" stopColor="#FF7E8A"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_0_10848"
          x1="33.172"
          x2="33.172"
          y1="41.443"
          y2="30.557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8B89"></stop>
          <stop offset="1" stopColor="#F34C5D"></stop>
        </linearGradient>
        <linearGradient
          id="paint15_linear_0_10848"
          x1="34.494"
          x2="35.86"
          y1="43.955"
          y2="40.911"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F7D"></stop>
          <stop offset="1" stopColor="#F21248"></stop>
        </linearGradient>
        <linearGradient
          id="paint16_linear_0_10848"
          x1="42.222"
          x2="42.222"
          y1="45.167"
          y2="39.438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8B89"></stop>
          <stop offset="1" stopColor="#F34C5D"></stop>
        </linearGradient>
        <linearGradient
          id="paint17_linear_0_10848"
          x1="42.222"
          x2="42.222"
          y1="41.443"
          y2="30.557"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8B89"></stop>
          <stop offset="1" stopColor="#F34C5D"></stop>
        </linearGradient>
        <linearGradient
          id="paint18_linear_0_10848"
          x1="38.922"
          x2="40.456"
          y1="32.488"
          y2="29.517"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F7D"></stop>
          <stop offset="1" stopColor="#F21248"></stop>
        </linearGradient>
        <linearGradient
          id="paint19_linear_0_10848"
          x1="38.828"
          x2="38.828"
          y1="41.156"
          y2="30.844"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7F7D"></stop>
          <stop offset="1" stopColor="#F21248"></stop>
        </linearGradient>
        <filter
          id="filter0_d_0_10848"
          width="20"
          height="35.383"
          x="20"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.921569 0 0 0 0 0.215686 0 0 0 0 0.305882 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_0_10848"
          width="20"
          height="35.383"
          x="32"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.921569 0 0 0 0 0.215686 0 0 0 0 0.305882 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_0_10848"
          width="20"
          height="35.383"
          x="26"
          y="38"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.921569 0 0 0 0 0.215686 0 0 0 0 0.305882 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_i_0_10848"
          width="12"
          height="7.5"
          x="7.5"
          y="24.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10848"></feBlend>
        </filter>
        <filter
          id="filter4_i_0_10848"
          width="12"
          height="7.5"
          x="8.5"
          y="30.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10848"></feBlend>
        </filter>
        <filter
          id="filter5_i_0_10848"
          width="12"
          height="7.5"
          x="52.5"
          y="24.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10848"></feBlend>
        </filter>
        <filter
          id="filter6_i_0_10848"
          width="12"
          height="7.5"
          x="51.5"
          y="30.5"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10848"></feBlend>
        </filter>
        <filter
          id="filter7_i_0_10848"
          width="37.005"
          height="39.8"
          x="17.497"
          y="16.6"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.976471 0 0 0 0 0.713726 0 0 0 0 0.0352941 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10848"></feBlend>
        </filter>
        <filter
          id="filter8_d_0_10848"
          width="33.713"
          height="37.381"
          x="19.144"
          y="18.309"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.927721 0 0 0 0 0.665867 0 0 0 0 0.0535899 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter9_d_0_10848"
          width="20.445"
          height="26.333"
          x="25.778"
          y="23.833"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.733333 0 0 0 0 0.105882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter10_d_0_10848"
          width="10"
          height="10"
          x="44"
          y="24"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter11_d_0_10848"
          width="8"
          height="8"
          x="22"
          y="42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter12_d_0_10848"
          width="9"
          height="9"
          x="18"
          y="26"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter13_d_0_10848"
          width="7"
          height="7"
          x="44"
          y="41"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10848"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10848"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
