import { MBCoreSMSTemplate, PaginationAPI } from '@meilleursbiens/types';

import { MBApi } from '../../../index';

export default class MBCoreSMSTemplatesAdminAPI {

  static listTable(rows: number, page: number, query: string, sortBy = 'id', sortOrder = 'asc') {
    return MBApi.url(
      '/v1/core/admin/sms/templates' +
        query +
        '?page=' +
        page +
        '&pageSize=' +
        rows +
        '&sort_by=' +
        sortBy +
        '&sort_order=' +
        sortOrder
    )
      .needAuth()
      .get<PaginationAPI<MBCoreSMSTemplate>>();
  }

  static getTemplateById(id: number) {
      return MBApi.url(`/v1/core/admin/sms/templates/${id}`)
        .needAuth()
        .get<MBCoreSMSTemplate>()
  }

  static updateTemplate(id: number, values: MBCoreSMSTemplate) {
      return MBApi.url(`/v1/core/admin/sms/templates/${id}`)
        .parameters(values)
        .needAuth()
        .patch<MBCoreSMSTemplate>()
  }

  static createTemplate(values: MBCoreSMSTemplate) {
      return MBApi.url('/v1/mbpro/admin/sms-templates')
        .needAuth()
        .parameters(values)
        .put()
  }

  static deleteTemplate(id: number) {
    return new Promise((resolve, reject) => {
      MBApi.url('/v1/mbpro/admin/sms-templates/' + id)
        .needAuth()
        .delete()
        .then((r: any) => {
          if (r.data.error) {
            reject(r.data.message);
          } else {
            resolve(r.data.data);
          }
        })
        .catch((e: any) => {
          reject(e.message);
        });
    });
  }
}
