import * as React from 'react';
import { SVGProps } from 'react';

export default function (props: SVGProps<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      fill="none"
      viewBox="0 0 72 74"
      {...props}
    >
      <path
        fill="url(#paint0_linear_0_10500)"
        fillRule="evenodd"
        d="M53 62a2 2 0 1 1 0 4H35q-.177 0-.345-.03a29.86 29.86 0 0 1-17.079-6.292c-.387-.301-.97-.8-1.401-1.177A2.03 2.03 0 0 0 14.844 58H10a2 2 0 1 1 0-4h2a2 2 0 1 0 0-4H7a2 2 0 1 1 0-4h1a2 2 0 1 0 0-4c-.809 0-1.5-.583-1.635-1.38-.049-.29-.088-.523-.094-.57A30 30 0 0 1 6 36C6 19.432 19.432 6 36 6h15a2 2 0 1 1 0 4h-3a2 2 0 1 0 0 4h12a2 2 0 0 1 .002 4H57a2 2 0 1 0 0 4h4.306c.756 0 1.45.426 1.778 1.108.197.41.404.851.54 1.17A29.9 29.9 0 0 1 66 36a30 30 0 0 1-.246 3.857c-.027.212-.069.485-.114.764C65.51 41.42 64.81 42 64 42a2 2 0 1 0 0 4h1a2 2 0 1 1 0 4h-1.284c-.726 0-1.394.394-1.756 1.023-.236.409-.498.853-.693 1.156a30 30 0 0 1-3.08 4.014c-.313.343-.819.851-1.213 1.241a1.97 1.97 0 0 1-1.387.566H50a2 2 0 0 0-.023 4z"
        clipRule="evenodd"
      ></path>
      <circle
        cx="36"
        cy="36"
        r="22"
        fill="#F3A02A"
        opacity="0.1"
        style={{ mixBlendMode: "multiply" }}
      ></circle>
      <g filter="url(#filter0_d_0_10500)">
        <path
          fill="#F56123"
          fillRule="evenodd"
          d="M28 36h16v32.336a1 1 0 0 1-1.47.883L36 65.75l-6.53 3.47a1 1 0 0 1-1.47-.884z"
          clipRule="evenodd"
        ></path>
      </g>
      <circle
        cx="36"
        cy="38"
        r="20"
        fill="#F0BA6E"
        opacity="0.2"
        style={{ mixBlendMode: "multiply" }}
      ></circle>
      <g filter="url(#filter1_d_0_10500)">
        <circle
          cx="36"
          cy="36"
          r="20"
          fill="url(#paint1_linear_0_10500)"
        ></circle>
      </g>
      <mask
        id="mask0_0_10500"
        width="40"
        height="40"
        x="16"
        y="16"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <circle cx="36" cy="36" r="20" fill="#fff"></circle>
      </mask>
      <g mask="url(#mask0_0_10500)">
        <path
          stroke="#DA913B"
          d="M16.5 36c0 10.77 8.73 19.5 19.5 19.5S55.5 46.77 55.5 36"
        ></path>
        <path
          stroke="#E1A04B"
          strokeLinecap="round"
          d="M49.789 49.789a19.44 19.44 0 0 0 5.711-13.79c0-2.933-.648-5.716-1.809-8.212M51.395 24.03C47.827 19.448 42.258 16.5 36 16.5c-10.77 0-19.5 8.73-19.5 19.5 0 5.385 2.183 10.26 5.711 13.789"
        ></path>
        <path
          stroke="#FFC875"
          strokeLinecap="round"
          d="M51.4 24.037C47.832 19.45 42.26 16.5 36 16.5c-6.296 0-11.896 2.984-15.46 7.615"
          opacity="0.6"
        ></path>
        <path
          stroke="#FFECD0"
          strokeLinecap="round"
          d="M36 16.5a19.4 19.4 0 0 0-10.006 2.76"
          opacity="0.8"
        ></path>
        <rect
          width="10"
          height="35"
          x="39.839"
          y="40.59"
          stroke="#EBB570"
          strokeWidth="2"
          opacity="0.2"
          rx="5"
          style={{ mixBlendMode: "multiply" }}
          transform="rotate(45 39.839 40.59)"
        ></rect>
        <path
          stroke="#EBB570"
          strokeWidth="2"
          d="M38.045 46.703a1.823 1.823 0 1 1 2.579 2.579L21.282 68.624a1.823 1.823 0 0 1-2.579-2.579zM.044 28.37c4.763-4.762 12.484-4.762 17.246 0s4.762 12.483 0 17.245l-4.674 4.675c-4.763 4.762-12.484 4.762-17.246 0s-4.762-12.483 0-17.246z"
          opacity="0.2"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <rect
          width="10"
          height="35"
          x="53.839"
          y="8.59"
          stroke="#EBB570"
          strokeWidth="2"
          opacity="0.2"
          rx="5"
          style={{ mixBlendMode: "multiply" }}
          transform="rotate(45 53.839 8.59)"
        ></rect>
        <path
          stroke="#EBB570"
          strokeWidth="2"
          d="M54.796 12.126a1.823 1.823 0 0 1 2.578 2.578l-22.17 22.17a1.823 1.823 0 0 1-2.578-2.578z"
          opacity="0.2"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <g filter="url(#filter2_d_0_10500)">
        <circle
          cx="36"
          cy="36"
          r="15"
          fill="url(#paint2_linear_0_10500)"
        ></circle>
        <circle
          cx="36"
          cy="36"
          r="15.5"
          stroke="#D78834"
          strokeOpacity="0.06"
        ></circle>
      </g>
      <mask
        id="mask1_0_10500"
        width="30"
        height="30"
        x="21"
        y="21"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <circle cx="36" cy="36" r="15" fill="#fff"></circle>
      </mask>
      <g mask="url(#mask1_0_10500)">
        <path
          stroke="#EBB570"
          strokeWidth="2"
          d="M2.044 28.37c4.763-4.762 12.484-4.762 17.246 0s4.762 12.483 0 17.245l-4.674 4.675c-4.763 4.762-12.484 4.762-17.246 0s-4.762-12.483 0-17.246z"
          opacity="0.2"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <rect
          width="10"
          height="35"
          x="56.163"
          y="1.414"
          stroke="#EBB570"
          strokeWidth="2"
          opacity="0.2"
          rx="5"
          style={{ mixBlendMode: "multiply" }}
          transform="rotate(45 56.163 1.414)"
        ></rect>
        <rect
          width="10"
          height="35"
          x="40.839"
          y="38.59"
          stroke="#EBB570"
          strokeWidth="2"
          opacity="0.2"
          rx="5"
          style={{ mixBlendMode: "multiply" }}
          transform="rotate(45 40.839 38.59)"
        ></rect>
        <path
          stroke="#EBB570"
          strokeWidth="2"
          d="M57.045 4.703a1.823 1.823 0 1 1 2.579 2.579L40.282 26.624a1.823 1.823 0 0 1-2.579-2.579zM38.721 44.88a1.823 1.823 0 0 1 2.579 2.578L21.958 66.8a1.823 1.823 0 1 1-2.579-2.579z"
          opacity="0.2"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <path
          stroke="#FFECD0"
          strokeLinecap="round"
          d="M36 21.5c-1.966 0-3.84.391-5.55 1.1"
          opacity="0.8"
        ></path>
      </g>
      <g filter="url(#filter3_d_0_10500)">
        <mask
          id="path-26-outside-1_0_10500"
          width="18"
          height="19"
          x="27"
          y="25"
          fill="#000"
          maskUnits="userSpaceOnUse"
        >
          <path fill="#fff" d="M27 25h18v19H27z"></path>
          <path
            fillRule="evenodd"
            d="M40.5 26s-1 0-2.5 1a5.44 5.44 0 0 0-1.778 2.003L36 29c-4.418 0-8 3.134-8 7s3.582 7 8 7 8-3.134 8-7c0-2.366-1.342-4.458-3.395-5.725A1.3 1.3 0 0 0 40.5 30c-1-2 0-4 0-4"
            clipRule="evenodd"
          ></path>
        </mask>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M40.5 26s-1 0-2.5 1a5.44 5.44 0 0 0-1.778 2.003L36 29c-4.418 0-8 3.134-8 7s3.582 7 8 7 8-3.134 8-7c0-2.366-1.342-4.458-3.395-5.725A1.3 1.3 0 0 0 40.5 30c-1-2 0-4 0-4"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="m38 27 .555.832zm2.5-1 .894.447A1 1 0 0 0 40.5 25zm-4.278 3.003-.024 1a1 1 0 0 0 .911-.54zm4.383 1.272-.974.226a1 1 0 0 0 .449.625zM40.5 30l.894-.447zm-1.945-2.168c.663-.442 1.193-.649 1.532-.745a2.6 2.6 0 0 1 .431-.087h.002-.019l-.001-1-.001-1h-.042l-.067.004q-.08.005-.202.02a5 5 0 0 0-.65.14c-.536.153-1.256.446-2.093 1.004zm-1.446 1.632a4.44 4.44 0 0 1 1.446-1.632l-1.11-1.664a6.44 6.44 0 0 0-2.11 2.374zM36 30q.099 0 .198.002l.047-2L36 28zm-7 6c0-3.193 3.004-6 7-6v-2c-4.841 0-9 3.46-9 8zm7 6c-3.996 0-7-2.807-7-6h-2c0 4.54 4.159 8 9 8zm7-6c0 3.193-3.004 6-7 6v2c4.841 0 9-3.46 9-8zm-2.92-4.874C41.886 32.241 43 34.034 43 36h2c0-2.766-1.57-5.156-3.87-6.576zm-.474-.679.024.054q.005.015 0 0l1.949-.452a2.3 2.3 0 0 0-.185-.496zM40.5 26l-.895-.447v.001l-.001.002-.002.004-.005.01-.014.03-.042.092a6.324 6.324 0 0 0-.403 1.393c-.147.885-.156 2.116.468 3.362l1.788-.894c-.376-.754-.385-1.523-.283-2.139a4.3 4.3 0 0 1 .266-.927l.018-.04.002-.005v.001l-.001.002-.001.002z"
          mask="url(#path-26-outside-1_0_10500)"
        ></path>
      </g>
      <g filter="url(#filter4_i_0_10500)">
        <path
          fill="url(#paint3_linear_0_10500)"
          fillRule="evenodd"
          d="M40.5 26s-1 0-2.5 1a5.44 5.44 0 0 0-1.778 2.003L36 29c-4.418 0-8 3.134-8 7s3.582 7 8 7 8-3.134 8-7c0-2.257-1.22-4.265-3.116-5.545-.167-.159-.318-.324-.384-.455-1-2 0-4 0-4"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#FFECD0"
        d="m38 27 .555.832zm2.5-1 .894.447A1 1 0 0 0 40.5 25zm-4.278 3.003-.024 1a1 1 0 0 0 .911-.54zm4.662 1.452-.69.724q.06.059.13.105zM40.5 30l-.894.447zm-1.945-2.168c.663-.442 1.193-.649 1.532-.745a2.6 2.6 0 0 1 .431-.087h.002-.019l-.001-1-.001-1h-.042l-.067.004q-.08.005-.202.02a5 5 0 0 0-.65.14c-.536.153-1.256.446-2.093 1.004zm-1.446 1.632a4.44 4.44 0 0 1 1.446-1.632l-1.11-1.664a6.44 6.44 0 0 0-2.11 2.374zM36 30q.099 0 .198.002l.047-2L36 28zm-7 6c0-3.193 3.004-6 7-6v-2c-4.841 0-9 3.46-9 8zm7 6c-3.996 0-7-2.807-7-6h-2c0 4.54 4.159 8 9 8zm7-6c0 3.193-3.004 6-7 6v2c4.841 0 9-3.46 9-8zm-2.676-4.716C41.987 32.407 43 34.124 43 36h2c0-2.637-1.428-4.936-3.556-6.373zm-.718-.837c.153.306.428.58.588.732l1.38-1.447a3 3 0 0 1-.196-.21c-.005-.007.003.004.016.03zM40.5 26l-.895-.447v.001l-.001.002-.002.004-.005.01-.014.03-.042.092a6.324 6.324 0 0 0-.403 1.393c-.147.885-.156 2.116.468 3.362l1.788-.894c-.376-.754-.385-1.523-.283-2.139a4.3 4.3 0 0 1 .266-.927l.018-.04.002-.005v.001l-.001.002-.001.002z"
      ></path>
      <circle cx="33.25" cy="35.25" r="1.25" fill="#FFECD0"></circle>
      <circle cx="38.75" cy="35.25" r="1.25" fill="#FFECD0"></circle>
      <defs>
        <filter
          id="filter0_d_0_10500"
          width="20"
          height="37.338"
          x="26"
          y="36"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.937255 0 0 0 0 0.67451 0 0 0 0 0.235294 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10500"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10500"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_0_10500"
          width="48"
          height="48"
          x="12"
          y="14"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.752941 0 0 0 0 0.266667 0 0 0 0 0.266667 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10500"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10500"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_0_10500"
          width="36"
          height="36"
          x="18"
          y="19"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.643137 0 0 0 0 0.301509 0 0 0 0 0.0196078 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10500"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10500"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_d_0_10500"
          width="22"
          height="23"
          x="25"
          y="24"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.625 0 0 0 0 0.409034 0 0 0 0 0.12934 0 0 0 0.22 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10500"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10500"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_i_0_10500"
          width="18"
          height="20"
          x="27"
          y="25"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 0.801791 0 0 0 0 0.623822 0 0 0 0 0.41026 0 0 0 1 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10500"></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_0_10500"
          x1="5"
          x2="5"
          y1="6"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDD72"></stop>
          <stop offset="1" stopColor="#FFC32B"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_10500"
          x1="16"
          x2="16"
          y1="16"
          y2="56"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9C685"></stop>
          <stop offset="1" stopColor="#DB963B"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_10500"
          x1="21"
          x2="21"
          y1="21"
          y2="51"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDDA9D"></stop>
          <stop offset="1" stopColor="#EFB35E"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_10500"
          x1="28"
          x2="28"
          y1="26"
          y2="43"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6C280"></stop>
          <stop offset="1" stopColor="#E2A65E"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
