import { MBProUserDiscount } from '@meilleursbiens/types';

import MBApi from '../../../../../MBApi';

export default class MBProAdminUserDiscountAPI {
  static create(user_id: number, data: Partial<MBProUserDiscount>) {
    return MBApi.url('/v1/mbpro/admin/agent/discount/' + user_id)
      .parameters(data)
      .needAuth()
      .post<MBProUserDiscount>();
  }

  static getDiscount(user_id: number) {
    return MBApi.url('/v1/mbpro/admin/agent/discount/' + user_id)
      .needAuth()
      .get<MBProUserDiscount | null>();
  }
}
