import * as React from 'react';
import { SVGProps } from 'react';

export default function (props: SVGProps<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      fill="none"
      viewBox="0 0 72 74"
      {...props}
    >
      <path
        fill="url(#paint0_linear_0_10706)"
        fillRule="evenodd"
        d="M53 62a2 2 0 1 1 0 4H35q-.177 0-.345-.03a29.86 29.86 0 0 1-17.079-6.292c-.387-.301-.97-.8-1.401-1.177A2.03 2.03 0 0 0 14.844 58H10a2 2 0 1 1 0-4h2a2 2 0 1 0 0-4H7a2 2 0 1 1 0-4h1a2 2 0 1 0 0-4c-.809 0-1.5-.583-1.635-1.38-.049-.29-.088-.523-.094-.57A30 30 0 0 1 6 36C6 19.432 19.432 6 36 6h15a2 2 0 1 1 0 4h-3a2 2 0 1 0 0 4h12a2 2 0 0 1 .002 4H57a2 2 0 1 0 0 4h4.306c.756 0 1.45.426 1.778 1.108.197.41.404.851.54 1.17A29.9 29.9 0 0 1 66 36a30 30 0 0 1-.246 3.857c-.027.212-.069.485-.114.764C65.51 41.42 64.81 42 64 42a2 2 0 1 0 0 4h1a2 2 0 1 1 0 4h-1.284c-.726 0-1.394.394-1.756 1.023-.236.409-.498.853-.693 1.156a30 30 0 0 1-3.08 4.014c-.313.343-.819.851-1.213 1.241a1.97 1.97 0 0 1-1.387.566H50a2 2 0 0 0-.023 4z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#0602F3"
        fillRule="evenodd"
        d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.197v18.127a6 6 0 0 1-3 5.196l-15.699 9.063a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.937a6 6 0 0 1 3-5.197z"
        clipRule="evenodd"
        opacity="0.1"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <g filter="url(#filter0_d_0_10706)">
        <path
          fill="#F55462"
          fillRule="evenodd"
          d="m29.072 33 13.856 8L26.76 69.004a1 1 0 0 1-1.714.03l-3.921-6.27-7.39-.26a1 1 0 0 1-.831-1.5z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask0_0_10706"
        width="31"
        height="37"
        x="12"
        y="33"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m29.072 33 13.856 8L26.76 69.004a1 1 0 0 1-1.714.03l-3.921-6.27-7.39-.26a1 1 0 0 1-.831-1.5z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_0_10706)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m24.536 48.856 6.928 4-9 15.589-6.928-4z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter1_d_0_10706)">
        <path
          fill="#F55462"
          fillRule="evenodd"
          d="m42.928 33-13.856 8L45.24 69.004a1 1 0 0 0 1.714.03l3.921-6.27 7.39-.26a1 1 0 0 0 .831-1.5z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask1_0_10706"
        width="31"
        height="37"
        x="29"
        y="33"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m42.928 33-13.856 8L45.24 69.004a1 1 0 0 0 1.714.03l3.921-6.27 7.39-.26a1 1 0 0 0 .831-1.5z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask1_0_10706)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m47.464 48.856-6.928 4 9 15.589 6.928-4z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#0602F3"
        fillRule="evenodd"
        d="M34 16.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 59.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V28.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
        opacity="0.2"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <path
        fill="url(#paint1_linear_0_10706)"
        fillRule="evenodd"
        stroke="url(#paint2_linear_0_10706)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M54 22.677a1 1 0 0 1 .629-.928l3.548-1.42a.6.6 0 0 1 .823.557v15.437a1 1 0 0 1-.629.928L54 39z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask2_0_10706"
        width="7"
        height="19"
        x="53"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M54 23.354a2 2 0 0 1 1.257-1.857l1-.4A2 2 0 0 1 59 22.954v12.692a2 2 0 0 1-1.257 1.857l-1 .4A2 2 0 0 1 54 36.046z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask2_0_10706)">
        <path
          fill="#0602F3"
          fillRule="evenodd"
          d="M38.999 12.677a6 6 0 0 0-6 0L17.3 21.74a6 6 0 0 0-3 5.197v18.127a6 6 0 0 0 3 5.196l15.699 9.063a6 6 0 0 0 6 0l15.698-9.063a6 6 0 0 0 3-5.196V26.937a6 6 0 0 0-3-5.197z"
          clipRule="evenodd"
          opacity="0.04"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint3_linear_0_10706)"
        fillRule="evenodd"
        stroke="url(#paint4_linear_0_10706)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18 22.677a1 1 0 0 0-.629-.928l-3.548-1.42a.6.6 0 0 0-.823.557v15.437a1 1 0 0 0 .629.928L18 39z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask3_0_10706"
        width="7"
        height="19"
        x="12"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M18 23.354a2 2 0 0 0-1.257-1.857l-1-.4A2 2 0 0 0 13 22.954v12.692a2 2 0 0 0 1.257 1.857l1 .4A2 2 0 0 0 18 36.046z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask3_0_10706)">
        <path
          fill="#0602F3"
          fillRule="evenodd"
          d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.197v18.127a6 6 0 0 1-3 5.196l-15.699 9.063a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.937a6 6 0 0 1 3-5.197z"
          clipRule="evenodd"
          opacity="0.04"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint5_linear_0_10706)"
        fillRule="evenodd"
        stroke="url(#paint6_linear_0_10706)"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M49 17.354a1 1 0 0 0-1.294-.956L36 20l-11.706-3.602a1 1 0 0 0-1.294.956V32a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask4_0_10706"
        width="28"
        height="10"
        x="22"
        y="15"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M49 17.354a1 1 0 0 0-1.294-.956L36 20l-11.706-3.602a1 1 0 0 0-1.294.956V23a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask4_0_10706)">
        <path
          fill="#0602F3"
          fillRule="evenodd"
          d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.197v18.127a6 6 0 0 1-3 5.196l-15.699 9.063a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.937a6 6 0 0 1 3-5.197z"
          clipRule="evenodd"
          opacity="0.04"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <path
        fill="url(#paint7_linear_0_10706)"
        fillRule="evenodd"
        stroke="#FFF04D"
        strokeOpacity="0.4"
        d="M34 14.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 57.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V26.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#F9B307"
        d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382a3.5 3.5 0 0 0 1.75 3.03l15.919 9.191a3.5 3.5 0 0 0 3.5 0l15.919-9.19a3.5 3.5 0 0 0 1.75-3.031V26.809a3.5 3.5 0 0 0-1.75-3.03z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        d="m37.75 57.412 3.98-2.297"
        opacity="0.4"
      ></path>
      <path
        stroke="#FAC00E"
        strokeLinecap="round"
        d="M55.419 45.19V26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382"
      ></path>
      <path
        stroke="#FDDB3D"
        strokeLinecap="round"
        d="M55.419 26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031"
        opacity="0.6"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-7.96 4.595"
        opacity="0.6"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        d="m34.25 14.588-3.98 2.297"
      ></path>
      <g filter="url(#filter2_d_0_10706)">
        <path
          fill="url(#paint8_linear_0_10706)"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#F5900D"
          strokeOpacity="0.1"
          d="M37.25 20.144a2.5 2.5 0 0 0-2.5 0L22.894 26.99a2.5 2.5 0 0 0-1.25 2.165v13.69a2.5 2.5 0 0 0 1.25 2.165l11.856 6.846a2.5 2.5 0 0 0 2.5 0l11.856-6.846a2.5 2.5 0 0 0 1.25-2.165v-13.69a2.5 2.5 0 0 0-1.25-2.165z"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <mask
        id="mask5_0_10706"
        width="28"
        height="32"
        x="22"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask5_0_10706)">
        <g fill="#FFD640" opacity="0.3" style={{ mixBlendMode: "multiply" }}>
          <path
            fillRule="evenodd"
            d="m36 36-16 4v-8zm0 0 4-16h-8zm0 0 16-4v8zm0 0 8.485-14.142 5.657 5.657zm0 0 8.485 14.142 5.657-5.657zm0 0-4 16h8zm0 0-8.485 14.142-5.657-5.657z"
            clipRule="evenodd"
          ></path>
          <path d="m27.515 21.858-5.657 5.657L36 36z"></path>
        </g>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M48.606 27.856 36.75 21.01a1.5 1.5 0 0 0-1.5 0l-2.964 1.712-2.964 1.71-5.928 3.424M48.606 44.11 36.75 50.955a1.5 1.5 0 0 1-1.5 0"
          opacity="0.4"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m35.25 21.01-2.964 1.712"
        ></path>
      </g>
      <g filter="url(#filter3_d_0_10706)">
        <path
          fill="#fff"
          d="M33.45 32.49 36 28.769l2.55 3.721 4.327 1.276-2.75 3.575.123 4.51L36 40.338l-4.25 1.511.124-4.51-2.751-3.575z"
        ></path>
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M36.825 28.204a1 1 0 0 0-1.65 0l-2.35 3.427-3.985 1.175a1 1 0 0 0-.51 1.57l2.534 3.293-.114 4.154a1 1 0 0 0 1.335.97L36 41.4l3.915 1.392a1 1 0 0 0 1.335-.97l-.114-4.153 2.534-3.294a1 1 0 0 0-.51-1.569l-3.986-1.175z"
        ></path>
      </g>
      <path
        fill="url(#paint9_linear_0_10706)"
        d="M33.45 32.49 36 28.769l2.55 3.721 4.327 1.276-2.75 3.575.123 4.51L36 40.338l-4.25 1.511.124-4.51-2.751-3.575z"
      ></path>
      <path
        fill="url(#paint10_linear_0_10706)"
        d="M36 36v-7.231l-2.584 3.67z"
        opacity="0.4"
      ></path>
      <path
        fill="url(#paint11_linear_0_10706)"
        d="m36 36-2.584-3.561-4.293 1.326z"
        opacity="0.8"
      ></path>
      <path
        fill="url(#paint12_linear_0_10706)"
        d="m36 36-4.184 1.361-.066 4.49z"
        opacity="0.5"
      ></path>
      <path
        fill="url(#paint13_linear_0_10706)"
        d="m40.25 41.85-.066-4.489L36 36z"
        opacity="0.4"
      ></path>
      <path fill="#FEBA1B" d="m40.184 37.361 2.693-3.595L36 36z"></path>
      <path
        fill="url(#paint14_linear_0_10706)"
        d="m42.877 33.765-4.293-1.326L36 36z"
        opacity="0.6"
      ></path>
      <g filter="url(#filter4_d_0_10706)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m46.91 29.78-.518-.205a.618.618 0 0 1 0-1.15l.519-.205a2.32 2.32 0 0 0 1.309-1.31l.205-.518a.618.618 0 0 1 1.15 0l.205.519c.236.599.71 1.073 1.31 1.31l.518.204a.618.618 0 0 1 0 1.15l-.519.205a2.32 2.32 0 0 0-1.31 1.31l-.204.518a.618.618 0 0 1-1.15 0l-.205-.519a2.32 2.32 0 0 0-1.31-1.31"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter5_d_0_10706)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m24.607 46.52-.346-.137a.412.412 0 0 1 0-.766l.346-.137c.4-.157.716-.473.873-.873l.137-.346a.412.412 0 0 1 .766 0l.137.346c.157.4.473.716.873.873l.346.137a.412.412 0 0 1 0 .766l-.346.137a1.55 1.55 0 0 0-.873.873l-.137.346a.412.412 0 0 1-.766 0l-.137-.346a1.55 1.55 0 0 0-.873-.873"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter6_d_0_10706)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m20.76 31.15-.434-.17a.515.515 0 0 1 0-.96l.433-.17a1.94 1.94 0 0 0 1.091-1.09l.17-.434a.515.515 0 0 1 .96 0l.17.433c.197.5.592.894 1.09 1.091l.434.17a.515.515 0 0 1 0 .96l-.433.17c-.5.197-.894.592-1.091 1.09l-.17.434a.515.515 0 0 1-.96 0l-.17-.433a1.94 1.94 0 0 0-1.09-1.091"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter7_d_0_10706)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m46.455 45.89-.26-.102a.31.31 0 0 1 0-.576l.26-.102c.3-.118.537-.355.655-.654l.102-.26a.31.31 0 0 1 .575 0l.103.26c.118.299.355.536.654.654l.26.102a.31.31 0 0 1 0 .576l-.26.102c-.299.118-.536.355-.654.654l-.102.26a.31.31 0 0 1-.576 0l-.102-.26a1.16 1.16 0 0 0-.655-.654"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_0_10706"
          x1="5"
          x2="5"
          y1="6"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6F61EC"></stop>
          <stop offset="1" stopColor="#3D2CD4"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_10706"
          x1="59"
          x2="59"
          y1="20"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_10706"
          x1="59"
          x2="59"
          y1="20"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_10706"
          x1="13"
          x2="13"
          y1="20"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_0_10706"
          x1="13"
          x2="13"
          y1="20"
          y2="39"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_0_10706"
          x1="23"
          x2="23"
          y1="16.354"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F7C71A"></stop>
          <stop offset="1" stopColor="#FBAA00"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_0_10706"
          x1="23"
          x2="23"
          y1="16.354"
          y2="33"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDF6B"></stop>
          <stop offset="1" stopColor="#F8A60A"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_0_10706"
          x1="13"
          x2="13"
          y1="13"
          y2="59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDE046"></stop>
          <stop offset="1" stopColor="#F9B800"></stop>
        </linearGradient>
        <linearGradient
          id="paint8_linear_0_10706"
          x1="20"
          x2="20"
          y1="20"
          y2="52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF550"></stop>
          <stop offset="1" stopColor="#FFD541"></stop>
        </linearGradient>
        <linearGradient
          id="paint9_linear_0_10706"
          x1="28.769"
          x2="28.769"
          y1="28.769"
          y2="43.231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FCA50E"></stop>
          <stop offset="1" stopColor="#FFC823"></stop>
        </linearGradient>
        <linearGradient
          id="paint10_linear_0_10706"
          x1="33.416"
          x2="29.14"
          y1="26.961"
          y2="30.017"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEC36" stopOpacity="0.468"></stop>
          <stop offset="1" stopColor="#FFF7AB"></stop>
        </linearGradient>
        <linearGradient
          id="paint11_linear_0_10706"
          x1="29.166"
          x2="33.196"
          y1="33.318"
          y2="38.278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED08" stopOpacity="0.8"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
        <linearGradient
          id="paint12_linear_0_10706"
          x1="33.875"
          x2="37.29"
          y1="43.313"
          y2="38.35"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFED08" stopOpacity="0.01"></stop>
          <stop offset="1" stopColor="#FFEF63"></stop>
        </linearGradient>
        <linearGradient
          id="paint13_linear_0_10706"
          x1="36"
          x2="32.585"
          y1="34.538"
          y2="39.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEC36" stopOpacity="0.468"></stop>
          <stop offset="1" stopColor="#FFE94A"></stop>
        </linearGradient>
        <linearGradient
          id="paint14_linear_0_10706"
          x1="36"
          x2="34.92"
          y1="31.548"
          y2="35.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEC36" stopOpacity="0.468"></stop>
          <stop offset="1" stopColor="#FFF7AB"></stop>
        </linearGradient>
        <filter
          id="filter0_d_0_10706"
          width="34.16"
          height="40.504"
          x="10.768"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.401412 0 0 0 0 0.333333 0 0 0 0 0.819608 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10706"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10706"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_0_10706"
          width="34.16"
          height="40.504"
          x="27.072"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.401412 0 0 0 0 0.333333 0 0 0 0 0.819608 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10706"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10706"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_0_10706"
          width="33.713"
          height="37.381"
          x="19.144"
          y="18.309"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.927721 0 0 0 0 0.665867 0 0 0 0 0.0535899 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10706"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10706"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_d_0_10706"
          width="21.754"
          height="21.081"
          x="25.123"
          y="25.769"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.733333 0 0 0 0 0.105882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10706"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10706"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_d_0_10706"
          width="10"
          height="10"
          x="44"
          y="24"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10706"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10706"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter5_d_0_10706"
          width="8"
          height="8"
          x="22"
          y="42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10706"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10706"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter6_d_0_10706"
          width="9"
          height="9"
          x="18"
          y="26"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10706"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10706"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter7_d_0_10706"
          width="7"
          height="7"
          x="44"
          y="42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10706"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10706"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}
