import React, { useEffect } from 'react';

import { MBProCRMClientsAPI } from '@meilleursbiens/api';
import { MBProClient } from '@meilleursbiens/types';
import { Button, IconButton, NoContentPage, Persona, Section, Toolbar, fadeInLeft } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { ChevronRightIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';

import { useDebounce } from 'use-debounce';

import { MBProCrmClientsFormCreateComponent } from '../../forms';
import { MBProCrmClientsCardComponent } from '../../index';

interface ContactChooseModalComponentProps {
  disclosure: ReturnType<typeof useDisclosure>;
  onChooseContact: (client: MBProClient) => void;
  rules?: string[];
  hiddenEditFields?: string[];
  isDelegation?: boolean;
}

const _ruleToMessage = (rule: string): string => {
  const rules = {
    civility: 'la civilité',
    first_name: 'le prénom',
    name: 'le nom',
    email: "l'email",
    phone: 'le téléphone',
    address: "l'adresse",
    postal_code: 'le code postal',
    city: 'la ville',
    country: 'le pays',
    'profile.date_of_birth': 'la date de naissance',
    'profile.birth_city': 'la ville de naissance',
    business_name: "le nom de l'entreprise",
    business_siret: 'le numéro de SIRET',
  } as { [key: string]: string };

  return rules[rule] || rule;
};

export default function MBProCrmClientsContactSelectModalComponent(props: ContactChooseModalComponentProps) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [clients, setClients] = React.useState<MBProClient[]>([]);

  const disclosureCreate = useDisclosure({
    defaultIsOpen: false,
    onClose: () => {
      setIsLoading(false);
    },
  });

  // Search clients
  const [search, setSearch] = React.useState<string>('');
  const [value] = useDebounce(search, 500);

  useEffect(() => {
    _fetchAPI();
  }, []);

  useEffect(() => {
    _refreshAPI();
  }, [value]);

  useEffect(() => {
    if (!props.disclosure.isOpen) {
      if (search !== undefined) {
        if (value !== '') {
          _fetchAPI();
        }
      }
      setSearch('');
    }
  }, [props.disclosure.isOpen]);

  const _refreshAPI = () => {
    if (search === undefined) return;
    if (value === '') _fetchAPI();
    else _searchClients();
  };

  const _fetchAPI = () => {
    MBProCRMClientsAPI.list()
      .then((r) => {
        setIsLoading(false);
        setClients(r.clients);
      })
      .catch((e) => {
        disclosureCreate.onOpen();
        ToastUtils.showError(e);
      });
  };

  const _searchClients = () => {
    if (isLoading || value === '') return;
    setIsLoading(true);
    MBProCRMClientsAPI.search(value)
      .then((r) => {
        setClients(r);
      })
      .catch((e) => {
        ToastUtils.showError(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const _onChooseContact = (client: MBProClient) => {
    const rules = props.rules;

    const validateClient = (client: MBProClient, rules: string[]): boolean => {
      return rules.every((rule) => {
        const keys = rule.split('.');
        let value = client;
        for (const key of keys) {
          // @ts-ignore
          if (value[key] === undefined || value[key] === null) {
            return false;
          }
          // @ts-ignore
          value = value[key];
        }
        return true;
      });
    };

    if (rules) {
      console.log('rules', rules, validateClient(client, rules));
      if (!validateClient(client, rules)) {
        // @ts-ignore
        const rules = props.rules.map(_ruleToMessage).join(', ');

        console.log(
          'Le client ne répond pas à tous les critères pour être sélectionné, merci de bien remplir ' + rules
        );

        ToastUtils.showError(
          'Le client ne répond pas à tous les critères pour être sélectionné, merci de bien remplir ' + rules
        );
        return;
      }
    }

    props.onChooseContact(client);
    props.disclosure.onClose();
  };

  return (
    <Modal
      size={'lg'}
      isOpen={props.disclosure.isOpen}
      closeOnOverlayClick={!isLoading}
      closeOnEsc={!isLoading}
      autoFocus={false}
      onClose={() => {
        props.disclosure.onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0} mt={1}>
          <Toolbar w={'100%'} animation={`${fadeInLeft} 0.3s ease`}>
            {disclosureCreate.isOpen && (
              <Tooltip label="Choisir un client">
                <IconButton
                  variant={'ghost'}
                  aria-label={'Choisir un client'}
                  size={'sm'}
                  icon={ArrowLeftIcon}
                  onClick={disclosureCreate.onClose}
                  mr={2}
                >
                  Retour
                </IconButton>
              </Tooltip>
            )}
            <Section
              size={'xs'}
              title={disclosureCreate.isOpen ? 'Créer un client' : 'Sélectionner un client'}
              description={disclosureCreate.isOpen ? 'Remplissez les informations' : 'Choisir un client existant'}
              actions={<ModalCloseButton />}
            ></Section>
          </Toolbar>
          <Flex px={3} py={2} pb={0} w={'full'} align={'center'} justify={'space-between'}>
            {!disclosureCreate.isOpen && (
              <InputGroup variant={'outline'} size={'sm'} maxW={175}>
                <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
                <Input
                  type="text"
                  placeholder="Rechercher..."
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </InputGroup>
            )}
            {!disclosureCreate.isOpen && (
              <Tooltip label="Ajouter un contact" placement={'right'}>
                <Button
                  aria-label={'Créer un contact'}
                  leftIcon={PlusIcon}
                  size={'sm'}
                  mt={'2px'}
                  onClick={disclosureCreate.onOpen}
                >
                  Créer un contact
                </Button>
              </Tooltip>
            )}
          </Flex>

          {disclosureCreate.isOpen ? (
            <Box w={'full'} pt={0}>
              <MBProCrmClientsFormCreateComponent
                onCreated={(c) => {
                  props.disclosure.onClose();
                  disclosureCreate.onClose();
                  _onChooseContact(c);
                }}
                isDelegation={props.isDelegation}
              />
            </Box>
          ) : (
            <Box w={'full'}>
              {isLoading ? (
                <Flex py={8} direction={'column'} align={'center'} justify={'center'}>
                  <Spinner mb={3} />
                  <p>Chargement des clients...</p>
                </Flex>
              ) : (
                <>
                  {clients.length === 0 ? (
                    <Box my={4}>
                      <NoContentPage
                        title={'Aucun client'}
                        description={'Aucune client, vous pouvez en créer un en cliquant ici'}
                        actions={
                          <Button leftIcon={PlusIcon} size={'sm'} mt={3} onClick={disclosureCreate.onOpen}>
                            Ajouter un contact
                          </Button>
                        }
                      />
                    </Box>
                  ) : (
                    <Box w={'100%'} maxH={'500px'} overflowY={'auto'} position={'relative'}>
                      {clients.map((client, i) => (
                        <MBProCrmClientsCardComponent
                          key={i}
                          hiddenEditFields={props.hiddenEditFields}
                          client={client}
                          onClick={() => _onChooseContact(client)}
                          hasContextMenu
                          onUpdate={(client) => {
                            _refreshAPI();
                          }}
                          requiredFields={props.rules}
                          ruleToMessage={_ruleToMessage}
                        />
                      ))}
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
