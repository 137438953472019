import React, { useState } from 'react';

import { MBProAdminUserDiscountAPI } from '@meilleursbiens/api';
import { MBProUser, MBProUserDiscount } from '@meilleursbiens/types';
import { Button, NumberInput, SwitchInput, TextInput } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import {
  Collapse,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

interface MBProAdminUsersDiscountViewerUIComponentProps {
  user: MBProUser;
  disclosure: ReturnType<typeof useDisclosure>;
  onCreated?: () => void;
  discount?: MBProUserDiscount;
}

const ValidationSchema = Yup.object().shape({
  name: Yup.string().max(255, 'Vous ne pouvez pas saisir plus de 255 caractères !').required('Le nom est requis'),
  is_discounted: Yup.boolean().required('Le champ de promotion est requis'),
  discount_percentage: Yup.number().required('Le pourcentage de promotion est requis'),
  is_upfront_fee_free: Yup.boolean().required('Le champ de frais initiaux gratuits est requis'),
  upfront_fee_percentage: Yup.number().required('Le pourcentage de frais initiaux est requis'),
  periods: Yup.number()
    .min(1, 'Le nombre de période doit être supérieur à 1 mois')
    .required('Le nombre de périodes est requis'),
});

export default function MBProAdminUsersDiscountCreateUIComponent({
  discount,
  user,
  disclosure,
  onCreated,
}: MBProAdminUsersDiscountViewerUIComponentProps) {
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: discount || {
      name: '',
      is_discounted: false,
      discount_percentage: 0,
      is_upfront_fee_free: false,
      upfront_fee_percentage: 0,
      periods: 0,
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setIsLoading(true);

      MBProAdminUserDiscountAPI.create(user.id, values)
        .then((response) => {
          ToastUtils.showSuccess('La réduction a été crée avec succès !');
        })
        .finally(() => {
          setIsLoading(false);
          disclosure.onClose();
          onCreated && onCreated();
        })
        .catch((error) => {
          ToastUtils.showError(error);
        });
    },
  });

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Créer / Modifier une réduction</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={formik.handleSubmit}>
            <Stack>
              <TextInput
                label={'Titre de la réduction'}
                name={'name'}
                isRequired
                placeholder={'ex: Réduction 6 mois à -50%'}
                helperText={"Il sera affiché à l'agent sur sa facture d'abonnement comme titre de la réduction"}
                errors={formik.errors}
                values={formik.values}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />

              <Divider my={2} />
              <SwitchInput
                label={'Activer une réduction sur les frais de mise en service ?'}
                name={'is_upfront_fee_free'}
                isRequired
                helperText={'Souhaitez-vous offrir un pourcentage sur les frais de mise en service ?'}
                errors={formik.errors}
                values={formik.values}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />

              <Collapse unmountOnExit={true} in={formik.values.is_upfront_fee_free}>
                <NumberInput
                  w={'100%'}
                  label={"Pourcentage de réduction sur les frais d'inscription"}
                  name={'upfront_fee_percentage'}
                  helperText={"Ce pourcentage sera appliqué sur les frais d'inscription de l'agent"}
                  isRequired
                  placeholder={'ex: 60%'}
                  unity={'%'}
                  errors={formik.errors}
                  values={formik.values}
                  touched={formik.touched}
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </Collapse>

              <Divider my={2} />

              <SwitchInput
                label={"Activer une réduction sur l'abonnement ?"}
                name={'is_discounted'}
                isRequired
                helperText={"Souhaitez-vous offrir un pourcentage sur l'abonnement ?"}
                errors={formik.errors}
                values={formik.values}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
              <Collapse unmountOnExit={true} in={formik.values.is_discounted}>
                <NumberInput
                  label={"Pourcentage de réduction sur l'abonnement de l'utilisateur"}
                  name={'discount_percentage'}
                  isRequired
                  placeholder={'ex: Réduction 6 mois à -50%'}
                  helperText={"Ce pourcentage sera appliqué sur l'abonnement de l'agent"}
                  unity={'%'}
                  errors={formik.errors}
                  values={formik.values}
                  touched={formik.touched}
                  handleBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                />
              </Collapse>
              <Divider my={2} />

              <NumberInput
                label={'Nombre de mois de réduction'}
                name={'periods'}
                isRequired
                placeholder={'ex: 6'}
                helperText={"Saisissez le nombre de mois de réduction que vous souhaitez offrir à l'utilisateur"}
                unity={'mois'}
                errors={formik.errors}
                values={formik.values}
                touched={formik.touched}
                handleBlur={formik.handleBlur}
                handleChange={formik.handleChange}
              />
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={disclosure.onClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={formik.submitForm} isLoading={isLoading} isDisabled={formik.isSubmitting}>
            Valider
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
