import React, { useState } from 'react';

import { MBProAdminAfiliationsAPI } from '@meilleursbiens/api';
import { MBProUser } from '@meilleursbiens/types';
import { AutocompleteUserInput, Button, PriceInput } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { HStack, Spacer } from '@chakra-ui/react';

import { useFormik } from 'formik';
import * as Yup from 'yup';


const ValidationSchema = Yup.object().shape({
  amount_reduction: Yup.number()
    .min(1, 'Le montant doit être supérieur à 1€ !')
    .required('Le montant est requis'),
});

interface MBProAdminAfiliationsUserCreateFormComponentProps{
  user: MBProUser;
  onCreated: () => void
}

export default function ({user, onCreated}: MBProAdminAfiliationsUserCreateFormComponentProps){

  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      sponsored_id: '',
      amount_reduction: 30
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      setIsLoading(true);

      MBProAdminAfiliationsAPI.create(user.id, values)
        .then((response) => {
          ToastUtils.showSuccess('L\'affiliation a été crée avec succès !');
          onCreated();
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          ToastUtils.showError(error);
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
       <HStack spacing={4}>
         <AutocompleteUserInput
           label={'Filleul'}
           name={'sponsored_id'}
           helperText={'Choisissez le filleul à sponsoriser'}
           handleChange={formik.handleChange}
           handleBlur={formik.handleBlur}
           values={formik.values}
           errors={formik.errors}
           touched={formik.touched}
         />
         <PriceInput
           label={'Montant de la réduction'}
           name={'amount_reduction'}
           isRequired
           placeholder={'ex: 30€'}
           helperText={"La réduction s'appliquera automatiquement"}
           errors={formik.errors}
           values={formik.values}
           touched={formik.touched}
           handleBlur={formik.handleBlur}
           handleChange={formik.handleChange}
         />

         <Button w={'200px'} variant={'primary'} isLoading={isLoading} type={'submit'}>
           Ajouter
         </Button>
       </HStack>
      </form>
    </>
  )
}
