import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

import { navGroupAnatomy } from '../../anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(navGroupAnatomy.keys);

const baseStyle = definePartsStyle((props) => {
  return {
    container: {
      '&:not(:last-of-type)': {
        mb: 3,
      },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      px: 2,
      my: 1,
      height: 6,
      fontSize: '10px',
      letterSpacing: 'wider',
      fontWeight: '700',
      textTransform: 'uppercase',
      color: 'gray.400',
      _dark: {
        color: 'whiteAlpha.600',
      },
      transitionProperty: 'common',
      transitionDuration: 'normal',
      '&.sui-collapse-toggle .chakra-icon': {
        opacity: 1,
      },
      '&.sui-collapse-toggle': {
        cursor: 'pointer',
        borderRadius: 'md',
        _hover: {
          bg: 'blackAlpha.100',
          '& .chakra-icon': {
            opacity: 1,
          },
          _dark: {
            bg: 'whiteAlpha.200',
          },
        },
      },
      '[data-compact] &': {
        opacity: 0,
      },
    },
    content: {},
  };
});

export const navGroupTheme = defineMultiStyleConfig({
  baseStyle,
});
