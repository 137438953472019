import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { AuthConstant } from '@meilleursbiens/constants';
import { MBProUser } from '@meilleursbiens/types';
import { PersonaUser } from '@meilleursbiens/ui';

import { Button } from '@chakra-ui/react';

import { loginUser } from '../../../redux/actions/auth/AuthActions';
import { Routes } from '../../../routes/routes';
import { useIsImpersonate } from '@meilleursbiens/hooks';

function ImpersonateComponent({ user, loginUser }: { user: MBProUser; loginUser: (user: MBProUser) => void }) {
  const navigate = useNavigate();

  const {isImpersonating, setIsImpersonating} = useIsImpersonate(user);

  const _leaveImpersonate = () => {
    sessionStorage.clear();

    const oldUser = localStorage.getItem(AuthConstant.BACKUP_KEY + AuthConstant.IMPERSONATE_USER_SAVE);

    const jwt_token = localStorage.getItem(AuthConstant.BACKUP_KEY + AuthConstant.JWT_TOKEN_KEY);
    const refresh_token = localStorage.getItem(AuthConstant.BACKUP_KEY + AuthConstant.JWT_REFRESH_TOKEN_KEY);
    const mb_id = localStorage.getItem(AuthConstant.BACKUP_KEY + AuthConstant.MB_ID_KEY);

    if (jwt_token == null || refresh_token == null || mb_id == null || oldUser == null) return;

    localStorage.setItem(AuthConstant.JWT_TOKEN_KEY, jwt_token);
    localStorage.setItem(AuthConstant.JWT_REFRESH_TOKEN_KEY, refresh_token);
    localStorage.setItem(AuthConstant.MB_ID_KEY, mb_id);
    loginUser(JSON.parse(oldUser));

    setTimeout(() => {
      localStorage.removeItem('mb_impersonated');
      localStorage.removeItem(AuthConstant.BACKUP_KEY + AuthConstant.JWT_TOKEN_KEY);
      localStorage.removeItem(AuthConstant.BACKUP_KEY + AuthConstant.JWT_REFRESH_TOKEN_KEY);
      localStorage.removeItem(AuthConstant.BACKUP_KEY + AuthConstant.MB_ID_KEY);
      localStorage.removeItem(AuthConstant.BACKUP_KEY + AuthConstant.IMPERSONATE_USER_SAVE);
    }, 50);

    setTimeout(() => {
      navigate(Routes.ADMIN_AGENTS.url);
    }, 150);
  };

  if (!isImpersonating) return <></>;

  return (
    <div className="impersonate-bar animated fadeInUp faster">
      <PersonaUser user={user} size={'sm'} secondaryText={'Impersonification en cours'} />
      <Button colorScheme={'red'} size={'sm'} onClick={_leaveImpersonate}>
        Quitter
      </Button>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (data) => {
      dispatch(loginUser(data));
    },
  };
};

const mapStateToProps = (state) => ({
  isLogged: state.auth.isLogged,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateComponent);
