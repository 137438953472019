import { MBProUser } from '@meilleursbiens/types';

import MBApi from '../../../MBApi';

export default class MBProAdminAgentsAPI {
  static searchAutocomplete(search: string) {
    return MBApi.url('/v1/mbpro/admin/agents/autocomplete')
      .parameters({
        search,
      })
      .needAuth()
      .post<Partial<MBProUser[]>>();
  }
}
