import { useEffect, useState } from 'react';

import { MBCoreSMSTemplatesAdminAPI } from '@meilleursbiens/api';
import { MBCoreSMSTemplate } from '@meilleursbiens/types';
import { Button, SelectInput, TextAreaInput } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { ButtonGroup, Flex } from '@chakra-ui/react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  sms: Yup.string().required('Le sms est requis !'),
  category: Yup.string().required('Une dcatégorie est requise !'),
  created_at: Yup.date(),
  updated_at: Yup.date(),
});

const SMSTemplatesCategories = [
  { id: 1, title: 'Abonnement' },
  { id: 2, title: 'Agent' },
  { id: 3, title: 'Documents' },
  { id: 4, title: 'Mandats' },
  { id: 5, title: 'RCP' },
  { id: 6, title: 'Transactions' },
  { id: 7, title: 'Judiciaire & Litiges' },
  { id: 8, title: 'Inscription' },
  { id: 9, title: 'Portage' },
  { id: 10, title: 'Résiliation' },
  { id: 11, title: 'Intégration' },
  { id: 12, title: 'Autres' },
];

interface MBAdminCoreSMSAddFormComponentProps {
  isEdit?: boolean;
  sms?: MBCoreSMSTemplate;
  onClose: () => void;
  onUpdate: () => void;
}

export default function MBAdminCoreSMSFormComponent({
  isEdit = false,
  sms,
  onClose,
  onUpdate,
}: MBAdminCoreSMSAddFormComponentProps) {
  const [isLoading, setIsLoading] = useState<{ sms?: boolean }>({
    sms: false,
  });
  const [categories, setCategories] = useState(SMSTemplatesCategories);

  const formik = useFormik({
    initialValues: {
      id: sms ? sms.id : 0,
      sms: '',
      category: '',
      created_at: sms ? sms.created_at : new Date().toDateString(),
      updated_at: new Date().toDateString(),
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setIsLoading({ sms: true });
      if (isEdit) {
        if (!sms) return;
        MBCoreSMSTemplatesAdminAPI.updateTemplate(sms.id, values).then((res) => {
          onUpdate();
          ToastUtils.showSuccess('Le modèle de SMS a bien été modifié !');
          setIsLoading({ sms: false });
        });
      } else {
        MBCoreSMSTemplatesAdminAPI.createTemplate(values).then(() => {
          onUpdate();
          ToastUtils.showSuccess('Le modèle de SMS a bien été créé !');
          setIsLoading({ sms: false });
        });
      }
      onClose();
    },
  });

  useEffect(() => {
    if (isEdit) {
      if (!sms) return;
      formik.setFieldValue('sms', sms.sms);
      formik.setFieldValue('category', sms.category);
    }
  }, [sms]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="delay-0_2s">
        <TextAreaInput
          label={'Modèle SMS'}
          name={'sms'}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          placeholder={"Achat d'emplacements LeBonCoin via des passerelles..."}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          marginBottom={'1rem'}
        />

        <SelectInput
          label={'Catégorie du SMS'}
          name={'category'}
          placeholder={'Rechercher une catégorie'}
          options={categories.map((category) => ({
            value: category?.title,
            label: category?.title,
          }))}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          marginBottom={'1rem'}
          overflow={'hidden'}
          className="selectCategory"
        />

        <Flex justify={'flex-end'} marginTop={'1rem'}>
          <ButtonGroup>
            <Button onClick={onClose} variant={'ghost'}>
              Annuler
            </Button>
            <Button type={'submit'} variant={'primary'} isLoading={isLoading.sms}>
              Sauvegarder
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    </>
  );
}
