import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useSidebarCounts } from '@meilleursbiens/hooks';
import { useTeamPermission } from '@meilleursbiens/permissions';
import { TeamsSwitchMenu, useTeam } from '@meilleursbiens/teams';
import { useTenant, useTenantFeature } from '@meilleursbiens/tenants';
import { MBProUser } from '@meilleursbiens/types';
import {
  IconButton,
  NavGroup,
  NavItem,
  Persona,
  Sidebar,
  SidebarOverlay,
  SidebarSection,
  SidebarToggleButton,
  Toolbar,
  fadeInUp,
  useSidebarToggleButton,
} from '@meilleursbiens/ui';

import {
  Badge,
  Box,
  Divider, HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  SimpleGrid,
  Skeleton,
  Spacer,
  useColorMode,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  ArrowPathIcon,
  ArrowRightOnRectangleIcon,
  ArrowUturnLeftIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  Bars4Icon,
  ClipboardIcon,
  EllipsisHorizontalCircleIcon,
  ArrowsPointingOutIcon as LogoutIcon,
  MoonIcon,
  SunIcon,
} from '@heroicons/react/24/outline';
import { LifebuoyIcon } from '@heroicons/react/24/solid';

import { ACCOUNT_NAVIGATION_V2, SectionCategory } from '../../../../constants/ACCOUNT_NAVIGATION';
import { Routes } from '../../../../routes/routes';
import LocalStorageUtils from '../../../../utils/LocalStorageUtils';
import AccountSearchBarComponent from '../AccountSearchBarComponent';
import IconSidebarComponent from './Icons/IconSidebarComponent';
import { MBProUsersAfiliationsBannerComponent } from '@meilleursbiens/pro';

export interface AccountSidebarLayoutComponentProps {
  onDeviceChange: (isMobile: boolean) => void;
  user?: MBProUser;
  onOpenUsefullLinksModal: () => void;
  onSearch: () => void;
}

function AccountSidebarLayoutComponent(props: AccountSidebarLayoutComponentProps) {
  const location = useLocation();

  const { tenant } = useTenant();
  const { currentTeam } = useTeam();
  const { isFeatureEnabled } = useTenantFeature();
  const { hasPermission, isLoadingPermissions } = useTeamPermission();
  const { isLoading: isLoadingSidebarCounts, sidebarCounts, refetch } = useSidebarCounts();

  const { colorMode, toggleColorMode } = useColorMode();

  const { isMobile, isOpen, toggleSidebar } = useSidebarToggleButton();

  const bg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('#e7e7e8', 'gray.700');

  useEffect(() => {
    props.onDeviceChange(isMobile);
  }, []);

  useEffect(() => {
    props.onDeviceChange(isMobile);
  }, [isMobile]);

  const toggleCrisp = () => {
    // @ts-ignore
    const isChatVisible = window.$crisp.is('chat:visible');
    if (isChatVisible) {
      // @ts-ignore
      window.$crisp.push(['do', 'chat:hide']);
    } else {
      // @ts-ignore
      window.$crisp.push(['do', 'chat:show']);
    }
  };

  function formatNumber(num: number): string {
    if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + 'M';
    } else if (num >= 1000) {
      return (num / 1000).toFixed(0) + 'K';
    } else {
      return num.toString();
    }
  }

  function showBadge(c: SectionCategory){
    if(c.isCountable){
      const sidebarCount: number | number[] = sidebarCounts[c.id] || 0;

      // Check if the count is an array and has 2 elements
      if(Array.isArray(sidebarCount)){
        if(sidebarCount.length !== 2){
          return null;
        }
      }

      return (
        <>
          {isLoadingSidebarCounts ? (
            <Skeleton width="20px" height="20px" borderRadius={12} />
          ) : (
            <HStack spacing={1}>
              {Array.isArray(sidebarCount) ? (
                <Box position={'relative'}>
                  {sidebarCount[0] > 0 && (
                    <Badge
                      bg={"primary.500"}
                      boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                      borderWidth={1}
                      fontSize={'8px'}
                      px={1}
                      color={'white'}
                      borderRadius={12}
                      position={'absolute'}
                      top={'-7px'}
                      right={'-10px'}
                    >
                      {formatNumber(sidebarCount[0])}
                    </Badge>
                  )}
                  <Badge
                    bg={bg}
                    boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                    borderWidth={1}
                    fontSize={'10px'}
                    color={'muted'}
                    borderRadius={12}
                    px={2}
                  >
                    {formatNumber(sidebarCount[1])}
                  </Badge>
                </Box>
              ) : (
                <Badge
                  bg={bg}
                  boxShadow={'0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}
                  borderWidth={1}
                  fontSize={'10px'}
                  color={'muted'}
                  borderRadius={12}
                  px={1}
                >
                  {formatNumber(sidebarCounts[c.id] || 0)}
                </Badge>
              )}
            </HStack>
          )}
        </>
      )
    }

    if(!c.badge){
      return null;
    }

    return c.badge;
  }

  return (
    <Sidebar
      variant={isOpen ? 'default' : isMobile ? 'default' : 'default'}
      transition="width"
      transitionDuration="normal"
      minWidth="auto"
      pt={0}
      pb={0}
    >
      {!isOpen && isMobile ? (
        <SidebarToggleButton isRound={true} icon={<Bars4Icon width={20} strokeWidth={2} />} />
      ) : null}

      <SidebarSection flex="1" overflowY="auto" overflowX="hidden" style={{ marginTop: 0 }} px={0}>
        <Toolbar h={'58px'} position={'sticky'} top={0}  bg={'transparent'}>
          <Persona
            size={'sm'}
            name={currentTeam.is_digital == 0 ? tenant.name : currentTeam.name}
            secondaryText={currentTeam.is_digital == 0 ? tenant.domain : currentTeam.name}
            avatarUrl={currentTeam.is_digital == 0 ? tenant.logo_square : currentTeam.logo}
            avatarProps={{
              borderRadius: 'md',
              boxShadow: 'base',
              borderWidth: '1px',
              borderColor: useColorModeValue('gray.50', 'gray.600'),
            }}
          />

          <Spacer />

          <Menu strategy={'fixed'} closeOnSelect={false} isLazy={true}>
            <MenuButton
              autoFocus={false}
              as={IconButton}
              icon={EllipsisHorizontalCircleIcon}
              variant={'ghost'}
              size={'md'}
            />
            <Portal>
              <MenuList>
                <MenuItem
                  icon={
                    colorMode === 'dark' ? (
                      <MoonIcon width={17} strokeWidth={2} />
                    ) : (
                      <SunIcon width={17} strokeWidth={2} />
                    )
                  }
                  onClick={toggleColorMode}
                >
                  Mode {colorMode === 'dark' ? 'sombre' : 'clair'}
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  icon={
                    isOpen ? (
                      <ArrowsPointingInIcon width={17} strokeWidth={2} />
                    ) : (
                      <ArrowsPointingOutIcon width={17} strokeWidth={2} />
                    )
                  }
                  onClick={toggleSidebar}
                >
                  {isOpen ? 'Réduire' : 'Agrandir'} le menu
                </MenuItem>
                <MenuItem icon={<ArrowPathIcon width={17} strokeWidth={2} />} onClick={() => window.location.reload()}>
                  Recharger la page
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={toggleCrisp} icon={<LifebuoyIcon width={17} strokeWidth={2} />}>
                  Afficher / Cacher le tchat
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  as={Link}
                  to={Routes.PRO_DASHBOARD.url}
                  icon={<ArrowUturnLeftIcon width={17} strokeWidth={2} />}
                >
                  Retour à mon espace
                </MenuItem>
                <MenuItem
                  icon={<ArrowRightOnRectangleIcon width={17} strokeWidth={2} />}
                  onClick={() => {
                    LocalStorageUtils.clear();
                    window.location.reload();
                  }}
                >
                  Se déconnecter
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Toolbar>
        <Box px={3} py={2} pb={5}>
          <AccountSearchBarComponent onSearch={props.onSearch} isSidebar />
          {ACCOUNT_NAVIGATION_V2.map((l, i) => {
            if (l.isAdmin && props.user.admin !== 1) return null;
            if (l.children) {
              return (
                <NavGroup
                  key={'sidebar-group-' + i}
                  title={l.label}
                  isCollapsible={l.hasTitle}
                  isTitleHidden={!l.hasTitle}
                  animation={`${fadeInUp} ${(i + 1) * 50}ms ease`}
                >
                  {l.children.map((c, i) => {
                    if (c.isAdmin && props.user.admin !== 1) return null;
                    if (c.isBetaTester && props.user.is_beta_tester !== 1) return null;
                    if (c.feature) {
                      if (!isFeatureEnabled(c.feature)) return null;
                    }
                    let hasPermissionLink = false;
                    if (c.permission) {
                      hasPermissionLink = hasPermission(c.permission);
                    } else {
                      hasPermissionLink = true;
                    }
                    return (
                      <NavItem
                        key={'sidebar-item-' + i}
                        id={c.id.replace('.', '-')}
                        icon={<IconSidebarComponent icon={c.id} isActive={location.pathname === c.href} />}
                        badge={showBadge(c)}
                        badgeProps={{
                          bg: 'transparent',
                          variant: 'subtle',
                          fontSize: '9px',
                          color: 'red.500',
                          letterSpacing: 'wider',
                          textTransform: 'uppercase',
                        }}
                        isActive={location.pathname === c.href}
                        as={Link}
                        isDisabled={!hasPermissionLink}
                        to={hasPermissionLink ? c.href : '#'}
                      >
                        {c.label}
                      </NavItem>
                    );
                  })}
                </NavGroup>
              );
            }
          })}
        </Box>
      </SidebarSection>
      <SidebarSection
        py={2}
        borderTop={'1px solid'}
        borderTopColor={borderColor}
        style={{ marginTop: 0 }}
      >
        <MBProUsersAfiliationsBannerComponent value={sidebarCounts['account.afiliations']}/>
      </SidebarSection>
      <SidebarSection
        py={2}
        borderTop={'1px solid'}
        borderTopColor={borderColor}
        style={{ marginTop: 0 }}
      >
        <SimpleGrid columns={[1, props.user.admin === 1 ? 2 : 1]} spacing={2}>
          <NavItem
            icon={
              <IconSidebarComponent
                icon={'menu.support'}
                isActive={location.pathname.includes(Routes.SUPPORT_PRO_LIST.url)}
              />
            }
            isActive={location.pathname.includes(Routes.SUPPORT_PRO_LIST.url)}
            as={Link}
            to={Routes.SUPPORT_PRO_LIST.url}
          >
            Support client
          </NavItem>
          {props.user.admin === 1 && (
            <NavItem
              icon={<IconSidebarComponent icon={'menu.back_office'} isActive={false} />}
              as={Link}
              to={Routes.ADMIN_DASHBOARD.url}
            >
              Back-office
            </NavItem>
          )}
        </SimpleGrid>

        <Divider borderColor={borderColor} my={1} />

        <TeamsSwitchMenu
          user={props.user}
          isSquare={false}
          redirectTo={Routes.PRO_DASHBOARD.url}
          subItems={
            <>
              <MenuItem
                icon={<ClipboardIcon width={20} />}
                px={2}
                onClick={() => {
                  LocalStorageUtils.clear();
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }}
              >
                Identifiant : #{props.user.id_proabono}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                icon={<LogoutIcon width={20} />}
                px={2}
                onClick={() => {
                  LocalStorageUtils.clear();
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                }}
              >
                Se déconnecter
              </MenuItem>
            </>
          }
        />
      </SidebarSection>
      <SidebarOverlay zIndex="999" />
    </Sidebar>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(AccountSidebarLayoutComponent);
