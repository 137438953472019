import React from 'react';

import { MBProCRMClientsAPI, MBProCRMClientsProfileAPI } from '@meilleursbiens/api';
import { CountriesSelectConstant } from '@meilleursbiens/constants';
import { MBProClient, MBProContact } from '@meilleursbiens/types';
import { EditableApiInput, Section } from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { Collapse, Divider, Skeleton } from '@chakra-ui/react';
import {
  AtSymbolIcon,
  BanknotesIcon,
  BookmarkIcon as BookmarkAltIcon,
  BuildingOffice2Icon,
  CalendarIcon,
  ChatBubbleLeftIcon,
  EyeIcon,
  FaceSmileIcon,
  FlagIcon,
  HomeModernIcon,
  LinkIcon,
  MapIcon,
  MapPinIcon,
  PhoneIcon,
  TagIcon,
  UserGroupIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

import * as Yup from 'yup';

interface MBProCrmClientsEditProfileComponentProps {
  client: MBProClient | MBProContact | undefined;
  onUpdate: (client: MBProClient) => void;
  hiddenFields?: string[];
}

export default function MBProCrmClientsFormEditProfileComponent({
  client,
  onUpdate,
  hiddenFields: hideFields,
}: MBProCrmClientsEditProfileComponentProps) {
  const hiddenFields = hideFields || [];

  const _editClientV3 = (key: string, value: any): Promise<void> => {
    if (!client) return Promise.reject();
    return new Promise<void>((resolve, reject) => {
      MBProCRMClientsAPI.editClient(client.id, key, value)
        .then((r) => {
          ToastUtils.showSuccess('Le client a été modifié avec succès !');
          onUpdate(r);
          resolve();
        })
        .catch((e) => {
          ToastUtils.showError(e);
          reject();
        });
    });
  };

  const _editClientProfile = (key: string, value: any): Promise<void> => {
    if (!client) return Promise.reject();
    return new Promise<void>((resolve, reject) => {
      MBProCRMClientsProfileAPI.edit(client.id, key, value)
        .then((r) => {
          ToastUtils.showSuccess('Le client a été modifié avec succès !');
          onUpdate(client);
          resolve();
        })
        .catch((e) => {
          ToastUtils.showError(e);
          reject();
        });
    });
  };

  const _includesAll = (array: string[], values: string[]): boolean => {
    return values.every((value) => array.includes(value));
  };

  if (!client)
    return (
      <>
        <Skeleton height={'80px'} w={'100%'} />
        <Skeleton height={'80px'} w={'100%'} mt={2} />
        <Skeleton height={'80px'} w={'100%'} mt={2} />
        <Skeleton height={'80px'} w={'100%'} mt={2} />
        <Skeleton height={'80px'} w={'100%'} mt={2} />
        <Skeleton height={'80px'} w={'100%'} mt={2} />
        <Skeleton height={'80px'} w={'100%'} mt={2} />
      </>
    );

  return (
    <>
      {hiddenFields.includes('civility') ? null : (
        <EditableApiInput
          label={'Civilité'}
          defaultValue={client.civility}
          inputType={'select'}
          options={[
            { value: 'Monsieur', label: 'Monsieur' },
            { value: 'Madame', label: 'Madame' },
          ]}
          editAPI={(element, value) => _editClientV3(element, value)}
          icon={(width, color) => <UserIcon width={width} color={color} strokeWidth={2} />}
          element={'civility'}
        />
      )}

      {hiddenFields.includes('first_name') ? null : (
        <EditableApiInput
          label={'Prénom'}
          defaultValue={client.first_name}
          inputType={'text'}
          editAPI={(element, value) => _editClientV3(element, value)}
          helperText={'Saisissez un prénom valide, il sera utilisé pour les communications'}
          validationRule={Yup.string()
            .min(3, 'Le prénom doit être plus long !')
            .max(50, 'Le prénom doit être plus court !')
            .required('Le nom est requis')}
          icon={(width, color) => <TagIcon width={width} color={color} strokeWidth={2} />}
          element={'first_name'}
        />
      )}

      {hiddenFields.includes('name') ? null : (
        <EditableApiInput
          label={'Nom de famille'}
          defaultValue={client.name}
          inputType={'text'}
          editAPI={(element, value) => _editClientV3(element, value)}
          helperText={'Saisissez un nom valide, il sera utilisé pour les communications'}
          validationRule={Yup.string()
            .min(2, 'Le nom doit être plus long !')
            .max(75, 'Le nom doit être plus court !')
            .required('Le nom est requis')}
          icon={(width, color) => <BookmarkAltIcon width={width} color={color} />}
          element={'name'}
        />
      )}

      {hiddenFields.includes('maiden_name') ? null : (
        <>
          {client.civility == 'Madame' && (
            <EditableApiInput
              label={'Nom de jeune fille'}
              defaultValue={client.maiden_name}
              inputType={'text'}
              editAPI={(element, value) => _editClientV3(element, value)}
              helperText={'Saisissez un nom de jeune fille valide, il sera utilisé pour les communications'}
              validationRule={Yup.string()
                .min(2, 'Le nom doit être plus long !')
                .max(120, 'Le nom doit être plus court !')
                .required('Le nom est requis')}
              icon={(width, color) => <BookmarkAltIcon width={width} color={color} strokeWidth={2} />}
              element={'maiden_name'}
            />
          )}
        </>
      )}

      {_includesAll(hiddenFields, ['email', 'phone']) ? null : (
        <Section
          mt={4}
          size={'xs'}
          title={'Coordonnées personnelles'}
          description={'Ces informations sont utilisées pour les communications'}
        >
          {hiddenFields.includes('email') ? null : (
            <EditableApiInput
              label={'Email'}
              defaultValue={client.email}
              inputType={'text'}
              editAPI={(element, value) => _editClientV3(element, value)}
              helperText={'Saisissez un email valide, il sera utilisé pour les communications'}
              validationRule={Yup.string().email('Veuillez saisir un mail valide !').required('Le mail est requis')}
              icon={(width, color) => <AtSymbolIcon width={width} color={color} strokeWidth={2} />}
              element={'email'}
            />
          )}

          {hiddenFields.includes('phone') ? null : (
            <EditableApiInput
              label={'Téléphone'}
              defaultValue={client.phone}
              inputType={'tel'}
              editAPI={(element, value) => _editClientV3(element, value)}
              helperText={'Saisissez un téléphone valide, il sera utilisé pour les communications'}
              validationRule={Yup.string().required('Le téléphone est requis')}
              icon={(width, color) => <PhoneIcon width={width} color={color} strokeWidth={2} />}
              element={'phone'}
            />
          )}
        </Section>
      )}

      {_includesAll(hiddenFields, ['is_business']) ? null : (
        <Section
          mt={4}
          size={'xs'}
          title={'Informations professionnelles'}
          description={"Si le client est une entreprise, saisissez les informations de l'entreprise"}
        >
          {hiddenFields.includes('is_business') ? null : (
            <>
              <EditableApiInput
                label={'Est-ce que le client est une entreprise ?'}
                defaultValue={client.is_business}
                inputType={'onoff'}
                editAPI={(element, value) => _editClientV3(element, value)}
                helperText={'Cette information permet de remplir les documents de manière correct'}
                icon={(width, color) => <UserGroupIcon width={width} color={color} strokeWidth={2} />}
                element={'is_business'}
              />

              <Collapse in={client.is_business === 1}>
                <EditableApiInput
                  label={'Statut de la société'}
                  defaultValue={client.business_name}
                  inputType={'text'}
                  editAPI={(element, value) => _editClientV3(element, value)}
                  helperText={'Saisissez un statut de société valide, exemple : SAS MEILLEURS BIENS'}
                  validationRule={Yup.string()
                    .min(2, 'Le statut de la société doit être plus long !')
                    .max(200, 'Le statut de la société doit être plus court !')
                    .required('Le statut de la société est requis')}
                  icon={(width, color) => <BuildingOffice2Icon width={width} color={color} strokeWidth={2} />}
                  element={'business_name'}
                />
                <EditableApiInput
                  label={'SIRET'}
                  defaultValue={client.business_siret}
                  inputType={'text'}
                  editAPI={(element, value) => _editClientV3(element, value)}
                  helperText={'Saisissez un SIRET valide, vous pouvez trouver le SIRET sur Pappers.fr'}
                  validationRule={Yup.string().required('Le SIRET est requis')}
                  icon={(width, color) => <TagIcon width={width} color={color} strokeWidth={2} />}
                  element={'business_siret'}
                />
              </Collapse>
            </>
          )}
        </Section>
      )}

      {_includesAll(hiddenFields, ['address', 'city', 'postal_code', 'country']) ? null : (
        <Section size={'xs'} title={'Adresse'} description={'Localisation du client'} mt={5}>
          {hiddenFields.includes('address') ? null : (
            <EditableApiInput
              label={'Adresse'}
              defaultValue={client.address}
              inputType={'text'}
              editAPI={(element, value) => _editClientV3(element, value)}
              helperText={'Saisissez une adresse valide, elle sera utilisée pour les communications'}
              validationRule={Yup.string().required("L'adresse est requise !")}
              icon={(width, color) => <MapPinIcon width={width} color={color} strokeWidth={2} />}
              element={'address'}
            />
          )}
          {hiddenFields.includes('city') ? null : (
            <EditableApiInput
              label={'Ville'}
              defaultValue={client.city}
              inputType={'text'}
              editAPI={(element, value) => _editClientV3(element, value)}
              helperText={'Saisissez une ville valide, elle sera utilisée pour les communications'}
              validationRule={Yup.string().required('La ville est requise !')}
              icon={(width, color) => <MapIcon width={width} color={color} strokeWidth={2} />}
              element={'city'}
            />
          )}
          {hiddenFields.includes('postal_code') ? null : (
            <EditableApiInput
              label={'Code postal'}
              defaultValue={client.postal_code}
              inputType={'number'}
              editAPI={(element, value) => _editClientV3(element, value)}
              helperText={'Saisissez un code postal valide, elle sera utilisée pour les communications'}
              validationRule={Yup.string().required('Le code postal est requis !')}
              icon={(width, color) => <MapPinIcon width={width} color={color} strokeWidth={2} />}
              element={'postal_code'}
            />
          )}
          {hiddenFields.includes('country') ? null : (
            <EditableApiInput
              label={'Pays'}
              defaultValue={client.country}
              inputType={'select'}
              options={CountriesSelectConstant}
              editAPI={(element, value) => _editClientV3(element, value)}
              helperText={'Saisissez un pays valide, il sera utilisé pour les mandats'}
              validationRule={Yup.string().required('Le pays est requis !')}
              icon={(width, color) => <FlagIcon width={width} color={color} strokeWidth={2} />}
              element={'country'}
            />
          )}
        </Section>
      )}

      <Divider />

      {_includesAll(hiddenFields, [
        'date_of_birth',
        'birth_city',
        'marital_status',
        'nb_of_childrens',
        'father_status',
        'mother_status',
        'is_first_time',
        'is_family',
        'is_investor',
        'is_owner',
        'is_renter',
        'comment',
      ]) ? null : (
        <Section
          mt={4}
          size={'xs'}
          title={'Profil du client'}
          description={'Entrez les informations du client pour le qualifier'}
        >
          {hiddenFields.includes('date_of_birth') ? null : (
            <EditableApiInput
              label={'Date de naissance'}
              defaultValue={client.profile?.date_of_birth}
              inputType={'date'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={'La date de naissance est utilisée pour les communications'}
              icon={(width, color) => <CalendarIcon width={width} color={color} strokeWidth={2} />}
              element={'date_of_birth'}
            />
          )}

          {hiddenFields.includes('birth_city') ? null : (
            <EditableApiInput
              label={'Lieu de naissance'}
              defaultValue={client.profile?.birth_city}
              inputType={'text'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={'Indiquez la ville de naissance du client pour pouvoir générer des mandats'}
              icon={(width, color) => <MapPinIcon width={width} color={color} strokeWidth={2} />}
              element={'birth_city'}
            />
          )}

          {hiddenFields.includes('marital_status') ? null : (
            <EditableApiInput
              label={'Statut marital'}
              defaultValue={client.profile?.marital_status}
              inputType={'select'}
              options={[
                { value: '0', label: 'Aucun statut sélectionné' },
                { value: '1', label: 'Célibataire' },
                { value: '2', label: 'Marié(e)' },
                { value: '3', label: 'Divorcé(e)' },
                { value: '4', label: 'Veuf / Veuve' },
                { value: '5', label: 'Pacsé(e)' },
              ]}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={'Le statut marital est utilisé pour le rapprochement automatique des annonces'}
              icon={(width, color) => <LinkIcon width={width} color={color} strokeWidth={2} />}
              element={'marital_status'}
            />
          )}

          {hiddenFields.includes('nb_of_childrens') ? null : (
            <EditableApiInput
              label={"Nombre d'enfant(s)"}
              defaultValue={client.profile?.nb_of_childrens}
              inputType={'number'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={"Le nombre d'enfant(s) est utilisé pour le rapprochement automatique des annonces"}
              icon={(width, color) => <UserGroupIcon width={width} color={color} strokeWidth={2} />}
              element={'nb_of_childrens'}
            />
          )}

          {hiddenFields.includes('father_status') ? null : (
            <EditableApiInput
              label={'Situation professionnelle du parent 1'}
              defaultValue={client.profile?.father_status}
              inputType={'select'}
              options={[
                { value: '0', label: 'Aucun statut sélectionné' },
                { value: '1', label: 'Chomage' },
                { value: '2', label: 'CDI' },
                { value: '3', label: 'CDD' },
                { value: '4', label: 'Entrepreneur' },
                { value: '5', label: 'Retraite' },
                { value: '6', label: 'Autre' },
              ]}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={'Saisissez le statut du parent 1, il sera utilisé pour le dossier du client'}
              icon={(width, color) => <FaceSmileIcon width={width} color={color} strokeWidth={2} />}
              element={'father_status'}
            />
          )}

          {hiddenFields.includes('mother_status') ? null : (
            <EditableApiInput
              label={'Situation professionnelle du parent 2'}
              defaultValue={client.profile?.mother_status}
              inputType={'select'}
              options={[
                { value: '0', label: 'Aucun statut sélectionné' },
                { value: '1', label: 'Chomage' },
                { value: '2', label: 'CDI' },
                { value: '3', label: 'CDD' },
                { value: '4', label: 'Entrepreneur' },
                { value: '5', label: 'Retraite' },
                { value: '6', label: 'Autre' },
              ]}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={'Saisissez le statut du parent 2, il sera utilisé pour le dossier du client'}
              icon={(width, color) => <FaceSmileIcon width={width} color={color} strokeWidth={2} />}
              element={'mother_status'}
            />
          )}

          {hiddenFields.includes('is_first_time') ? null : (
            <EditableApiInput
              label={'Est-ce le premier achat ou location de votre client ?'}
              defaultValue={client.profile?.is_first_time}
              inputType={'onoff'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={"Cette information sera utilisée pour les rapprochements d'annonces"}
              icon={(width, color) => <EyeIcon width={width} color={color} strokeWidth={2} />}
              element={'is_renter'}
            />
          )}

          {hiddenFields.includes('is_family') ? null : (
            <EditableApiInput
              label={'Est-ce que le client est une famille ?'}
              defaultValue={client.profile?.is_family}
              inputType={'onoff'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={"Cette information sera utilisée pour les rapprochements d'annonces"}
              icon={(width, color) => <UserGroupIcon width={width} color={color} strokeWidth={2} />}
              element={'is_family'}
            />
          )}

          {hiddenFields.includes('is_investor') ? null : (
            <EditableApiInput
              label={'Est-ce que le client est un investisseur ?'}
              defaultValue={client.profile?.is_investor}
              inputType={'onoff'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={"Cette information sera utilisée pour les rapprochements d'annonces"}
              icon={(width, color) => <BanknotesIcon width={width} color={color} strokeWidth={2} />}
              element={'is_investor'}
            />
          )}

          {hiddenFields.includes('is_owner') ? null : (
            <EditableApiInput
              label={'Est-ce que le client est propriétaire ?'}
              defaultValue={client.profile?.is_owner}
              inputType={'onoff'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={"Cette information sera utilisée pour les rapprochements d'annonces"}
              icon={(width, color) => <HomeModernIcon width={width} color={color} strokeWidth={2} />}
              element={'is_owner'}
            />
          )}

          {hiddenFields.includes('is_renter') ? null : (
            <EditableApiInput
              label={'Est-ce que le client fait de la location ?'}
              defaultValue={client.profile?.is_renter}
              inputType={'onoff'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={"Cette information sera utilisée pour les rapprochements d'annonces"}
              icon={(width, color) => <BuildingOffice2Icon width={width} color={color} strokeWidth={2} />}
              element={'is_renter'}
            />
          )}

          {hiddenFields.includes('comment') ? null : (
            <EditableApiInput
              label={'Commentaires'}
              defaultValue={client.profile?.comment}
              inputType={'text'}
              editAPI={(element, value) => _editClientProfile(element, value)}
              helperText={"Saisissez une note sur le client, elle sera utilisée pour les rapprochements d'annonces"}
              icon={(width, color) => <ChatBubbleLeftIcon width={width} color={color} strokeWidth={2} />}
              element={'comment'}
            />
          )}
        </Section>
      )}
    </>
  );
}
