import { useState } from 'react';

function useAdvancedSessionStorage<T extends Record<string, any>>(
  key: string,
  initialValue: T,
  expirationInSeconds?: number
) {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      if (!item) return initialValue;

      const { value, expiration } = JSON.parse(item);
      if (expiration && new Date().getTime() > expiration) {
        window.sessionStorage.removeItem(key);
        return initialValue;
      }
      return value;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      const item = {
        value: valueToStore,
        expiration: expirationInSeconds ? new Date().getTime() + expirationInSeconds * 1000 : null,
      };
      setStoredValue(valueToStore);
      window.sessionStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
      console.error(error);
    }
  };

  const updateValue = (field: keyof T, value: any) => {
    setValue((prev) => ({ ...prev, [field]: value }));
  };

  const getValue = (field: keyof T): any => {
    if (!storedValue) return null;
    return storedValue[field];
  };

  return [storedValue, setValue, updateValue, getValue] as const;
}

export default useAdvancedSessionStorage;
