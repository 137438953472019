import React, { useEffect, useState } from 'react';

import { MBProCRMClientsAPI } from '@meilleursbiens/api';
import { MBProCRMClientsRequests, MBProClient, MBProClientOrigin, MBProClientStatus } from '@meilleursbiens/types';
import {
  AddressInputComponent,
  Button,
  DateInput,
  EmailInput,
  PhoneInput,
  SelectInput,
  SwitchInput,
  TextInput,
  Toolbar,
} from '@meilleursbiens/ui';
import { ToastUtils } from '@meilleursbiens/utils';

import { ButtonGroup, Divider, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';

import { useFormik } from 'formik';
import * as Yup from 'yup';

interface MBProClientsFormCreateComponentProps {
  onCreated: (client: MBProClient) => void;
  clientDatas?: MBProCRMClientsRequests;
  isDelegation?: boolean;
}

export default function MBProClientsFormCreateComponent(props: MBProClientsFormCreateComponentProps) {
  const ValidationSchema = Yup.object().shape(
    {
      civility: Yup.string().required('La civilité est requise !'),
      first_name: Yup.string()
        .max(100, 'Le prénom ne doit pas dépasser 100 caractères !')
        .required('Le prénom est requis !'),
      name: Yup.string()
        .max(100, 'Le nom de famille ne doit pas dépasser 100 caractères !')
        .required('Le nom de famille est requis !'),
      email: Yup.string().when('phone', {
        is: '',
        then: Yup.string().email("L'adresse e-mail est requise !"),
        otherwise: Yup.string().nullable(),
      }),
      phone: Yup.string().when('email', {
        is: '',
        then: Yup.string().required('Le téléphone est requis !'),
        otherwise: Yup.string().nullable(),
      }),
      address: Yup.string().max(255, "L'adresse ne doit pas dépasser 255 caractères !").nullable(),
      city: Yup.string().max(120, "L'adresse ne doit pas dépasser 120 caractères !").nullable(),
      postal_code: Yup.string().nullable(),
      country: Yup.string().required('Le pays du contact est requis !'),
      is_business: Yup.bool().required('Le type de business est requis !'),
      ...(props.isDelegation && {
        business_name: Yup.string().required('Le nom de la société est requis !'),
        business_siret: Yup.string().required('Le SIRET de la société est requis !'),
      }),
    },
    // @ts-ignore
    ['email', 'phone']
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [origins, setOrigins] = useState<MBProClientOrigin[]>([]);
  const [status, setStatus] = useState<MBProClientStatus[]>([]);

  const _listStatus = async () => {
    MBProCRMClientsAPI.listStatus()
      .then((response) => setStatus(response.statuses))
      .catch((error) => ToastUtils.showError(error));
  };

  const _listOrigins = async () => {
    MBProCRMClientsAPI.listOrigins()
      .then((response) => setOrigins(response.origins))
      .catch((error) => ToastUtils.showError(error));
  };

  useEffect(() => {
    _listOrigins();
    _listStatus();

    if (props.clientDatas) {
      formik.setFieldValue('civility', props.clientDatas.civility || 'Monsieur');
      formik.setFieldValue('first_name', props.clientDatas.first_name || '');
      formik.setFieldValue('name', props.clientDatas.last_name || '');
      formik.setFieldValue('email', props.clientDatas.email || '');
      formik.setFieldValue('phone', props.clientDatas.phone || '');
      formik.setFieldValue('postal_code', props.clientDatas.postal_code || '');
      formik.setFieldValue('city', props.clientDatas.city || '');
    }
    if (props.isDelegation) {
      formik.setFieldValue('is_business', true);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      civility: 'Monsieur',
      first_name: '',
      name: '',
      email: '',
      phone: (props.clientDatas && props.clientDatas.phone) || '',
      address: '',
      city: '',
      postal_code: (props.clientDatas && props.clientDatas.postal_code) || '',
      country: 'France',
      origin: '1',
      status: '1',
      is_business: false,
      business_name: '',
      business_siret: '',
      date_of_birth: '',
      birth_city: '',
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      MBProCRMClientsAPI.createContact(values)
        .then((r) => {
          props.onCreated(r.client);
          formik.resetForm();
        })
        .catch((e) => ToastUtils.showError(e))
        .finally(() => setIsLoading(false));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack pt={2} pb={5} px={6} align={'flex-start'}>
        <SelectInput
          name={'civility'}
          label={'Civilité'}
          options={[
            { label: 'Monsieur', value: 'Monsieur' },
            { label: 'Madame', value: 'Madame' },
          ]}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
        />

        <SimpleGrid w={'100%'} columns={[2]} spacing={3} mt={2}>
          <TextInput
            name={'first_name'}
            label={'Prénom'}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired
          />
          <TextInput
            name={'name'}
            label={'Nom de famille'}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired
          />
        </SimpleGrid>

        <Divider my={1} mt={3} />

        <SwitchInput
          name={'is_business'}
          label={"S'agit-il d'une société ?"}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
        />

        {formik.values.is_business && (
          <SimpleGrid w={'100%'} columns={[2]} spacing={3} mb={3}>
            <TextInput
              name={'business_name'}
              label={'Nom de la société'}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              {...(props.isDelegation && { isRequired: true })}
            />
            <TextInput
              name={'business_siret'}
              label={'Siret de la société'}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
              values={formik.values}
              errors={formik.errors}
              touched={formik.touched}
              {...(props.isDelegation && { isRequired: true })}
            />
          </SimpleGrid>
        )}

        <Divider my={1} mt={1} />

        <Text fontWeight={600} fontSize={'xs'} mb={2}>
          Coordonnées de contact
        </Text>

        <EmailInput
          name={'email'}
          label={'Adresse e-mail'}
          helperText={'L’adresse e-mail est requise pour les communications'}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired
        />

        <PhoneInput
          name={'phone'}
          label={'Numéro de téléphone'}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired
        />

        <Divider my={1} mt={3} />

        <Text fontWeight={600} fontSize={'xs'} mb={2}>
          Coordonnées géographiques
        </Text>

        <AddressInputComponent
          autofocus={false}
          formik={formik}
          mapInputName={{
            address: 'address',
            city: 'city',
            zip: 'postal_code',
            department: 'department',
            region: 'region',
            country: 'country',
            district: 'district',
            latitude: 'lat',
            longitude: 'lon',
          }}
          isMapHidden
          isAccurate={false}
        />

        <Divider my={1} mt={3} />

        <Text fontWeight={600} fontSize={'xs'} mb={2}>
          Informations juridiques pour les documents
        </Text>

        <DateInput
          name={'date_of_birth'}
          label={'Date de naissance'}
          helperText={'La date de naissance est requise pour les documents officiels'}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
        />

        <TextInput
          name={'birth_city'}
          label={'Lieu de naissance'}
          helperText={'Le lieu de naissance est requis pour les documents officiels'}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
        />
      </VStack>

      <Toolbar
        position={'sticky'}
        bottom={0}
        w={'100%'}
        alignItems={'center'}
        justifyContent={'flex-end'}
        borderBottom={0}
        borderTop={1}
        boxShadow={'0px -5px 22px 9px rgba(0,0,0,0.03)'}
        borderBottomRadius={'md'}
      >
        <ButtonGroup>
          <Button onClick={(e) => formik.resetForm()} variant={'ghost'}>
            Réinitialiser
          </Button>
          <Button
            onClick={formik.submitForm}
            isLoading={isLoading}
            isDisabled={formik.isSubmitting}
            variant={'primary'}
            rightIcon={PlusIcon}
          >
            Créer le client
          </Button>
        </ButtonGroup>
      </Toolbar>
    </form>
  );
}
