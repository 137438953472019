import React, { useEffect, useRef, useState } from 'react';

import { AnnouncementModal, Section } from '@meilleursbiens/ui';

import { ModalBody, ModalCloseButton, ModalHeader, Text, useColorModeValue } from '@chakra-ui/react';
import { DocumentTextIcon, GiftIcon } from '@heroicons/react/24/outline';

import Plyr, { APITypes } from 'plyr-react';

export default function () {
  const playerRef = useRef<APITypes | null>(null);
  const [isVideoSeen, setIsVideoSeen] = useState(true);

  /*   useEffect(() => {
    const checkPlayerStatus = () => {
      const player = playerRef.current?.plyr;
      if (player && player.currentTime >= player.duration - 1) {
        setIsVideoSeen(true);
      }
    };

    checkPlayerStatus();

    const interval = setInterval(checkPlayerStatus, 1000);

    return () => clearInterval(interval);
  }, [isVideoSeen]); */

  return (
    <AnnouncementModal
      seenKey={'mb.pro.affiliation.onboarding'}
      modalProps={{
        size: 'xl',
        closeOnOverlayClick: isVideoSeen ? true : false,
      }}
      isSeen={true}
    >
      <ModalHeader pb={2}>
        <Section
          icon={GiftIcon}
          iconColor={'red.500'}
          size={'xs'}
          title={'Découvrez notre nouveau programme de parrainage'}
          description="Parrainez des agents et réduisez votre facture mensuelle !"
        />
        {isVideoSeen ? <ModalCloseButton /> : null}
      </ModalHeader>
      <ModalBody p={0}>
        <Text fontSize={'11px'} fontWeight={'semibold'} textAlign={'center'} color={'muted'}>
          {/*           {isVideoSeen
            ? 'Vous pouvez maintenant fermer la fenêtre.'
            : 'Vous pourrez fermer la fenêtre à la fin de la vidéo.'} */}
        </Text>
        <Plyr
          ref={playerRef}
          source={{
            type: 'video',
            sources: [
              {
                src: 'https://www.youtube.com/watch?v=TURX2E-xBIw',
                provider: 'youtube',
              },
            ],
          }}
          options={{
            autoplay: true,
            resetOnEnd: true,
            disableContextMenu: true,
            clickToPlay: true,
            i18n: {
              restart: 'Recommencer',
              rewind: 'Retour {seektime}s',
              play: 'Play',
              pause: 'Pause',
              fastForward: 'Avancer {seektime}s',
              seek: 'Seek',
              seekLabel: '{currentTime} de {duration}',
              played: 'Déjà joué',
              buffered: 'Pré-chargement',
              currentTime: '',
              duration: 'Durée',
              volume: 'Volume',
              mute: 'Muet',
              unmute: 'Désactiver muet',
              enableCaptions: 'Activer les sous-titres',
              disableCaptions: 'Désactiver les sous-titres',
              download: 'Télécharger',
              enterFullscreen: 'Ouvrir en plein écran',
              exitFullscreen: 'Quitter le plein écran',
              frameTitle: 'Lecteur de {title}',
              captions: 'Sous-titres',
              settings: 'Paramètres',
              pip: 'PIP',
              menuBack: 'Retour',
              speed: 'Vitesse',
              normal: 'Normal',
              quality: 'Qualité',
              loop: 'Boucle',
              start: 'Démarrage',
              end: 'Fin',
              all: 'Tous',
              reset: 'Réinitialiser',
              disabled: 'Désactivé',
              enabled: 'Activé',
              advertisement: 'Pub',
              qualityBadge: {
                2160: '4K',
                1440: 'HD',
                1080: 'HD',
                720: 'HD',
                576: 'SD',
                480: 'SD',
              },
            },
            youtube: {
              disableKeyboard: false,
              playsinline: false,
              modestBranding: true,
              cc_load_policy: 0,
              iv_load_policy: 3,
              rel: 0,
              showinfo: 0,
              controls: 0,
            },
          }}
        />
      </ModalBody>
    </AnnouncementModal>
  );
}
