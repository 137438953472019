import formbricks from "@formbricks/js";

export default class FormbricksUtils {
  static getClient(user_id?: number) {
    if (typeof window !== 'undefined') {
      formbricks.init({
        environmentId: 'cm7dk6vmh0009ql01pg1o68ww',
        apiHost: 'https://ask.mbiens.com',
        userId: user_id ? user_id.toString() : undefined,
      });
    }
  }

  static registerUser(id: number, email: string, firstName: string, lastName: string, isAdmin: boolean) {
    this.getClient(id);
    formbricks.setEmail(email);
    formbricks.setAttribute('id', id.toString());
    formbricks.setAttribute('firstName', firstName);
    formbricks.setAttribute('lastName', lastName);
    formbricks.setAttribute('isAdmin', isAdmin ? 'true' : 'false');
  }

  static logoutUser() {
    this.getClient();
    formbricks.logout();
  }

  static routeChange() {
    this.getClient();
    formbricks.registerRouteChange();
  }
}
