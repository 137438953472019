import {
  AcademicCapIcon,
  BanknotesIcon,
  BookOpenIcon,
  BriefcaseIcon,
  CameraIcon,
  CreditCardIcon as CashIcon,
  ChartBarIcon,
  ChatBubbleLeftIcon as ChatIcon,
  CodeBracketSquareIcon,
  CogIcon,
  CreditCardIcon,
  CubeTransparentIcon,
  CursorArrowRippleIcon as CursorClickIcon,
  DocumentTextIcon,
  FolderOpenIcon,
  GlobeAltIcon,
  HomeIcon,
  HomeModernIcon,
  InboxStackIcon,
  BuildingOffice2Icon as OfficeBuildingIcon,
  ReceiptPercentIcon,
  RectangleGroupIcon,
  ScaleIcon,
  ShoppingCartIcon,
  SparklesIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserPlusIcon,
  VariableIcon,
} from '@heroicons/react/24/solid';

interface SectionLink {
  id: string;
  label: string;
  subLabel: string;
  feature?: string;
  icon: any;
  href?: string;
  permission: string[];
}

interface SectionCategory {
  id: string;
  label: string;
  subLabel?: string;
  feature?: string;
  icon: any;
  href?: string;
  collapseByDefault?: boolean;
  children?: SectionLink[];
  permission?: string[];
  badge?: string;
}


const ADMIN_NAVIGATION: SectionCategory[] = [
  {
    id: 'admin_dashboard',
    label: 'Tableau de Bord',
    icon: RectangleGroupIcon,
    href: '/admin/dashboard',
    permission: ['back.office.dashboard'],
    subLabel: 'Vue globale du réseau',
  },
  {
    id: 'admin_user_management',
    label: 'Utilisateurs & Équipes',
    icon: UserGroupIcon,
    collapseByDefault: false,
    children: [
      {
        id: 'admin_agents',
        label: 'Utilisateurs',
        subLabel: "Liste des utilisateurs du réseau",
        icon: UserCircleIcon,
        href: '/admin/agents',
        permission: ['back.office.users.list'],
      },
      {
        id: 'admin_teams',
        label: 'Équipes',
        subLabel: "Structure organisationnelle",
        icon: UserGroupIcon,
        href: '/admin/core/teams',
        permission: ['back.office.teams.list'],
      },
      {
        id: 'admin_affiliations',
        label: 'Carte des parrainages',
        subLabel: "Gestion des affiliations",
        icon: UserPlusIcon,
        href: '/admin/afiliations',
        permission: ['back.office.teams.list'],
      },
    ]
  },
  {
    id: 'admin_business',
    label: 'Activité immobilière',
    icon: ChartBarIcon,
    collapseByDefault: false,
    children: [
      {
        id: 'admin_estimations',
        label: 'Estimations',
        subLabel: 'Suivi des estimations réalisées',
        feature: 'mb_estimation',
        icon: CubeTransparentIcon,
        href: '/admin/estimations',
        permission: ['back.office.estimations.list'],
      },
      {
        id: 'admin_leads',
        label: 'Prospects',
        subLabel: 'Leads transmis aux agents',
        feature: 'mb_estimation_leads',
        icon: CursorClickIcon,
        href: '/admin/estimations/leads',
        permission: ['back.office.leads.list'],
      },
      {
        id: 'admin_contacts',
        label: 'Clients',
        subLabel: 'Base de contacts du réseau',
        feature: 'mb_contacts',
        icon: UserGroupIcon,
        href: '/admin/contacts',
        permission: ['back.office.contacts.list'],
      },
      {
        id: 'admin_mandats',
        label: 'Mandats',
        subLabel: 'Suivi des mandats et DPE',
        icon: FolderOpenIcon,
        href: '/admin/mandates',
        permission: ['back.office.mandats.list'],
      },
      {
        id: 'admin_property_listings',
        label: 'Annonces',
        subLabel: 'Biens en diffusion',
        feature: 'mb_neo_properties',
        icon: HomeModernIcon,
        href: '/admin/neo/properties',
        permission: ['back.office.neo.properties.list'],
      },
      {
        id: 'admin_platforms',
        label: 'Portails',
        subLabel: 'Plateformes de diffusion',
        feature: 'mb_neo_properties',
        icon: GlobeAltIcon,
        href: '/admin/neo/platforms',
        permission: ['back.office.neo.platforms.list'],
      },
      {
        id: 'admin_transactions_dashboard',
        label: 'Cockpit des transactions',
        subLabel: 'Vue globale des transactions',
        icon: InboxStackIcon,
        href: '/admin/transactions/dashboard',
        permission: ['back.office.transactions.list'],
      },
      {
        id: 'admin_transactions_ventes',
        label: 'Ventes',
        subLabel: 'Transactions ventes',
        icon: HomeIcon,
        href: '/admin/transactions',
        permission: ['back.office.transactions.list'],
      },
      {
        id: 'admin_transactions_locations',
        label: 'Locations',
        subLabel: 'Transactions locations',
        icon: OfficeBuildingIcon,
        href: '/admin/transactions-location',
        permission: ['back.office.transactions.list'],
      },
    ],
  },
  {
    id: 'admin_finances',
    label: 'Finance',
    icon: BanknotesIcon,
    collapseByDefault: false,
    children: [
      {
        id: 'admin_comptabilites',
        label: 'Factures comptables',
        subLabel: 'Gestion des factures',
        icon: FolderOpenIcon,
        href: '/admin/invoices',
        permission: ['back.office.accounting.invoice.list'],
      },
      {
        id: 'admin_proabono_invoices',
        label: "Factures d'abonnements",
        subLabel: 'Suivi facturation ProAbono',
        feature: 'mb_subscription',
        icon: CreditCardIcon,
        href: '/admin/subscriptions/invoices',
        permission: ['back.office.subscription.view'],
      },
      {
        id: 'admin_harp_salaries',
        label: 'Salaires',
        subLabel: 'Gestion de la paie',
        feature: 'mb_subscription',
        icon: BriefcaseIcon,
        href: '/admin/harp/salaries',
        permission: ['back.office.harp.salaries'],
      },
      {
        id: 'admin_afilliations_discounts',
        label: 'Réductions (parrainage)',
        subLabel: "Dans le cadre des affiliations",
        icon: ReceiptPercentIcon,
        href: '/admin/afiliations/discounts',
        permission: ['back.office.teams.list'],
      },
    ],
  },
  {
    id: 'admin_legal',
    label: 'Juridique',
    icon: ScaleIcon,
    collapseByDefault: false,
    subLabel: 'Aspects légaux et contrats',
    children: [
      {
        id: 'admin_overdues',
        label: 'Litiges & Contentieux',
        subLabel: 'Suivi des dossiers contentieux',
        icon: ScaleIcon,
        href: '/admin/overdues',
        permission: ['back.office.users.overdues.list'],
      },
      {
        id: 'admin_contracts',
        label: 'Contrats',
        subLabel: "Gestion des contrats mandataires",
        icon: DocumentTextIcon,
        href: '/admin/contrats',
        permission: ['back.office.contracts.list'],
      },
    ],
  },
  {
    id: 'admin_resources',
    label: 'Ressources',
    icon: FolderOpenIcon,
    collapseByDefault: false,
    subLabel: 'Documentation et outils',
    children: [
      {
        id: 'admin_documents',
        label: 'Centre de documents',
        subLabel: 'Bibliothèque documentaire',
        feature: 'mb_documents',
        icon: FolderOpenIcon,
        href: '/admin/documents',
        permission: ['back.office.documents.list'],
      },
      {
        id: 'admin_formations',
        label: 'Formations',
        subLabel: 'Catalogue de formations',
        feature: 'mb_formations',
        icon: AcademicCapIcon,
        href: '/admin/formations',
        permission: ['back.office.formation.list'],
      },
      {
        id: 'admin_replays',
        label: 'Replays',
        subLabel: 'Vidéos et enregistrements',
        feature: 'mb_replays',
        icon: CameraIcon,
        href: '/admin/replays',
        permission: ['back.office.replays.list'],
      },
      {
        id: 'admin_marketplace',
        label: 'Marketplace',
        subLabel: 'Offres partenaires',
        feature: 'mb_pro_marketplace',
        icon: ShoppingCartIcon,
        href: '/admin/marketplace',
        permission: ['back.office.marketplace.list'],
      },
    ],
  },
  {
    id: 'admin_config',
    label: 'Configuration',
    icon: CogIcon,
    collapseByDefault: false,
    subLabel: 'Paramétrage du système',
    children: [
      {
        id: 'admin_proabono_features',
        label: 'Fonctionnalités',
        subLabel: 'Gestion des options',
        feature: 'mb_subscription',
        icon: SparklesIcon,
        href: '/admin/subscriptions/features',
        permission: ['back.office.subscription.view'],
      },
      {
        id: 'admin_permissions_roles',
        label: 'Rôles et permissions',
        subLabel: 'Gestion des accès',
        icon: UserPlusIcon,
        href: '/admin/core/permissions/roles',
        permission: ['back.office.permissions'],
      },
      {
        id: 'admin_sms',
        label: 'Centre de messages',
        subLabel: 'Templates et envoi SMS',
        icon: ChatIcon,
        href: '/admin/core/sms',
        permission: ['back.office.sms-templates.list'],
      },
      {
        id: 'admin_forms',
        label: 'Générateur de formulaires',
        subLabel: 'Création de formulaires',
        icon: VariableIcon,
        href: '/admin/saas/form-schemas',
        permission: ['back.office.saas.form-builder.list'],
      },
      {
        id: 'admin_subscriptions',
        label: 'Gestion de ProAbono',
        subLabel: 'Gestion des abonnements',
        feature: 'mb_subscription',
        icon: CashIcon,
        href: '/admin/subscriptions',
        permission: ['back.office.subscription.view'],
      },
    ],
  },
  {
    id: 'admin_history',
    label: "Fil d'activité",
    icon: BookOpenIcon,
    href: '/admin/logs',
    permission: ['back.office.logs.list'],
    subLabel: 'Historique des actions',
  },
  {
    id: 'admin_tools',
    label: 'Outils développeur',
    icon: CodeBracketSquareIcon,
    href: '/admin/saas/tools',
    permission: [],
    subLabel: 'Fonctions techniques',
  },
];

export default ADMIN_NAVIGATION;
