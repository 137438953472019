'use client';

import React, { useEffect, useState } from 'react';



import { MBNeoPropertiesType, MBProClient } from '@meilleursbiens/types';
import {
  DateInput,
  PriceInput,
  Section,
  SelectButtonInput,
  TextInput,
  fadeInRight,
  IconButton, Button,
} from '@meilleursbiens/ui';



import { Box, Divider, Fade, Flex, SimpleGrid, Spacer, useColorModeValue, useDisclosure } from '@chakra-ui/react';



import { useFormik } from 'formik';
import moment from 'moment/moment';
import * as Yup from 'yup';
import {
  ClientsUtils,
  MBProCrmClientsCardComponent,
  MBProCrmClientsContactSelectModalComponent,
} from '@meilleursbiens/clients';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { MBProCRMClientsAPI } from '@meilleursbiens/api';
import { ToastUtils } from '@meilleursbiens/utils';

interface MBNeoPropertyViewBuyOffersCreateFormComponentProps {
  property: MBNeoPropertiesType;
  onSubmit: (values: any) => void;
}

const INITIAL_VALUES = {
  buyer_id: '',
  seller_id: '',
  price_public: 0,
  is_loan: false,
  price_contribution_loan: 0,
  price_loan: 0,
  date_max_offer: moment().add('1', 'day').format('DD/MM/YYYY'),
  signature_place: '',
};

const ValidationSchema = Yup.object().shape({
  buyers_ids: Yup.array()
    .of(Yup.number())
    .min(1, 'Veuillez sélectionner au moins un mandant')
    .max(64, 'Vous ne pouvez pas sélectionner plus de 64 mandants')
    .required('Veuillez sélectionner au moins un mandant'),
  sellers_ids: Yup.array()
    .of(Yup.number())
    .min(1, 'Veuillez sélectionner au moins un mandant')
    .max(64, 'Vous ne pouvez pas sélectionner plus de 64 mandants')
    .required('Veuillez sélectionner au moins un mandant'),
  is_loan: Yup.boolean().required('Ce champ est requis'),
  price_public: Yup.number().min(1, "Le prix doit être supérieur à 1€ !").required('Ce champ est requis'),
  date_max_offer: Yup.string().required('Ce champ est requis'),
  signature_place: Yup.string().required('Ce champ est requis'),
});

type ModalSelectType = 'seller' | 'buyer';

export default function (props: MBNeoPropertyViewBuyOffersCreateFormComponentProps) {
  const [sellerClient, setSellerClient] = React.useState<MBProClient | null>(null);
  const [buyerClient, setBuyerClient] = React.useState<MBProClient | null>(null);

  const [type, setType] = useState<ModalSelectType>('seller');

  const [sellers, setSellers] = useState<MBProClient[]>([]);
  const [buyers, setBuyers] = useState<MBProClient[]>([]);
  const [clients, setClients] = useState<MBProClient[]>([]);
  const [apiClients, setApiClients] = useState<MBProClient[]>([]);

  const disclosure = useDisclosure();

  const formik = useFormik({
    initialValues: {
      ...INITIAL_VALUES,
      signature_place: props.property.city,
      price_public: props.property.price_public,
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      props.onSubmit(formik.values);
    },
  });

  const _fetchClients = async () => {
    try {
      const { clients } = await MBProCRMClientsAPI.list();
      setApiClients(clients);
    } catch (error) {
      ToastUtils.showError('Une erreur est survenue lors de la récupération des mandants');
    }
  };

  const _precompleteSeller = () => {
    const vendor = ClientsUtils.getNeoClientsFirstVendor(props.property.clients || []);
    if (vendor) {
      _selectSeller(vendor);
    }
  }

  const _selectSeller = (client: MBProClient) => {
    // CHeck if the client is already in the list
    if (sellers.find((c) => c.id === client.id)) {
      return;
    }

    setSellers([...sellers, client]);
    formik.setFieldValue(
      'sellers_ids',
      [...sellers, client].map((c) => c.id)
    );
  }

  const _deleteSeller = (id: number) => {
    const updatedClients = sellers.filter((c) => c.id !== id);
    if (updatedClients.length === 0) {
      ToastUtils.showError('Au moins un vendeur est requis !');
      return;
    }

    setSellers(updatedClients);

    formik.setFieldValue(
      'sellers_ids',
      updatedClients.map((c) => c.id)
    );
  }

  const _selectBuyer = (client: MBProClient) => {
    // CHeck if the client is already in the list
    if (buyers.find((c) => c.id === client.id)) {
      return;
    }

    setBuyers([...buyers, client]);
    formik.setFieldValue(
      'buyers_ids',
      [...buyers, client].map((c) => c.id)
    );
  }

  const _deleteBuyer = (id: number) => {
    const updatedClients = buyers.filter((c) => c.id !== id);
    if (updatedClients.length === 0) {
      ToastUtils.showError('Au moins un acquéreur est requis !');
      return;
    }

    setBuyers(updatedClients);

    formik.setFieldValue(
      'buyers_ids',
      updatedClients.map((c) => c.id)
    );
  }

  useEffect(() => {
    _precompleteSeller();
    _fetchClients();
  }, []);

  useEffect(() => {
    _precompleteSeller();
  }, [props.property]);

  const bg = useColorModeValue('gray.50', 'gray.700');

  return (
    <Box as={'form'} id="form-buy-offers" onSubmit={formik.handleSubmit} animation={`${fadeInRight} 0.2s ease`} pb={5}>
      <Section
        size={'xs'}
        title={'Sélectionner les vendeurs'}
        description={'Les vendeurs seronts automatiquements pré-rempli avec les vendeurs du bien.'}
        mb={4}
      >
        {sellers.length === 0 && (
          <Box p={4} textAlign={'center'} color={'muted'}>
            Aucun client sélectionné
          </Box>
        )}

        {sellers.map((client, index) => (
          <MBProCrmClientsCardComponent
            key={index}
            client={client}
            hiddenEditFields={[
              'marital_status',
              'nb_of_childrens',
              'father_status',
              'mother_status',
              'is_first_time',
              'is_family',
              'is_investor',
              'is_owner',
              'is_renter',
              'comment',
            ]}
            rightIcon={
              <IconButton
                variant={'ghost'}
                icon={TrashIcon}
                onClick={() => _deleteSeller(client.id)}
                aria-label={'Supprimer le vendeur'}
                color={'red.500'}
              />
            }
            onClick={() => {}}
            onUpdate={(client) => {
              const index = sellers.findIndex((c) => c.id === client.id);
              clients[index] = client;
              setSellers([...clients]);
            }}
          />
        ))}

        <Flex align={'center'} justify={'center'} w={'full'} bg={bg} borderRadius={'lg'} p={2}>
          <Button
            size={'sm'}
            leftIcon={PlusIcon}
            onClick={() => {
              setType('seller');
              disclosure.onOpen();
            }}
          >
            Ajouter un vendeur
          </Button>
        </Flex>

        <TextInput
          type={'hidden'}
          name={'seller_id'}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
        />
      </Section>
      <Section
        size={'xs'}
        title={'Sélectionner les acquéreurs'}
        description={'Les acquéreurs font référence à vos clients acheteurs faisant l\'offre.'}
        mb={4}
      >
        {buyers.length === 0 && (
          <Box p={4} textAlign={'center'} color={'muted'}>
            Aucun client sélectionné
          </Box>
        )}

        {buyers.map((client, index) => (
          <MBProCrmClientsCardComponent
            key={"buyers-" + index}
            client={client}
            hiddenEditFields={[
              'marital_status',
              'nb_of_childrens',
              'father_status',
              'mother_status',
              'is_first_time',
              'is_family',
              'is_investor',
              'is_owner',
              'is_renter',
              'comment',
            ]}
            rightIcon={
              <IconButton
                variant={'ghost'}
                icon={TrashIcon}
                onClick={() => _deleteBuyer(client.id)}
                aria-label={'Supprimer l\'acquéreur'}
                color={'red.500'}
              />
            }
            onClick={() => {}}
            onUpdate={(client) => {
              const index = buyers.findIndex((c) => c.id === client.id);
              clients[index] = client;
              setBuyers([...clients]);
            }}
          />
        ))}

        <Flex align={'center'} justify={'center'} w={'full'} bg={bg} borderRadius={'lg'} p={2}>
          <Button
            size={'sm'}
            leftIcon={PlusIcon}
            onClick={() => {
              setType('buyer');
              disclosure.onOpen();
            }}
          >
            Sélectionner l'acquéreur
          </Button>
        </Flex>

        <TextInput
          type={'hidden'}
          name={'buyer_id'}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
        />
      </Section>
      <Spacer my={3} />
      <Section
        size={'xs'}
        title={'Prix et Financement'}
        description={
          'Définissez le financement pour ce mandat, vous pouvez saisir soit un pourcentage, soit un montant fixe.'
        }
      >
        <PriceInput
          label={"Montant de l'offre d'achat"}
          name={'price_public'}
          helperText={"Saisissez le montant de l'offre d'achat de votre client"}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired={true}
        />
        <Spacer my={3} />
        <SelectButtonInput
          label={'Est-ce que le client a besoin d’un emprunt ?'}
          name={'is_loan'}
          options={[
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' },
          ]}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired={true}
        />
        <Spacer my={3} />
        <Fade in={formik.values.is_loan} unmountOnExit>
          <PriceInput
            label={'Apport immobilier'}
            name={'price_contribution_loan'}
            helperText={'Saisissez le montant de l’apport de votre client'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
          <Spacer my={2} />
          <PriceInput
            label={'Emprunt immobilier'}
            name={'price_loan'}
            helperText={'Saisissez le montant de l’emprunt immobilier'}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
            isRequired={true}
          />
        </Fade>
      </Section>

      <Divider my={3} />

      <Section
        size={'xs'}
        title={'Conditions de l\'offre'}
        description={
          'Définissez les conditions de l\'offre d\'achat, la date d\'expiration ainsi que la ville de signature.'
        }
      >
      </Section>

      <SimpleGrid columns={[1, 2]} spacing={3}>
        <DateInput
          label={"Date d'expiration de l'offre"}
          name={'date_max_offer'}
          minDate={moment().format('DD/MM/YYYY')}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired={true}
        />
        <TextInput
          label={'Lieu de signature'}
          name={'signature_place'}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          isRequired={true}
        />
      </SimpleGrid>
      <Spacer my={3} />

      <MBProCrmClientsContactSelectModalComponent
        disclosure={disclosure}
        hiddenEditFields={[
          'marital_status',
          'nb_of_childrens',
          'father_status',
          'mother_status',
          'is_first_time',
          'is_family',
          'is_investor',
          'is_owner',
          'is_renter',
          'comment',
        ]}
        rules={[
          'first_name',
          'name',
          'email',
          'phone',
          'address',
          'postal_code',
          'city',
          'country',
          'profile.date_of_birth',
          'profile.birth_city',
        ]}
        onChooseContact={(client) => {
          if (type === 'seller') {
            _selectSeller(client);
          } else {
            _selectBuyer(client);
          }
          disclosure.onClose();
        }}
        isDelegation={false}
      />
    </Box>
  );
}
