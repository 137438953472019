import React, { useEffect, useState } from 'react';

import { MBNeoPropertyTypesAPI } from '@meilleursbiens/api';
import { MBNeoPropertiesTypesType, MBProMandate, MBProMandateCreateRequest } from '@meilleursbiens/types';
import {
  AddressInputComponent,
  Card,
  LoadingPage,
  NumberInput,
  SelectInput,
  SwitchInput,
  TextAreaInput,
  TextInput,
  fadeInRight,
} from '@meilleursbiens/ui';
import { MandateUtils } from '@meilleursbiens/utils';

import { Box, Divider, SimpleGrid, Spacer } from '@chakra-ui/react';

import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';

interface MBProMandatesCreateFormPropertyDetailsComponentProps {
  mandate: MBProMandateCreateRequest;
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  mandateDatas?: MBProMandate;
}

const ValidationSchemaSell = Yup.object().shape({
  property_type_id: Yup.number().required('Le type de bien est requis !'),
  area: Yup.number().required('La surface est requise !'),
  address: Yup.string().required("L'adresse est requise !"),
  city: Yup.string().required('La ville est requise !'),
  postal_code: Yup.string().required('Le code postal est requis !'),
  country: Yup.string().required('Le pays est requis !'),
  occupation: Yup.number().required("L'occupation du bien est requise !"),
});

const ValidationSchemaRent = Yup.object().shape({
  property_type_id: Yup.number().required('Le type de bien est requis !'),
  area: Yup.number().required('La surface est requise !'),
  address: Yup.string().required("L'adresse est requise !"),
  city: Yup.string().required('La ville est requise !'),
  postal_code: Yup.string().required('Le code postal est requis !'),
  country: Yup.string().required('Le pays est requis !'),
});

const ValidationSchemaSearch = Yup.object().shape({
  property_type_id: Yup.number().required('Le type de bien est requis !'),
  search_locations: Yup.string().required('La localisation est requise !'),
  occupation: Yup.number().required("L'occupation du bien est requise !"),
});

const ValidationSchemaDelegation = Yup.object().shape({
  property_type_id: Yup.number().required('Le type de bien est requis !'),
  area: Yup.number().required('La surface est requise !'),
  address: Yup.string().required("L'adresse est requise !"),
  city: Yup.string().required('La ville est requise !'),
  postal_code: Yup.string().required('Le code postal est requis !'),
  country: Yup.string().required('Le pays est requis !'),
  occupation: Yup.number().required("L'occupation du bien est requise !"),
});

const INITIAL_VALUES_SELL = {
  occupation: 0,
};

const INITIAL_VALUES_DELEGATION = {
  occupation: 0,
  delegation_approval: "total pour le ou les clients que l'agence proposera",
  delegation_diffusion: 'autorise à diffuser le bien sur tous sites et passerelles Internet, à sa convenance',
};

export default function (props: MBProMandatesCreateFormPropertyDetailsComponentProps) {
  const { mandate, isEdit, mandateDatas } = props;

  const INITIAL_VALUES_EDIT = {
    ...mandateDatas,
    address_search: `${mandateDatas?.address}, ${mandateDatas?.postal_code} ${mandateDatas?.city}, ${mandateDatas?.country}`,
  } as FormikValues;

  const INITIAL_VALUES_CREATE = {
    property_type_id: 1,
    ...mandate,
    ...(mandate.is_sell ? INITIAL_VALUES_SELL : {}),
    ...(mandate.is_delegation ? INITIAL_VALUES_DELEGATION : {}),
    address_search:
      mandate.address && mandate.city && mandate.postal_code && mandate.country
        ? `${mandate?.address}, ${mandate?.postal_code} ${mandate?.city}, ${mandate?.country}`
        : '',
  } as FormikValues;

  const [isLoading, setIsLoading] = useState(false);
  const [types, setTypes] = useState<MBNeoPropertiesTypesType[]>([]);

  const _getValidationSchema = () => {
    if (mandate.is_sell) {
      return ValidationSchemaSell;
    } else if (mandate.is_rent) {
      return ValidationSchemaRent;
    } else if (mandate.is_delegation) {
      return ValidationSchemaDelegation;
    } else {
      return ValidationSchemaSearch;
    }
  };

  const formik = useFormik({
    initialValues: isEdit ? INITIAL_VALUES_EDIT : INITIAL_VALUES_CREATE,
    validationSchema: _getValidationSchema,
    onSubmit: (values) => {
      props.onSubmit(values);
    },
  });

  const _fetchApi = () => {
    MBNeoPropertyTypesAPI.getAll()
      .then((r) => {
        // Order by alphabetical order
        setTypes(r.types.sort((a, b) => a.label.localeCompare(b.label)));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    _fetchApi();

    formik.setValues(isEdit ? INITIAL_VALUES_EDIT : INITIAL_VALUES_CREATE);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Box as={'form'} id={'form-mandate'} onSubmit={formik.handleSubmit} animation={`${fadeInRight} 0.2s ease`}>
      <SelectInput
        label={'Choisissez le type de bien concerné ?'}
        name={'property_type_id'}
        options={types.map((mandateType) => {
          return {
            value: mandateType.id,
            label: mandateType.label,
          };
        })}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      {mandate.is_sell && <SellInputs formik={formik} isEdit={isEdit} mandate={mandate} />}

      {mandate.is_rent && <SellInputs formik={formik} isEdit={isEdit} mandate={mandate} />}

      {mandate.is_search && <SearchInputs formik={formik} />}

      {mandate.is_delegation && (
        <>
          <SellInputs formik={formik} isEdit={isEdit} mandate={mandate} />
          <DelegationInputs formik={formik} />
        </>
      )}
    </Box>
  );
}

function SellInputs({
  formik,
  isEdit,
  mandate,
}: {
  formik: any;
  isEdit?: boolean;
  mandate: MBProMandateCreateRequest;
}) {
  return (
    <>
      <Divider my={4} />
      <SimpleGrid columns={[1, 2]} spacing={4} my={4}>
        <NumberInput
          label={'Surface habitable'}
          placeholder={'ex: 100'}
          unity={'m²'}
          name={'area'}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
          isRequired
        />
        <NumberInput
          label={'Surface du terrain'}
          placeholder={'ex: 100'}
          unity={'m²'}
          name={'area_land'}
          errors={formik.errors}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          values={formik.values}
        />
      </SimpleGrid>
      <Card p={3} my={4}>
        <SwitchInput
          label={'La mesure a-t-elle été faite ?'}
          name={'is_measurement_done'}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
        />
      </Card>
      <Divider my={4} />

      <SimpleGrid columns={[1, 2]} spacing={4} my={4}>
        <NumberInput
          label={'Nombre de pièces'}
          name={'rooms'}
          placeholder={'4'}
          unity={'pièces'}
          errors={formik.errors}
          values={formik.values}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
        <NumberInput
          label={'Nombre de chambres'}
          name={'bedrooms'}
          placeholder={'2'}
          unity={'chambres'}
          errors={formik.errors}
          values={formik.values}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={formik.handleChange}
        />
      </SimpleGrid>

      <Divider my={4} />

      <TextInput
        label={'Référence cadastrale'}
        name={'ref_cadastre'}
        placeholder={'ex: AR109'}
        helperText={"Pour trouver la référence cadastrale rendez-vous sur https://cadastre.gouv.fr"}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Divider my={4} />

      <AddressInputComponent
        autofocus={true}
        formik={formik}
        mapInputName={{
          address: 'address',
          city: 'city',
          zip: 'postal_code',
          department: 'department',
          region: 'region',
          country: 'country',
          district: 'district',
          latitude: 'lat',
          longitude: 'lon',
        }}
        isAccurate={false}
        isMapHidden
      />

      <Divider my={4} />

      {!mandate.is_rent && (
        <>
          <SelectInput
            label={'Occupation du bien'}
            name={'occupation'}
            options={MandateUtils.getMandateOccupations()}
            helperText={"Ces informations seront visibles sur le mandat et permet de détailler l'occupation du bien"}
            errors={formik.errors}
            values={formik.values}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            isRequired
          />

          <Divider my={4} />
        </>
      )}

      <TextAreaInput
        label={'Détails additionnels concernant le bien'}
        name={'details'}
        placeholder={
          "ex: L'appartement est situé au 3ème étage avec ascenseur, il est pourvu d'une cuisine équipée et d'une salle de bain"
        }
        helperText={'Ces informations seront visibles sur le mandat et permettent de détailler le bien'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Spacer my={4} />
    </>
  );
}

function SearchInputs({ formik }: { formik: any }) {
  return (
    <>
      <Spacer my={4} />

      <TextAreaInput
        label={'Localisations de la recherche'}
        name={'search_locations'}
        placeholder={
          'ex: Paris 17ème, Neuilly-sur-Seine, Levallois-Perret, Boulogne-Billancourt, Saint-Cloud, Suresnes, Puteaux, Courbevoie'
        }
        helperText={'Les locations permettent de définir le périmètre de recherche'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        isRequired
      />

      <Spacer my={4} />

      <SelectInput
        label={'Occupation du bien'}
        name={'occupation'}
        options={MandateUtils.getMandateOccupations()}
        helperText={"Ces informations seront visibles sur le mandat et permet de détailler l'occupation du bien"}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        isRequired
      />

      <Divider my={4} />

      <TextInput
        label={'Critères de prix'}
        name={'search_price'}
        placeholder={'ex: Au maximum 500 000 €'}
        helperText={'Si votre mandant a un prix minimum / maximum souhaitée'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Divider my={4} />

      <TextInput
        label={'Critères de surface habitable'}
        name={'search_area'}
        placeholder={'ex: A partir de 45m²'}
        helperText={'Si votre mandant a une surface habitable minimum / maximum souhaitée'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Spacer my={4} />

      <TextInput
        label={'Critères de surface de terrain'}
        name={'search_area_land'}
        placeholder={'ex: A partir de 1000m²'}
        helperText={'Si votre mandant a une surface de terrain minimum / maximum souhaitée'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Divider my={4} />

      <TextInput
        label={'Critères du nombre de pièces'}
        name={'search_rooms'}
        placeholder={'ex: 3 pièces minimum'}
        helperText={'Si votre mandant a un nombre de pièces minimum / maximum souhaitée'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Spacer my={4} />

      <TextInput
        label={'Critères du nombre de chambres'}
        name={'search_bedrooms'}
        placeholder={'ex: 2 chambres'}
        helperText={'Si votre mandant a un nombre de chambre minimum / maximum souhaité'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Divider my={4} />

      <TextAreaInput
        label={'Critères supplémentaires'}
        name={'search_details'}
        placeholder={
          'ex: Souhaite une cave et une place de parking, proche des transports en commun, proche des écoles'
        }
        helperText={'Ces informations permettent de détailler les critères de recherche de votre mandant'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Card p={3} my={4}>
        <SwitchInput
          label={'Le mandant souhaite-il faire des travaux ?'}
          name={'search_is_work_needed'}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
        />
      </Card>

      <Divider my={4} />

      <TextInput
        label={'Critères de date de disponibilité'}
        name={'search_date'}
        placeholder={'ex: Souhaite emménager avant le 31/12/2025'}
        helperText={'Si votre mandant a une date de disponibilité souhaitée'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
      />

      <Spacer my={4} />
    </>
  );
}

function DelegationInputs({ formik }: { formik: any }) {
  return (
    <>
      <Divider my={4} />

      <NumberInput
        label={"Numéro de mandat externe (chez l'agence partenaire)"}
        name={'external_id'}
        helperText={"Précisez le numéro de mandat dans l'agence partenaire, attention à ne pas faire d'erreur"}
        helperTextProps={{
          color: 'red.500',
        }}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        isRequired
      />

      <Spacer my={4} />

      <SelectInput
        label={'Cet accord inter-agences est ?'}
        name={'delegation_approval'}
        options={[
          { value: 'partiel', label: 'Partiel' },
          {
            value: "total pour le ou les clients que l'agence proposera",
            label: "Total pour le ou les clients que l'agence proposera",
          },
        ]}
        helperText={'Choisissez le type de délégation que vous souhaitez mettre en place'}
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        isRequired
      />

      <Spacer my={4} />

      <SelectInput
        label={'Autorisation de diffusion ?'}
        name={'delegation_diffusion'}
        options={[
          {
            value: 'autorise à diffuser le bien sur tous sites et passerelles Internet, à sa convenance',
            label: 'Autorise à diffuser le bien sur tous sites et passerelles Internet, à sa convenance',
          },
          {
            value: "n'autorise pas à diffuser le bien sur tous sites et passerelles Internet, à sa convenance",
            label: "N'autorise pas à diffuser le bien sur tous sites et passerelles Internet, à sa convenance",
          },
        ]}
        helperText={
          'Choisissez si vous autorisez la diffusion du bien sur tous les sites et passerelles de diffusions (ex: SeLoger, LeBonCoin, etc.)'
        }
        errors={formik.errors}
        values={formik.values}
        touched={formik.touched}
        handleBlur={formik.handleBlur}
        handleChange={formik.handleChange}
        isRequired
      />

      <Spacer my={4} />
    </>
  );
}
