import React, { useEffect, useState } from 'react';

import { MBProClient, MBProMandate, MBProMandateCreateRequest } from '@meilleursbiens/types';
import { SelectInput, TextAreaInput, fadeInRight } from '@meilleursbiens/ui';

import { Box, Divider } from '@chakra-ui/react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

interface MBProMandatesEditFormResiliateComponentProps {
  onSubmit: (values: any) => void;
  isEdit?: boolean;
  mandateDatas?: MBProMandate;
}

const resiliateReasons = [
  { id: 0, label: 'Mandat non retourné' },
  { id: 1, label: 'Changement de projet' },
  { id: 2, label: 'Prix jugé trop bas' },
  { id: 3, label: 'Mauvaise relation avec l’agent' },
  { id: 4, label: 'Vente directe par le vendeur' },
  { id: 5, label: 'Non-respect des obligations légales' },
  { id: 6, label: 'Refus de modification du mandat' },
  { id: 7, label: 'Manque de résultats' },
  { id: 8, label: 'Mauvaise visibilité de l’annonce' },
  { id: 9, label: 'Faible engagement de l’agent' },
  { id: 10, label: 'Absence d’efforts pour négocier' },
  { id: 11, label: 'Stratégie de vente jugée inefficace' },
  { id: 12, label: 'Fin de la période d’irrévocabilité' },
  { id: 13, label: 'Mandat non conforme' },
  { id: 14, label: 'Double mandat' },
  { id: 15, label: 'Problème de marché' },
  { id: 16, label: 'Problèmes juridiques' },
  { id: 17, label: 'Retard dans les diagnostics' },
  { id: 18, label: 'Problèmes personnels' },
  { id: 19, label: 'Autre' },
];

const ValidationSchema = Yup.object().shape({
  resiliate_reason_id: Yup.number().required('Veuillez spécifier la raison de clôture du mandat.'),
  resiliate_reason_other: Yup.string().when('resiliate_reason_id', {
    is: 19,
    then: Yup.string()
      .required('Veuillez spécifier la raison de clôture du mandat.')
      .min(3, 'La raison doit contenir au moins 3 caractères.'),
    otherwise: Yup.string(),
  }),
});

export default function MBProMandatesEditFormResiliateComponent(props: MBProMandatesEditFormResiliateComponentProps) {
  const { mandateDatas } = props;

  const formik = useFormik({
    initialValues: { resiliate_reason_id: 1, resiliate_reason_other: '' },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      props.onSubmit({
        resiliate_reason: currentReason,
        resiliate_reason_other: values.resiliate_reason_other,
      });
    },
  });

  const [currentReason, setCurrentReason] = useState(resiliateReasons[0]);

  useEffect(() => {
    setCurrentReason((prev) => ({ ...prev, label: formik.values.resiliate_reason_other }));
  }, [formik.values.resiliate_reason_other]);

  return (
    <Box as={'form'} id={'form-mandate'} onSubmit={formik.handleSubmit} animation={`${fadeInRight} 0.2s ease`}>
      <SelectInput
        label={'Raison de clôture du mandat'}
        name={'resiliate_reason_id'}
        options={resiliateReasons.map((reason) => {
          return {
            value: reason.id,
            label: reason.label,
          };
        })}
        handleChange={(e) => {
          formik.handleChange(e);
          setCurrentReason({
            id: parseInt(e.target.value),
            label: resiliateReasons.find((r) => r.id === parseInt(e.target.value))?.label || '',
          });
        }}
        handleBlur={formik.handleBlur}
        values={formik.values}
        errors={formik.errors}
        touched={formik.touched}
        isRequired
      />

      {formik.values.resiliate_reason_id === 19 && (
        <>
          <Divider my={4} />

          <TextAreaInput
            label={'Autre raison'}
            name={'resiliate_reason_other'}
            placeholder={'Veuillez préciser la raison de clôture du mandat.'}
            errors={formik.errors}
            values={formik.values}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
            isRequired
          />
        </>
      )}
    </Box>
  );
}
