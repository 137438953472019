import { MBNeoPropertiesDetailsType, MBNeoPropertiesType, MBNeoPropertiesTypesType } from '@meilleursbiens/types';

import MBApi from '../../MBApi';

export default class MBNeoPropertyTypesAPI {
  static listForType(level: number, parent_id: number | null = null) {
    return MBApi.url('/v1/neo/schema/types/' + level + (parent_id ? '/' + parent_id : ''))
      .needAuth()
      .get<{
        types: MBNeoPropertiesTypesType[];
      }>();
  }

  static get(id: number) {
    return MBApi.url('/v1/neo/schema/type/' + id)
      .needAuth()
      .get<{
        type: MBNeoPropertiesTypesType;
      }>();
  }

  static getAll() {
    return MBApi.url('/v1/neo/schema/types').needAuth().needCache(60).get<{
      types: MBNeoPropertiesTypesType[];
    }>();
  }
}
