import React from 'react';

import { Select } from 'chakra-react-select';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';

import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  SelectProps,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

export interface SelectInputProps extends SelectProps {
  label?: string;
  name: string;
  placeholder?: string;
  helperText?: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;
  values: FormikValues;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  isHorizontal?: boolean;
  isRequired?: boolean;
  options: ISelectInputOption[];
}
export interface ISelectInputOption {
  label: string;
  value: string | number;
}
const SelectInput = (props: SelectInputProps) => {
  const isInvalid = props.errors[props.name] && props.touched[props.name];

  const _getValuesFromChoices = () => {
    return props.options.find((option) => option.value === props.values[props.name]);
  };

  if (props.isHorizontal)
    return (
      <SimpleGrid columns={[1, 2]} spacing={4}>
        <Box>
          {props.label && (
            <Text fontSize={13} fontWeight={500}>
              {props.label}
            </Text>
          )}
          {props.helperText && (
            <Text fontSize={12} fontWeight={400} color={'muted'}>
              {props.helperText}
            </Text>
          )}
        </Box>
        <Box>
          <Select
            autoComplete="off"
            {...props}
            size={'sm'}
            name={props.name}
            onChange={(newValue, actionMeta) => {
              props.handleChange({
                // @ts-ignore
                target: {
                  name: props.name,
                  // @ts-ignore
                  value: newValue ? newValue.value : null,
                },
              });
            }}
            value={_getValuesFromChoices()}
            placeholder={''}
            // @ts-ignore
            isInvalid={isInvalid}
          >
            <option value="" disabled>
              {props.placeholder || 'Sélectionnez une valeur'}
            </option>
            {props.options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          {props.errors[props.name] && props.touched[props.name] && (
            <Text fontSize={12} color={useColorModeValue('red.600', 'red.300')} mt={1}>
              {/* @ts-ignore */}
              {props.errors[props.name]}
            </Text>
          )}
        </Box>
      </SimpleGrid>
    );

  return (
    <FormControl>
      {props.label && (
        <FormLabel fontSize={12} fontWeight={500}>
          {props.label}
        </FormLabel>
      )}
      <Select
        autoComplete="off"
        {...props}
        size={'sm'}
        name={props.name}
        onChange={(newValue, actionMeta) => {
          props.handleChange({
            // @ts-ignore
            target: {
              name: props.name,
              // @ts-ignore
              value: newValue ? newValue.value : null,
            },
          });
        }}
        value={_getValuesFromChoices()}
        placeholder={''}
        // @ts-ignore
        isInvalid={isInvalid}
      >
        <option value="" disabled>
          {props.placeholder || 'Sélectionnez une valeur'}
        </option>
        {props.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {props.errors[props.name] && props.touched[props.name] && (
        <FormHelperText fontSize={13} color={'red.600'}>
          {/* @ts-ignore */}
          {props.errors[props.name]}
        </FormHelperText>
      )}
      {props.helperText && (!props.errors[props.name] || !props.touched[props.name]) && (
        <FormHelperText fontSize={13} color={'muted'}>
          {props.helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectInput;
