import { useEffect, useState } from 'react';

import { MBProDashboardAPI } from '@meilleursbiens/api';
import { MBProSidebarCounts } from '@meilleursbiens/types';

const defaultSidebarCounts: MBProSidebarCounts = {
  'account.estimations': 0,
  'account.diffusion': 0,
  'account.transactions': 0,
  'account.mandates': 0,
  'account.contacts': 0,
  'account.documents': 0,
  'account.clients_requests': [0, 0],
  'account.afiliations': 0,
};

export default function useSidebarCounts() {
  const [isLoading, setIsLoading] = useState(true);
  const [sidebarCounts, setSidebarCounts] = useState<MBProSidebarCounts>(defaultSidebarCounts);

  useEffect(() => {
    _fetchSidebarCounts();
  }, []);

  const _fetchSidebarCounts = async () => {
    setIsLoading(true);
    MBProDashboardAPI.getSidebarCounts()
      .then((sidebarCounts) => {
        setSidebarCounts(sidebarCounts);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    sidebarCounts,
    refetch: _fetchSidebarCounts,
  };
}
