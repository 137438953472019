import React from 'react';

import { Button, SelectProps, Wrap, WrapItem } from '@chakra-ui/react';

import { FormikErrors, FormikTouched, FormikValues } from 'formik';

import TextInput from '../TextInput/TextInput';

export interface SelectInputProps extends SelectProps {
  label: string;
  name: string;
  placeholder?: string;
  helperText?: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLSelectElement>) => void;
  values: FormikValues;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  isHorizontal?: boolean;
  isRequired?: boolean;
  options: ISelectInputOption[];
}

export interface ISelectInputOption {
  label: string;
  value: any;
}

const SelectButtonInput = (props: SelectInputProps) => {
  const isInvalid = props.errors[props.name] && props.touched[props.name];

  return (
    <TextInput
      type="hidden"
      customInput={
        <Wrap my={1}>
          {props.options.map((option, index) => {
            return (
              <WrapItem>
                <Button
                  key={props.name + '-choice-' + index}
                  size={'sm'}
                  variant={props.values[props.name] == option.value ? 'primary' : 'outline'}
                  value={option.value}
                  onClick={() => {
                    props.handleChange({
                      target: {
                        name: props.name,
                        value: option.value,
                      },
                    } as any);
                  }}
                >
                  {option.label}
                </Button>
              </WrapItem>
            );
          })}
        </Wrap>
      }
      helperText={props.helperText || ''}
      name={props.name + '_hidden'}
      placeholder={''}
      value={props.values[props.name]}
      errors={props.errors}
      touched={props.touched}
      label={props.label}
      isHorizontal={props.isHorizontal}
      values={props.values}
      handleChange={() => {}}
      handleBlur={() => {}}
    />
  );
};

export default SelectButtonInput;
