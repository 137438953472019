import React, { useEffect } from 'react';

import { MBProUserSeenAPI } from '@meilleursbiens/api';
import { useAdvancedSessionStorage } from '@meilleursbiens/hooks';
import { MBProUserSeen } from '@meilleursbiens/types';

import { Modal, ModalContent, ModalOverlay, ModalProps, useDisclosure } from '@chakra-ui/react';

interface AnnouncementModalProps {
  seenKey: string;
  children: string | React.ReactElement | React.ReactNode;
  modalProps?: Partial<ModalProps>;
  isSeen?: boolean;
}

interface SeenCacheStorage {
  [key: string]: MBProUserSeen;
}

export default function ({ seenKey, modalProps, isSeen, children }: AnnouncementModalProps) {
  const disclosure = useDisclosure();

  const [seenModels, setSeenModels, updateOneSeen, getOneSeen] = useAdvancedSessionStorage<SeenCacheStorage>(
    'mb_pro_user_seens',
    {},
    60 * 5
  );

  useEffect(() => {
    _checkIfAPIFetchIsNeeded();
  }, []);

  useEffect(() => {
    if (isSeen) {
      updateOneSeen(seenKey, { has_seen: true });
      disclosure.onClose();
    }
  }, [isSeen]);

  const _checkIfAPIFetchIsNeeded = () => {
    const seen: MBProUserSeen = getOneSeen(seenKey);
    if (seen == null) return _fetchAPI();

    if (!seen.has_seen) _openModal();
  };

  const _fetchAPI = () => {
    MBProUserSeenAPI.getOne(seenKey)
      .then((r) => {
        updateOneSeen(seenKey, r);
        if (!r.has_seen) {
          _openModal();
        }
      })
      .catch((e) => {
        _openModal();
      });
  };

  const _openModal = () => {
    disclosure.onOpen();
  };

  const _closeModal = () => {
    disclosure.onClose();
    MBProUserSeenAPI.create(seenKey, true).then((r) => {
      updateOneSeen(seenKey, r);
      console.log(r);
    });
  };

  return (
    <Modal isOpen={disclosure.isOpen} onClose={_closeModal} motionPreset="slideInBottom" {...modalProps}>
      <ModalOverlay />

      <ModalContent>{children}</ModalContent>
    </Modal>
  );
}
