import React, { memo } from 'react';

import { MBProUserAfiliations } from '@meilleursbiens/types';
import { Badge, Card, Persona, TextDate } from '@meilleursbiens/ui';

import moment from 'moment/moment';

import { Handle, Position } from '@xyflow/react';
import { useColorModeValue, Text } from '@chakra-ui/react';
import { PriceUtils } from '@meilleursbiens/utils';

const defaultWidth = 190;
const defaultHeight = 50;


export default memo(({ data, isConnectable }: {isConnectable: boolean, data: {
    afiliation: MBProUserAfiliations;
    amount: number
}}) => {
  const { afiliation, amount } = data;

  const handleColor = useColorModeValue('#F7F7F7', '#262626')
  const borderColor = useColorModeValue('red.700', 'red.300')

  return (
    <>
      <Handle
        type="target"
        position={Position.Bottom}
        style={{ background: handleColor }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Top}
        style={{ background: handleColor }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Card p={2} borderRadius={'50px'} pr={3} width={defaultWidth} h={defaultHeight}>
        <Persona size={'sm'}
                 name={afiliation.sponsored?.firstName + ' ' + afiliation.sponsored?.name}
                 avatarUrl={afiliation.sponsored?.profile_picture}
                 presence={afiliation.is_active ? 'online' : "offline"}
                 secondaryText={afiliation.is_active ? 'Inscrit' : 'Résilié'}
                 secondaryTextProps={{
                   fontSize: '10px'
                 }}
                 secondaryTextRightElement={(
                   <TextDate noOfLines={1} date={moment(afiliation.is_active ? afiliation.sponsored?.start_subscription_at : afiliation.sponsored?.terminate_at).toDate()} fontSize={'10px'} color={'muted'} defaultFormat={'ago'}/>
                 )}

        />
        <Badge position={'absolute'} top={-2} right={-1} bg={"primary.500"} borderWidth={1} borderColor={borderColor} px={1} fontSize={'10px'} textColor={'white'} borderRadius={'50px'}>{PriceUtils.formatPrice(amount)}</Badge>
      </Card>
    </>
  );
});
