import { createElement, useEffect, useState } from 'react';

import { Box, Flex, useColorModeValue, Text, Progress } from '@chakra-ui/react';

import { MBProUserAfiliationsUtils, MBProUsersAfiliationsRank } from '../index';
import { ColorUtils } from '@meilleursbiens/utils';
import { Link } from 'react-router-dom';
import { fadeIn } from '@meilleursbiens/ui';
import { BoxProps } from '@chakra-ui/icons';

interface MBProUsersAfiliationsBannerComponentProps {
  value: number;
  boxProps?: BoxProps;
  iconProps?: any;
}

export default function(props: MBProUsersAfiliationsBannerComponentProps) {

  const { value, boxProps, iconProps } = props;

  const [rank, setRank] = useState<MBProUsersAfiliationsRank>(MBProUserAfiliationsUtils.getRankByValue(value));

  useEffect(() => {
    setRank(MBProUserAfiliationsUtils.getRankByValue(value));
  }, [value]);

  const percentage = (value / 15) * 100;

  const bgColor = ColorUtils.generateMeshGradientFromColor(rank.color, 1);
  const borderColor = useColorModeValue('whiteAlpha.500', 'whiteAlpha.500');
  const textColor = ColorUtils.darkenColor(rank.color, 50);
  const progressColorOne = ColorUtils.darkenColor(rank.color, 60);
  const progressColorTwo = ColorUtils.lightenColor(rank.color, 10);

  const _generateGlowShadow = (color: string) => {
    return `0 0 1px ${color}, 0 0 1px ${color}, 0 0 1px ${color}, 0 0 1px ${color}, 0 0 30px ${color}`;
  }

  const _generateHoverGlowShadow = (color: string) => {
    return `0 0 1px ${color}, 0 0 1px ${color}, 0 0 5px ${color}, 0 0 1px ${color}, 0 0 60px ${color}`;
  }

  const _getTextInfo = () => {
    const difference = 4 - value;

    if(value >= 0 && value <= 3) {
      return "Encore " + (difference) + " parrainage" + (difference > 1 ? "s" : "") + " pour l'abonnement gratuit";
    }
    if(value > 3) {
      return "Votre abonnement est gratuit !";
    }
  }

  return (
    <Box
      position={"relative"}
      animation={fadeIn + ' 0.5s ease'}
      p={1}
      w={'full'}
      bg={bgColor}
      borderRadius={'xl'}
      borderColor={borderColor}
      borderWidth={2}
      as={Link}
      to={"/auth/account?tab=1"}
      _hover={{
        boxShadow: _generateHoverGlowShadow(rank.color),
        textDecoration: 'none',
      }}
      transition={'all 0.4s ease'}
      boxShadow={_generateGlowShadow(rank.color)}
      {...boxProps}
    >
      <Flex direction={'row'} justifyContent={'flex-start'} alignItems={'center'} w={'full'}>
        {createElement(rank.icon, { height: 50, width: 50, ...iconProps })}
        <Flex px={1} w={'full'} flexDirection={'column'} justifyContent={'space-around'} alignItems={'flex-start'}>
          <Text fontSize={'8px'} letterSpacing={'0.05em'} fontWeight={600} color={textColor}>PARRAINAGE</Text>
          <Text fontSize={'xs'} fontWeight={600} color={textColor} noOfLines={1}>{rank.name}
          <small style={{paddingLeft: 5}}>({value} parrainage{value > 1 ? "s" : ""})</small>
          </Text>
          <Progress mt={1} size={'xs'}
                    w={'100%'}
                    bg={"rgba(255, 255, 255, 0.3)"}
                    sx={{
                      '& > div': {
                        background: 'linear-gradient(90deg, ' + progressColorOne + ' 10%, ' + progressColorTwo + ' 90%)',
                      },
                    }}
                    value={percentage}
                    isAnimated>

          </Progress>
          <Text fontSize={'8px'} mt={1} fontWeight={400} color={textColor} noOfLines={1}>{_getTextInfo()}</Text>
        </Flex>
      </Flex>
    </Box>
  );
}
