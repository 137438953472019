import React from 'react';

import { EllipsisHorizontalCircleIcon, FaceFrownIcon } from '@heroicons/react/24/outline';

import { ErrorPageProps } from '../ErrorPage/ErrorPage';
import { ErrorPage } from '../index';

export interface NoContentPageProps extends ErrorPageProps {}

const NoContentPage = (props: NoContentPageProps) => {
  return (
    <ErrorPage
      title={'Aucun contenu'}
      description={'Aucun contenu à afficher pour le moment'}
      icon={<EllipsisHorizontalCircleIcon width={50} />}
      {...props}
    />
  );
};

export default NoContentPage;
