import React, { useEffect, useMemo, useRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Map, Marker, NavigationControl } from 'react-map-gl';

import { CountriesConstant, ServicesConstant } from '@meilleursbiens/constants';

import { Box, ButtonGroup, Collapse, FormLabel, SimpleGrid, Spacer, Text } from '@chakra-ui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { PencilSquareIcon } from '@heroicons/react/24/solid';

import { FormikProps } from 'formik';

import { Button, Section } from '../../../index';
import { AutocompleteInputs, TextInput } from '../index';

interface MapInputName {
  address: string;
  city: string;
  zip: string;
  department: string;
  region: string;
  country: string;
  district: string;
  latitude: string;
  longitude: string;
}

export interface AddressInputComponentProps {
  formik: FormikProps<any>;
  disableManual?: boolean;
  isAccurate?: boolean;
  mapInputName?: MapInputName;
  autofocus?: boolean;
  isVisible?: boolean;
  isMapHidden?: boolean;
  initialAddress?: string;
}

const DEFAULT_MAP_INPUT_NAME: MapInputName = {
  address: 'street',
  city: 'city',
  zip: 'zip',
  department: 'department',
  region: 'region',
  country: 'country',
  district: 'district',
  latitude: 'latitude',
  longitude: 'longitude',
};

function AddressInputComponent(props: AddressInputComponentProps) {
  const { placesService, placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: ServicesConstant.GOOGLE.API_KEY,
  });

  const mapRef = useRef(null);

  const formik = props.formik;

  const mapInputName = props.mapInputName || DEFAULT_MAP_INPUT_NAME;

  const [address, setAddress] = React.useState('');

  const countries = useMemo(() => {
    return CountriesConstant.map((country) => country[0]);
  }, []);

  const isAccurate = props.isAccurate === undefined ? true : props.isAccurate;

  const formatAddress = (place: any) => {
    setAddress(place.formatted_address);

    const address_components =
      // @ts-ignore
      place.address_components as google.maps.GeocoderAddressComponent[];

    let street = '';
    address_components.forEach((address_component) => {
      if (address_component.types.includes('street_number')) {
        formik.setFieldValue(mapInputName['address'], address_component.long_name);
        street = address_component.long_name + ' ';
      }
      if (address_component.types.includes('route')) {
        formik.setFieldValue(mapInputName['address'], address_component.long_name);
        street += address_component.long_name;
      }
      if (address_component.types.includes('postal_code')) {
        formik.setFieldValue(mapInputName['zip'], address_component.long_name);
      }
      if (address_component.types.includes('locality')) {
        formik.setFieldValue(mapInputName['city'], address_component.long_name);
      }
      if (address_component.types.includes('administrative_area_level_2')) {
        formik.setFieldValue(mapInputName['department'], address_component.long_name);
      }
      if (address_component.types.includes('administrative_area_level_1')) {
        formik.setFieldValue(mapInputName['region'], address_component.long_name);
      }
      if (address_component.types.includes('country')) {
        formik.setFieldValue(mapInputName['country'], address_component.long_name);
      }
    });

    formik.setFieldValue(mapInputName['address'], street);

    const lat = place.geometry.location.lat();
    const lon = place.geometry.location.lng();
    formik.setFieldValue(mapInputName['latitude'], lat);
    formik.setFieldValue(mapInputName['longitude'], lon);

    if (mapRef.current)
      // @ts-ignore
      mapRef.current.flyTo({
        center: [lon, lat],
        zoom: 16,
        speed: 4,
      });
    setIsOpen(true);
  };

  const { ref } = usePlacesWidget({
    apiKey: ServicesConstant.GOOGLE.API_KEY,
    onPlaceSelected: (place) => {
      formatAddress(place);
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: ['fr', 'gp', 'mq', 're'] },
    },
  });

  useEffect(() => {
    if (props.autofocus) {
      if (props.isVisible) {
        console.log('Focus input');
        // @ts-ignore
        ref.current.focus();
      }
    }
  }, [props.isVisible]);

  useEffect(() => {
    formik.values['address_search'] && setAddress(formik.values['address_search']);
  }, [formik]);

  useEffect(() => {
    if (placePredictions.length)
      placesService?.getDetails(
        {
          placeId: placePredictions[0].place_id,
        },
        (place: any) => {
          formik.setFieldValue('address_search', place.formatted_address);
          formatAddress(place);
        }
      );
  }, [placePredictions]);

  useEffect(() => {
    if (props.initialAddress !== null) {
      getPlacePredictions({ input: props.initialAddress });
    }
  }, [props.initialAddress]);

  const [isOpen, setIsOpen] = React.useState(false);

  const hasMarker =
    formik.values[mapInputName.latitude] &&
    formik.values[mapInputName.longitude] &&
    !isNaN(formik.values[mapInputName.latitude]) &&
    !isNaN(formik.values[mapInputName.longitude]);

  return (
    <Section
      size={'sm'}
      description={
        'Commencez par rechercher une adresse, si vous ne trouvez pas votre adresse, choisissez une adresse à proximité et glissez le marqueur sur la carte pour préciser la localisation précise du bien'
      }
      {...(!props.disableManual && {
        actions: (
          <ButtonGroup>
            {isOpen && (
              <Button size={'sm'} leftIcon={MagnifyingGlassIcon} onClick={() => setIsOpen(false)}>
                Rechercher une adresse
              </Button>
            )}
            {!isOpen && !props.disableManual ? (
              <Button size={'sm'} leftIcon={PencilSquareIcon} onClick={() => setIsOpen(true)}>
                Entrer une adresse manuellement
              </Button>
            ) : (
              <></>
            )}
          </ButtonGroup>
        ),
      })}
    >
      <Spacer mt={2} mb={2} />
      <Collapse in={!isOpen}>
        <TextInput
          // @ts-ignore
          ref={ref}
          label={'Rerchercher une adresse'}
          name={'address_search'}
          placeholder={'ex: 34 Rue de la Paix'}
          helperText={'Commencez à taper pour rechercher une adresse'}
          errors={formik.errors}
          values={{
            address_search: address,
          }}
          touched={formik.touched}
          handleBlur={formik.handleBlur}
          handleChange={(e) => setAddress(e.target.value)}
        />
      </Collapse>
      <Collapse in={isOpen}>
        <SimpleGrid columns={[1]} spacing={3} mb={3}>
          <TextInput
            label={'Adresse'}
            name={mapInputName['address']}
            isRequired={true}
            placeholder={'ex: 2 rue de la Paix'}
            errors={formik.errors}
            values={formik.values}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </SimpleGrid>
        <SimpleGrid columns={[1, 2]} spacing={3} mb={3}>
          <TextInput
            label={'Code postal'}
            name={mapInputName['zip']}
            isRequired={true}
            placeholder={'ex: 75002'}
            errors={formik.errors}
            values={formik.values}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
          <TextInput
            label={'Ville'}
            name={mapInputName['city']}
            isRequired={true}
            placeholder={'ex: Paris'}
            errors={formik.errors}
            values={formik.values}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </SimpleGrid>
        {isAccurate && (
          <SimpleGrid columns={[1]} spacing={3} mb={3}>
            <TextInput
              label={'Quartier'}
              name={mapInputName['district']}
              isRequired={false}
              helperText={'Le quartier est optionnel, il permet de mieux cibler la localisation'}
              placeholder={'ex: Monceau'}
              errors={formik.errors}
              values={formik.values}
              touched={formik.touched}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </SimpleGrid>
        )}
        {isAccurate && (
          <SimpleGrid columns={[1, 2]} spacing={3} mb={3}>
            <AutocompleteInputs
              label={'Département'}
              name={mapInputName['department']}
              isRequired={false}
              choices={DEPARTEMENTS_ARRAY()}
              placeholder={'Commencez à taper pour rechercher...'}
              errors={formik.errors}
              values={formik.values}
              touched={formik.touched}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
            <AutocompleteInputs
              label={'Région'}
              isRequired={false}
              name={mapInputName['region']}
              choices={REGIONS_ARRAY()}
              placeholder={'Commencez à taper pour rechercher...'}
              errors={formik.errors}
              values={formik.values}
              touched={formik.touched}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </SimpleGrid>
        )}
        <SimpleGrid columns={[1]} spacing={3} mb={3}>
          <AutocompleteInputs
            label={'Pays'}
            name={mapInputName['country']}
            isRequired={true}
            choices={countries}
            placeholder={'Commencez à taper pour rechercher...'}
            errors={formik.errors}
            values={formik.values}
            touched={formik.touched}
            handleBlur={formik.handleBlur}
            handleChange={formik.handleChange}
          />
        </SimpleGrid>
        {!props.isMapHidden && (
          <SimpleGrid>
            <FormLabel fontSize={12} fontWeight={500} mt={3} style={{ marginBottom: 0 }}>
              Carte
            </FormLabel>
            <Text fontSize={12} color={'muted'} style={{ marginBottom: 10 }}>
              Glissez le marqueur sur la carte pour choisir la localisation précise
            </Text>
            <Box w={'100%'} h={'200px'} borderRadius={'md'} position={'relative'}>
              <Map
                ref={mapRef}
                initialViewState={{
                  longitude: formik.values[mapInputName.longitude],
                  latitude: formik.values[mapInputName.latitude],
                  zoom: 8,
                }}
                onDrag={(event) => {
                  formik.setFieldValue(mapInputName.longitude, event.viewState.longitude);
                  formik.setFieldValue(mapInputName.latitude, event.viewState.latitude);
                }}
                mapStyle="mapbox://styles/mapbox/standard-beta"
                mapboxAccessToken={ServicesConstant.MAPBOX.PUBLIC_TOKEN}
                style={{ width: '100%', height: '100%', borderRadius: 8 }}
              >
                {hasMarker && (
                  <Marker
                    longitude={formik.values[mapInputName.longitude]}
                    latitude={formik.values[mapInputName.latitude]}
                    anchor="bottom"
                    draggable
                    onDragEnd={(event) => {
                      formik.setFieldValue(mapInputName.longitude, event.lngLat.lng);
                      formik.setFieldValue(mapInputName.latitude, event.lngLat.lat);
                    }}
                  >
                    <Pin size={20} />
                  </Marker>
                )}

                <NavigationControl />
              </Map>
            </Box>
          </SimpleGrid>
        )}
      </Collapse>
    </Section>
  );
}

export default AddressInputComponent;

interface Departement {
  label: string;
  value: string;
}

const DEPARTEMENT_LIST: Departement[] = [
  { label: '01 - Ain', value: 'Ain' },
  { label: '02 - Aisne', value: 'Aisne' },
  { label: '03 - Allier', value: 'Allier' },
  { label: '04 - Alpes-de-Haute-Provence', value: 'Alpes-de-Haute-Provence' },
  { label: '05 - Hautes-Alpes', value: 'Hautes-Alpes' },
  { label: '06 - Alpes-Maritimes', value: 'Alpes-Maritimes' },
  { label: '07 - Ardèche', value: 'Ardèche' },
  { label: '08 - Ardennes', value: 'Ardennes' },
  { label: '09 - Ariège', value: 'Ariège' },
  { label: '10 - Aube', value: 'Aube' },
  { label: '11 - Aude', value: 'Aude' },
  { label: '12 - Aveyron', value: 'Aveyron' },
  { label: '13 - Bouches-du-Rhône', value: 'Bouches-du-Rhône' },
  { label: '14 - Calvados', value: 'Calvados' },
  { label: '15 - Cantal', value: 'Cantal' },
  { label: '16 - Charente', value: 'Charente' },
  { label: '17 - Charente-Maritime', value: 'Charente-Maritime' },
  { label: '18 - Cher', value: 'Cher' },
  { label: '19 - Corrèze', value: 'Corrèze' },
  { label: '2A - Corse-du-Sud', value: 'Corse-du-Sud' },
  { label: '2B - Haute-Corse', value: 'Haute-Corse' },
  { label: "21 - Côte-d'Or", value: "Côte-d'Or" },
  { label: "22 - Côtes-d'Armor", value: "Côtes-d'Armor" },
  { label: '23 - Creuse', value: 'Creuse' },
  { label: '24 - Dordogne', value: 'Dordogne' },
  { label: '25 - Doubs', value: 'Doubs' },
  { label: '26 - Drôme', value: 'Drôme' },
  { label: '27 - Eure', value: 'Eure' },
  { label: '28 - Eure-et-Loir', value: 'Eure-et-Loir' },
  { label: '29 - Finistère', value: 'Finistère' },
  { label: '30 - Gard', value: 'Gard' },
  { label: '31 - Haute-Garonne', value: 'Haute-Garonne' },
  { label: '32 - Gers', value: 'Gers' },
  { label: '33 - Gironde', value: 'Gironde' },
  { label: '34 - Hérault', value: 'Hérault' },
  { label: '35 - Ille-et-Vilaine', value: 'Ille-et-Vilaine' },
  { label: '36 - Indre', value: 'Indre' },
  { label: '37 - Indre-et-Loire', value: 'Indre-et-Loire' },
  { label: '38 - Isère', value: 'Isère' },
  { label: '39 - Jura', value: 'Jura' },
  { label: '40 - Landes', value: 'Landes' },
  { label: '41 - Loir-et-Cher', value: 'Loir-et-Cher' },
  { label: '42 - Loire', value: 'Loire' },
  { label: '43 - Haute-Loire', value: 'Haute-Loire' },
  { label: '44 - Loire-Atlantique', value: 'Loire-Atlantique' },
  { label: '45 - Loiret', value: 'Loiret' },
  { label: '46 - Lot', value: 'Lot' },
  { label: '47 - Lot-et-Garonne', value: 'Lot-et-Garonne' },
  { label: '48 - Lozère', value: 'Lozère' },
  { label: '49 - Maine-et-Loire', value: 'Maine-et-Loire' },
  { label: '50 - Manche', value: 'Manche' },
  { label: '51 - Marne', value: 'Marne' },
  { label: '52 - Haute-Marne', value: 'Haute-Marne' },
  { label: '53 - Mayenne', value: 'Mayenne' },
  { label: '54 - Meurthe-et-Moselle', value: 'Meurthe-et-Moselle' },
  { label: '55 - Meuse', value: 'Meuse' },
  { label: '56 - Morbihan', value: 'Morbihan' },
  { label: '57 - Moselle', value: 'Moselle' },
  { label: '58 - Nièvre', value: 'Nièvre' },
  { label: '59 - Nord', value: 'Nord' },
  { label: '60 - Oise', value: 'Oise' },
  { label: '61 - Orne', value: 'Orne' },
  { label: '62 - Pas-de-Calais', value: 'Pas-de-Calais' },
  { label: '63 - Puy-de-Dôme', value: 'Puy-de-Dôme' },
  { label: '64 - Pyrénées-Atlantiques', value: 'Pyrénées-Atlantiques' },
  { label: '65 - Hautes-Pyrénées', value: 'Hautes-Pyrénées' },
  { label: '66 - Pyrénées-Orientales', value: 'Pyrénées-Orientales' },
  { label: '67 - Bas-Rhin', value: 'Bas-Rhin' },
  { label: '68 - Haut-Rhin', value: 'Haut-Rhin' },
  { label: '69 - Rhône', value: 'Rhône' },
  { label: '70 - Haute-Saône', value: 'Haute-Saône' },
  { label: '71 - Saône-et-Loire', value: 'Saône-et-Loire' },
  { label: '72 - Sarthe', value: 'Sarthe' },
  { label: '73 - Savoie', value: 'Savoie' },
  { label: '74 - Haute-Savoie', value: 'Haute-Savoie' },
  { label: '75 - Paris', value: 'Paris' },
  { label: '76 - Seine-Maritime', value: 'Seine-Maritime' },
  { label: '77 - Seine-et-Marne', value: 'Seine-et-Marne' },
  { label: '78 - Yvelines', value: 'Yvelines' },
  { label: '79 - Deux-Sèvres', value: 'Deux-Sèvres' },
  { label: '80 - Somme', value: 'Somme' },
  { label: '81 - Tarn', value: 'Tarn' },
  { label: '82 - Tarn-et-Garonne', value: 'Tarn-et-Garonne' },
  { label: '83 - Var', value: 'Var' },
  { label: '84 - Vaucluse', value: 'Vaucluse' },
  { label: '85 - Vendée', value: 'Vendée' },
  { label: '86 - Vienne', value: 'Vienne' },
  { label: '87 - Haute-Vienne', value: 'Haute-Vienne' },
  { label: '88 - Vosges', value: 'Vosges' },
  { label: '89 - Yonne', value: 'Yonne' },
  { label: '90 - Territoire de Belfort', value: 'Territoire de Belfort' },
  { label: '91 - Essonne', value: 'Essonne' },
  { label: '92 - Hauts-de-Seine', value: 'Hauts-de-Seine' },
  { label: '93 - Seine-Saint-Denis', value: 'Seine-Saint-Denis' },
  { label: '94 - Val-de-Marne', value: 'Val-de-Marne' },
  { label: "95 - Val-d'Oise", value: "Val-d'Oise" },
  { label: '971 - Guadeloupe', value: 'Guadeloupe' },
  { label: '972 - Martinique', value: 'Martinique' },
  { label: '973 - Guyane', value: 'Guyane' },
  { label: '974 - La Réunion', value: 'La Réunion' },
  { label: '976 - Mayotte', value: 'Mayotte' },
];

function DEPARTEMENTS_ARRAY(): string[] {
  return DEPARTEMENT_LIST.map((departement) => departement.value);
}

interface Region {
  label: string;
  value: string;
}

const REGION_LIST: Region[] = [
  { label: 'Auvergne-Rhône-Alpes', value: 'Auvergne-Rhône-Alpes' },
  { label: 'Bourgogne-Franche-Comté', value: 'Bourgogne-Franche-Comté' },
  { label: 'Bretagne', value: 'Bretagne' },
  { label: 'Centre-Val de Loire', value: 'Centre-Val de Loire' },
  { label: 'Corse', value: 'Corse' },
  { label: 'Grand Est', value: 'Grand Est' },
  { label: 'Hauts-de-France', value: 'Hauts-de-France' },
  { label: 'Île-de-France', value: 'Île-de-France' },
  { label: 'Normandie', value: 'Normandie' },
  { label: 'Nouvelle-Aquitaine', value: 'Nouvelle-Aquitaine' },
  { label: 'Occitanie', value: 'Occitanie' },
  { label: 'Pays de la Loire', value: 'Pays de la Loire' },
  { label: "Provence-Alpes-Côte d'Azur", value: "Provence-Alpes-Côte d'Azur" },
  { label: 'DROM (DOM-TOM)', value: 'DROM' },
];

function REGIONS_ARRAY(): string[] {
  return REGION_LIST.map((region) => region.value);
}

function Pin(props: any) {
  const { size = 20 } = props;

  const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

  return (
    <svg
      height={size}
      viewBox="0 0 24 24"
      style={{
        fill: '#d00',
        stroke: 'none',
      }}
    >
      <path d={ICON} />
    </svg>
  );
}
