import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { getColor, mode } from '@chakra-ui/theme-tools';

const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props;
  return {
    focusBorderColor: fc || mode('blue.500', 'blue.300')(props),
    errorBorderColor: ec || mode('red.500', 'red.300')(props),
  };
}

const variantOutline = definePartsStyle((props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      bg: props.colorMode === 'light' ? 'white' : 'gray.800',
      borderWidth: 1,
      borderColor: 'gray.100',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      borderRadius: 7,
      _hover: {
        borderColor: 'gray.300',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: 'none',
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: 'gray.100',
        boxShadow: '0 0 0 3px ' + getColor(theme, 'gray.100'),
      },
      _dark: {
        borderColor: 'gray.800',
        _hover: {
          borderColor: 'whiteAlpha.400',
        },
        _invalid: {
          borderColor: getColor(theme, ec),
        },
        _focusVisible: {
          boxShadow: '0 0 0 3px ' + getColor(theme, 'gray.700'),
        },
      },
    },
    addon: {
      borderColor: 'inherit',
      bg: 'gray.100',
      _dark: {
        borderColor: 'whiteAlpha.50',
        bg: 'whiteAlpha.300',
      },
    },
  };
});

const variantGlass = definePartsStyle((props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      bg: 'rgba(255, 255, 255, 0.07)',
      borderWidth: 1,
      borderColor: 'rgba(255, 255, 255, 0.1)',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
      backdropFilter: 'blur(10px)',
      borderRadius: 7,
      color: 'white',
      _hover: {
        borderColor: 'rgba(255, 255, 255, 0.15)',
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        boxShadow: 'none',
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: 'rgba(255, 255, 255, 0.15)',
        boxShadow: '0 0 0 3px ' + getColor(theme, 'gray.100'),
      },
      _dark: {
        borderColor: 'rgba(255, 255, 255, 0.2)',
        _hover: {
          borderColor: 'whiteAlpha.400',
        },
        _invalid: {
          borderColor: getColor(theme, ec),
        },
        _focusVisible: {
          boxShadow: '0 0 0 3px ' + getColor(theme, 'gray.700'),
        },
      },
    },
    addon: {
      borderColor: 'inherit',
      bg: 'gray.100',
      _dark: {
        borderColor: 'whiteAlpha.50',
        bg: 'whiteAlpha.300',
      },
    },
  };
});

const variantEditable = definePartsStyle((props) => {
  const { theme } = props;
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props);

  return {
    field: {
      bg: 'transparent',
      px: 0,
      py: 0,
      outlineOffset: 0,
      letterSpacing: '1px',
      ml: -2,
      my: 1,
      _hover: {
        borderColor: 'transparent',
      },
      _invalid: {
        borderColor: 'transparent',
      },
      _focusVisible: {
        zIndex: 1,
        borderColor: 'transparent',
        boxShadow: 'none',
      },
      _dark: {
        borderColor: 'transparent',
        _hover: {
          borderColor: 'transparent',
        },
        _invalid: {
          borderColor: 'transparent',
        },
        _focusVisible: {
          boxShadow: 'none',
        },
      },
    },
  };
});

const sizes = {
  sm: {
    field: {
      borderRadius: '4px',
      h: 8,
    },
    addon: {
      borderRadius: '4px',
      h: 8,
    },
  },
  md: {
    field: {
      borderRadius: '5px',
      px: 3,
      h: 9,
    },
    addon: {
      borderRadius: '5px',
      px: 3,
      h: 9,
    },
  },
};

const Input = {
  defaultProps: {
    variant: 'outline',
    size: 'md',
    focusBorderColor: 'primary.300',
    fontWeight: '500',
  },
  variants: {
    outline: variantOutline,
    editable: variantEditable,
    glass: variantGlass,
  },
  sizes,
};

export default {
  FormLabel: {
    baseStyle: {
      mb: 1,
      fontSize: 'xs',
    },
    variants: {
      horizontal: {
        mb: 0,
        marginStart: '0.5rem',
      },
    },
  },
  Input,
  NumberInput: Input,
  PinInput: Input,
  Textarea: Input,
  Select: Input,
};
