import React, { ReactElement, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { connect } from 'react-redux';

import { MBProAdminUserAPI } from '@meilleursbiens/api';
import { AuthConstant } from '@meilleursbiens/constants';
import { MBProAffiliationAnnouncementModal } from '@meilleursbiens/pro';
import { MBProUser } from '@meilleursbiens/types';
import { AppShell } from '@meilleursbiens/ui';
import { DevUtils, ToastUtils } from '@meilleursbiens/utils';

import { useDisclosure } from '@chakra-ui/react';

import MBProUsersSignatureUIModal from '../../../../../../libs/pro/src/lib/ui/users/signatures/MBProUsersSignatureUIModal';
import RCPModalComponent from '../../News/RCPModalComponent';
import V2ModalComponent from '../../News/V2ModalComponent';
import UsefullLinksModalComponent from '../../Screens/Pro/Marketplace/UsefullLinksModalComponent';
import RetourMandatModalComponent from '../../Screens/Pro/Transactions/RetourMandatModalComponent';
import ImpersonateComponent from '../../UI/Impersonate/ImpersonateComponent';
import AccountSearchCommandBarComponent from './Search/AccountSearchCommandBarComponent';
import AccountSidebarLayoutComponent from './Sidebar/AccountSidebarLayoutComponent';
import LocalStorageUtils from '../../../utils/LocalStorageUtils';
import { useIsImpersonate } from '@meilleursbiens/hooks';

interface AccountSkeletonLayoutComponentProps {
  children: ReactElement;
  user?: MBProUser;
}

function AccountSkeletonLayoutComponent(props: AccountSkeletonLayoutComponentProps) {
  const [isMobile, setIsMobile] = React.useState(false);

  const { isImpersonating } = useIsImpersonate(props.user);

  const searchDisclosure = useDisclosure();
  const {
    isOpen: isUsefullLinksModalOpen,
    onOpen: onOpenUsefullLinksModal,
    onClose: onCloseUsefullLinksModal,
  } = useDisclosure();
  const {
    isOpen: isRegisterDocumentModalOpen,
    onOpen: onOpenRegisterDocumentModal,
    onClose: onCloseRegisterDocumentModal,
  } = useDisclosure();
  const { isOpen: isRegisterModalOpen, onOpen: onOpenRegisterModal, onClose: onCloseRegisterModal } = useDisclosure();

  useEffect(() => {
    const { user } = props;

    const isImpersonate = isImpersonating;

    if (user.idCard == null && !DevUtils.isDev() && isImpersonate === null) {
      onOpenRegisterDocumentModal();
    } else if (user.V2Modal === 0 && !DevUtils.isDev() && isImpersonate === null) {
      onOpenRegisterModal();
    }
  }, []);

  React.useEffect(() => {
    const down = (e) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        searchDisclosure.onToggle();
      }
    };

    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  useHotkeys('option+shift+t', (e) => {
    e.preventDefault();
    MBProAdminUserAPI.toggleDemo().then((r) => {
      if (r) {
        ToastUtils.showSuccess('Mode démonstration activé');
      } else {
        ToastUtils.showDanger('Mode démonstration désactivé');
      }
    });
  });

  return (
    <>
      <AppShell
        sidebar={
          <AccountSidebarLayoutComponent
            onDeviceChange={(isMobile) => setIsMobile(isMobile)}
            onOpenUsefullLinksModal={onOpenUsefullLinksModal}
            onSearch={searchDisclosure.onToggle}
          />
        }
      >
        <ImpersonateComponent />

        {React.cloneElement(props.children, { isMobile: isMobile })}

        <V2ModalComponent isOpen={isRegisterModalOpen} onClose={onCloseRegisterModal} />

        <UsefullLinksModalComponent isOpen={isUsefullLinksModalOpen} onClose={onCloseUsefullLinksModal} />

        <RetourMandatModalComponent />

        <RCPModalComponent />

        <MBProUsersSignatureUIModal />

        {!isImpersonating && (
          <MBProAffiliationAnnouncementModal />
        )}
      </AppShell>

      <AccountSearchCommandBarComponent disclosure={searchDisclosure} />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, null)(AccountSkeletonLayoutComponent);
