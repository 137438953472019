import * as React from 'react';
import { SVGProps } from 'react';

export default function (props: SVGProps<any>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      fill="none"
      viewBox="0 0 72 74"
      {...props}
    >
      <path
        fill="url(#paint0_linear_0_10658)"
        fillRule="evenodd"
        d="M53 62a2 2 0 1 1 0 4H35q-.177 0-.345-.03a29.86 29.86 0 0 1-17.079-6.292c-.387-.301-.97-.8-1.401-1.177A2.03 2.03 0 0 0 14.844 58H10a2 2 0 1 1 0-4h2a2 2 0 1 0 0-4H7a2 2 0 1 1 0-4h1a2 2 0 1 0 0-4c-.809 0-1.5-.583-1.635-1.38-.049-.29-.088-.523-.094-.57A30 30 0 0 1 6 36C6 19.432 19.432 6 36 6h15a2 2 0 1 1 0 4h-3a2 2 0 1 0 0 4h12a2 2 0 0 1 .002 4H57a2 2 0 1 0 0 4h4.306c.756 0 1.45.426 1.778 1.108.197.41.404.851.54 1.17A29.9 29.9 0 0 1 66 36a30 30 0 0 1-.246 3.857c-.027.212-.069.485-.114.764C65.51 41.42 64.81 42 64 42a2 2 0 1 0 0 4h1a2 2 0 1 1 0 4h-1.284c-.726 0-1.394.394-1.756 1.023-.236.409-.498.853-.693 1.156a30 30 0 0 1-3.08 4.014c-.313.343-.819.851-1.213 1.241a1.97 1.97 0 0 1-1.387.566H50a2 2 0 0 0-.023 4z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#0274F3"
        fillRule="evenodd"
        d="M32.999 12.677a6 6 0 0 1 6 0l15.698 9.063a6 6 0 0 1 3 5.197v18.127a6 6 0 0 1-3 5.196l-15.699 9.063a6 6 0 0 1-6 0L17.3 50.26a6 6 0 0 1-3-5.196V26.937a6 6 0 0 1 3-5.197z"
        clipRule="evenodd"
        opacity="0.1"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <g filter="url(#filter0_d_0_10658)">
        <path
          fill="#2DCD6B"
          fillRule="evenodd"
          d="m29.072 33 13.856 8L26.76 69.004a1 1 0 0 1-1.714.03l-3.921-6.27-7.39-.26a1 1 0 0 1-.831-1.5z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask0_0_10658"
        width="31"
        height="37"
        x="12"
        y="33"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m29.072 33 13.856 8L26.76 69.004a1 1 0 0 1-1.714.03l-3.921-6.27-7.39-.26a1 1 0 0 1-.831-1.5z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_0_10658)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m24.536 48.856 6.928 4-9 15.589-6.928-4z"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter1_d_0_10658)">
        <path
          fill="#2DCD6B"
          fillRule="evenodd"
          d="m42.928 33-13.856 8L45.24 69.004a1 1 0 0 0 1.714.03l3.921-6.27 7.39-.26a1 1 0 0 0 .831-1.5z"
          clipRule="evenodd"
        ></path>
      </g>
      <mask
        id="mask1_0_10658"
        width="31"
        height="37"
        x="29"
        y="33"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m42.928 33-13.856 8L45.24 69.004a1 1 0 0 0 1.714.03l3.921-6.27 7.39-.26a1 1 0 0 0 .831-1.5z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask1_0_10658)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m47.464 48.856-6.928 4 9 15.589 6.928-4z"
          clipRule="evenodd"
        ></path>
      </g>
      <path
        fill="#0274F3"
        fillRule="evenodd"
        d="M34 16.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 59.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V28.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
        opacity="0.2"
        style={{ mixBlendMode: "multiply" }}
      ></path>
      <path
        fill="url(#paint1_linear_0_10658)"
        fillRule="evenodd"
        stroke="#FFF04D"
        strokeOpacity="0.4"
        d="M34 14.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 57.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V26.809a4 4 0 0 1 2-3.464z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask2_0_10658"
        width="42"
        height="46"
        x="15"
        y="13"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          stroke="#fff"
          d="M34 14.155a4 4 0 0 1 4 0l15.919 9.19a4 4 0 0 1 2 3.464v18.382a4 4 0 0 1-2 3.464L38 57.845a4 4 0 0 1-4 0l-15.919-9.19a4 4 0 0 1-2-3.464V26.809a4 4 0 0 1 2-3.464z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g strokeLinecap="round" mask="url(#mask2_0_10658)">
        <path
          stroke="#F9B607"
          d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382a3.5 3.5 0 0 0 1.75 3.03l15.919 9.191a3.5 3.5 0 0 0 3.5 0l15.919-9.19a3.5 3.5 0 0 0 1.75-3.031V26.809a3.5 3.5 0 0 0-1.75-3.03z"
        ></path>
        <path
          stroke="#FBC618"
          d="M55.419 45.19V26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031v18.382"
        ></path>
        <path
          stroke="#FFF24E"
          d="M55.419 26.81a3.5 3.5 0 0 0-1.75-3.032l-15.919-9.19a3.5 3.5 0 0 0-3.5 0l-15.919 9.19a3.5 3.5 0 0 0-1.75 3.031"
          opacity="0.6"
        ></path>
        <path
          stroke="#fff"
          d="M37.75 14.588a3.5 3.5 0 0 0-3.5 0l-7.96 4.595"
          opacity="0.4"
        ></path>
        <path stroke="#fff" d="m34.25 14.588-3.98 2.297"></path>
        <path stroke="#fff" d="m37.75 57.412 3.98-2.297" opacity="0.4"></path>
      </g>
      <g filter="url(#filter2_d_0_10658)">
        <path
          fill="url(#paint2_linear_0_10658)"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
        <path
          stroke="#F5900D"
          strokeOpacity="0.1"
          d="M37.25 20.144a2.5 2.5 0 0 0-2.5 0L22.894 26.99a2.5 2.5 0 0 0-1.25 2.165v13.69a2.5 2.5 0 0 0 1.25 2.165l11.856 6.846a2.5 2.5 0 0 0 2.5 0l11.856-6.846a2.5 2.5 0 0 0 1.25-2.165v-13.69a2.5 2.5 0 0 0-1.25-2.165z"
          style={{ mixBlendMode: "multiply" }}
        ></path>
      </g>
      <mask
        id="mask3_0_10658"
        width="28"
        height="32"
        x="22"
        y="20"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "luminance" }}
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M35 20.577a2 2 0 0 1 2 0l11.856 6.846a2 2 0 0 1 1 1.732v13.69a2 2 0 0 1-1 1.732L37 51.423a2 2 0 0 1-2 0l-11.856-6.846a2 2 0 0 1-1-1.732v-13.69a2 2 0 0 1 1-1.732z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask3_0_10658)">
        <path
          fill="#FFD640"
          fillRule="evenodd"
          d="m36 36 4-16h-8zl-8.485-14.142-5.657 5.657zl-16-4v8zl-14.142 8.485 5.657 5.657zl-4 16h8zl8.485 14.142 5.657-5.657zl16 4v-8zm0 0 14.142-8.485-5.657-5.657z"
          clipRule="evenodd"
          opacity="0.3"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="M48.606 27.856 36.75 21.01a1.5 1.5 0 0 0-1.5 0l-5.928 3.423-5.928 3.423"
          opacity="0.4"
        ></path>
        <path
          stroke="#fff"
          strokeLinecap="round"
          d="m35.25 21.01-2.964 1.712"
        ></path>
      </g>
      <g filter="url(#filter3_d_0_10658)">
        <path
          fill="#fff"
          d="M33.45 32.49 36 28.769l2.55 3.721 4.327 1.276-2.75 3.575.123 4.51L36 40.338l-4.25 1.511.124-4.51-2.751-3.575z"
        ></path>
        <path
          stroke="#fff"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M36.825 28.204a1 1 0 0 0-1.65 0l-2.35 3.427-3.985 1.175a1 1 0 0 0-.51 1.57l2.534 3.293-.114 4.154a1 1 0 0 0 1.335.97L36 41.4l3.915 1.392a1 1 0 0 0 1.335-.97l-.114-4.153 2.534-3.294a1 1 0 0 0-.51-1.569l-3.986-1.175z"
        ></path>
      </g>
      <g filter="url(#filter4_i_0_10658)">
        <path
          fill="url(#paint3_linear_0_10658)"
          d="M33.45 32.49 36 28.769l2.55 3.721 4.327 1.276-2.75 3.575.123 4.51L36 40.338l-4.25 1.511.124-4.51-2.751-3.575z"
        ></path>
      </g>
      <g filter="url(#filter5_d_0_10658)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m45.91 28.78-.518-.205a.618.618 0 0 1 0-1.15l.519-.205a2.32 2.32 0 0 0 1.31-1.31l.204-.518a.618.618 0 0 1 1.15 0l.205.519c.236.599.71 1.073 1.31 1.31l.518.204a.618.618 0 0 1 0 1.15l-.519.205a2.32 2.32 0 0 0-1.31 1.31l-.204.518a.618.618 0 0 1-1.15 0l-.205-.519a2.32 2.32 0 0 0-1.31-1.31"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter6_d_0_10658)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m25.607 44.52-.346-.137a.412.412 0 0 1 0-.766l.346-.137c.4-.157.716-.473.873-.873l.137-.346a.412.412 0 0 1 .766 0l.137.346c.157.4.474.716.873.873l.346.137a.412.412 0 0 1 0 .766l-.346.137c-.4.157-.716.473-.873.873l-.137.346a.412.412 0 0 1-.766 0l-.137-.346a1.55 1.55 0 0 0-.873-.873"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter7_d_0_10658)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m24.304 29.26-.173-.068a.206.206 0 0 1 0-.384l.173-.068a.77.77 0 0 0 .436-.436l.068-.173a.206.206 0 0 1 .384 0l.068.173c.079.2.237.357.436.436l.173.068a.206.206 0 0 1 0 .384l-.173.068a.77.77 0 0 0-.436.436l-.068.173a.206.206 0 0 1-.384 0l-.068-.173a.77.77 0 0 0-.436-.436"
          clipRule="evenodd"
        ></path>
      </g>
      <g filter="url(#filter8_d_0_10658)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m45.456 45.89-.26-.102a.31.31 0 0 1 0-.576l.26-.102c.299-.118.536-.355.654-.654l.102-.26a.31.31 0 0 1 .576 0l.102.26c.118.299.355.536.655.654l.26.102a.31.31 0 0 1 0 .576l-.26.102c-.3.118-.537.355-.655.654l-.102.26a.31.31 0 0 1-.575 0l-.103-.26a1.16 1.16 0 0 0-.654-.654"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d_0_10658"
          width="34.16"
          height="40.504"
          x="10.768"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.333333 0 0 0 0 0.615373 0 0 0 0 0.819608 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10658"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10658"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_0_10658"
          width="34.16"
          height="40.504"
          x="27.072"
          y="33"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.333333 0 0 0 0 0.615373 0 0 0 0 0.819608 0 0 0 0.2 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            mode="darken"
            result="effect1_dropShadow_0_10658"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10658"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d_0_10658"
          width="33.713"
          height="37.381"
          x="19.144"
          y="18.309"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.927721 0 0 0 0 0.665867 0 0 0 0 0.0535899 0 0 0 0.4 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10658"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10658"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_d_0_10658"
          width="21.754"
          height="21.081"
          x="25.123"
          y="25.769"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.996078 0 0 0 0 0.733333 0 0 0 0 0.105882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10658"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10658"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_i_0_10658"
          width="13.754"
          height="14.081"
          x="29.123"
          y="28.769"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1"></feOffset>
          <feGaussianBlur stdDeviation="0.5"></feGaussianBlur>
          <feComposite
            in2="hardAlpha"
            k2="-1"
            k3="1"
            operator="arithmetic"
          ></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.634454 0 0 0 0 0 0 0 0 0.4 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_0_10658"></feBlend>
        </filter>
        <filter
          id="filter5_d_0_10658"
          width="10"
          height="10"
          x="43"
          y="23"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10658"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10658"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter6_d_0_10658"
          width="8"
          height="8"
          x="23"
          y="40"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10658"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10658"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter7_d_0_10658"
          width="6"
          height="6"
          x="22"
          y="26"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10658"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10658"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter8_d_0_10658"
          width="7"
          height="7"
          x="43"
          y="42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 0.94902 0 0 0 0 0.305882 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_10658"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_0_10658"
            result="shape"
          ></feBlend>
        </filter>
        <linearGradient
          id="paint0_linear_0_10658"
          x1="5"
          x2="5"
          y1="6"
          y2="66"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#90B3FF"></stop>
          <stop offset="1" stopColor="#06F"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_10658"
          x1="13"
          x2="13"
          y1="13"
          y2="59"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDE046"></stop>
          <stop offset="1" stopColor="#F9B800"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_0_10658"
          x1="20"
          x2="20"
          y1="20"
          y2="52"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF550"></stop>
          <stop offset="1" stopColor="#FFD541"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_0_10658"
          x1="28.769"
          x2="28.769"
          y1="28.769"
          y2="43.231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC823"></stop>
          <stop offset="1" stopColor="#FCA50E"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
